<template>
  <EditarBase
    nomeSingular="FuncionarioCargo"
    nomePlural="FuncionarioCargos"
    @recurso="recurso = $event"
    @submitSuccess="emitSubmitSuccess"
    @submitError="emitSubmitError"
    @closeModalEdit="closeModalEdit"
  >
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-span class="opacity-5 text-dark">
            {{ $t("Descricao") }}
          </v-span>
          <v-text-field
            v-model="recurso.descricao"
            :label="$t('Descricao')"
            name="descricao"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-span class="opacity-5 text-dark">
            {{ $t("CBO_extenso") }}
          </v-span>
          <v-text-field
            v-model="recurso.cbo"
            :label="$t('CBO_extenso')"
            name="cbo"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-span class="opacity-5 text-dark">
            {{ $t("Status") }}
          </v-span>
          <v-select
            v-model="recurso.statusComum"
            :items="itensStatusComum"
            :label="$t('Status')"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field-money
            v-model="recurso.salarioBase"
            v-bind:label="$t('Salario_base')"
            v-bind:properties="{
              prefix: 'R$',
              readonly: false,
              disabled: false,
              outlined: false,
              clearable: true,
              rules: required,
            }"
            v-bind:options="{
              locale: 'pt-BR',
              length: 12,
              precision: 2,
              empty: '0',
            }"
            class="font-size-input text-light-input placeholder-light"
            dense
            flat
            filled
            solo
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-checkbox v-model="recurso.cargoConfianca">
            <template v-slot:label>
              {{ $t("cargoConfianca") }}
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" md="3" v-if="possuiTransporteValores()">
          <v-checkbox v-model="recurso.transporteValoresChefeEquipe">
            <template v-slot:label>
              {{ $t("transporteValoresChefeEquipe") }}
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="3" v-if="possuiTransporteValores()">
          <v-checkbox v-model="recurso.transporteValoresMotorista">
            <template v-slot:label>
              {{ $t("transporteValoresMotorista") }}
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="3" v-if="possuiTransporteValores()">
          <v-checkbox v-model="recurso.transporteValoresEscolta">
            <template v-slot:label>
              {{ $t("transporteValoresEscolta") }}
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-col>
  </EditarBase>
</template>

<script>
import EditarBase from "@/components/base/Crud/EditarBase";

import FuncionarioCargoDataService from "@/services/pessoal/FuncionarioCargoDataService";
const DataService = FuncionarioCargoDataService;

import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "R$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "EditarFuncionarioCargo",
  components: { EditarBase },
  props: {
    recurso: Object,
  },
  data() {
    return {
      DataService: DataService,
      submited: false,
      mask: currencyMask,

      recurso: {
        id: null,
        descricao: "",
        statusComum: "",
        salarioBase: "",
      },
      salBase: "12",
      errors: [],

      itensStatusComum: [
        { text: this.$t("Ativo"), value: "ATIVO" },
        { text: this.$t("Inativo"), value: "INATIVO" },
      ],
    };
  },

  computed: {
    salarioBaseSemMascara: function () {
      let str = this.recurso.salarioBase;

      str = str.toString();
      str = str.replace("$", "");
      str = str.replace(/\,/gi, "");
      str = str.replace(/([A-z])/, "");

      console.log(str);
      return str;
    },
  },

  mounted() {
    //this.getRecurso();
  },

  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccess");
    },
    emitSubmitError() {
      this.$emit("submitError");
    },
    closeModalEdit() {
      this.$emit("closeModalEdit");
    },
    submitForm(event) {
      DataService.update({
        descricao: this.recurso.descricao,
        statusComum: this.recurso.statusComum,
        salarioBase: this.salarioBaseSemMascara,
        id: this.recurso.id,
      })
        .then((response) => {
          this.erros = [];
          this.submited = true;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          this.errors = error.response.data.errors;
        });
    },

    getRecurso() {
      DataService.find(this.recurso.id)
        .then((response) => {
          this.recurso.id = response.data.id;
          this.recurso.salarioBase = response.data.salarioBase;
          this.recurso.descricao = response.data.descricao;
          this.recurso.statusComum = response.data.statusComum;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
          this.errors.push({ field: "ID", message: "Usuário não encontrado" });
        });
    },

    mascaraMonetaria(val) {
      val = this.$t("R$") + " " + val;
    },

    possuiTransporteValores() {
      try {
        let param = this.$store.state.inquilino.modulosContratacao.find(
          (item) => {
            return item.tipo == "TRANSPORTEVALORES";
          }
        );
        if (param != undefined && param.id != undefined) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },
  },
};
</script>

<style scoped>
</style>
