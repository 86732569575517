import Axios from "axios";

class FuncionarioSindicatoDataService{

  getAll(){
    return Axios.get('FuncionarioSindicatos');
  }

  getPage(params){
    return Axios.get('FuncionarioSindicatos/page', {params})
  }

  create(params){
    return Axios.post('FuncionarioSindicatos', params)
  }

  update(params){
    return Axios.put('FuncionarioSindicatos/' + (params.id), params)
  }

  find(id){
    return Axios.get(`FuncionarioSindicatos/${id}`)
  }

  delete(id){
    return Axios.delete(`FuncionarioSindicatos/${id}`);
  }
}

export default new FuncionarioSindicatoDataService()
