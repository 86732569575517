<template>
  <CadastroBase
    nomePlural="PedidoConsumiveis"
    nomeSingular="PedidoConsumivel"
    @submitSuccessInsert="emitSubmitSuccess"
    @submitErrorInsert="emitSubmitError"
    @closeModalInsert="closeModal"
  >
    <v-dialog
      v-model="formRemoveProduto"
      persistent
      max-width="350"
      class="dialog-deletado"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t("Confirma_exclusao?") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            height="39"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            text
            @click="formRemoveProduto = false"
          >
            {{ $t("nao") }}
          </v-btn>

          <v-btn
            class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
            height="39"
            text
            @click="removeProduto()"
          >
            {{ $t("Confirmar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="formAddProduto"
      persistent
      max-width="350"
      name="dlgAddProduto"
    >
      <v-card>
        <v-form @submit.prevent="addProduto">
          <v-card-title class="headline">
            {{ $t("Adicionar_produto") }} <br />
            <v-btn
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >
              {{ this.produto.descricao }}
            </v-btn>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  type="number"
                  :label="$t('Quantidade')"
                  v-model="quantidadeSolicitada"
                  min="1"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                />
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field-money
                  v-model="valorUnitario"
                  v-bind:label="$t('valorUnitario')"
                  v-bind:properties="{
                    prefix: 'R$',
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    clearable: true,
                    rules: required,
                  }"
                  v-bind:options="{
                    locale: 'pt-BR',
                    length: 12,
                    precision: 2,
                    empty: '0',
                  }"
                  class="font-size-input text-light-input placeholder-light"
                  dense
                  flat
                  filled
                  solo
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              @click="limpaFormProduto"
            >
              {{ $t("Cancelar") }}
            </v-btn>

            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              @click.prevent="addProduto"
            >
              {{ $t("Adicionar") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-col cols="12">
      <v-card>
        <v-row>
          <v-col cols="2" md="2" class="d-flex">
            <CampoData
              @date="dataPedido = $event"
              :label="$t('Data')"
              id="dataPedido"
            />
          </v-col>

          <v-col cols="4" md="4">
            <v-autocomplete
              id="selecionaEscopo"
              outlined
              v-model="centroCusto"
              :items="centroCustos"
              :item-text="(item) => item.descricao"
              :label="$t('Centro_custo')"
              return-object
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="4"
            md="4"
            v-if="
              this.$store.state.inquilino.parametrizacoes.find(
                (item) => item.tipo == 'MULTIPLOSCENTROSESTOQUE'
              )
            "
          >
            <v-autocomplete
              id="centroEstoque"
              outlined
              v-model="centroEstoque"
              :items="centrosEstoque"
              :item-text="(item) => item.descricao"
              :label="$t('CentroEstoque')"
              return-object
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
            >
            </v-autocomplete>
          </v-col>

          <v-col
            cols="2"
            md="2"
            v-if="
              this.$store.state.inquilino.parametrizacoes.find(
                (item) => item.tipo == 'MULTIPLOSCENTROSESTOQUE'
              )
            "
          >
            <v-checkbox
              v-model="reabastecimentoCentrosEstoque"
              class="font-size-input text-light-input placeholder-light"
              dense
              flat
              filled
              solo
            >
              <template v-slot:label>
                {{ $t("reabastecimentoCentrosEstoque") }}
              </template>
            </v-checkbox>
          </v-col>

          <v-col cols="2" md="2">
            <v-checkbox
              v-model="gerarDocumentoAcessorio"
              class="font-size-input text-light-input placeholder-light"
              dense
              flat
              filled
              solo
            >
              <template v-slot:label>
                {{ $t("gerarDocumentoAcessorio") }}
              </template>
            </v-checkbox>
          </v-col>

          <v-col cols="12" md="3" v-if="gerarDocumentoAcessorio">
            <v-autocomplete
              id="selecionaCliente"
              outlined
              v-model="cliente"
              :items="clientes"
              item-text="fantasia"
              :label="$t('Cliente')"
              return-object
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" v-if="gerarDocumentoAcessorio">
            <v-autocomplete
              id="selecionaParametrizacao"
              outlined
              v-model="parametrizacaoFiscal"
              :items="parametrizacoesFiscais"
              :item-text="(item) => item.descricao"
              :label="$t('Parametrizacao_fiscal')"
              return-object
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="3"
            v-if="
              gerarDocumentoAcessorio &&
              parametrizacaoFiscal !== null &&
              !parametrizacaoFiscal.simplesRemessa
            "
          >
            <v-autocomplete
              id="selecionaMetodoCobranca"
              outlined
              v-model="metodoCobranca"
              :items="metodosCobranca"
              :item-text="(item) => item.descricao"
              :label="$t('MetodoCobranca')"
              return-object
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
            >
            </v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            md="3"
            v-if="
              gerarDocumentoAcessorio &&
              parametrizacaoFiscal !== null &&
              !parametrizacaoFiscal.simplesRemessa
            "
          >
            <v-autocomplete
              outlined
              :items="[
                {
                  text: $t('DIA05MESCORRENTE'),
                  value: 'DIA05MESCORRENTE',
                },

                {
                  text: $t('DIA10MESCORRENTE'),
                  value: 'DIA10MESCORRENTE',
                },
                {
                  text: $t('DIA12MESCORRENTE'),
                  value: 'DIA12MESCORRENTE',
                },
                {
                  text: $t('DIA15MESCORRENTE'),
                  value: 'DIA15MESCORRENTE',
                },
                {
                  text: this.$t('DIA25MESCORRENTE'),
                  value: 'DIA25MESCORRENTE',
                },
                {
                  text: $t('DIA17MESCORRENTE'),
                  value: 'DIA17MESCORRENTE',
                },
                {
                  text: $t('DIA20MESCORRENTE'),
                  value: 'DIA20MESCORRENTE',
                },
                {
                  text: $t('ULTIMODIAMESCORRENTE'),
                  value: 'ULTIMODIAMESCORRENTE',
                },
                {
                  text: $t('DIA01MESSUBSEQUENTE'),
                  value: 'DIA01MESSUBSEQUENTE',
                },
                {
                  text: $t('DIA03MESSUBSEQUENTE'),
                  value: 'DIA03MESSUBSEQUENTE',
                },
                {
                  text: $t('DIA05MESSUBSEQUENTE'),
                  value: 'DIA05MESSUBSEQUENTE',
                },
                {
                  text: $t('DIA10MESSUBSEQUENTE'),
                  value: 'DIA10MESSUBSEQUENTE',
                },
                {
                  text: $t('DIA12MESSUBSEQUENTE'),
                  value: 'DIA12MESSUBSEQUENTE',
                },
                {
                  text: $t('DIA15MESSUBSEQUENTE'),
                  value: 'DIA15MESSUBSEQUENTE',
                },
                {
                  text: $t('DIA17MESSUBSEQUENTE'),
                  value: 'DIA17MESSUBSEQUENTE',
                },
                {
                  text: $t('DIA20MESSUBSEQUENTE'),
                  value: 'DIA20MESSUBSEQUENTE',
                },
                {
                  text: $t('DIA25MESSUBSEQUENTE'),
                  value: 'DIA25MESSUBSEQUENTE',
                },
                {
                  text: $t('ULTIMODIAMESSUBSEQUENTE'),
                  value: 'ULTIMODIAMESSUBSEQUENTE',
                },
                {
                  text: $t('TRESVEZESDIAS051020MESSUBSEQUENTE'),
                  value: 'TRESVEZESDIAS051020MESSUBSEQUENTE',
                },
                {
                  text: $t('TRESVEZESDIAS031119MESSUBSEQUENTE'),
                  value: 'TRESVEZESDIAS031119MESSUBSEQUENTE',
                },

                {
                  text: $t('TRINTADIASAPOSEMISSAO'),
                  value: 'TRINTADIASAPOSEMISSAO',
                },
                {
                  text: $t('TRINTAEUMDIASAPOSEMISSAO'),
                  value: 'TRINTAEUMDIASAPOSEMISSAO',
                },
                {
                  text: $t('TRINTAECINCODIASAPOSEMISSAO'),
                  value: 'TRINTAECINCODIASAPOSEMISSAO',
                },
                {
                  text: $t('QUARENTADIASAPOSEMISSAO'),
                  value: 'QUARENTADIASAPOSEMISSAO',
                },
                {
                  text: $t('DEZDIASAPOSEMISSAO'),
                  value: 'DEZDIASAPOSEMISSAO',
                },
              ]"
              :label="$t('CondicaoPagamento')"
              v-model="condicaoPagamento"
              name="descricao"
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12" v-if="gerarDocumentoAcessorio">
            <v-textarea
              v-model="escopoDocumento"
              :label="$t('Informacoes_adicionais')"
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
            >
            </v-textarea>
          </v-col>
          <v-col cols="12">
            <v-text-field
              id="observacao"
              v-model="observacao"
              :label="$t('Observacao')"
              name="observacao"
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
            >
            </v-text-field>
          </v-col>
          <!-- <v-col cols="6" md="6" class="d-flex">
            <CampoData
              @date="dataRetorno = $event"
              :label="$t('Previsao_retorno')"
            />
          </v-col> -->

          <v-col cols="12" md="12">
            <div class="card-header-padding">
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  {{ $t("Listagem_de_produtos") }}
                </h5>
                <p class="text-sm text-body mb-0">
                  {{ $t("Clique_para_adicionar") }}
                </p>
              </div>
            </div>

            <v-col cols="12" md="5">
              <v-text-field
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
                height="39"
                v-model="search"
                :label="$t('Procurar')"
                name="searchProdutos"
                ref="searchProdutos"
              >
                <template slot="prepend-inner">
                  <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="font-weight-bold text-h6 text-typo mb-0">
                <p id="saldo">
                  Subtotal:
                  {{ subTotalValor | formatoMonetario }}
                </p>
              </div>
            </v-col>
            <v-data-table
              id="produto"
              :headers="headers"
              :items="produtos"
              :search="search"
              @click:row="handleClick"
              class="table"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="editCliente(item.id)"
                  >ni-ruler-pencil</v-icon
                >
                <v-icon small @click="confirmaExclusao(item)"
                  >ni-fat-remove</v-icon
                >
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12" md="12">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                {{ $t("Resumo_do_pedido") }}
              </h5>
            </div>

            <v-col cols="12" md="5">
              <v-text-field
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
                height="39"
                v-model="searchPedido"
                :label="$t('Procurar')"
                name="searchProdutosPedido"
              >
                <template slot="prepend-inner">
                  <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="font-weight-bold text-h6 text-typo mb-0">
                <p id="saldo">
                  Subtotal:
                  {{ subTotalValor | formatoMonetario }}
                </p>
              </div>
            </v-col>
            <v-data-table
              :headers="headersPedido"
              :items="pedido"
              :search="searchPedido"
              class="table"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="confirmaRemoveProduto(item)"
                  >ni-fat-remove</v-icon
                >
              </template>
              <template v-slot:[`item.valorUnitario`]="{ item }">
                <span>{{ item.valorUnitario | formatoMonetario }}</span>
              </template>
            </v-data-table>
            <v-card-actions> </v-card-actions>
          </v-col>

          <v-col cols="12"> </v-col>
        </v-row>
      </v-card>
    </v-col>
  </CadastroBase>
</template>

<script>
import CadastroBase from "@/components/base/Crud/CadastroBase";
import TabelaSelecionaRecurso from "@/components/base/proprios/TabelaSelecionaRecurso";
import ComumDataService from "@/services/ComumDataService";
import DataService from "@/services/recursos/PedidoConsumivelDataService";
export default {
  name: "CadastroPedido",
  components: { CadastroBase, TabelaSelecionaRecurso, ComumDataService },
  data() {
    return {
      DataService: DataService,
      DeveLimparForm: true,
      submited: false,
      dataPedido: "",
      dataDevolucao: "",
      descricao: "",
      observacao: "",
      centroCustos: [],
      centroCusto: {},
      item: {},
      items: [],
      gerarDocumentoAcessorio: false,
      cliente: {},
      clientes: [],
      parametrizacoesFiscais: [],
      parametrizacaoFiscal: {},
      escopoDocumento: "",
      pedido: [],
      produtos: [],
      search: "",
      searchPedido: "",
      errors: [],
      produto: {},
      formAddProduto: false,
      formRemoveProduto: false,
      quantidadeSolicitada: 0,
      valorUnitario: 0.0,
      subTotalValor: 0,
      metodosCobranca: [],
      metodoCobranca: {},
      condicaoPagamento: "",
      centrosEstoque: [],
      reabastecimentoCentrosEstoque: false,
      multiplosCentroEstoque: false,
      headersPedido: [
        {
          text: this.$t("Descricao"),
          value: "produto.descricao",
          align: "start",
          sortable: "false",
        },
        {
          text: this.$t("quantidadeSolicitada"),
          value: "quantidadeSolicitada",
          sortable: "false",
        },
        {
          text: this.$t("valorUnitario"),
          value: "valorUnitario",
          sortable: "false",
        },
        {
          text: this.$t("cbarras"),
          value: "produto.cbarras",
          sortable: "true",
        },
        { text: this.$t("Acoes"), value: "actions", sortable: "false" },
      ],
    };
  },
  computed: {
    headers() {
      if (this.multiplosCentroEstoque) {
        return [
          {
            text: this.$t("Descricao"),
            value: "descricao",
            align: "start",
            sortable: "false",
          },
          { text: this.$t("cbarras"), value: "cbarras", sortable: "true" },
        ];
      } else {
        return [
          {
            text: this.$t("Descricao"),
            value: "descricao",
            align: "start",
            sortable: "false",
          },
          { text: this.$t("cbarras"), value: "cbarras", sortable: "true" },
          {
            text: this.$t("Estoque"),
            value: "estoques[0].estoque",
            sortable: "true",
          },
        ];
      }
    },
    recurso: function () {
      return {
        dataPedido: this.dataPedido,
        dataRetorno: this.dataRetorno,
        observacao: this.observacao,
        descricao: this.descricao,
        items: this.pedido,
        centroCusto: this.centroCusto,
        escopoDocumento: this.escopoDocumento,
        cliente: this.cliente,
        gerarDocumentoAcessorio: this.gerarDocumentoAcessorio,
        parametrizacaoFiscal: this.parametrizacaoFiscal,
        metodoCobranca: this.metodoCobranca,
        condicaoPagamento: this.condicaoPagamento,
        centroEstoque: this.centroEstoque,
        reabastecimentoCentrosEstoque: this.reabastecimentoCentrosEstoque,
      };
    },
  },
  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccessInsert");
    },
    emitSubmitError() {
      this.$emit("submitErrorInsert");
    },
    closeModal() {
      this.$emit("closeModalInsert");
    },
    limpaForm() {
      this.pedido = [];
      this.dataPedido = "";
      this.dataDevolucao = "";
      this.observacao = "";
      this.descricao = "";
      this.cliente = {};
      this.parametrizacaoFiscal = {};
      this.gerarDocumentoAcessorio = false;
      this.escopoDocumento = "";
    },

    getProdutos() {
      DataService.getProdutos().then((response) => {
        this.produtos = response.data;
      });
    },

    getCentroCustos() {
      ComumDataService.get("centroCustos/tipo/4").then((res) => {
        this.centroCustos = res.data;
      });
    },

    getClientes() {
      ComumDataService.get("clientes").then((res) => {
        this.clientes = res.data;
      });
    },

    getParametrizacoesFiscais() {
      ComumDataService.get("parametrizacaofiscal").then((res) => {
        this.parametrizacoesFiscais = res.data;
      });
    },

    handleClick(event) {
      this.produto = event;
      this.formAddProduto = true;
    },
    addProduto() {
      var produtoExistente = this.pedido.find(
        (produto) => produto.produto.id == this.produto.id
      );
      if (produtoExistente) {
        this.quantidadeSolicitada =
          parseInt(this.quantidadeSolicitada) +
          parseInt(produtoExistente.quantidadeSolicitada);
        this.valorUnitario = parseFloat(this.valorUnitario);
        this.pedido = this.pedido.filter((produto) => {
          return produto.produto.id !== produtoExistente.produto.id;
        });
      }
      this.pedido.push({
        produto: this.produto,
        quantidadeSolicitada: this.quantidadeSolicitada,
        valorUnitario: this.valorUnitario,
      });
      this.produto = {};
      this.quantidadeSolicitada = 0;
      this.valorUnitario = 0.0;
      this.search = "";
      this.formAddProduto = false;

      console.log("searchProdutos", this.$refs.searchProdutos);
      this.$refs.searchProdutos.focus();
    },
    limpaFormProduto() {
      this.formAddProduto = false;
      this.quantidadeSolicitada = 0;
      this.valorUnitario = 0.0;
    },
    confirmaRemoveProduto(item) {
      this.produto = item.produto;
      this.formRemoveProduto = true;
    },
    removeProduto() {
      this.pedido = this.pedido.filter((produto) => {
        return produto.produto.id !== this.produto.id;
      });
      this.formRemoveProduto = false;
      this.$swal.fire(this.$t("Removido_com_sucesso"), "", "success");
    },
    calcularSubtotal() {
      console.log("calcularSubtotal", this.recursosFinais);

      this.subTotalValor = this.recurso.items.reduce((total, item) => {
        let valorTitulo =
          (item.valorUnitario !== undefined
            ? parseFloat(item.valorUnitario)
            : 0) *
          (item.quantidadeSolicitada !== undefined
            ? parseFloat(item.quantidadeSolicitada)
            : 0);

        return total + valorTitulo;
      }, 0);
      this.subTotalValor = this.subTotalValor.toFixed(6);
    },

    getMetodosCobranca() {
      ComumDataService.get("metodoCobrancas").then((res) => {
        this.metodosCobranca = res.data;
      });
    },
    getCentrosEstoque() {
      ComumDataService.get("centroEstoques").then((res) => {
        this.centrosEstoque = res.data;
      });
    },
  },
  mounted() {
    this.getProdutos();
    this.getCentroCustos();
    this.getClientes();
    this.getParametrizacoesFiscais();
    this.gerarDocumentoAcessorio = false;
    this.metodoCobranca = null;
    this.condicaoPagamento = null;
    this.calcularSubtotal();
    this.getMetodosCobranca();

    if (
      this.$store.state.inquilino.parametrizacoes.find(
        (item) => item.tipo == "MULTIPLOSCENTROSESTOQUE"
      )
    ) {
      this.multiplosCentroEstoque = true;
      this.getCentrosEstoque();
    }
  },
};
</script>
<style scoped>
#data {
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 99px;
}
.mdl-textfield:not(.is-dirty) input::-webkit-datetime-edit {
  color: transparent;
}
input:focus::-webkit-datetime-edit {
  color: rgba(255, 255, 255, 0.46) !important;
}
</style>
