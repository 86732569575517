<template>
  <ListagemBase
    nomePlural="Inquilinos"
    nomeSingular="Inquilino"
    :headers="headers"
  >
    <!-- <template v-slot:upload>

     <ModalImagem :modalImagem="modalImagem" @modalImagem="modalImagem = $event">
        <v-btn
        class="ma-2"
        color="secondary"
        @click="modalImagem = true"
      >
        {{$t('Enviar_imagem')}}
        <v-icon left dark> mdi-plus-circle </v-icon>
      </v-btn>
         </ModalImagem>

  </template> -->
  </ListagemBase>
</template>

<script>
import InquilinoDataService from "@/services/software/InquilinoDataService";
const DataService = InquilinoDataService;

import ListagemBase from "@/components/base/Crud/ListagemBase";
import ModalImagem from "@/components/base/proprios/ModalImagem";

export default {
  name: "ListagemInquilinos",
  components: { ListagemBase, ModalImagem },

  data() {
    return {
      DataService: DataService,
      modalImagem: false,

      headers: [
        { text: this.$t("Id"), value: "id", sortable: "false" },
        { text: this.$t("Fantasia"), value: "fantasia", sortable: "false" },
        // { text: this.$t("RazaoSocial"), value: "razaoSocial", sortable: "false" },
        { text: this.$t("Status"), value: "statusComum", sortable: "false" },

        { text: this.$t("Tipo"), value: "tipoCliente", sortable: "false" },
        { text: this.$t("nRegistro"), value: "nRegistro", sortable: "false" },
        {
          text: this.$t("Tipo_contratacao"),
          value: "tipoContratacao",
          sortable: "false",
        },
        // { text: this.$t("Data_de_alteracao"), value: "dtAlteracao", sortable: "false" },
        {
          text: this.$t("Data_de_inclusao"),
          value: "dtInclusao",
          sortable: "false",
        },

        { text: this.$t("Acoes"), value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style>
</style>
