<template>
  <v-overlay :value="loading" :z-index="99999">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "LoadingOverlay",
  props: {
    loading: { type: Boolean, required: true, default: false },
  },
};
</script>

<style>
</style>