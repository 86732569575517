<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="700">
      <v-card>
        <div class="d-flex flex-column">
          <div class="ma-2">
            <span class="font-weight-bold text-h5 text-typo mb-0">{{
              $t("Detalhes_abaixo")
            }}</span>
          </div>
        </div>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="errors"
            item-class="danger"
            hide-default-footer
            class="table"
          >
            <template v-slot:[`item.message`]="{ item }">
              {{ $t(item.message) }}
            </template>
            <template v-slot:[`item.fieldName`]="{ item }">
              {{ $t(item.fieldName) }}
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="
              ma-1
              font-weight-bold
              text-none
              btn-primary
              bg-gradient-primary
              py-3
              px-6
              ms-3
            "
            height="39"
            text
            @click="closeDialog()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ReturnDetail",
  props: ["errors", "title"],

  data() {
    return {
      headers: [
        { text: this.$t("Item"), value: "fieldName", sortable: "false" },
        {
          text: this.$t("Descricao"),
          value: "message",
          sortable: "false",
        },
      ],
      dialog: false,
    };
  },

  methods: {
    closeDialog() {
      this.dialog = false;

      this.checkPendingTutorialError();
    },
  },

  watch: {
    errors: function (errors) {
      if (errors)
        if (errors.length > 0) {
          this.dialog = true;
        }
    },
  },

  computed: {
    messages: function () {
      var string = "";

      this.errors.forEach((error) => {
        string = string.concat(
          "<b>" + error.fieldName + "</b> - " + error.message + "<br>"
        );
      });

      return string;
    },
  },
};
</script>

<style>
</style>