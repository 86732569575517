<template>
  <EditarBase
    nomeSingular="Usuario"
    nomePlural="Usuarios"
    :submitPersonalizado="true"
    @recurso="recurso = $event"
    @submitSuccess="emitSubmitSuccess"
    @submitError="emitSubmitError"
    @closeModalEdit="closeModalEdit"
  >
    <ErrorAlert :errors="errors" />
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-span class="opacity-5 text-dark">
            {{ $t("Email_ou_cpf") }}
          </v-span>
          <v-text-field
            :label="$t('Email_ou_cpf')"
            v-model="recurso.email"
            disabled
            v-mask="maskEmailCpf(recurso.email)"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          />
        </v-col>
        <v-col cols="12">
          <v-span class="opacity-5 text-dark">
            {{ $t("Perfis_do_usuario") }}
          </v-span>
          <v-combobox
            v-model="recurso.perfis"
            :items="todosPerfis"
            item-text="descricao"
            :label="$t('Perfis_do_usuario')"
            multiple
            chips
            deletable-chips
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-combobox>
        </v-col>

        <v-col cols="12">
          <v-span class="opacity-5 text-dark">
            {{ $t("Inquilinos") }}
          </v-span>
          <v-combobox
            v-model="recurso.inquilinos"
            :items="itensInquilinos"
            item-text="inquilino.fantasia"
            :label="$t('Inquilinos')"
            multiple
            return-object
            chips
            deletable-chips
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-combobox>
        </v-col>

        <v-col cols="12" md="6">
          <v-span class="opacity-5 text-dark">
            {{ $t("Status") }}
          </v-span>
          <v-select
            v-model="recurso.statusComum"
            :items="statusComumItems"
            item-text="text"
            item-value="value"
            label="Status comum"
            single-line
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-checkbox
            v-model="recurso.fullLog"
            class="font-size-input text-light-input placeholder-light"
            dense
            flat
            filled
            solo
          >
            <template v-slot:label>
              <p>{{ $t("Log_detalhado") }}</p>
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" md="6">
          <v-span class="opacity-5 text-dark">
            {{ $t("senha") }}
          </v-span>
          <v-text-field
            v-model="recurso.senha"
            :label="$t('senha')"
            name="senha"
            type="password"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-span class="opacity-5 text-dark">
            {{ $t("confirme_a_senha") }}
          </v-span>
          <v-text-field
            v-model="senhaConfirma"
            :label="$t('confirme_a_senha')"
            name="senhaConfirma"
            type="password"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          />
        </v-col>

        <v-col cols="12" md="6" v-if="deveMostrarTetoOrcamento()">
          <v-text-field-money
            v-model="recurso.comprasTetoAprovacaoOrcamento"
            v-bind:label="$t('Compras_teto_aprovacao_orcamento')"
            v-bind:properties="{
              prefix: 'R$',
              readonly: false,
              disabled: false,
              outlined: false,
              clearable: true,
            }"
            v-bind:options="{
              locale: 'pt-BR',
              length: 12,
              precision: 2,
              empty: '0',
            }"
            class="font-size-input text-light-input placeholder-light"
            dense
            flat
            filled
            solo
          />
        </v-col>
        <v-col
          cols="4"
          md="4"
          v-if="
            this.$store.state.inquilino.parametrizacoes.find(
              (item) => item.tipo == 'MULTIPLOSCENTROSESTOQUE'
            )
          "
        >
          <v-span class="opacity-5 text-dark">
            {{ $t("CentroEstoque") }}
          </v-span>
          <v-autocomplete
            id="centroEstoque"
            outlined
            v-model="recurso.centroEstoque"
            :items="centrosEstoque"
            :item-text="(item) => item.descricao"
            :label="$t('CentroEstoque')"
            return-object
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-col>
  </EditarBase>
</template>

<script>
import EditarBase from "@/components/base/Crud/EditarBase";
import UsuarioDataService from "@/services/acessos/UsuarioDataService";
import ComumDataService from "@/services/ComumDataService";
const DataService = UsuarioDataService;

export default {
  name: "EditarUsuario",
  components: { EditarBase, ComumDataService },
  props: {
    recurso: Object,
  },
  data() {
    return {
      DataService: DataService,
      itensInquilinos: [],
      senhaConfirma: "",
      errors: [],
      recurso: {
        email: "",
        statusComum: null,
        perfis: [],
      },
      centrosEstoque: [],
      todosPerfis: [],
      statusComumItems: [
        { text: "Ativo", value: "ATIVO" },
        { text: "Inativo", value: "INATIVO" },
      ],
    };
  },
  computed: {},
  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccess");
    },
    emitSubmitError() {
      this.$emit("submitError");
    },
    closeModalEdit() {
      this.$emit("closeModalEdit");
    },
    submitForm(event) {
      return new Promise((resolve, reject) => {
        if (!this.senhaCoincide()) {
          reject();
          return;
        }

        DataService.update({
          id: this.recurso.id,
          email: this.recurso.email,
          imagem: this.recurso.imagem,
          imagem64: this.recurso.imagem64,
          perfis: this.recurso.perfis,
          inquilinos: this.recurso.inquilinos,
          fullLog: this.recurso.fullLog,
          senha: this.recurso.senha,
          statusComum: this.recurso.statusComum,
          comprasTetoAprovacaoOrcamento:
            this.recurso.comprasTetoAprovacaoOrcamento,
          centroEstoque: this.recurso.centroEstoque,
        })
          .then((response) => {
            console.log("entrou no .then");
            this.errors = [];
            this.submited = true;
            resolve("");
          })
          .catch((error) => {
            reject();
            console.log(error);
            this.errors = error.response.data.errors;
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          });
      });
    },

    getRecurso() {
      DataService.find(this.recurso.id)
        .then((response) => {
          this.recurso = response.data;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
          this.errors.push({ field: "ID", message: "Usuário não encontrado" });
        });
    },

    findPerfis() {
      DataService.findPerfis()
        .then((response) => {
          this.todosPerfis = response.data;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
        });
    },

    senhaCoincide() {
      if (
        this.recurso.senha !== this.senhaConfirma &&
        this.recurso.senha !== null &&
        this.recurso.senha?.length > 0
      ) {
        this.errors = [
          { field: this.$t("senha"), message: this.$t("senhas_nao_conferem") },
        ];
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return false;
      }
      return true;
    },

    deveMostrarTetoOrcamento() {
      return this.$store.state.inquilino.modulosContratacao.find((item) => {
        return item.tipo == "COMPRAS";
      });
    },

    getCentrosEstoque() {
      ComumDataService.get("centroEstoques").then((res) => {
        this.centrosEstoque = res.data;
      });
    },
  },
  mounted() {
    UsuarioDataService.find(this.$store.state.user.id).then((response) => {
      console.log(response.data);
      this.itensInquilinos = response.data.inquilinos;
    });

    this.findPerfis();

    if (
      this.$store.state.inquilino.parametrizacoes.find(
        (item) => item.tipo == "MULTIPLOSCENTROSESTOQUE"
      )
    ) {
      this.getCentrosEstoque();
    }
  },
};
</script>

<style scoped>
</style>
