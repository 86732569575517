import Axios from "axios";

class InquilinoDataService {
  
  getAll() {
    return Axios.get("inquilinos");
  }

  getPage(params) {
    return Axios.get("inquilinos/page", { params });
  }

  create(params) {
    return Axios.post("inquilinos", params);
  }

  update(params) {
    return Axios.put("inquilinos/" + params.id, params);
  }

  find(id) {
    return Axios.get(`inquilinos/${id}`);
  }

  delete(id) {
    return Axios.delete(`inquilinos/${id}`);
  }
}

export default new InquilinoDataService();
