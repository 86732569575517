// Tag de uso
// <CamposTipoNregistro
//    :nRegistro="recurso.nRegistro"
//    :tipo="recurso.tipoCliente"
//     @nRegistro="recurso.nRegistro = $event"
//     @tipo="recurso.tipoCliente = $event"
//     :disabled="false"
//                           >
// </CamposTipoNregistro>

<template>
  <v-row>
    <v-col cols="6" md="6">
      <v-select
        :items="[
          { text: $t('Pessoa_fisica'), value: 'PESSOAFISICA' },
          { text: $t('Pessoa_juridica'), value: 'PESSOAJURIDICA' },
        ]"
        :label="$t('Tipo_pessoa')"
        name="tipoPessoa"
        item-value="value"
        v-model="tipo"
        :disabled="disabled"
        hide-details
        class="
          input-style
          font-size-input
          text-light-input
          placeholder-light
          input-icon
        "
        dense
        flat
        filled
        solo
      ></v-select>
    </v-col>

    <v-col cols="6" md="6">
      <v-text-field
        :label="$t(labelTipo)"
        name="nRegistro"
        v-model="registro"
        :disabled="disabled"
        v-mask="mask"
        hide-details
        class="
          input-style
          font-size-input
          text-light-input
          placeholder-light
          input-icon
        "
        dense
        flat
        filled
        solo
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CamposTipoNregistro",
  props: ["nRegistro", "tipoCliente", "disabled"],

  data() {
    return {
      label: "",
    };
  },

  computed: {
    mask() {
      if (this.label == "PESSOAFISICA") return "###.###.###-##";

      if (this.label == "PESSOAJURIDICA") return "##.###.###.####-##";

      return "##.###.###.####-##";
    },

    registro: {
      get() {
        return this.nRegistro;
      },
      set(val) {
        this.$emit("nRegistro", val);
      },
    },

    tipo: {
      get() {
        return this.tipoCliente;
      },
      set(val) {
        this.label = val;
        this.$emit("tipo", val);
      },
    },

    labelTipo: function () {
      if (this.label == "PESSOAFISICA") return "CPF";

      if (this.label == "PESSOAJURIDICA") return "CNPJ";

      return "nRegistro";
    },
  },
};
</script>

<style>
</style>
