<template>
  <ListagemBase
    nomePlural="FuncionarioAvisoPrevios"
    nomeSingular="FuncionarioAvisoPrevio"
    :headers="headers"
  >
  </ListagemBase>
</template>

<script>
import FuncionarioAvisoPrevioDataService from "@/services/pessoal/FuncionarioAvisoPrevioDataService";
const DataService = FuncionarioAvisoPrevioDataService;

import ListagemBase from "@/components/base/Crud/ListagemBase";

export default {
  name: "ListagemFuncionarioAvisoPrevios",
  components: { ListagemBase },
  data() {
    return {
      DataService: DataService,

      headers: [
        {
          text: this.$t("Matricula"),
          value: "funcionario.matricula",
          sortable: "false",
        },
        {
          text: this.$t("Funcionario"),
          value: "funcionario.nomeSocial",
          sortable: "false",
        },
        { text: this.$t("Inicio"), value: "inicio", sortable: "false" },
        {
          text: this.$t("Encerramento"),
          value: "encerramento",
          sortable: "false",
        },
        { text: this.$t("tipoAvisoPrevio"), value: "tipoAvisoPrevio" },
        { text: this.$t("origemAvisoPrevio"), value: "origemAvisoPrevio" },
        {
          text: this.$t("beneficioAvisoPrevio"),
          value: "beneficioAvisoPrevio",
        },

        { text: this.$t("Acoes"), value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style>
</style>
