<template>
  <CadastroBase
    nomePlural="ModeloDocumentacaoAcessorias"
    nomeSingular="ModeloDocumentacaoAcessoria"
    @submitSuccessInsert="emitSubmitSuccess"
    @submitErrorInsert="emitSubmitError"
    @closeModalInsert="closeModal"
  >
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="recurso.descricao"
            :label="$t('Descricao')"
            name="descricao"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-checkbox v-model="recurso.impressaoComCabecalho">
            <template v-slot:label>
              {{ $t("impressaoComCabecalho") }}
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="6">
          <v-checkbox v-model="recurso.impressaoSemCabecalho">
            <template v-slot:label>
              {{ $t("impressaoSemCabecalho") }}
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" md="4">
          <v-checkbox v-model="recurso.geracaoAutomaticaAdmissao">
            <template v-slot:label>
              {{ $t("geracaoAutomaticaAdmissao") }}
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" md="4">
          <v-checkbox v-model="recurso.geracaoAutomaticaAdmissaoIntermitente">
            <template v-slot:label>
              {{ $t("geracaoAutomaticaAdmissaoIntermitente") }}
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" md="4">
          <v-checkbox v-model="recurso.geracaoAutomaticaAvisoPrevio">
            <template v-slot:label>
              {{ $t("geracaoAutomaticaAvisoPrevio") }}
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="4">
          <v-checkbox v-model="recurso.geracaoAutomaticaFerias">
            <template v-slot:label>
              {{ $t("geracaoAutomaticaFerias") }}
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" md="4">
          <v-checkbox v-model="recurso.geracaoAutomaticaSuspensao">
            <template v-slot:label>
              {{ $t("geracaoAutomaticaSuspensao") }}
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="4">
          <v-checkbox v-model="recurso.geracaoAutomaticaAdvertencia">
            <template v-slot:label>
              {{ $t("geracaoAutomaticaAdvertencia") }}
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" md="12">
          <v-textarea
            v-model="recurso.texto"
            :label="$t('Texto')"
            :payload="recurso.texto"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-textarea>
        </v-col>
      </v-row>
    </v-col>
  </CadastroBase>
</template>

<script>
import CadastroBase from "@/components/base/Crud/CadastroBase";

import ModeloDocumentacaoAcessoriaDataService from "@/services/pessoal/ModeloDocumentacaoAcessoriaDataService";
const DataService = ModeloDocumentacaoAcessoriaDataService;

import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "R$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  components: { CadastroBase },
  name: "CadastroModeloDocumentacaoAcessoria",

  data() {
    return {
      DataService: DataService,

      submited: false,
      mask: currencyMask,

      recurso: {
        descricao: "",
        salarioBase: "",
      },

      errors: [],
    };
  },
  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccessInsert");
    },
    emitSubmitError() {
      this.$emit("submitErrorInsert");
    },
    closeModal() {
      this.$emit("closeModalInsert");
    },
  },
};
</script>

<style scoped>
#app .INATIVO {
  color: red;
}
</style>
