<template>
  <v-row>
    <v-dialog v-model="formEditPessoa" persistent max-width="500">
      <v-card>
        <v-form @submit.prevent="enviaUpdatePessoa()">
          <v-card-title class="headline">
            {{ $t("Editar_pessoa") }} <br />
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  type="number"
                  :label="$t('Quantidade')"
                  v-can="'ROLE_Pessoa_Update'"
                  class="purple-input"
                  min="1"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              height="39"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-secondary
                bg-gradient-light
                py-3
                px-6
              "
              @click="formEditPessoa = false"
            >
              {{ $t("Cancelar") }}
            </v-btn>

            <v-btn
              class="
                ma-1
                font-weight-bold
                text-none
                btn-primary
                bg-gradient-primary
                py-3
                px-6
                ms-3
              "
              height="39"
            >
              {{ $t("Adicionar") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-col cols="12" md="12">
      <div class="card-header-padding">
        <div>
          <h5 class="font-weight-bold text-h5 text-typo mb-0">
            {{ $t(titulo) }}
          </h5>
          <p class="text-sm text-body mb-0">
            {{ $t("Clique_para_selecionar") }}
          </p>
        </div>
        <div class="d-flex align-center">
          <v-col cols="12" md="5">
            <v-text-field
              hide-details
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
              "
              dense
              flat
              filled
              solo
              height="39"
              v-model="search"
              :label="$t('Procurar')"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
              </template></v-text-field
            >
          </v-col>
        </div>
      </div>

      <v-switch
        v-model="itemsPerPage"
        :false-value="1"
        :true-value="4"
        v-if="editar"
        @change="search = ''"
      >
        <template v-slot:label> {{ $t("Editar") }} </template>
      </v-switch>

      <v-data-table
        :headers="headers"
        :items="pessoas"
        :search="search"
        show-select
        item-key="id"
        :single-select="true"
        v-model="pessoa"
        :items-per-page="itemsPerPage"
        class="table"
      >
        <template v-slot:[`item.cpf`]="{ item }">
          <span>{{ item.cpf | VMask("###.###.###-##") }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editPessoa(item.id)"
            >ni-ruler-pencil</v-icon
          >
        </template>
      </v-data-table>

      <v-card-actions>
        <v-btn
          to="/dashboard/pessoas/cadastro"
          v-can="'ROLE_Pessoa_Insert'"
          class="
            ma-1
            font-weight-bold
            text-none
            btn-primary
            bg-gradient-primary
            py-3
            px-6
            ms-3
          "
          height="39"
        >
          {{ $t("Cadastrar_pessoa") }}
          <v-icon left dark> mdi-plus-circle </v-icon>
        </v-btn>
      </v-card-actions>
    </v-col>
  </v-row>
</template>

<script>
import DataService from "@/services/ComumDataService";

export default {
  name: "TabelaSelecionaPessoa",

  props: {
    titulo: { type: String, required: false, default: "Pessoa" },
    payload: {
      type: Object,
      default() {
        return {};
      },
    },
    editar: { type: Boolean, default: false },
  },

  data() {
    return {
      itemsPerPage: 4,

      idEditado: "",
      formEditPessoa: false,
      pessoas: [],
      pessoa: [],
      search: "",
      headers: [
        { text: this.$t("Nome"), value: "nome", sortable: "false" },
        { text: this.$t("CPF"), value: "cpf", sortable: "false" },
      ],
    };
  },

  mounted() {
    if (this.editar) {
      this.itemsPerPage = 1;
    }

    this.getPessoas().then(() => {
      console.log(this.payload);
      this.pessoa.push(
        this.pessoas.find((item) => item.id == this.payload.id) || {}
      );

      if (this.pessoa[0].nome) {
        this.search = this.pessoa[0].nome;
      }
    });
  },

  methods: {
    getPessoas() {
      return new Promise((resolve, reject) => {
        DataService.getPessoas()
          .then((response) => {
            this.pessoas = response.data;
            resolve();
          })
          .catch((error) => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            console.log(error);
          });
      });
    },
    editPessoa(id) {
      this.idEditado = id;
      this.formEditPessoa = true;
    },
  },

  watch: {
    pessoa: function (newValue) {
      if (this.pessoa.length > 0) this.$emit("pessoa", { id: newValue[0].id });
    },
  },
};
</script>

<style>
</style>