<template>
  <v-text-field
    v-model="displayValue"
    :label="$t(label)"
    :name="name || label"
    hint="HH:MM"
    v-mask="'##:##'"
    @blur="onBlur"
    autocomplete="off"
    :rules="[(v) => !!v || this.$t('Campo_obrigatorio')]"
    hide-details
    class="input-style font-size-input text-light-input placeholder-light input-icon"
    dense
    flat
    filled
    solo
  >
  </v-text-field>
</template>

<script>
export default {
  name: "CampoHorario",

  props: {
    label: { type: String, default: "Data" },
    name: { type: String, required: false },
    payload: { type: String, required: false },
  },

  mounted() {
    setTimeout(() => {
      if (this.payload) {
        this.internalValue = this.formatTime(this.payload);
      }
    }, 200);
  },

  watch: {
    payload: function (val) {
      this.internalValue = this.formatTime(val);
    },
  },

  data() {
    return {
      internalValue: "",
    };
  },

  computed: {
    displayValue: {
      get() {
        return this.internalValue.substring(0, 5); // Mostra apenas HH:MM
      },
      set(value) {
        if (value.length === 5) {
          this.internalValue = value + ":00"; // Adiciona :00
        } else {
          this.internalValue = value; // Caso para strings inválidas, manter o valor
        }
      },
    },
  },

  methods: {
    formatTime(dateTime) {
      if (!dateTime) {
        return "";
      }

      let time;

      if (dateTime.length === 19 && dateTime.includes("T")) {
        // Formato ISO 8601
        time = dateTime.substring(11, 19); // Extrai HH:MM:SS
      } else if (
        dateTime.length >= 5 &&
        dateTime.length <= 8 &&
        dateTime.includes(":")
      ) {
        // Formato HH:MM ou HH:MM:SS
        time = dateTime;
      } else {
        return ""; // Formato inválido
      }

      // Adiciona ":00" se estiver no formato HH:MM
      if (time.length === 5) {
        time += ":00";
      }

      return time;
    },

    onBlur() {
      this.$emit("date", this.internalValue);
    },
  },
};
</script>

<style scoped>
/* Adicione seu estilo aqui, se necessário */
</style>
