<template>
  <div class="tutorial__info-box">
      <p class="info-box__title">
          {{this.propobject.boxTitle}}
      </p>
      <p v-html="this.propobject.boxText" class="info-box__text">
          {{this.propobject.boxText}}
      </p>

      <button id="okInfoBox-button" @click="resolveStep" class="info-box__button">
          OK
      </button>
  </div>
</template>

<script>
import {dispatchAction} from '../main.js';
export default {
    name: 'OkInfoBox',
    props: {
        propobject: {required: true}
    },

    mounted() {
        window.addEventListener("tutorial-overlay-click", () => {
            let btn = document.getElementById("okInfoBox-button")

            btn.classList.add("okInfoBox-button-grow");

            setTimeout(() => {
                btn.classList.remove("okInfoBox-button-grow");
            }, 300)
        })
    },

    methods: {
        resolveStep(){
            console.log(this.propobject)
            if(this.propobject.clickTarget){
                dispatchAction('clickTarget');
            }else{
                dispatchAction('resolve')
            }
        }
    }
}
</script>

<style scoped>
    .tutorial__info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  /*     box-shadow: 
    12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3); */
  border-radius: 16px;
  padding: 1rem 0.5rem;
  color: #000;

  width: 75vw;
  max-width: 200px;
  position: relative;
}

.info-box__button {
  background: lightblue;
  padding: 0.5rem 1rem;
  width: 100%;
  max-width: 200px;
  border-radius: 4px;
  font-size: 1.125rem;
  text-align: center;
  margin-top: 1rem;
}

.info-box__title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.4rem;
  color: #000;
}

.info-box__text {
  font-size: 0.9rem;
  color: #3d3d3d;
  line-height: 1.3;

  text-align: center;
}

.info-box__button {
  background: lightblue;
  padding: 0.5rem 1rem;
  width: 100%;
  max-width: 200px;
  border-radius: 4px;
  font-size: 1.125rem;
  text-align: center;
  margin-top: 1rem;
  
transition: transform .3s;
  }

  .okInfoBox-button-grow{
      transform: scale(1.1) translateY(-5px);
  }
</style>