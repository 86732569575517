"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contains = void 0;
var contains = function (value, selector) {
    var _a;
    if (selector === void 0) { selector = '*'; }
    var elements = document.querySelectorAll(selector);
    var filtered = [];
    for (var i = 0; i < elements.length; i++) {
        var a = elements[i];
        if (((_a = a.textContent) === null || _a === void 0 ? void 0 : _a.includes(value)) && a.clientHeight > 0 && a.innerText.includes(value)) {
            filtered.push(a);
        }
    }
    return filtered.length > 0 ? filtered : false;
};
exports.contains = contains;
