<template>
   <v-text-field-datetimepicker
            @blur="$emit('date', formatedValue)"
          v-model="value"
          :label="$t(label)"
          v-bind:properties="{
            dense: true,
            clearable: false,
            outlined: true,
            prependIcon: 'mdi-calendar',
            appendIcon: ''
          }"
          v-bind:options="{
            tabDateTitle: $t('Data'),
            tabTimeTitle: $t('Hora'),
            tabBackgroundColor: 'blue',
            locale: 'pt-BR',
            format: 'DD/MM/YYYY',
            closeOnDateClick: false,
            useSeconds: false,
          }"
        />
</template>

<script>
export default {
  name: "CampoDataHora",

  props: {
    label: { type: String, default: 'Data'},
    payload: { type: String, required: false }
  },

  mounted() {
    setTimeout(() => {
      console.log(new Date());

      if (this.payload) {
        this.value =  this.$options.filters.dataParaLeitura(this.payload);
    }
      }, 200)
  },

  data() {
    return {
      value: ""
    };
  },
  watch: {
      value: function(newValue) {
          this.$emit("date", this.formatedValue)
      }
  },

  computed: {
    formatedValue: function() {
        const pad = function (number) {
      if (number < 10) {
        return '0' + number;
      }
      return number;
    };
      if(!this.value){
        return null
      }

      let data = new Date(this.value);
      console.log(data, this.value)


         return data.getFullYear() +
        '-' + pad(data.getMonth() + 1) +
        '-' + pad(data.getDate()) +
        'T' + pad(data.getHours()) +
        ':' + pad(data.getMinutes()) +
        ':' + pad(data.getSeconds())


    }
  }
};
</script>

<style></style>

