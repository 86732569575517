<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="itensDataTable"
      :items-per-page="25"
      class="elevation-1"
    ></v-data-table>
  </div>
</template>

<script>
import DataService from "@/services/ComumDataService";
export default {
  name: "CheckPUF",

  data() {
    return {
      pessoas: [],
      usuarios: [],
      funcionarios: [],

      registros: [
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 1115,
    "Nome do Funcionário": "ALEX ANTONIO DE JESUS DA SILVA",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "21/09/2012",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": "013446981-07",
    "Identidade": "2410043 - SSP - DF",
    "PIS/PASEP": 19031212477,
    "Carteira de Trabalho": "00097019/00021 - DF",
    "Nascimento": "11/8/1985",
    "Mãe": "ISABEL MARIA DE JESUS",
    "Pai": "JESUINO ANDRADE DA SILVA",
    "Celular": 92548139,
    "Telefone": "",
    "Endereço": "QUADRA 29 QD 64 LOTE",
    "Complemento": "PQ NAPOLES B",
    "Bairro": "OCIDENTAL",
    "Cidade": "Goiás",
    "E-mail": "alex.jesus@grupogriffo.com.br",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 799,
    "Nome do Funcionário": "ALINE DE FATIMA PINTO SARAIVA",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "RECEPCIONISTA",
    "Admissão": "01/09/2011",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.901,53",
    "CPF": "017658241-09",
    "Identidade": "2506916 - SSP - DF",
    "PIS/PASEP": 19027835422,
    "Carteira de Trabalho": "00035627/00024 - DF",
    "Nascimento": "3/21/1985",
    "Mãe": "RENY DE FATIMA PINTO SARAIVA",
    "Pai": "LUIZ CARLOS SARAIVA",
    "Celular": "",
    "Telefone": "",
    "Endereço": "QR 206 CONJ G LOTE 14",
    "Complemento": "",
    "Bairro": "SANTA AMRIA",
    "Cidade": "Brasília",
    "E-mail": "aline.pinto@grupogriffo.com.br",
    "SX": "F"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 668,
    "Nome do Funcionário": "ANTONIO CARLOS ALVES DE OLIVEIRA",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "01/08/2010",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": "808274707-20",
    "Identidade": "069657005 - SSP - RJ",
    "PIS/PASEP": 12159549195,
    "Carteira de Trabalho": "00071275/00013 - CE",
    "Nascimento": "4/20/1965",
    "Mãe": "MARIA ALVES DE OLIVEIRA",
    "Pai": "JOSE RIBEIRO DE OLIVEIRA",
    "Celular": 91860215,
    "Telefone": 33358426,
    "Endereço": "QUADRA SETOR TRADICIONAL",
    "Complemento": "CASA 60",
    "Bairro": "SAO SEBASTIAO",
    "Cidade": "Brasília",
    "E-mail": "Kakadf22@gmail.com",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 614,
    "Nome do Funcionário": "ANTONIO JOSE DA CONCEIÇÃO FERREIRA",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "01/06/2010",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": "028508621-93",
    "Identidade": "2523517 - SESPDS - DF",
    "PIS/PASEP": 21076407554,
    "Carteira de Trabalho": "00073710/00038 - GO",
    "Nascimento": "8/28/1988",
    "Mãe": "MARIA DA CONCEIÇÃO FERREIRA",
    "Pai": "",
    "Celular": "",
    "Telefone": "",
    "Endereço": "QD 14 LOTE 03 CASA 01 CH ANHANGUERA A",
    "Complemento": "",
    "Bairro": "VALPARAISO",
    "Cidade": "Goiás",
    "E-mail": "antonio.conceicao@grupogriffo.com.br",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 2104,
    "Nome do Funcionário": "CARLOS TEIXEIRA SOARES JUNIOR",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "23/06/2017",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": 59931507187,
    "Identidade": "1290564 - SSP - DF",
    "PIS/PASEP": 12416156782,
    "Carteira de Trabalho": "02491093/00050 - DF",
    "Nascimento": "9/15/1973",
    "Mãe": "GERALDA DOS SANTOS SOARES",
    "Pai": "CARLOS TEIXEIRA SOARES",
    "Celular": 85964182,
    "Telefone": 33478942,
    "Endereço": "QUADRA SCLRN 716 BL A EN 07 AP",
    "Complemento": "",
    "Bairro": "ASA NORTE",
    "Cidade": "Brasília",
    "E-mail": "carlosjuniorjr050@gmail.com",
    "SX": "M"
  }, 
   {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 2640,
    "Nome do Funcionário": "CRISTIANE CRUZ DA PENA",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AUXILIAR ADMINISTRATIVO",
    "Admissão": "01/03/2021",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.331,14",
    "CPF": 85967068104,
    "Identidade": "1940685 - SSP DF - DF",
    "PIS/PASEP": 14001866273,
    "Carteira de Trabalho": "00015551/00016 - DF",
    "Nascimento": "1/3/1980",
    "Mãe": "MARLY FAUSTINA",
    "Pai": "LAERTE RIBEIRO DA PENA",
    "Celular": 82122620,
    "Telefone": "",
    "Endereço": "Rua Marajó",
    "Complemento": "",
    "Bairro": "Ypiranga",
    "Cidade": "Valparaíso de Goiás",
    "E-mail": "cristiane.pena@grupogriffo.com.br",
    "SX": "F"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 1984,
    "Nome do Funcionário": "DIVINA APARECIDA FERREIRA FEITOSA",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "RECEPCIONISTA",
    "Admissão": "07/03/2016",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.901,53",
    "CPF": 63483408134,
    "Identidade": "1521585 - SSP - DF",
    "PIS/PASEP": 16094046111,
    "Carteira de Trabalho": "00086316/00031 - DF",
    "Nascimento": "5/21/1977",
    "Mãe": "DESCENDINA MARIA DE JESUS",
    "Pai": "JOAQUIM FERREIRA FEITOSA",
    "Celular": "9344-8358",
    "Telefone": "3388-0428",
    "Endereço": "QUADRA 03 CONJ C CASA",
    "Complemento": "",
    "Bairro": "VILA BURITIS",
    "Cidade": "Brasília",
    "E-mail": "divina.feitosa@grupogriffo.com.br",
    "SX": "F"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 2214,
    "Nome do Funcionário": "ERICK JUSTINO PEREIRA",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "16/07/2018",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": 80454429134,
    "Identidade": "1708439 - SSP/DF - DF",
    "PIS/PASEP": 12493962402,
    "Carteira de Trabalho": "00050417/00013 - DF",
    "Nascimento": "11/26/1978",
    "Mãe": "MARINEIDE DE SOUZA PEREIRA",
    "Pai": "PEDRO ANESIO DE OLIVEIRA PEREIRA",
    "Celular": 81364983,
    "Telefone": 82732114,
    "Endereço": "Quadra 110 Conjunto",
    "Complemento": "CASA 02",
    "Bairro": "Recanto das Emas",
    "Cidade": "Brasília",
    "E-mail": "erick.pereira@grupogriffo.com.br",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 1883,
    "Nome do Funcionário": "FELIPE EDUARDO PEREIRA MENEZES",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "21/04/2015",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": "03201060127",
    "Identidade": "2832493 - SSP - DF",
    "PIS/PASEP": 13586584279,
    "Carteira de Trabalho": "00071936/00026 - DF",
    "Nascimento": "1/13/1991",
    "Mãe": "NUBIA RAIMUNDA DE JESUS PEREIRA",
    "Pai": "EXPEDITO FERNANDES DE MENEZES",
    "Celular": "9373-4635",
    "Telefone": "3605-2919",
    "Endereço": "QUADRA QNB 09 LT 02 AP",
    "Complemento": "",
    "Bairro": "TAGUATINGA",
    "Cidade": "Brasília",
    "E-mail": "felipe.eduardo@grupogriffo.com.br",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 1607,
    "Nome do Funcionário": "HELTON DA CONCEICAO BARRETO",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "24/12/2013",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": 70171009215,
    "Identidade": "3.588.119 - POLITEC - AP",
    "PIS/PASEP": 12616778035,
    "Carteira de Trabalho": "02475956/00010 - AP",
    "Nascimento": "9/13/1982",
    "Mãe": "MARIA LEONICE SILVA CONCEICAO",
    "Pai": "JOAO SILVEIRA BARRETO FILHO",
    "Celular": 82002080,
    "Telefone": "",
    "Endereço": "QN 8C CJ 03 LT 42 AP",
    "Complemento": "",
    "Bairro": "RIACHO FUNDO II",
    "Cidade": "Brasília",
    "E-mail": "helton.conceicao@grupogriffo.com.br",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 472,
    "Nome do Funcionário": "JAIRO BEZERRA DOS SANTOS CHAVES",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "25/06/2009",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": "008778645-19",
    "Identidade": "2214202 - SSP - DF",
    "PIS/PASEP": 20953760280,
    "Carteira de Trabalho": "00083169/00019 - DF",
    "Nascimento": "3/9/1982",
    "Mãe": "ZULMIRA DOS SANTOS",
    "Pai": "JOAO PEREIRA BEZERRA",
    "Celular": "",
    "Telefone": "",
    "Endereço": "RF II QC 004 CJ 03",
    "Complemento": "CASA",
    "Bairro": "RIACHO FUNDO",
    "Cidade": "Brasília",
    "E-mail": "jairo.santos@grupogriffo.com.br",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 1670,
    "Nome do Funcionário": "JANDER BRANDAO SILVEIRA DOS SANTOS",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "25/04/2014",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": 63455030106,
    "Identidade": "191370 - SSP - TO",
    "PIS/PASEP": 15846807163,
    "Carteira de Trabalho": "03304703/00060 - DF",
    "Nascimento": "8/28/1974",
    "Mãe": "ISABEL SILVEIRA DOS SANTOS",
    "Pai": "JOAO ALVES DA SILVA",
    "Celular": 85557638,
    "Telefone": "",
    "Endereço": "QUADRA 55/56 LOTES 15/17 BLOCO 02 APARTAMENTO",
    "Complemento": "",
    "Bairro": "GAMA",
    "Cidade": "Brasília",
    "E-mail": "janderbrandao74@gmail.com",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 1583,
    "Nome do Funcionário": "JARBAS DA SILVA SOUSA",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "26/10/2013",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": 92574114191,
    "Identidade": "29329418 - SSP - DF",
    "PIS/PASEP": 21015268813,
    "Carteira de Trabalho": "00056856/00015 - DF",
    "Nascimento": "7/15/1980",
    "Mãe": "EDISANILDE DA SILVA",
    "Pai": "NAO DECLARADO",
    "Celular": 93178013,
    "Telefone": 3393281,
    "Endereço": "QR 403 CJ O CS",
    "Complemento": "",
    "Bairro": "SANTA MARIA",
    "Cidade": "Brasília",
    "E-mail": "jarbas.sousa@grupogriffo.com.br",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 2130,
    "Nome do Funcionário": "JEAN HENRIQUE VERAS OLIVEIRA",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "16/08/2017",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": "04410801112",
    "Identidade": "2531997 - SSP/DF - DF",
    "PIS/PASEP": 20159222847,
    "Carteira de Trabalho": "00065229/00028 - DF",
    "Nascimento": "12/3/1991",
    "Mãe": "LUCIA IRLENE VERAS",
    "Pai": "ENILSON OLIVEIRA DA SILVA",
    "Celular": 30817671,
    "Telefone": 993025298,
    "Endereço": "Quadra 803 Conjunto 08 LT",
    "Complemento": "",
    "Bairro": "Recanto das Emas",
    "Cidade": "Brasília",
    "E-mail": "jeanhenrique.jh10@gmail.com",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 1442,
    "Nome do Funcionário": "JOAO DOMINGOS DA SILVA NETO",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "04/04/2013",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": "02105907180",
    "Identidade": "2638753 - SSP - DF",
    "PIS/PASEP": 21066732142,
    "Carteira de Trabalho": "00088823/00024 - DF",
    "Nascimento": "8/20/1989",
    "Mãe": "LUZIA MARIA VIEIRA DA SILVA",
    "Pai": "JOSE DOMINGOS DA SILVA",
    "Celular": 95362056,
    "Telefone": 30817206,
    "Endereço": "309 CONJUNTO 07 CASA 02",
    "Complemento": "",
    "Bairro": "RECANTO DAS EMAS",
    "Cidade": "Brasília",
    "E-mail": "joao.neto@grupogriffo.com.br",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 1198,
    "Nome do Funcionário": "LUCIA TATIANE ROMAO",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "14/11/2012",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": "922300081-53",
    "Identidade": "1877737 - SSP - DF",
    "PIS/PASEP": 12938875276,
    "Carteira de Trabalho": "00097403/00018 - DF",
    "Nascimento": "10/8/1980",
    "Mãe": "MARIA ZILDA ROMAO",
    "Pai": "",
    "Celular": 81593198,
    "Telefone": 36280619,
    "Endereço": "01 HI RUA 29 CASA",
    "Complemento": "",
    "Bairro": "NOVO GAMA",
    "Cidade": "Goiás",
    "E-mail": "lucia.tatiane@grupogriffo.com.br",
    "SX": "F"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 2111,
    "Nome do Funcionário": "LUIZ CLAUDIO PEREIRA DA SILVA",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "07/07/2017",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": 37607740163,
    "Identidade": "953279 - SSP/DF - DF",
    "PIS/PASEP": 12316065157,
    "Carteira de Trabalho": "00075843/00006 - DF",
    "Nascimento": "1/31/1969",
    "Mãe": "ZENILDE PEREIRA DA SILVA",
    "Pai": "LUIZ RODRIGUES DA SILVA",
    "Celular": 84936191,
    "Telefone": 30414559,
    "Endereço": "QS 408",
    "Complemento": "APT 101",
    "Bairro": "Samambaia Norte (Sam",
    "Cidade": "Brasília",
    "E-mail": "lpereiradasilva13@gmail.com",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 36,
    "Nome do Funcionário": "MANOEL MESSIAS RODRIGUES DE OLIVEIRA",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "01/10/2001",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": "725504306-20",
    "Identidade": "M5538812 - SSP - DF",
    "PIS/PASEP": 12468594055,
    "Carteira de Trabalho": "00008125/00079 - DF",
    "Nascimento": "11/26/1968",
    "Mãe": "ELZIRA PEREIRA DE OLIVEIRA",
    "Pai": "ADAO RODRIGUES GUIMARAES",
    "Celular": "",
    "Telefone": "",
    "Endereço": "QUADRA QD 1R",
    "Complemento": "B",
    "Bairro": "COND RES NOVA PLANAL",
    "Cidade": "Brasília",
    "E-mail": "mm7664624@gmail.com",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 2643,
    "Nome do Funcionário": "MANOEL PEDRO DA SILVA FILHO",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "Fiscal de piso",
    "Admissão": "09/03/2021",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": "04706339138",
    "Identidade": "3065062 - SSP DF - DF",
    "PIS/PASEP": 19059652234,
    "Carteira de Trabalho": "00066093/00029 - DF",
    "Nascimento": "7/29/1993",
    "Mãe": "CARLA TEREZA PERALTA GOMES",
    "Pai": "MANOEL PEDRO DA SILVA NETO",
    "Celular": 981258717,
    "Telefone": "",
    "Endereço": "QI 10 Bloco S",
    "Complemento": "QI 10 BLOCO S A",
    "Bairro": "Guará I",
    "Cidade": "Brasília",
    "E-mail": "manoel.silva@grupogriffo.com.br",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 1982,
    "Nome do Funcionário": "RAFAELA DA CONCEIÇAO",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "RECEPCIONISTA",
    "Admissão": "05/03/2016",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.901,53",
    "CPF": "02360758101",
    "Identidade": "2441011 - SSP - DF",
    "PIS/PASEP": 16557083512,
    "Carteira de Trabalho": "00047335/00022 - DF",
    "Nascimento": "6/28/1988",
    "Mãe": "FRANCISCA MARIA DA CONCEIÇAO",
    "Pai": "",
    "Celular": "9124-0050",
    "Telefone": "3373-9301",
    "Endereço": "QUADRA QNM 07 CONJUNTO G CASA",
    "Complemento": "",
    "Bairro": "CEILANDIA SUL",
    "Cidade": "Brasília",
    "E-mail": "rafaela.conceicao@grupogriffo.com.br",
    "SX": "F"
  }, 
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 2158,
    "Nome do Funcionário": "ROGERIO DE JESUS RODRIGUES LOPES",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "29/12/2017",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": "02073020143",
    "Identidade": "2543798 - SSP/DF - DF",
    "PIS/PASEP": 14051533316,
    "Carteira de Trabalho": "00018392/00038 - GO",
    "Nascimento": "9/2/1988",
    "Mãe": "DALZINETE RODRIGUES LOPES",
    "Pai": "ADALBERTO FERREIRA LOPES",
    "Celular": 92578929,
    "Telefone": 95100456,
    "Endereço": "QUADRA QSC 25 LOTE",
    "Complemento": "",
    "Bairro": "Taguatinga Sul",
    "Cidade": "Brasília",
    "E-mail": "rogerio.lopes@grupogriffo.com.br",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 2652,
    "Nome do Funcionário": "Rodrigo Amaral Loureiro Pimentel",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "18/05/2021",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": 13914969733,
    "Identidade": "3073318 - SSP-DF - DF",
    "PIS/PASEP": 13123453676,
    "Carteira de Trabalho": "00020798/00032 - DF",
    "Nascimento": "9/14/1995",
    "Mãe": "Margareth Amaral Loureiro de Almeida",
    "Pai": "Márcio José Pimentel de Almeida",
    "Celular": "",
    "Telefone": 991975757,
    "Endereço": "QR 202 Conjunto H",
    "Complemento": 20,
    "Bairro": "Santa Maria",
    "Cidade": "Brasília",
    "E-mail": "rodrigo.pimentel@grupogriffo.com.br",
    "SX": "M"
  },
  {
    "Empresa": "00259",
    "Nome da Empresa": "GRIFFO SERVIÇOS GERAIS LTDA",
    "Matricula": 1485,
    "Nome do Funcionário": "WESLEY BARROS RIBEIRO",
    "Departamento": "Globo Comunicação e Participações S/A",
    "Função": "AGENTE DE PORTARIA",
    "Admissão": "02/06/2013",
    "Demissão": "",
    "Causa da Demissão": "",
    "Salário Base": "R$ 1.403,56",
    "CPF": "00206988109",
    "Identidade": "2149480 - SSP - DF",
    "PIS/PASEP": 13102676277,
    "Carteira de Trabalho": "00071353/00020 - DF",
    "Nascimento": "12/12/1984",
    "Mãe": "MARIA ALZENIRA BARROS RIBEIRO",
    "Pai": "JOSE JOSE RIBEIRO",
    "Celular": 84560746,
    "Telefone": 33911112,
    "Endereço": "QUADRA QD 35 CJ E LT 15 CS",
    "Complemento": "VILA SAO JOSE",
    "Bairro": "BRAZLANDIA",
    "Cidade": "Brasília",
    "E-mail": "wesley.ribeiro@grupogriffo.com.br",
    "SX": "M"
  }
], //da tabela excel

      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nome",
        },
        {
          text: "Pessoa",
          align: "start",
          sortable: true,
          value: "pessoa",
        },
        {
          text: "usuario",
          align: "start",
          sortable: true,
          value: "usuario",
        },
        {
          text: "funcionario",
          align: "start",
          sortable: true,
          value: "funcionario",
        },
      ],
    };
  },

  computed: {
    itensDataTable() {
      return this.registros.map((item) => {
        let resultado = {};

        resultado.pessoa = this.checkPessoa(item);
        resultado.usuario = this.checkUsuario(item);
        resultado.funcionario = this.checkFuncionario(item);
        resultado.nome = item["Nome do Funcionário"];

        return resultado
      });
    },
  },

  mounted() {
    this.getRecursos();
  },

  methods: {
    getRecursos() {
      DataService.get("/pessoas").then((res) => {
        this.pessoas = res.data;
      });
      DataService.get("/funcionarios").then((res) => {
        this.funcionarios = res.data;
      });
      DataService.get("/usuarios").then((res) => {
        this.usuarios = res.data;
      });
    },

    checkPessoa(registro) {
      let resultado = this.pessoas.find((item) => {
        return item.nome == registro["Nome do Funcionário"];
      });

      return !!resultado;
    },

    checkUsuario(registro) {
      let resultado = this.usuarios.find((item) => {
        return item.pessoa?.nome == registro["Nome do Funcionário"];
      });

      return !!resultado;
    },

    checkFuncionario(registro) {
      let resultado = this.funcionarios.find((item) => {
        return item.pessoa?.nome == registro["Nome do Funcionário"];
      });

      return !!resultado;
    },
  },
};
</script>

<style>
</style>