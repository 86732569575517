<template>
  <div>
    <v-text-field
      @change="$emit('numero', numero)"
      @blur="geocode()"
      v-model="numero"
      :label="$t('Numero')"
      name="numero"
      class="purple-input"
      type="number"
      hide-details
      dense
      flat
      filled
      solo
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "CampoNumeroBuscaGeoCode",

  props: {
    logradouro: { type: String, default: "" },
    bairro: { type: String, default: "" },
    cep: { type: String, default: "" },
    numero: { type: String, default: "" },
  },

  computed: {
    address: function () {
      if (this.logradouro && this.bairro && this.numero && this.cep) {
        return (
          this.logradouro +
          ", " +
          this.numero +
          ", " +
          this.bairro +
          " " +
          this.cep
        );
      }
    },
  },

  data() {},

  methods: {
    geocode() {
      geocoder = new google.maps.Geocoder();

      geocoder.geocode({ address: this.address }, function (results, status) {
        if (status == "OK") {
          console.log(results[0].geometry.location);
          this.$emit("latitude", results[0].geometry.location.latitude);
          this.$emit("longitude", results[0].geometry.location.longitude);
        } else {
          alert(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    },
  },
};
</script>

<style></style>
