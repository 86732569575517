<template>
  <EditarBase
    nomeSingular="Pais"
    nomePlural="Paises"
    @recurso="recurso = $event"
    @submitSuccess="emitSubmitSuccess"
    @submitError="emitSubmitError"
    @closeModalEdit="closeModalEdit"
  >
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="6">
          <v-span class="opacity-5 text-dark">
            {{ $t("Nome") }}
          </v-span>
          <v-text-field
            v-model="recurso.nome"
            :label="$t('Nome')"
            name="nome"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-span class="opacity-5 text-dark">
            {{ $t("Sigla") }}
          </v-span>
          <v-text-field
            v-model="recurso.sigla"
            :label="$t('Sigla')"
            name="sigla"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-span class="opacity-5 text-dark">
            {{ $t("Moeda") }}
          </v-span>
          <v-text-field
            v-model="recurso.moeda"
            :label="$t('Moeda')"
            name="moeda"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </EditarBase>
</template>

<script>
import EditarBase from "@/components/base/Crud/EditarBase";
import DataService from "@/services/locais/PaisDataService";

export default {
  name: "EditarPais",
  components: { EditarBase },
  props: {
    recurso: Object,
  },
  data() {
    return {
      submited: false,
      DataService: DataService,

      recurso: {
        nome: "",
        sigla: "",
        moeda: "",
      },

      errors: [],
    };
  },
  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccess");
    },
    emitSubmitError() {
      this.$emit("submitError");
    },
    closeModalEdit() {
      this.$emit("closeModalEdit");
    },
  },
};
</script>

<style scoped>
</style>
