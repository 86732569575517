<template>
  <CadastroBase
    nomePlural="FuncionarioSindicatos"
    nomeSingular="FuncionarioSindicato"
    @submitSuccessInsert="emitSubmitSuccess"
    @submitErrorInsert="emitSubmitError"
    @closeModalInsert="closeModal"
  >
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="recurso.descricao"
            :label="$t('Descricao')"
            name="descricao"
            id="descricao"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </CadastroBase>
</template>

<script>
import CadastroBase from "@/components/base/Crud/CadastroBase";
import CriaSuperSet from "@/components/base/proprios/CriaSuperset.vue";
import listas from "@/mixins/listas";
import FuncionarioSindicatoDataService from "@/services/pessoal/FuncionarioSindicatoDataService";
const DataService = FuncionarioSindicatoDataService;

export default {
  name: "CadastroFuncionarioSindicato",
  components: { CadastroBase, CriaSuperSet },
  mixins: [listas],
  data() {
    return {
      DataService: DataService,

      recurso: {
        descricao: "",
      },

      errors: [],
    };
  },
  methods: {
    submitForm() {
      let recurso = this.recurso;

      return new Promise((resolve, reject) => {
        DataService.create(this.limpaMascara(recurso))
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err.response.data.errors);
          });
      });
    },
    emitSubmitSuccess() {
      this.$emit("submitSuccessInsert");
    },
    emitSubmitError() {
      this.$emit("submitErrorInsert");
    },
    closeModal() {
      this.$emit("closeModalInsert");
    },
  },
};
</script>

<style scoped></style>
