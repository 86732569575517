"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.dispatchAction = exports.startTutorial = void 0;
var helpers = require("./helpers");
var vue_esm_browser_js_1 = require("./node_modules/vue/dist/vue.esm.browser.js");
var highlightPadding = 50;
var vueInstance;
var startTutorial = function startTutorial(resources) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    createBlockOverlay();
                    return [4 /*yield*/, iterateSteps(resources)];
                case 1:
                    _a.sent();
                    stopLoading();
                    destroyBlockOverlay();
                    clearFrame();
                    return [2 /*return*/];
            }
        });
    });
};
exports.startTutorial = startTutorial;
var dispatchAction = function dispatchAction(action, payload, origin) {
    return __awaiter(this, void 0, void 0, function () {
        var overlay, event;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTarget("#tutorial-overlay")];
                case 1:
                    overlay = _a.sent();
                    event = new CustomEvent("tutorialAction", {
                        detail: {
                            action: action,
                            payload: payload
                        }
                    });
                    console.log("called dispatchAction()", "Action: ".concat(action, "  --"), "Origin: ".concat(origin));
                    if (overlay)
                        document.dispatchEvent(event);
                    return [2 /*return*/];
            }
        });
    });
};
exports.dispatchAction = dispatchAction;
function iterateSteps(resources) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                    var _loop_1, _i, resources_1, step, state_1;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _loop_1 = function (step) {
                                    var callbackResult, iterationResult, postRenderAction_1, err_1;
                                    return __generator(this, function (_b) {
                                        switch (_b.label) {
                                            case 0:
                                                _b.trys.push([0, 9, , 10]);
                                                callbackResult = null;
                                                iterationResult = null;
                                                if (!(typeof step.callBeforeRender == "function")) return [3 /*break*/, 2];
                                                return [4 /*yield*/, step.callBeforeRender(helpers)];
                                            case 1:
                                                callbackResult = _b.sent();
                                                _b.label = 2;
                                            case 2:
                                                if (!(Array.isArray(callbackResult) && callbackResult.length > 0)) return [3 /*break*/, 5];
                                                return [4 /*yield*/, iterateSteps(callbackResult)];
                                            case 3:
                                                iterationResult = _b.sent();
                                                if (iterationResult == "breakAll") {
                                                    resolve("breakAll");
                                                    return [2 /*return*/, "break"];
                                                }
                                                return [4 /*yield*/, step.callBeforeRender(helpers)];
                                            case 4:
                                                callbackResult = _b.sent();
                                                _b.label = 5;
                                            case 5:
                                                if (callbackResult === "breakAll") {
                                                    console.log("break all from step callback");
                                                    resolve("breakAll");
                                                    return [2 /*return*/, "break"];
                                                }
                                                if (!(callbackResult !== "breakThis")) return [3 /*break*/, 8];
                                                //Render step
                                                //in case it succeeds rendering wait for step resolution
                                                //in case it fails keep the loop going
                                                return [4 /*yield*/, renderStep(step)
                                                        .then(function () { return __awaiter(_this, void 0, void 0, function () {
                                                        return __generator(this, function (_a) {
                                                            switch (_a.label) {
                                                                case 0: return [4 /*yield*/, waitForStepResolution(step)];
                                                                case 1:
                                                                    postRenderAction_1 = _a.sent();
                                                                    return [2 /*return*/];
                                                            }
                                                        });
                                                    }); })
                                                        .catch(function (err) {
                                                        console.log("Error rendering step", err);
                                                    })];
                                            case 6:
                                                //Render step
                                                //in case it succeeds rendering wait for step resolution
                                                //in case it fails keep the loop going
                                                _b.sent();
                                                console.log('depois do catch');
                                                if (!postRenderAction_1) return [3 /*break*/, 8];
                                                if (postRenderAction_1.action == "breakAll") {
                                                    resolve("breakAll");
                                                    return [2 /*return*/, "break"];
                                                }
                                                if (!(postRenderAction_1.action == "includeSteps")) return [3 /*break*/, 8];
                                                return [4 /*yield*/, iterateSteps(postRenderAction_1.payload)];
                                            case 7:
                                                iterationResult = _b.sent();
                                                if (iterationResult == "breakAll") {
                                                    resolve("breakAll");
                                                    return [2 /*return*/, "break"];
                                                }
                                                _b.label = 8;
                                            case 8: return [3 /*break*/, 10];
                                            case 9:
                                                err_1 = _b.sent();
                                                console.log(err_1);
                                                reject();
                                                return [3 /*break*/, 10];
                                            case 10: return [2 /*return*/];
                                        }
                                    });
                                };
                                _i = 0, resources_1 = resources;
                                _a.label = 1;
                            case 1:
                                if (!(_i < resources_1.length)) return [3 /*break*/, 4];
                                step = resources_1[_i];
                                return [5 /*yield**/, _loop_1(step)];
                            case 2:
                                state_1 = _a.sent();
                                if (state_1 === "break")
                                    return [3 /*break*/, 4];
                                _a.label = 3;
                            case 3:
                                _i++;
                                return [3 /*break*/, 1];
                            case 4:
                                //Resolve after the loop has been executed successfully
                                resolve(true);
                                return [2 /*return*/];
                        }
                    });
                }); })];
        });
    });
}
function awaitTimeout(timeout) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    setTimeout(function () {
                        console.log(resolve());
                        resolve();
                    }, timeout);
                })];
        });
    });
}
/**
 * Creates the overlay that display the step's information.
 *  */
function renderStep(step) {
    return __awaiter(this, void 0, void 0, function () {
        var overlay, target, position, proporcao, infoBox_1, intervalId, hasBeenClicked_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    //clear all rendered frames
                    clearFrame();
                    overlay = document.createElement("div");
                    overlay.id = "tutorial-overlay";
                    //create overlay based on the step.type
                    if (step.type === "textOnly") {
                        //create textOnly element
                        overlay.classList.add("overlay--textonly");
                        document.body.appendChild(overlay);
                        createTextInfoBox(overlay, step);
                    }
                    if (!(step.type === "text" || step.type === "textWaitInput")) return [3 /*break*/, 4];
                    console.log("before get target");
                    startLoading();
                    return [4 /*yield*/, getTarget(step.selector).catch(function (err) {
                            console.log("error getting target", err);
                            throw new Error('target was not found, ignore the step');
                        })];
                case 1:
                    target = _a.sent();
                    console.log("after get target ");
                    stopLoading();
                    position = target.getBoundingClientRect();
                    if (!(step.scrollIntoView !== false)) return [3 /*break*/, 3];
                    return [4 /*yield*/, scrollIntoView(target)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    //create, style and position the highlight overlay
                    overlay.style.position = "fixed";
                    overlay.style.height = target.clientHeight + highlightPadding + "px";
                    overlay.style.width = target.clientWidth + highlightPadding + "px";
                    positionHighlightOverlay(overlay, position);
                    overlay.classList.add("highlightOverlay");
                    document.body.appendChild(overlay);
                    overlay.classList.add("highlightOverlay--shadow");
                    proporcao = position.height / position.width;
                    console.log("proporcao", proporcao);
                    if (proporcao > 0.4) {
                        overlay.style.setProperty("border-radius", "50%");
                    }
                    else {
                        overlay.style.setProperty("border-radius", "16px");
                    }
                    infoBox_1 = createInfoBox(step, position, overlay);
                    intervalId = setInterval(function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var currentPosition;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        console.log(intervalId);
                                        currentPosition = target.getBoundingClientRect();
                                        if (currentPosition.y !== position.y ||
                                            currentPosition.x !== position.x) {
                                            position = currentPosition;
                                            positionHighlightOverlay(overlay, currentPosition);
                                            positionInfoBox(infoBox_1, currentPosition, overlay);
                                        }
                                        if (!(target.clientHeight < 1)) return [3 /*break*/, 2];
                                        console.log('interval', intervalId, step);
                                        //startLoading()
                                        return [4 /*yield*/, getTarget(step.selector)
                                                .then(function (res) {
                                                //stopLoading
                                                target = res;
                                            })
                                                .catch(function (err) {
                                                //stopLoading
                                                //The step has been rendered
                                                //The selected target is no longer in view and cant be found
                                                //well resolve the step so the flow keeps going
                                                clearInterval(intervalId);
                                                (0, exports.dispatchAction)("resolve", null, "renderStep at update invalid target ".concat(intervalId));
                                                console.log("error updating target", err);
                                            })];
                                    case 1:
                                        //startLoading()
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        });
                    }, 200);
                    hasBeenClicked_1 = false;
                    overlay.addEventListener("click", function (e) {
                        console.log("stoped propagation");
                        window.dispatchEvent(new Event("tutorial-overlay-click"));
                        if (e.target === overlay && !hasBeenClicked_1 && step.clickTarget !== false) {
                            hasBeenClicked_1 = true;
                            target.click();
                            if (step.type === "text") {
                                clearInterval(intervalId);
                                (0, exports.dispatchAction)('step-action-fired', null, 'renderStep @click on highLightOverlay');
                            }
                            if (step.type == "textWaitInput") {
                                target.addEventListener("blur", function () {
                                    //stop loop when step has been completed
                                    clearInterval(intervalId);
                                    (0, exports.dispatchAction)('step-action-fired', null, 'renderStep @click on highLightOverlay');
                                });
                            }
                        }
                    });
                    _a.label = 4;
                case 4:
                    document.addEventListener("tutorialAction", function (e) {
                        //Allow infobox components to click the target
                        if (e.detail.action === 'clickTarget') {
                            target.click();
                        }
                        //Clear the interval when the step is resolved by a command
                        clearInterval(intervalId);
                    });
                    return [2 /*return*/];
            }
        });
    });
}
/*******
 * Resolved after the action required by the step is completed.
 *  */
function waitForStepResolution(step) {
    var _this = this;
    return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
        var target, tutorialOverlay;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tutorialOverlay = document.getElementById("tutorial-overlay");
                    if (tutorialOverlay) {
                        //Listener for actions issued after step rendering
                        //these actions are create by the function dispatchAction()
                        document.addEventListener("tutorialAction", function (event) {
                            console.log('resolved t action', event);
                            resolve(event.detail);
                        });
                    }
                    if (!(step.type === "textOnly")) return [3 /*break*/, 2];
                    return [4 /*yield*/, getTarget("#tutorial__button")];
                case 1:
                    target = _a.sent();
                    //Action that indicates the step is completed
                    target.addEventListener("click", function () {
                        //resolve when step has been completed
                        resolve(false);
                    });
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); });
}
// ====== OVERLAY MANAGEMENT ======
/**
 * Creates the element responsible for preventing
 * the user from clicking something he isn't supposed to.
 *  */
function createBlockOverlay() {
    var overlay = document.createElement("div");
    overlay.id = "tutorial-overlay-block";
    document.body.appendChild(overlay);
}
function destroyBlockOverlay() {
    var blockOverlay = document.querySelector("#tutorial-overlay-block");
    if (blockOverlay) {
        blockOverlay.parentNode.removeChild(blockOverlay);
    }
}
/**
 * Destroys the element that displays the tutorial information
 *  */
function clearFrame() {
    var oldOverlay = document.querySelector("#tutorial-overlay");
    if (oldOverlay) {
        oldOverlay.parentNode.removeChild(oldOverlay);
    }
}
//======= FUNCTIONS THAT ARE COMPLEMENTARY TO renderStep() =======
/*******
 * Set the highlighOverlay's position over the target element
 *  */
function positionHighlightOverlay(overlay, position) {
    overlay.style.bottom = position.bottom + "px";
    overlay.style.left = position.left - highlightPadding / 2 + "px";
    overlay.style.top = position.top - highlightPadding / 2 + "px";
    overlay.style.right = position.right + "px";
}
function createExitTutorialButton() {
    var expandableContainer = document.createElement("div");
    expandableContainer.style.position = "absolute";
    expandableContainer.style.right = "5px";
    expandableContainer.style.top = "5px";
    var btnClose = document.createElement("button");
    btnClose.classList.add("tutorial__button-exit");
    btnClose.innerHTML = "&#x2715";
    expandableContainer.appendChild(btnClose);
    var expandableContent = document.createElement("div");
    expandableContainer.appendChild(expandableContent);
    expandableContent.classList.add("tutorial__expandable-content");
    var title = document.createElement("p");
    title.classList.add("info-box__title");
    title.innerHTML = "Quer sair do tutorial ?";
    title.style.marginBottom = ".75rem";
    expandableContent.appendChild(title);
    var btnArea = document.createElement("div");
    btnArea.classList.add("col-2-center");
    var btnConfirm = document.createElement("div");
    btnConfirm.classList.add("tutorial__button--outline", "tutorial__button--red");
    btnConfirm.innerHTML = "<span style=\"color: #c91d12\">Sair</span>";
    btnArea.appendChild(btnConfirm);
    var btnCancel = document.createElement("div");
    btnCancel.classList.add("tutorial__button--outline", "tutorial__button--green");
    btnCancel.innerHTML = "<span style=\"color: #009933\">Ficar</span>";
    btnArea.appendChild(btnCancel);
    expandableContent.appendChild(btnArea);
    btnClose.addEventListener("click", function () {
        toggleExpandable();
    });
    btnConfirm.addEventListener("click", function () {
        toggleExpandable();
        (0, exports.dispatchAction)("breakAll", null, "main.ts createExitTutorialButton()");
    });
    btnCancel.addEventListener("click", function () {
        toggleExpandable();
    });
    var toggleExpandable = function () {
        if (expandableContent.classList.contains("tutorial__expandable-content--open")) {
            expandableContent.classList.remove("tutorial__expandable-content--open");
        }
        else {
            expandableContent.classList.add("tutorial__expandable-content--open");
        }
    };
    return expandableContainer;
}
/*****************
 * Creates an infoBox
 *
 * Called by the render function to create an infoBox
 *
 * called when step.type == text
 *
 */
function createInfoBox(step, position, overlay) {
    var infoBox = document.createElement("div");
    infoBox.id = "tutorial-info-box";
    infoBox.style.position = "absolute";
    var infoBoxContent = document.createElement("div");
    infoBoxContent.id = "tutorial-info-box-content";
    infoBox.appendChild(infoBoxContent);
    overlay.appendChild(infoBox);
    if (step.infoBoxComponent) {
        //Allow for component props to be passed
        var props_1 = { boxTitle: step.boxTitle, boxText: step.boxText };
        console.log("props", props_1);
        if (step.componentProps) {
            props_1 = __assign(__assign({}, props_1), step.componentProps);
        }
        //infoBoxContent.innerHTML = `<step-content :propobject="`+ props + `" />`;
        infoBoxContent.style.setProperty("width", "100%");
        infoBoxContent.style.setProperty("height", "100%");
        vueInstance = new vue_esm_browser_js_1.default({
            el: "#tutorial-info-box-content",
            components: { stepContent: step.infoBoxComponent },
            template: '<step-content :propobject="propsValue" />',
            computed: {
                propsValue: function () {
                    return props_1;
                }
            }
        });
    }
    else {
        infoBoxContent.classList.add("tutorial__info-box");
        if (step.boxTitle) {
            var title = document.createElement("p");
            title.classList.add("info-box__title");
            title.innerHTML = step.boxTitle;
            infoBoxContent.appendChild(title);
        }
        var text = document.createElement("p");
        text.classList.add("info-box__text");
        text.innerHTML = step.boxText;
        infoBoxContent.appendChild(text);
        var expandableButton = createExitTutorialButton();
        infoBoxContent.appendChild(expandableButton);
    }
    positionInfoBox(infoBox, position, overlay);
    return infoBox;
}
function positionInfoBox(infoBox, position, overlay) {
    var infoBoxMargin = 10;
    //Max-size logic
    var widthLimit = 400;
    var heightLimit = 400;
    var maxWidth = window.innerWidth - infoBoxMargin > widthLimit
        ? widthLimit
        : window.innerWidth - infoBoxMargin * 2;
    var maxHeight = 300;
    infoBox.style.height = maxHeight;
    infoBox.style.display = "flex";
    //horizontal
    var spaceLeft = position.x;
    var spaceRight = window.innerWidth - (position.x + position.width);
    if (spaceLeft > spaceRight) {
        infoBox.style.right = infoBoxMargin + "px";
        infoBox.style.width = maxWidth + "px";
        //Max-size logic
        infoBox.style.setProperty("justify-content", "flex-end");
    }
    else {
        infoBox.style.left = infoBoxMargin + "px";
        infoBox.style.width = maxWidth + "px";
        //Max-size logic
        infoBox.style.setProperty("justify-content", "flex-start");
    }
    //vertical
    var spaceTop = position.y;
    var spaceBottom = window.innerHeight - (position.y + position.height);
    console.log(spaceTop + " " + spaceBottom, "top - bottom");
    if (spaceTop > spaceBottom) {
        infoBox.style.removeProperty("top");
        infoBox.style.bottom =
            overlay.getBoundingClientRect().height + highlightPadding / 8 + "px";
        //Max-size logic
        infoBox.style.setProperty("align-items", "flex-end");
        maxHeight =
            spaceTop > heightLimit ? heightLimit : spaceTop - infoBoxMargin * 2;
    }
    else {
        infoBox.style.removeProperty("bottom");
        infoBox.style.top =
            overlay.getBoundingClientRect().height + highlightPadding / 8 + "px";
        //Max-size logic
        infoBox.style.setProperty("align-items", "flex-start");
        maxHeight =
            spaceBottom > heightLimit ? heightLimit : spaceBottom - infoBoxMargin * 2;
    }
    //Max-size logic
    infoBox.style.height = maxHeight;
}
/*******
 * Creates a textOnly infoBox.
 *
 * used when step.type == textOnly
 *
 * this infoBox is used to display text for the user.
 *
 * it is only used when no action is required from the user.
 *
 * it is not used with a highlight / highlighted element.
 *
 *  */
function createTextInfoBox(overlay, step) {
    var infoBox = document.createElement("div");
    infoBox.classList.add("tutorial__info-box--textonly");
    //Add infoBox to the overlay
    overlay.appendChild(infoBox);
    if (step.infoBoxComponent) {
        var infoBoxContent = document.createElement("div");
        infoBoxContent.id = "tutorial-info-box-content";
        infoBox.appendChild(infoBoxContent);
        //Allow for component props to be passed
        var props_2 = { boxTitle: step.boxTitle, boxText: step.boxText };
        console.log("props", props_2);
        if (step.componentProps) {
            props_2 = __assign(__assign({}, props_2), step.componentProps);
        }
        //infoBoxContent.innerHTML = `<step-content :propobject="`+ props + `" />`;
        infoBoxContent.style.setProperty("width", "100%");
        infoBoxContent.style.setProperty("height", "100%");
        vueInstance = new vue_esm_browser_js_1.default({
            el: "#tutorial-info-box-content",
            components: { stepContent: step.infoBoxComponent },
            template: '<step-content :propobject="propsValue" />',
            computed: {
                propsValue: function () {
                    return props_2;
                }
            }
        });
    }
    else {
        //Add title to the infoBox
        if (step.boxTitle) {
            var title = document.createElement("p");
            title.classList.add("info-box__title");
            title.innerHTML = step.boxTitle;
            infoBox.appendChild(title);
        }
        //Add text to the infoBox
        var text = document.createElement("p");
        text.classList.add("info-box__text");
        text.innerHTML = step.boxText;
        infoBox.appendChild(text);
        //Add button to the infoBox
        var btn = document.createElement("button");
        btn.id = "tutorial__button";
        btn.classList.add("info-box__button");
        btn.innerHTML = step.buttonText || "OK";
        infoBox.appendChild(btn);
    }
}
// ======= HELPER FUNCTIONS =======
/*******
 * Tries to get the target element multiple times
 *  */
function getTarget(selector) {
    var _this = this;
    return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
        var target, targetArr, i, intervalId;
        return __generator(this, function (_a) {
            targetArr = document.querySelectorAll(selector);
            setTimeout(function () {
                targetArr = document.querySelectorAll(selector);
                if ((targetArr === null || targetArr === void 0 ? void 0 : targetArr.length) > 0) {
                    targetArr.forEach(function (item) {
                        if (item.clientHeight > 0) {
                            target = item;
                            resolve(item);
                        }
                    });
                    if (target) {
                        return;
                    }
                }
            }, 100);
            i = 0;
            intervalId = setInterval(function () {
                i++;
                if (i > 10) {
                    clearInterval(intervalId);
                    reject();
                    return;
                }
                targetArr = document.querySelectorAll(selector);
                if ((targetArr === null || targetArr === void 0 ? void 0 : targetArr.length) > 0) {
                    targetArr.forEach(function (item) {
                        if (item.clientHeight > 0) {
                            target = item;
                            resolve(item);
                            clearInterval(intervalId);
                        }
                    });
                }
            }, 500);
            return [2 /*return*/];
        });
    }); });
}
function scrollIntoView(element) {
    return new Promise(function (resolve, reject) {
        element.scrollIntoView({ behavior: "smooth", block: "end" });
        resolve();
    });
}
function startLoading() {
    var loadingOverlay;
    loadingOverlay = document.getElementById('tutorial-overlay-loading');
    if (!loadingOverlay) {
        loadingOverlay = document.createElement('div');
        loadingOverlay.id = 'tutorial-overlay-loading';
        loadingOverlay.classList.add('tutorial-overlay-loading');
        var wheel = document.createElement('div');
        wheel.classList.add('tutorial-loading-wheel');
        loadingOverlay.appendChild(wheel);
        var blockOverlay = document.getElementById('tutorial-overlay-block');
        blockOverlay.appendChild(loadingOverlay);
    }
}
function stopLoading() {
    var loadingOverlay = document.getElementById('tutorial-overlay-loading');
    if (loadingOverlay) {
        loadingOverlay.parentElement.removeChild(loadingOverlay);
    }
}
