<template>
  <nav class="drawer-container">
    <div
      :style="drawerStyles"
      id="own-navigation-drawer"
      class="own-navigation-drawer own-navigation-drawer--open theme--dark"
    >
      <div v-show="drawer">
        <slot> </slot>
      </div>
    </div>

    <div
      v-if="isMobile && drawer"
      id="own-drawer-scrim"
      class="own-drawer-scrim"
      @click="handleScrimClick($event)"
    ></div>
  </nav>
</template>

<script>
export default {
  name: "ownNavigationDrawer",

  data() {
    return {
      contentIsRepositioned: false,
    };
  },

  computed: {
    drawer() {
      return this.$store.state.drawer;
    },

    isMobile() {
      console.log(
        "window = ",
        this.windowWidth,
        "  //  mb brkp ",
        this.mobileBreakPoint,
        this.windowWidth < this.mobileBreakPoint
      );

      if (
        this.windowWidth > this.mobileBreakPoint &&
        !this.contentIsRepositioned &&
        this.drawer
      ) {
        console.log("opened ismobile");
        this.openDrawer();
      }

      return this.windowWidth < this.mobileBreakPoint;
    },

    drawerStyles() {
      return {
        width: this.drawerWidth,
      };
    },
  },

  watch: {
    drawer(newValue, oldValue) {
      if (newValue) {
        this.openDrawer();
      } else {
        this.closeDrawer();
      }
    },

    $route(to, from) {
      this.handleResize();
      if (this.isMobile && this.drawer) {
        this.setDrawer(false);
      }
    },
  },

  mounted() {
    //Make sure appbar and main have been loaded
    this.getTarget("main > .v-main").then(async () => {
      await this.getTarget("#app-bar");
      console.log("got navbar");
      //Set the default value for drawer
      this.handleResize(window.innerWidth);
      //Set the correct value for drawer when window size changes
      window.addEventListener("resize", () => {
        console.log("resize event");
        this.handleResize();
      });
    });
  },

  methods: {
    setDrawer(bool) {
      this.$store.commit("SET_DRAWER", bool);
    },

    async openDrawer() {
      let drawerEl = document.getElementById("own-navigation-drawer");
      drawerEl.classList.add("own-navigation-drawer--open");

      if (window.innerWidth > this.mobileBreakPoint) {
        //position content to avoid overlapping
        let mainEl = await this.getTarget("main > .v-main");
        let appBarEl = await this.getTarget("#app-bar");

        this.contentIsRepositioned = true;
      }
    },

    closeDrawer() {
      let drawerEl = document.getElementById("own-navigation-drawer");
      drawerEl.classList.remove("own-navigation-drawer--open");

      if (this.contentIsRepositioned) {
        //remove content positioning to avoid overlapping
        this.contentIsRepositioned = false;

        let mainEl = document.querySelector("main > .v-main");
        let appBarEl = document.querySelector("#app-bar");

        mainEl.style.paddingLeft = 0;
        appBarEl.style.left = 0;
      }
    },

    handleResize() {
      let width = window.innerWidth;
      this.windowWidth = width;

      if (width < this.mobileBreakPoint) {
        this.closeDrawer();
        this.setDrawer(false);
      } else {
        this.setDrawer(true);
      }
    },

    handleScrimClick(event) {
      console.log("scrim", event.target.id);
      if (event.target.id == "own-drawer-scrim") {
        this.setDrawer(false);
      }
    },

    getTarget(selector) {
      return new Promise(async (resolve, reject) => {
        let target;
        let targetArr = document.querySelectorAll(selector);

        setTimeout(() => {
          targetArr = document.querySelectorAll(selector);

          if (targetArr?.length > 0) {
            targetArr.forEach((item) => {
              if (item.clientHeight > 0) {
                target = item;
                resolve(item);
              }
            });

            if (target) {
              return;
            }
          }
        }, 100);

        let i = 0;
        let intervalId = setInterval(() => {
          i++;
          if (i > 5) {
            clearInterval(intervalId);
            reject();
            return;
          }

          targetArr = document.querySelectorAll(selector);
          if (targetArr?.length > 0) {
            targetArr.forEach((item) => {
              if (item.clientHeight > 0) {
                target = item;
                resolve(item);
                clearInterval(intervalId);
              }
            });
          }
        }, 500);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.own-navigation-drawer {
  overflow-y: scroll;
  z-index: 7;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  transform: translateX(-100%);
  transition: transform 0.3s;

  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg");
}
.own-navigation-drawer--open {
  transform: translateX(0);
}

.own-drawer-scrim {
  z-index: 6;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.24);
}

#app .theme--light.v-list {
  background: inherit;
  color: #fff;
}

#app
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff;
}

#app .theme--dark.v-list,
#app .theme--dark.v-sheet {
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>
