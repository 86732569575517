<template>
  <ListagemBase
    nomePlural="paises"
    nomeSingular="Pais"
    :headers="headers"
  >
  </ListagemBase>
</template>

<script>
import PaisDataService from "@/services/locais/PaisDataService"
const DataService = PaisDataService

import ListagemBase from "@/components/base/Crud/ListagemBase"

export default {
  name: "ListagemPaises",
  components: {ListagemBase},
  data(){
    return{
      DataService: DataService,
      headers: [
        { text: this.$t("Nome"), value: "nome", sortable: "false" },
        { text: this.$t("Moeda"), value: "moeda", sortable: "false" },
  
        { text: this.$t("Acoes"), value: "actions", sortable: "false" },
      ],
    }
  }
};
</script>

<style>
</style>
