<template>
  <div>
    <v-dialog v-model="modalImagem" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <slot>
          <!-- Aqui vem o activator definido em cada chamada do component -->

          <!--ESSE BTN É FALLBACK CASO NÃO SEJA DEFINIDO NADA  -->
          <v-btn color="primary" v-bind="attrs" v-on="on" dark>
            Open Dialog
          </v-btn>
        </slot>
      </template>
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="9" class="mx-auto mt-2">
                <base-material-card class="v-card-profile" :avatar="imagem">
                  <v-card-text class="text-center">
                    <h4 class="display-2 font-weight-light mb-3 black--text">
                      Inserir nova imagem
                    </h4>
                  </v-card-text>
                </base-material-card>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-file-input
                  v-model="fileInput"
                  accept="image/png, image/jpeg, image/bmp"
                  :placeholder="$t('Fazer_upload')"
                  prepend-icon="mdi-camera"
                  :label="$t('Imagem')"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            height="39"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            text
            @click="$emit('modalImagem', false)"
          >
            {{ $t("Cancelar") }}
          </v-btn>

          <v-btn
            class="
              ma-1
              font-weight-bold
              text-none
              btn-primary
              bg-gradient-primary
              py-3
              px-6
              ms-3
            "
            height="39"
            :disabled="!fileInput"
            text
            @click="submitForm()"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataService from "@/services/ComumDataService";

export default {
  name: "ModalImagem",
  props: {
    modalImagem: { type: Boolean, default: false, required: true },
    id: { type: Number, required: true },
    imagem: { type: String, required: true },
  },
  data() {
    return {
      dialog: false,
      fileInput: null,
    };
  },

  methods: {
    submitForm() {
      DataService.uploadInquilino({
        id: this.id,
        file: this.fileInput,
      }).then(() => {
        this.$forceUpdate();
        this.$emit("modalImagem", false);
        this.$swal.fire("Sucesso", "Imagem alterada !", "success");
      });
    },
  },
};
</script>

<style>
</style>
