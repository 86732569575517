<template v-slot:[`item`]="{ item }"
>
  <div>
    <slot name="expandable-content" v-bind:item="item">
      <v-list>
        <v-list-item>
          <v-list-item-avatar> </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Funcionario_ponto") }}</v-list-item-title>
            <v-list-item-subtitle>{{
              $t("Detalhes_do_registro")
            }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action> </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-row>
        <v-col cols="12" md="6" sm="8">
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-col cols="12" class="d-flex align-center flex-column">
                <v-img
                  max-height="30"
                  max-width="30"
                  :src="require('@/assets/perm_identity-24px-green.svg')"
                ></v-img>
                <h3>
                  {{ $t("Data") }}
                </h3>
              </v-col>
              <v-col cols="12" class="">
                {{ $t("Data") + ": " }}
                {{ recurso.data | dataHoraParaLeitura }}
              </v-col>
              <v-col cols="12" class="" v-if="recurso.justificativa">
                {{ $t("Justificativa") + ": " }}
                {{ recurso.justificativa || $t("Nao_definido") }}
              </v-col>
              <v-col cols="12" class="" v-if="recurso.justificativa">
                {{ $t("Responsavel") + ": " }}
                {{ recurso.reponsavel.nome || $t("Nao_definido") }}
              </v-col>

              <v-col cols="12" class="" v-if="!recurso.justificativa">
                <ShowImage :url="recurso.imagem">
                  <v-avatar color="primary" size="150"
                    ><v-img
                      contain
                      max-height="151"
                      max-width="250"
                      :src="recurso.imagem"
                    ></v-img
                  ></v-avatar>
                </ShowImage>
              </v-col>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              class="text-left d-flex align-center justify-center"
            >
              <p class="item-text">
                <strong style="font-weight: 700"
                  >{{ $t("Competencia") }}:</strong
                >
                {{ recurso.competencia | dataParaLeitura }}
              </p>

              <v-divider vertical class="mx-6"> </v-divider>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6" sm="6">
          <MapaLocalPonto style="max-height: 350px" :item="recurso" />
        </v-col>
      </v-row>
    </slot>
  </div>
</template>
<script>
import ListagemBase from "@/components/base/Crud/ListagemBase";
import MapaLocalPonto from "@/components/base/proprios/MapaLocalPonto";
import ShowImage from "@/components/base/proprios/ShowImage";
export default {
  name: "ConteudoExpansionPonto",
  components: { MapaLocalPonto, ShowImage, ListagemBase },
  props: {
    item: {},
    recurso: {},
  },
  methods: {
    share(item) {
      this.$emit("share", item);
    },

    invert(item) {
      this.$emit("invert", item);
    },
  },
};
</script>
