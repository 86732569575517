export default {
  data() {
    return {};
  },

  methods: {
    sortArrayChronologically(array, attributeName, attributeFormat = false) {
      if (!array) {
        return [];
      }

      return array.sort((aObject, bObject) => {
        let a;
        let b;
        if (attributeFormat == "dd/mm/yyyy") {
          a = new Date(this.dataLeituraParaYMD(aObject[attributeName]));
          b = new Date(this.dataLeituraParaYMD(bObject[attributeName]));
        } else {
          a = new Date(aObject[attributeName]);
          b = new Date(bObject[attributeName]);
        }

        if (a < b) {
          return -1;
        } else {
          return 1;
        }
      });
    },
    dataLeituraParaYMD(data) {
      var dia = data.split("/")[0];
      var mes = data.split("/")[1];
      var ano = data.split("/")[2];

      let string = ano + "-" + mes + "-" + dia;
      return string;
    },

    getStringDiaDaSemana(dateObj) {
      let dia = dateObj.getDay();
      switch (dia) {
        case 6:
          return "DOMINGO";
        case 0:
          return "SEGUNDA";
        case 1:
          return "TERCA";
        case 2:
          return "QUARTA";
        case 3:
          return "QUINTA";
        case 4:
          return "SEXTA";
        case 5:
          return "SABADO";
      }
    },

    maskEmailCpf(value) {
      //if value includes a letter
      if (value.match(/[a-z]/i)) {
        return null;
      } else {
        return "###.###.###-##";
      }
    },
  },
};
