var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"label":_vm.$t(_vm.label),"outlined":"","items":_vm.recursos,"item-text":(item) =>
      item.cidade.nome +
      '/' +
      item.cidade.estado.sigla +
      ' - ' +
      item.cfop.cfop +
      ' - ' +
      item.codigoTributacao,"return-object":"","dense":"","flat":"","filled":"","solo":""},on:{"change":_vm.emit},model:{value:(_vm.selecionado),callback:function ($$v) {_vm.selecionado=$$v},expression:"selecionado"}})
}
var staticRenderFns = []

export { render, staticRenderFns }