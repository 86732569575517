<template>
  <CadastroBase
    nomePlural="Inquilinos"
    nomeSingular="Inquilino"
    :submitPersonalizado="true"
    @submitSuccessInsert="emitSubmitSuccess"
    @submitErrorInsert="emitSubmitError"
    @closeModalInsert="closeModal"
  >
    <ErrorAlert :errors="errors" />
    <v-col cols="12">
      <v-expansion-panels class="mt-4">
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t("Dados_da_empresa") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <CamposTipoNregistro
                  :nRegistro="recurso.nregistro"
                  :tipoCliente="recurso.tipoCliente"
                  @nRegistro="recurso.nregistro = $event"
                  @tipo="recurso.tipoCliente = $event"
                  :disabled="false"
                ></CamposTipoNregistro>
              </v-col>
              <template v-if="recurso.tipoCliente == 'PESSOAJURIDICA'">
                <v-col cols="12">
                  <v-text-field
                    v-model="recurso.razaoSocial"
                    :label="$t('RazaoSocial')"
                    name="razaoSocial"
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="recurso.fantasia"
                    :label="$t('Fantasia')"
                    name="fantasia"
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="recurso.inscricaoEstadual"
                    :label="$t('nIe')"
                    name="nIe"
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="recurso.inscricaoMunicipal"
                    :label="$t('nIm')"
                    name="nIm"
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                  />
                </v-col>
              </template>

              <template v-else>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="recurso.fantasia"
                    :label="$t('Nome')"
                    name="nome"
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="recurso.razaoSocial"
                    :label="$t('Sobrenome')"
                    name="razaoSocial"
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="recurso.nIe"
                    :label="$t('RG')"
                    name="rg"
                    v-mask="'#.###-###'"
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                  ></v-text-field>
                </v-col>
              </template>

              <v-col class="d-flex" cols="12" md="4">
                <v-select
                  v-model="recurso.tipoContratacao"
                  :items="itensTipoContratacao"
                  :label="$t('Tipo_contratacao')"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t("Endereco_empresa") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  :label="$t('Descricao')"
                  name="empresa_descricao"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                />
              </v-col>
              <v-col cols="12" md="3">
                <BuscaCep
                  @cidades="cidades = $event"
                  @bairro="recurso.endereco.bairro = $event"
                  @cep="recurso.endereco.cep = $event"
                  @cidade="recurso.endereco.cidade = $event"
                  @logradouro="recurso.endereco.logradouro = $event"
                  :cepProp="recurso.endereco.cep || ''"
                ></BuscaCep>
              </v-col>
              <v-col cols="12" md="5">
                <v-autocomplete
                  :label="$t('Cidade')"
                  outlined
                  v-model="recurso.endereco.cidade"
                  :items="cidades"
                  return-object
                  item-text="nome"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="recurso.endereco.bairro"
                  :label="$t('Bairro')"
                  name="bairro"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="recurso.endereco.logradouro"
                  :label="$t('Logradouro')"
                  name="logradouro"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="recurso.endereco.numero"
                  :label="$t('Numero')"
                  name="empresa_numero"
                  type="number"
                  @blur="buscaGeoCode"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="recurso.endereco.complemento"
                  :label="$t('Complemento')"
                  name="empresa_complemento"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="recurso.endereco.latitude"
                  :label="$t('Latitude')"
                  name="latitude"
                  type="number"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="recurso.endereco.longitude"
                  :label="$t('Longitude')"
                  name="longitude"
                  type="number"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </CadastroBase>
</template>

<script>
import BuscaCep from "@/components/base/proprios/BuscaCep";
import InquilinoDataService from "@/services/software/InquilinoDataService";
const DataService = InquilinoDataService;

import CadastroBase from "@/components/base/Crud/CadastroBase";

import CamposTipoNregistro from "@/components/base/proprios/CamposTipoNregistro";

export default {
  name: "CadastroInquilino",

  components: { CamposTipoNregistro, CadastroBase, BuscaCep },

  data() {
    return {
      DataService: DataService,
      submited: false,
      senhaConfirma: "",
      cidades: [],
      errors: [],
      recurso: {
        endereco: {
          cep: "",
          cidade: {},
          bairro: "",
          logradouro: "",
        },
        fantasia: "",
        razaoSocial: "",
        tipoCliente: "",
        tipoContratacao: "",
        nregistro: "",
        imagem: "string",
        imagem64: "string",
        gestorCep: "",
        gestorCidade: {},
        gestorBairro: "",
        gestorLogradouro: "",
      },

      itensTipo: [
        { text: this.$t("Pessoa_fisica"), value: 1 },
        { text: this.$t("Pessoa_juridica"), value: 2 },
      ],
      itensTipoContratacao: [
        { text: this.$t("RRP"), value: "RRP" },
        { text: this.$t("GESTAOEFETIVO"), value: "GESTAOEFETIVO" },
        //{ text: this.$t("RRPPLUS"), value: "RRPPLUS" },
        //{ text: this.$t("GESTAOEFETIVOPLUS"), value: "GESTAOEFETIVOPLUS" },
      ],
    };
  },

  methods: {
    submitForm() {
      return new Promise((resolve, reject) => {
        DataService.create(this.limpaMascara(this.recurso))
          .then((response) => {
            this.erros = [];
            this.submited = true;
            this.senhaConfirma = "";
            this.recurso = {};
            resolve();
          })
          .catch((error) => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

            this.errors = error.response.data.errors;
            reject();
          });
      });
    },

    emitSubmitSuccess() {
      this.$emit("submitSuccessInsert");
    },
    emitSubmitError() {
      this.$emit("submitErrorInsert");
    },
    closeModal() {
      this.$emit("closeModalInsert");
    },
    buscaGeoCode() {
      if (this.recurso.endereco.numero.length >= 1) {
        ComumDataService.buscaGeoCode(
          this.enderecoParaString(this.recurso.endereco)
        ).then((response) => {
          if (response.data.results[0]) {
            this.recurso.endereco.latitude =
              response.data.results[0].geometry.location.lat;
            this.recurso.endereco.longitude =
              response.data.results[0].geometry.location.lng;
          }
        });
      }
    },
    enderecoParaString(endereco) {
      var string = "";

      if (endereco.logradouro) string = endereco.logradouro;

      if (endereco.numero) string = string + ", " + endereco.numero;

      if (endereco.bairro) string = string + ", " + endereco.bairro;

      return string;
    },
  },
};
</script>

<style scoped>
</style>
