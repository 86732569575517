import ClienteDataService from "@/services/comercial/ClienteDataService";
import Axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pathDataService: "@/services/locais/PaisDataService",
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    // barImage:
    //   "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",
    token: localStorage.getItem("user-token") || "",
    status: "",
    inquilino: {},
    permissoes: localStorage.getItem("permissoes") || "",
    skipTutorialPartModal: false,
    pendingTutorial: [],
    user: {
      id: 0,
      email: "",
      statusComum: "",
      nome: "",
      imagem: "",
      imagem64: "",
      permissoes: localStorage.getItem("permissoes") || "",
    },
  },
  mutations: {

    REMOVE_TOKEN(state) {
      state.user = null;
      state.token = "";
      state.status = "";
      localStorage.setItem("user-token", "");
      localStorage.removeItem("permissoes", []);
      localStorage.removeItem("user", []);
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
      localStorage.setItem("user-token", payload);
      Axios.defaults.headers.common["Authorization"] = payload;
    },

    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    AUTH_REQUEST: (state) => {
      state.status = "loading";
    },
    AUTH_SUCCESS: (state, token) => {
      state.status = "success";
      state.token = token;
    },
    AUTH_ERROR: (state) => {
      state.status = "error";
    },
    SET_USER: (state, response) => {
      state.user = response.data;

      state.inquilino = response.data.inquilino;
    },
    SET_PERMISSOES: (state, response) => {
      let permissoes = [];
      response.data.forEach((value) => {
        permissoes.push(value.descricao);
      });

      localStorage.setItem("permissoes", permissoes);
      localStorage.setItem("horaLogin", new Date());
      state.permissoes = permissoes;
    },

    SET_SKIP_OPTION_MODAL: (state, payload) => {
      state.skipTutorialPartModal = payload;
    },
    SET_PENDING_TUTORIAL: (state, payload) => {
      state.pendingTutorial = payload;
    },
  },
  actions: {
    AUTH_REQUEST: ({ commit, dispatch }, user) => {
      console.log("AUTH_REQUEST");
      return new Promise((resolve, reject) => {
        // The Promise used for router redirect in login
        commit("AUTH_REQUEST");
        Axios({ url: "/login", data: user, method: "POST" })
          .then((resp) => {
            const token = resp.headers.authorization;

            commit("SET_TOKEN", token);
            commit("AUTH_SUCCESS", token);

            dispatch("USER_REQUEST");

            // you have your token, now log in your user :)
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR", err);
            localStorage.removeItem("user-token"); // if the request fails, remove any possible user token if possible
            reject(err);
          });
      });
    },

    AUTH_LOGOUT: ({ commit, dispatch }) => {
      return new Promise((resolve) => {
        commit("REMOVE_TOKEN");

        delete Axios.defaults.headers.common["Authorization"];

        resolve();
      });
    },

    USER_REQUEST: ({ commit }) => {
      ClienteDataService.getUser()
        .then((response) => {
          console.log(response);
          commit("SET_USER", response);
        })
        .catch((e) => {
          console.log(e + "getUser");
        });

      ClienteDataService.getPermissoes()
        .then((response) => {
          commit("SET_PERMISSOES", response);
        })
        .catch((e) => console.log(e + "getPermissoes"));
    },

    SET_SKIP_OPTION_MODAL: ({ commit }, payload) => {
      console.log("called action");
      commit("SET_SKIP_OPTION_MODAL", payload);
    },

    SET_PENDING_TUTORIAL: ({ commit }, payload) => {
      commit("SET_PENDING_TUTORIAL", payload);
    },
  },

  getters: {
    isAuthenticated: (state) => state.token && state.token.length > 10,
    authStatus: (state) => state.status,
    quantidadePermissoes: (state) => state.user.permissoes.length,
    locale: (state) => {
      return "pt-br";
    },
  },
});
