var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"pl-3"},[_vm._l((_vm.foneCount),function(i,index){return [_c('v-col',{key:i,attrs:{"cols":"12","md":"5"}},[_c('v-text-field-simplemask',{attrs:{"label":_vm.$t('Telefone') + ' - ' + (index + 1),"properties":{
          prefix: '',
          suffix: '',
          readonly: false,
          disabled: false,
          outlined: false,
          clearable: true,
          placeholder: '',
        },"options":{
          inputMask: '(##) #####-####',
          outputMask: '###########',
          empty: null,
          applyAfter: false,
          alphanumeric: true,
          lowerCase: false,
        }},on:{"blur":[function($event){return _vm.$emit('telefones', _vm.telefones)},function($event){return _vm.$emit('telefones', _vm.telefones)}]},model:{value:(_vm.telefones[index].fone),callback:function ($$v) {_vm.$set(_vm.telefones[index], "fone", $$v)},expression:"telefones[index].fone"}})],1),_c('v-col',{key:'desc' + i,attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Descricao') + ' - ' + (index + 1),"name":'telefone' + (index + 1)},on:{"blur":function($event){return _vm.$emit('telefones', _vm.telefones)}},model:{value:(_vm.telefones[index].descricao),callback:function ($$v) {_vm.$set(_vm.telefones[index], "descricao", $$v)},expression:"telefones[index].descricao"}})],1)]}),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"2"}},[(_vm.foneCount > 1)?_c('v-btn',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"dense":"","flat":"","filled":"","solo":""},on:{"click":_vm.removeFone}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus ")])],1):_vm._e(),_c('v-btn',{staticClass:"ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3",on:{"click":_vm.addFone}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus-circle ")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }