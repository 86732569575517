import Axios from "axios";

class FuncionarioAvisoPrevioDataService {

  getAll() {
    return Axios.get('funcionarioAvisoPrevios');
  }

  getPage(params) {
    return Axios.get('funcionarioAvisoPrevios/page', { params })
  }

  create(params) {
    return Axios.post('funcionarioAvisoPrevios', params)
  }

  update(params) {
    console.log(params)
    return Axios.put('funcionarioAvisoPrevios/' + (params.id), params)
  }

  find(id) {
    return Axios.get(`funcionarioAvisoPrevios/${id}`)
  }

  delete(id) {
    return Axios.delete(`funcionarioAvisoPrevios/${id}`);
  }

  export(params) {
    return Axios.put("funcionarioAvisoPrevios/export", params);
  }


}

export default new FuncionarioAvisoPrevioDataService()
