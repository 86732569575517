import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "R$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {

  data() {
    return {
      monetaryMask: currencyMask
    }
  },

  methods: {
    limpaMascara(payload) {
      let recurso;
      recurso = payload;

      if (recurso.email) {
        let value = recurso.email;

        //se o valor inclui uma letra
        if (value.match(/[a-zA-Z]/)) {

        } else {
          //remove todos os caracteres que nao sejam numeros
          value = value.replace(/\D/g, '');

          recurso.email = value;
        }
      }

      //Retirar atributos não essenciais
      //Tratamento para mandar só id de objetos
      if (recurso.funcionario) {
        recurso.funcionario = { id: recurso.funcionario.id };
      }

      //Formatação de campos
      if (recurso.seguroInicio) {
        recurso.seguroInicio = recurso.seguroInicio.replace(" ", "T");
      }

      if (recurso.seguroVencimento) {
        recurso.seguroVencimento = recurso.seguroVencimento.replace(" ", "T");
      }

      if (recurso.tacografoData) {
        recurso.tacografoData = recurso.tacografoData.replace(" ", "T");
      }

      if (recurso.tacografoVencimento) {
        recurso.tacografoVencimento = recurso.tacografoVencimento.replace(" ", "T");
      }

      if (typeof recurso === "string") {
        let str = recurso;

        str = str.toString();
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");
        console.log("limpaString", str);
        recurso = str;
      }

      if (recurso.salarioBase) {
        let str = recurso.salarioBase;

        str = str.toString();
        str = str.replace("$", "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/([A-z])/, "");


        recurso.salarioBase = str;
      }

      if (recurso.valor) {
        let str = recurso.valor;

        str = str.toString();
        str = str.replace("$", "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/([A-z])/, "");


        recurso.valor = str;
      }
      if (recurso.saldo) {
        let str = recurso.saldo;

        str = str.toString();
        str = str.replace("$", "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/([A-z])/, "");


        recurso.saldo = str;
      }

      if (recurso.juros) {
        let str = recurso.juros;

        str = str.toString();
        str = str.replace("$", "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/([A-z])/, "");


        recurso.juros = str;
      }

      if (recurso.multa) {
        let str = recurso.multa;

        str = str.toString();
        str = str.replace("$", "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/([A-z])/, "");


        recurso.multa = str;
      }

      if (recurso.desconto) {
        let str = recurso.desconto;

        str = str.toString();
        str = str.replace("$", "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/([A-z])/, "");


        recurso.desconto = str;
      }

      if (recurso.outrosValores) {
        let str = recurso.outrosValores;

        str = str.toString();
        str = str.replace("$", "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/([A-z])/, "");


        recurso.outrosValores = str;
      }

      if (recurso.telefones && recurso.telefones.length > 0) {

        let telefones = [];

        recurso.telefones.forEach(telefone => {
          if (telefone) {
            let str = telefone;

            str.fone = str.fone.toString();
            str.fone = str.fone.replace(/\$/gi, "");
            str.fone = str.fone.replace(/\,/gi, "");
            str.fone = str.fone.replace(/\./gi, "");
            str.fone = str.fone.replace(/\-/gi, "");
            str.fone = str.fone.replace(/\(/gi, "");
            str.fone = str.fone.replace(/\)/gi, "");
            str.fone = str.fone.replace(/([A-z])/, "");

            telefones.push(str);
          }
        });

        recurso.telefones = telefones;
      }

      if (recurso.rg) {
        let str = recurso.rg;

        str = str.toString();
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");

        recurso.rg = str;
      }

      if (recurso.nRegistro) {
        let str = recurso.nRegistro;

        str = str.toString();
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");

        recurso.nRegistro = str;
      }
      if (recurso.nregistro) {
        let str = recurso.nregistro;

        str = str.toString();
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");

        recurso.nregistro = str;
      }

      if (recurso.nIe) {
        let str = recurso.nIe;

        str = str.toString();
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");

        recurso.nIe = str;
      }

      if (recurso.nIm) {
        let str = recurso.nIm;

        str = str.toString();
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");

        recurso.nIm = str;
      }

      if (recurso.pis) {
        let str = recurso.pis;

        str = str.toString();
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");

        recurso.pis = str;
      }

      if (recurso.gestorCpf) {
        let str = recurso.gestorCpf;

        str = str.toString();
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");

        recurso.gestorCpf = str;
      }

      if (recurso.cpf) {
        let str = recurso.cpf;

        str = str.toString();
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");

        recurso.cpf = str;
      }

      if (recurso.cep) {
        let str = recurso.cep;

        str = str.toString();
        str = str.replace(/\$/gi, "");
        str = str.replace(/\,/gi, "");
        str = str.replace(/\./gi, "");
        str = str.replace(/\-/gi, "");
        str = str.replace(/([A-z])/, "");

        recurso.cep = str;
      }

      if (recurso.pessoa) {
        if (recurso.pessoa.telefones) {
          let telefones = [];
          recurso.pessoa.telefones.forEach(telefone => {
            let str = telefone;

            str.fone = str.fone.toString();
            str.fone = str.fone.replace(/\$/gi, "");
            str.fone = str.fone.replace(/\,/gi, "");
            str.fone = str.fone.replace(/\./gi, "");
            str.fone = str.fone.replace(/\-/gi, "");
            str.fone = str.fone.replace(/\(/gi, "");
            str.fone = str.fone.replace(/\)/gi, "");
            str.fone = str.fone.replace(/([A-z])/, "");

            telefones.push(str);
          });

          recurso.pessoa.telefones = telefones;
        }

        if (recurso.pessoa.cep) {
          let str = recurso.pessoa.cep;

          str = str.toString();
          str = str.replace(/\$/gi, "");
          str = str.replace(/\,/gi, "");
          str = str.replace(/\./gi, "");
          str = str.replace(/\-/gi, "");
          str = str.replace(/([A-z])/, "");

          recurso.pessoa.cep = str;
        }

        if (recurso.pessoa.cpf) {
          let str = recurso.pessoa.cpf;

          str = str.toString();
          str = str.replace(/\$/gi, "");
          str = str.replace(/\,/gi, "");
          str = str.replace(/\./gi, "");
          str = str.replace(/\-/gi, "");
          str = str.replace(/([A-z])/, "");

          recurso.pessoa.cpf = str;
        }

        if (recurso.pessoa.rg) {
          let str = recurso.pessoa.rg;

          str = str.toString();
          str = str.replace(/\$/gi, "");
          str = str.replace(/\,/gi, "");
          str = str.replace(/\./gi, "");
          str = str.replace(/\-/gi, "");
          str = str.replace(/([A-z])/, "");

          recurso.pessoa.rg = str;
        }
      }

      if (recurso.enderecos && recurso.enderecos.length > 0) {
        let enderecos = [];
        recurso.enderecos.forEach(endereco => {

          if (endereco.cep) {
            let str = endereco.cep;
            str = str.toString();
            str = str.replace(/\$/gi, "");
            str = str.replace(/\,/gi, "");
            str = str.replace(/\./gi, "");
            str = str.replace(/\-/gi, "");
            str = str.replace(/([A-z])/, "");

            endereco.cep = str;
            enderecos.push(endereco);
          }

        });
      }

      return recurso;
    }
  }
};
