import Axios from "axios";

class AuthDataService{

  forgot(obj){
    return Axios.post('auth/forgot', obj)
  }

  refreshToken(){
    return Axios.post('auth/refresh_token')
  }

}


export default new AuthDataService()
