<template>
  <EditarBase
    nomeSingular="FuncionarioSindicato"
    nomePlural="FuncionarioSindicatos"
    @recurso="recurso = $event"
    :submitPersonalizado="true"
    @submitSuccess="emitSubmitSuccess"
    @submitError="emitSubmitError"
    @closeModalEdit="closeModalEdit"
  >
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-span class="opacity-5 text-dark">
            {{ $t("Descricao") }}
          </v-span>
          <v-text-field
            v-model="recurso.descricao"
            :label="$t('Descricao')"
            name="descricao"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>

        <v-col cols="12">
          <CriaSuperSet
            :podeEditarRecursoFinal="true"
            @recursos="recurso.rubricas = $event"
            :payload="recurso.rubricas"
            :usaRecursoBase="false"
            tituloFinais="Rubricas_exportacao_folha_pagamento"
            subtituloFinais="Clique_para_adicionar"
            :headersFinais="[
              {
                text: this.$t('Rubrica'),
                value: 'rubrica',
                sortable: 'true',
              },
              {
                text: this.$t('Tipo'),
                value: 'tipo',
                sortable: 'true',
              },

              {
                text: this.$t('Tipo_apuracao'),
                value: 'tipoApuracao',
                sortable: 'true',
              },

              { text: this.$t('Acoes'), value: 'actions', sortable: 'false' },
            ]"
            textoBotaoCadastro="Criar_item"
          >
            <template v-slot:default="slotProps">
              <!-- Form cria recurso final -->
              <v-col cols="12" md="12">
                <v-select
                  :items="itensParametrizacoes"
                  :label="$t('Tipo')"
                  id="tipo"
                  v-model="slotProps.recursoFinalAtual.tipo"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="
                  deveMostrarCampo(
                    'tipo_apuracao',
                    slotProps.recursoFinalAtual.tipo
                  )
                "
              >
                <v-select
                  :items="[
                    {
                      text: $t('Dia'),
                      value: 'DIA',
                    },
                    {
                      text: $t('Hora'),
                      value: 'HORA',
                    },
                  ]"
                  :label="$t('Tipo_apuracao')"
                  id="tipo_apuracao"
                  v-model="slotProps.recursoFinalAtual.tipoApuracao"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  :rules="rule_required"
                  v-model="slotProps.recursoFinalAtual.rubrica"
                  :label="$t('Rubrica')"
                  name="rubrica"
                  id="rubrica_cod"
                >
                </v-text-field>
              </v-col>
            </template>
            <template v-slot:formEditaRecursoFinal="slotProps">
              <v-col cols="12" md="12">
                <v-select
                  :items="itensParametrizacoes"
                  :label="$t('Tipo')"
                  v-model="slotProps.recursoFinalAtual.tipo"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="
                  deveMostrarCampo(
                    'tipo_apuracao',
                    slotProps.recursoFinalAtual.tipo
                  )
                "
              >
                <v-select
                  :items="[
                    {
                      text: $t('Dia'),
                      value: 'DIA',
                    },
                    {
                      text: $t('Hora'),
                      value: 'HORA',
                    },
                  ]"
                  :label="$t('Tipo_apuracao')"
                  v-model="slotProps.recursoFinalAtual.tipoApuracao"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  :rules="rule_required"
                  v-model="slotProps.recursoFinalAtual.rubrica"
                  :label="$t('Rubrica')"
                  name="rubrica"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-text-field>
              </v-col>
            </template>
          </CriaSuperSet>
        </v-col>

        <v-col cols="12">
          <CriaSuperSet
            :podeEditarRecursoFinal="true"
            @recursos="recurso.parametrizacoes = $event"
            :usaRecursoBase="false"
            :payload="recurso.parametrizacoes"
            tituloFinais="Parametrizacoes_calculo_ponto"
            subtituloFinais="Clique_para_adicionar"
            :headersFinais="[
              {
                text: this.$t('Tipo'),
                value: 'tipo',
                sortable: 'true',
              },
              {
                text: this.$t('Referencia'),
                value: 'referencia',
                sortable: 'true',
              },

              {
                text: this.$t('Ativo'),
                value: 'ativo',
                sortable: 'true',
              },

              { text: this.$t('Acoes'), value: 'actions', sortable: 'false' },
            ]"
            textoBotaoCadastro="Criar_item"
          >
            <template v-slot:default="slotProps">
              <!-- Form cria recurso final -->
              <v-col cols="12" md="12">
                <v-select
                  :items="[
                    { text: $t('COMPENSARHORAS'), value: 'COMPENSARHORAS' },
                    // { text: $t('BANCOHORAS'), value: 'BANCOHORAS' },
                    {
                      text: $t('HORAEXTRAFERIADOINTERMITENTE'),
                      value: 'HORAEXTRAFERIADOINTERMITENTE',
                    },
                    {
                      text: $t('HORAEXTRA100INTERMITENTE'),
                      value: 'HORAEXTRA100INTERMITENTE',
                    },
                    {
                      text: $t('CONVERTERCOMPENSAREMATRASO'),
                      value: 'CONVERTERCOMPENSAREMATRASO',
                    },
                    {
                      text: $t('ADICIONARABONOEMHORAEXTRA'),
                      value: 'ADICIONARABONOEMHORAEXTRA',
                    },
                    {
                      text: $t('FOLGAFERIADOINTERMITENTEEXCETO12X36'),
                      value: 'FOLGAFERIADOINTERMITENTEEXCETO12X36',
                    },
                    {
                      text: $t('APURARHSNOTURNAPRORROGADA'),
                      value: 'APURARHSNOTURNAPRORROGADA',
                    },
                    {
                      text: $t('APURARVAVTJORNADAMENORQUE6HORAS'),
                      value: 'APURARVAVTJORNADAMENORQUE6HORAS',
                    },
                    {
                      text: $t('ABATERINTRAJORNADAEMHE'),
                      value: 'ABATERINTRAJORNADAEMHE',
                    },
                    {
                      text: $t('ABATERINTRAJORNADAEMHE100'),
                      value: 'ABATERINTRAJORNADAEMHE100',
                    },
                    {
                      text: $t('EXTENSAOINTRAJORNADAMAXIMAMINUTOS'),
                      value: 'EXTENSAOINTRAJORNADAMAXIMAMINUTOS',
                    },
                    {
                      text: $t(
                        'APURARFALTAINJUSTIFICADAHORASQUANDOAPENASHORASABONADAS'
                      ),
                      value:
                        'APURARFALTAINJUSTIFICADAHORASQUANDOAPENASHORASABONADAS',
                    },
                    {
                      text: $t('ACRESCIMOAPURACAOVACOM2HE'),
                      value: 'ACRESCIMOAPURACAOVACOM2HE',
                    },
                    {
                      text: $t('APURARHORANOTURNACHEIA'),
                      value: 'APURARHORANOTURNACHEIA',
                    },
                    {
                      text: $t('JORNADAMINIMAPARAINTRAJORNADAMINUTOS'),
                      value: 'JORNADAMINIMAPARAINTRAJORNADAMINUTOS',
                    },
                    {
                      text: $t('HORAEXTRATIPO160'),
                      value: 'HORAEXTRATIPO160',
                    },
                    {
                      text: $t('HORAEXTRATIPO170'),
                      value: 'HORAEXTRATIPO170',
                    },
                    {
                      text: $t('HORAEXTRATIPO160SABADOS'),
                      value: 'HORAEXTRATIPO160SABADOS',
                    },
                    {
                      text: $t('REMOVERCOMPENSACAOSOBREAVISO'),
                      value: 'REMOVERCOMPENSACAOSOBREAVISO',
                    },
                    {
                      text: $t('REMOVERCOMPENSACAOATRASO'),
                      value: 'REMOVERCOMPENSACAOATRASO',
                    },
                    {
                      text: $t('HEEXTRASEMPREBH'),
                      value: 'HEEXTRASEMPREBH',
                    },
                    {
                      text: $t('DESABILITARHEINCOMPENSAVEL'),
                      value: 'DESABILITARHEINCOMPENSAVEL',
                    },
                    {
                      text: $t('DESABILITARTOLERANCIA10MINHORANOTURNA'),
                      value: 'DESABILITARTOLERANCIA10MINHORANOTURNA',
                    },
                    {
                      text: $t('HABILITARDSRSOBREFALTA12X36'),
                      value: 'HABILITARDSRSOBREFALTA12X36',
                    },
                  ]"
                  :label="$t('Tipo')"
                  v-model="slotProps.recursoFinalAtual.tipo"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="6"
                v-if="
                  deveMostrarCampo(
                    'referencia',
                    slotProps.recursoFinalAtual.tipo
                  )
                "
              >
                <v-text-field
                  :rules="rule_required"
                  v-model="slotProps.recursoFinalAtual.referencia"
                  :label="$t('Referencia')"
                  name="referencia"
                  type="number"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-checkbox v-model="slotProps.recursoFinalAtual.ativo">
                  <template v-slot:label>
                    {{ $t("Ativo") }}
                  </template>
                </v-checkbox>
              </v-col>
            </template>
            <template v-slot:formEditaRecursoFinal="slotProps">
              <v-col cols="12" md="12">
                <v-select
                  :items="[
                    { text: $t('COMPENSARHORAS'), value: 'COMPENSARHORAS' },
                    { text: $t('BANCOHORAS'), value: 'BANCOHORAS' },
                  ]"
                  :label="$t('Tipo')"
                  v-model="slotProps.recursoFinalAtual.tipo"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  :rules="rule_required"
                  v-model="slotProps.recursoFinalAtual.referencia"
                  :label="$t('Referencia')"
                  name="referencia"
                  type="number"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-checkbox
                  v-model="slotProps.recursoFinalAtual.ativo"
                  :value="false"
                >
                  <template v-slot:label>
                    {{ $t("Ativo") }}
                  </template>
                </v-checkbox>
              </v-col>
            </template>
          </CriaSuperSet>
        </v-col>

        <v-col cols="12">
          <CriaSuperSet
            :payload="recurso.apuracoesBh"
            :podeEditarRecursoFinal="true"
            @recursos="recurso.apuracoesBh = $event"
            :usaRecursoBase="false"
            tituloFinais="Apuracoes_banco_horas"
            subtituloFinais="Clique_para_adicionar"
            :headersFinais="[
              {
                text: this.$t('Tipo'),
                value: 'tipo',
                sortable: 'true',
              },

              { text: this.$t('Acoes'), value: 'actions', sortable: 'false' },
            ]"
            textoBotaoCadastro="Criar_item"
          >
            <template v-slot:default="slotProps">
              <!-- Form cria recurso final -->
              <v-col cols="12" md="12">
                <v-select
                  v-model="slotProps.recursoFinalAtual.tipo"
                  :items="itensParametrizacoes"
                  item-text="text"
                  item-value="value"
                  label="Tipo"
                  single-line
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>
            </template>
            <template v-slot:formEditaRecursoFinal="slotProps">
              <!-- Form edita recurso final -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="slotProps.recursoFinalAtual.tipo"
                  :items="itensParametrizacoes"
                  item-text="text"
                  item-value="value"
                  label="Tipo"
                  single-line
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>
            </template>
          </CriaSuperSet>
        </v-col>
      </v-row>
    </v-col>
  </EditarBase>
</template>

<script>
import EditarBase from "@/components/base/Crud/EditarBase";
import CriaSuperSet from "@/components/base/proprios/CriaSuperset.vue";
import listas from "@/mixins/listas";
import FuncionarioSindicatoDataService from "@/services/pessoal/FuncionarioSindicatoDataService";
const DataService = FuncionarioSindicatoDataService;

export default {
  name: "EditarFuncionarioSindicato",
  components: { EditarBase, CriaSuperSet },
  mixins: [listas],
  props: {
    recurso: Object,
  },
  data() {
    return {
      DataService: DataService,
      submited: false,

      recurso: {
        descricao: "",
        rubricas: [],
        parametrizacoes: [],
      },

      errors: [],

      itensStatusComum: [
        { text: this.$t("Ativo"), value: "ATIVO" },
        { text: this.$t("Inativo"), value: "INATIVO" },
      ],
    };
  },

  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccess");
    },
    emitSubmitError() {
      this.$emit("submitError");
    },
    closeModalEdit() {
      this.$emit("closeModalEdit");
    },
    submitForm() {
      let recurso = this.recurso;
      recurso.parametrizacoes = recurso.parametrizacoes.map((item) => {
        if (item.ativo == null) {
          item.ativo = false;
        }
        return item;
      });

      return new Promise((resolve, reject) => {
        DataService.update(this.limpaMascara(recurso))
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err.response.data.errors);
          });
      });
    },
    deveMostrarCampo(campo, tipo) {
      if (campo == "referencia") {
        if (tipo == "COMPENSARHORAS") {
          return false;
        }
        if (tipo == "HORAEXTRAFERIADOINTERMITENTE") {
          return false;
        }
        if (tipo == "HORAEXTRA100INTERMITENTE") {
          return false;
        }
        if (tipo == "CONVERTERCOMPENSAREMATRASO") {
          return false;
        }
        if (tipo == "ADICIONARABONOEMHORAEXTRA") {
          return false;
        }
        if (tipo == "FOLGAFERIADOINTERMITENTEEXCETO12X36") {
          return false;
        }
        if (tipo == "APURARHSNOTURNAPRORROGADA") {
          return false;
        }
        if (tipo == "APURARVAVTJORNADAMENORQUE6HORAS") {
          return true;
        }
        if (tipo == "ABATERINTRAJORNADAEMHE") {
          return false;
        }
        if (tipo == "ABATERINTRAJORNADAEMHE100") {
          return false;
        }

        if (tipo == "EXTENSAOINTRAJORNADAMAXIMAMINUTOS") {
          return true;
        }
        if (tipo == "JORNADAMINIMAPARAINTRAJORNADAMINUTOS") {
          return true;
        }
        if (tipo == "APURARFALTAINJUSTIFICADAHORASQUANDOAPENASHORASABONADAS") {
          return false;
        }
        if (tipo == "ACRESCIMOAPURACAOVACOM2HE") {
          return false;
        }
      } else if (campo == "tipo_apuracao") {
        if (tipo == "DESCONTOALIMENTACAO") {
          return false;
        } else if (tipo == "DESCONTOTRANSPORTE") {
          return false;
        } else if (tipo == "DESCONTOAJUDACUSTO") {
          return false;
        } else if (tipo == "CREDITOAJUDACUSTO") {
          return false;
        } else {
          return true;
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
