<template>
  <div cols="12" sm="6" class="text-right" id="pesquisa-menu">
    <v-text-field
      :placeholder="$t('Procurar')"
      rounded-sm
      hide-details
      outlined
      background-color="rgba(255,255,255,.9)"
      color="rgba(0,0,0,.6)"
      light
      class="font-size-input placeholder-lighter input-alternative input-icon"
      v-model="procurar"
      style="max-width: 360px"
    >
      <!-- <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn
          class=""
          background-color="rgba(255,255,255,.9)"
          color="rgba(0,0,0,.6)"
          light
          rounded-sm
          hide-details
          outlined
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template> -->
    </v-text-field>
    <v-expand-transition>
      <v-card
        v-if="procurar.length > 0 && itensFiltrados.length > 0"
        class="mt-0"
      >
        <v-list dense>
          <v-list-item-group color="primary">
            <v-expand-transition v-for="(item, i) in itensFiltrados" :key="i">
              <v-list-item :to="item.link">
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>

                <v-list-item-icon>
                  <v-icon v-text="item.prefix"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-expand-transition>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: "PesquisaMenu",

  props: {
    payload: { type: Array, required: true },
  },

  data() {
    return {
      itens: [],
      itensNaoFiltrados: [],
      procurar: "",
    };
  },

  computed: {
    itensFiltrados() {
      console.log("procurar", this.procurar);
      console.log("procurar - payload", this.transformaEmObjetos());
      return this.transformaEmObjetos()
        .filter((item) => {
          let title = this.$t(item.title)
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          return title.toLowerCase().includes(
            this.procurar
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
        })
        .slice(0, 3);
    },
  },

  methods: {
    transformaEmObjetos() {
      console.log("transformaEmObjetos()", this.payload);
      var objetos = [];
      for (let i = 0; i < this.payload.length; i++) {
        if (this.payload[i].items && this.payload[i].items.length > 0) {
          console.log("this.payload[i].items", this.payload[i].items);
          for (let j = 0; j < this.payload[i].items.length; j++) {
            objetos.push(this.payload[i].items[j]);
          }
        }
      }
      console.log("objetos", objetos);
      // this.payload.forEach((item) => {
      //   if (item.children) {
      //     item.children.forEach((child) => {
      //       if (child[4]) {
      //         child[4].forEach((child2) => {
      //           var object2 = {};
      //           object2.title = child2.title;
      //           object2.icon = child2.prefix;
      //           object2.to = child2.link;

      //           objetos.push(object2);
      //           return;
      //         });
      //       }

      //       if (!child[4]) {
      //         var object = {};
      //         object.title = child.title;
      //         object.icon = child.prefix;
      //         object.to = child.link;

      //         objetos.push(object);
      //       }
      //     });
      //   }
      // });
      // let arrFinal = new Set(objetos);
      return objetos;
    },
  },

  mounted() {
    this.itensNaoFiltrados = this.payload;
  },
};
</script>

<style>
</style>