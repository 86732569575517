<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="#fb8c00"
        target="_blank"
        @click="checkPendingTutorialError()"
        dark
        small
        :bottom="!positionRelative"
        :left="!positionRelative"
        :fixed="!positionRelative"
        v-if="allowSkipOption"
        style="z-index: 1"
      >
        Pular etapa
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn
        v-else
        target="_blank"
        fab
        small
        dark
        v-bind="attrs"
        v-on="on"
        class="fixed-plugin-button position-fixed btn-light bg-white text-dark z-index-9999"
        icon
        rounded
        :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
        height="35px"
        width="35px"
      >
        <v-icon>mdi-lightbulb-on-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h4"> <strong> SIGA </strong></v-card-title>
      <v-card-text
        ><center>
          Este é um dispositivo de ajuda em tempo real. <br />

          Basta digitar em “pesquisar” a funcionalidade que deseja e o
          <strong>SIGA</strong> irá guiá-lo por todos os passos necessários.
          <br />

          Além de itens individuais, você também pode pesquisar por processos
          completos. Esta função se chama <strong>CIRCUITO</strong> e simulará
          todas as funcionalidades necessárias à conclusão de um processo.
          <br />

          Note que o símbolo
          <v-btn id="btnTexto" fab small dark
            ><v-icon color="#FFF">mdi-lightbulb-on-outline</v-icon></v-btn
          >
          está presente em todas as telas no lado inferior direito.
        </center></v-card-text
      >

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <PesquisaTutorial
              @startTutorial="launchTutorial($event)"
              :payload="items"
            />
          </v-col>
          <v-col cols="12">
            <v-list nav class="text-left">
              <v-list-item-group>
                <!-- <template v-for="(item, index) in items">
                  <v-list-item
                    :key="item.title"
                    @click="launchTutorial(item.tutorialName)"
                  >
                    <v-list-item-avatar size="50" large color="primary">
                      <v-icon dark large>{{ item.icon }}</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        v-html="item.title"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="item.subtitle"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider
                    v-if="index % 2 == 0 && index < items.length"
                    :key="index"
                    :inset="item.inset"
                  ></v-divider>
                </template> -->
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          height="39"
          class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
          text
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PesquisaTutorial from "../components/base/proprios/PesquisaTutorial.vue";
import MultiText from "./aaTutorialPackage/components/MultiText.vue";
import OkInfoBox from "./aaTutorialPackage/components/OkInfobox";
import OpcoesMovimentacao from "./aaTutorialPackage/components/OpcoesMovimentacao";
import { startTutorial } from "./aaTutorialPackage/main.js";
export default {
  name: "TutorialsList",

  components: { PesquisaTutorial },

  props: {
    positionRelative: { default: false },
  },

  computed: {
    allowSkipOption() {
      let pendingTutorial = this.$store.state.pendingTutorial;

      return pendingTutorial?.routeName == this.$route.name;
    },
  },

  data() {
    return {
      dialog: false,

      items: [
        {
          icon: "mdi-account-cog",
          title: this.$t("Cadastrar_usuario"),
          subtitle: this.$t("Texto_tutorialsList_cadastrar_usuario"),
          tutorialName: "Cadastrar_usuario",
        },
        {
          icon: "mdi-account-group",
          title: this.$t("Cadastrar_pessoa"),
          subtitle: this.$t("Texto_tutorialsList_cadastrar_pessoa"),
          tutorialName: "Cadastrar_pessoa",
        },
        {
          icon: "mdi-account-hard-hat",
          title: this.$t("Cadastrar_funcionario"),
          subtitle: this.$t("Texto_tutorialsList_cadastrar_funcionario"),
          tutorialName: "Cadastrar_funcionario",
        },
        {
          icon: "mdi-file-export-outline",
          title: this.$t("Imprimir_folha_de_ponto"),
          subtitle: this.$t("Texto_tutorialsList_imprimir_folha_de_ponto"),
          tutorialName: "Imprimir_folha_de_ponto",
        },
        {
          icon: "mdi-file-export-outline",
          title: this.$t("Imprimir_folha_de_ponto"),
          subtitle: this.$t("Texto_tutorialsList_imprimir_folha_de_ponto"),
          tutorialName: "ImprimirFolhaPontoCircuito",
        },
        {
          icon: "mdi-account-clock-outline",
          title: this.$t("Registros_de_ponto"),
          subtitle: this.$t("Texto_tutorialsList_registroPonto"),
          tutorialName: "RegistrosPonto",
        },
        {
          icon: "mdi-calculator-variant-outline",
          title: this.$t("CalculoPonto"),
          subtitle: this.$t("Texto_tutorialsList_calcular_ponto"),
          tutorialName: "CalculoPonto",
        },
        {
          icon: "mdi-map-clock-outline",
          title: this.$t("Criar_periodo_folha_de_ponto"),
          subtitle: this.$t("Texto_tutorialsList_criar_periodo_folha_de_ponto"),
          tutorialName: "Criar_periodo_folha_de_ponto",
        },
        {
          icon: "ni-fat-remove-clock-outline",
          title: this.$t("Encerrar_periodo_folha_de_ponto"),
          subtitle: this.$t(
            "Texto_tutorialsList_encerrar_periodo_folha_de_ponto"
          ),
          tutorialName: "Encerrar_periodo_folha_de_ponto",
        },
        {
          icon: "mdi-chart-bar",
          title: this.$t("Relatorios_de_ponto"),
          subtitle: this.$t("Texto_tutorialsList_relatorios_de_ponto"),
          tutorialName: "Relatorios_de_ponto",
        },
        {
          icon: "mdi-map-marker-path",
          title: this.$t("Funcionario_movimentacoes"),
          subtitle: this.$t("Texto_tutorialsList_funcionario_movimentacoes"),
          tutorialName: "Funcionario_movimentacoes",
          keys: "Cargo Escala posto sindicato alterar transferir transferencia",
        },
        {
          icon: "mdi-account-cancel-outline",
          title: this.$t("Afastamentos"),
          subtitle: this.$t("Texto_tutorialsList_afastamentos"),
          tutorialName: "Cadastrar_afastamento",
          keys: "Cargo pessoal afastamentos",
        },
        {
          icon: "mdi-database-clock-outline",
          title: this.$t("FuncionarioBancoHoras"),
          subtitle: this.$t("Texto_tutorialsList_bancoHoras"),
          tutorialName: "Cadastrar_bancoHoras",
          keys: "funcionarios bancoHoras banco horas",
        },
        // {
        //   icon: "mdi-database-clock-outline",
        //   title: this.$t("Saldo_bancoHoras"),
        //   subtitle: this.$t("Texto_tutorialsList_saldoBancoHoras"),
        //   tutorialName: "Cadastrar_saldoBancoHoras",
        //   keys: "funcionarios bancoHoras banco horas",
        // },
        {
          icon: "mdi-account-group",
          title: this.$t("Introducao_gerenciamento_de_pessoas"),
          subtitle: this.$t(
            "Texto_tutorialsList_introducao_gerenciamento_de_pessoas"
          ),
          tutorialName: "Introducao_gerenciamento_de_pessoas",
          keys: "Contratacao Admissao Novo habilitar",
        },
        {
          icon: "mdi-book-marker-outline",
          title: this.$t("PostoTrabalhos"),
          subtitle: this.$t("Texto_tutorialsList_introducao_PostoTrabalhos"),
          tutorialName: "PostoTrabalhos",
          keys: "Contratacao posto trabalho postos",
        },
        {
          icon: "mdi-account-multiple-check",
          title: this.$t("gestaoEfetivo"),
          subtitle: this.$t("Texto_tutorialsList_gestaoEfetivo"),
          tutorialName: "gestaoEfetivo",
        },
        {
          icon: "mdi-account-switch",
          title: this.$t("Cadastrar_Cliente"),
          subtitle: this.$t("Texto_tutorialsList_cadastrarCliente"),
          tutorialName: "Cadastrar_Cliente",
        },
        {
          icon: "mdi-handshake-outline",
          title: this.$t("Contratos"),
          subtitle: this.$t("Texto_tutorialsList_cadastraContrato"),
          tutorialName: "Cadastrar_Contrato",
        },
        {
          icon: "mdi-file",
          title: this.$t("CFOPS"),
          subtitle: this.$t("Texto_tutorialsList_cadastraCFOPS"),
          tutorialName: "Cadastrar_CFOPS",
        },
        {
          icon: "mdi-map",
          title: this.$t("Cfop_cidade"),
          subtitle: this.$t("Texto_tutorialsList_cadastraCFOPMun"),
          tutorialName: "Cadastrar_CFOPMun",
        },
        {
          icon: "mdi-map-marker",
          title: this.$t("Cfop_estado"),
          subtitle: this.$t("Texto_tutorialsList_cadastraCFOPEst"),
          tutorialName: "Cadastrar_CFOPEst",
        },
        {
          icon: "mdi-nas",
          title: this.$t("EscopoNF"),
          subtitle: this.$t("Texto_tutorialsList_cadastraEscopo"),
          tutorialName: "Cadastrar_Escopo",
        },
        {
          icon: "mdi-book",
          title: this.$t("ParametrizacaoFiscal"),
          subtitle: this.$t("Texto_tutorialsList_parametrizacaoFiscal"),
          tutorialName: "Cadastrar_ParametrizacaoFiscal",
        },
        {
          icon: "mdi-file-cog",
          title: this.$t("ParametrizacaoServicos"),
          subtitle: this.$t("Texto_tutorialsList_parametrizacaoServicos"),
          tutorialName: "Cadastrar_ParametrizacaoServicos",
        },
        {
          icon: "mdi-credit-card-multiple-outline",
          title: this.$t("ContaBancarias"),
          subtitle: this.$t("Texto_tutorialsList_contaBancaria"),
          tutorialName: "Cadastrar_ContaBancaria",
          keys: "conta bancária, banco, contas bancárias, caixa",
        },
        {
          icon: "mdi-account-cash-outline",
          title: this.$t("Centro_custo"),
          subtitle: this.$t("Texto_tutorialsList_centroCusto"),
          tutorialName: "Cadastrar_CentroCusto",
          keys: "– centro de custo, alocação, consumo, despesa, gastos, receita, rateio, departamentos",
        },
        {
          icon: "mdi-notebook-outline",
          title: this.$t("contaFinanceiras"),
          subtitle: this.$t("Texto_tutorialsList_contaFinanceira"),
          tutorialName: "Cadastrar_contaFinanceira",
          keys: "categoria, grupo, família, tags",
        },
        {
          icon: "mdi-account-cog",
          title: this.$t("Fornecedores"),
          subtitle: this.$t("Texto_tutorialsList_Fornecedores"),
          tutorialName: "Cadastrar_Fornecedor",
          keys: "fornecedores, parceiros, provedor, abastecedor, produtos, serviços, lojas",
        },
        {
          icon: "mdi-credit-card-plus",
          title: this.$t("TituloReceber"),
          subtitle: this.$t("Texto_tutorialsList_TituloReceber"),
          tutorialName: "Cadastrar_TituloReceber",
          keys: "contas a receber, recebimento, fatura, crédito, faturamento",
        },
        {
          icon: "mdi-credit-card-minus",
          title: this.$t("TituloPagar"),
          subtitle: this.$t("Texto_tutorialsList_TituloPagar"),
          tutorialName: "Cadastrar_TituloPagar",
          keys: "contas a pagar, pagamento, fatura, boleto, débito, cartão de crédito, despesa",
        },
        {
          icon: "mdi-bank-plus",
          title: this.$t("RegistroRecebimentos"),
          subtitle: this.$t("Texto_tutorialsList_RegistroR"),
          tutorialName: "Cadastrar_RegistroR",
          keys: "contas a receber, recebimento, fatura, crédito, faturamento",
        },
        {
          icon: "mdi-bank-remove",
          title: this.$t("registrarPagamento"),
          subtitle: this.$t("Texto_tutorialsList_RegistroP"),
          tutorialName: "Cadastrar_RegistroP",
          keys: "contas a pagar, pagamento, boleto, débito",
        },
        {
          icon: "mdi-bank-transfer",
          title: this.$t("Transferir_valores"),
          subtitle: this.$t("Texto_tutorialsList_transferirValores"),
          tutorialName: "transferenciaContas",
          keys: "transferências, movimentações",
        },
        {
          icon: "mdi-tablet-dashboard",
          title: this.$t("fluxoDiario"),
          subtitle: this.$t("Texto_tutorialsList_fluxoDiario"),
          tutorialName: "fluxoDiario",
        },
        {
          icon: "mdi-credit-card-edit",
          title: this.$t("dfc.direto"),
          subtitle: this.$t("Texto_tutorialsList_dfc"),
          tutorialName: "dfcDireto",
        },
        {
          icon: "mdi-credit-card-marker",
          title: this.$t("analiseCC"),
          subtitle: this.$t("Texto_tutorialsList_analiseCC"),
          tutorialName: "analiseCC",
        },
        {
          icon: "mdi-account-cancel-outline",
          title: this.$t("TROCAPLANTAO"),
          subtitle: this.$t("Texto_tutorialsList_trocaPlantao"),
          tutorialName: "Cadastrar_trocaPlantao",
        },
        {
          icon: "mdi-account-cog",
          title: this.$t("FuncionarioCargo"),
          subtitle: this.$t("Texto_tutorialsList_cargo"),
          tutorialName: "Cadastrar_cargo",
        },
        {
          icon: "mdi-card-account-details-star",
          title: this.$t("FuncionarioSindicato"),
          subtitle: this.$t("Texto_tutorialsList_sindicato"),
          tutorialName: "Cadastrar_sindicato",
        },
        {
          icon: "mdi-file-clock",
          title: this.$t("Escala_de_trabalho"),
          subtitle: this.$t("Texto_tutorialsList_escalaTrabalho"),
          tutorialName: "Cadastrar_escalaTrabalho",
        },
        {
          icon: "mdi-map-clock-outline",
          title: this.$t("PeriodoFolhaPagamento"),
          subtitle: this.$t("Texto_tutorialsList_periodoFP"),
          tutorialName: "Cadastrar_periodoFP",
        },
        {
          icon: "mdi-map-clock-outline",
          title: this.$t("RubricaPagamento"),
          subtitle: this.$t("Texto_tutorialsList_rubrica"),
          tutorialName: "Cadastrar_rubrica",
        },
        {
          icon: "mdi-account-cash-outline",
          title: this.$t("BeneficioConvenios"),
          subtitle: this.$t("Texto_tutorialsList_beneficioConvenio"),
          tutorialName: "Cadastrar_beneficioConvenio",
        },
        {
          icon: "mdi-account-child-outline",
          title: this.$t("BeneficioConvenioOptantes"),
          subtitle: this.$t("Texto_tutorialsList_beneficioOptantes"),
          tutorialName: "Cadastrar_beneficioOptante",
        },
        {
          icon: "mdi-account-clock-outline",
          title: this.$t("BeneficioPeriodoApuracao"),
          subtitle: this.$t("Texto_tutorialsList_beneficioPeriodoApuracao"),
          tutorialName: "Cadastrar_beneficioPeriodoApuracao",
        },
        {
          icon: "mdi-account-cog-outline",
          title: this.$t("BeneficioApuracoes"),
          subtitle: this.$t("Texto_tutorialsList_beneficioApuracao"),
          tutorialName: "Cadastrar_beneficioApuracao",
        },
        {
          icon: "mdi-account-search-outline",
          title: this.$t("BeneficioAnaliseCliente"),
          subtitle: this.$t("Texto_tutorialsList_beneficioAnaliseCliente"),
          tutorialName: "Cadastrar_beneficioAnaliseCliente",
        },
        {
          icon: "mdi-cash-multiple",
          title: this.$t("CalculoFaturamento"),
          subtitle: this.$t("Texto_tutorialsList_calculoFaturamento"),
          tutorialName: "Cadastrar_calculoFaturamento",
        },
        {
          icon: "mdi-cash-multiple",
          title: this.$t("Gerar_documento_fat"),
          subtitle: this.$t("Texto_tutorialsList_docFaturamento"),
          tutorialName: "Gerar_docFat",
        },
        {
          icon: "mdi-text-box-multiple-outline",
          title: this.$t("DocumentoFiscal"),
          subtitle: this.$t("Texto_tutorialsList_docFiscal"),
          tutorialName: "Gerar_docFiscal",
        },
        {
          icon: "mdi-dots-square",
          title: this.$t("PracaFiscal"),
          subtitle: this.$t("Texto_tutorialsList_pracaFiscal"),
          tutorialName: "Cadastrar_pracaFiscal",
        },
        {
          icon: "mdi-barcode-scan",
          title: this.$t("Produtos"),
          subtitle: this.$t("Texto_tutorialsList_produtos"),
          tutorialName: "Cadastrar_produto",
        },
        {
          icon: "mdi-order-bool-ascending-variant",
          title: this.$t("SolicitacaoMateriais"),
          subtitle: this.$t("Texto_tutorialsList_pedidoMateriais"),
          tutorialName: "Cadastrar_pedidoMateriais",
          keys: "solicitação material materiais",
        },
        {
          icon: "mdi-account-tie-hat",
          title: this.$t("SolicitacaoUniformes"),
          subtitle: this.$t("Texto_tutorialsList_pedidoUniformes"),
          tutorialName: "Cadastrar_pedidoUniformes",
          keys: "solicitação uniforme uniformes",
        },
        {
          icon: "mdi-basket-fill",
          title: this.$t("SolicitacaoConsumiveis"),
          subtitle: this.$t("Texto_tutorialsList_pedidoConsumivel"),
          tutorialName: "Cadastrar_pedidoConsumivel",
          keys: "solicitação consumiveis consumivel",
        },
        {
          icon: "mdi-file-cabinet",
          title: this.$t("Imobilizados"),
          subtitle: this.$t("Texto_tutorialsList_imobilizados"),
          tutorialName: "Cadastrar_imobilizado",
        },
        {
          icon: "mdi-file-sync",
          title: this.$t("Imobilizado_movimentacoes"),
          subtitle: this.$t("Texto_tutorialsList_imobilizados"),
          tutorialName: "Cadastrar_imobilizadoMov",
        },
        {
          icon: "mdi-shopping-search-outline",
          title: this.$t("CompraPedidos"),
          subtitle: this.$t("Texto_tutorialsList_compraPedido"),
          tutorialName: "Cadastrar_compraPedido",
          keys: "compra compras pedido pedidos",
        },
        {
          icon: "mdi-store-check-outline",
          title: this.$t("CompraOrcamentos"),
          subtitle: this.$t("Texto_tutorialsList_compraOrcamento"),
          tutorialName: "Cadastrar_compraOrcamento",
          keys: "compra compras orcamento orcamentos",
        },
        {
          icon: "mdi-cart-arrow-down",
          title: this.$t("CompraOrdens"),
          subtitle: this.$t("Texto_tutorialsList_compraOrdens"),
          tutorialName: "Cadastrar_compraOrdens",
          keys: "compra compras ordem ordens",
        },
        {
          title: this.$t("circuitoPonto"),
          subtitle: this.$t("Texto_tutorialsList_circuitoPonto"),
          tutorialName: "circuitoPonto",
        },
        {
          title: this.$t("circuitoComercial"),
          subtitle: this.$t("Texto_tutorialsList_circuitoComercial"),
          tutorialName: "circuitoComercial",
        },
        {
          title: this.$t("circuitoFaturamento"),
          subtitle: this.$t("Texto_tutorialsList_circuitoFaturamento"),
          tutorialName: "circuitoFaturamento",
        },
        {
          title: this.$t("circuitoPessoal"),
          subtitle: this.$t("Texto_tutorialsList_circuitoPessoal"),
          tutorialName: "circuitoPessoal",
          keys: "circuito pessoal pessoa",
        },
        {
          title: this.$t("circuitoBeneficio"),
          subtitle: this.$t("Texto_tutorialsList_circuitoBeneficio"),
          tutorialName: "circuitoBeneficio",
          keys: "circuito beneficio",
        },
        {
          title: this.$t("circuitoRecursos"),
          subtitle: this.$t("Texto_tutorialsList_circuitoRecursos"),
          tutorialName: "circuitoRecursos",
          keys: "circuito recursos",
        },
        {
          title: this.$t("circuitoFinanceiro"),
          subtitle: this.$t("Texto_tutorialsList_circuitoFinanceiro"),
          tutorialName: "circuitoFinanceiro",
          keys: "circuito financeiro",
        },
        {
          title: this.$t("circuitoCompras"),
          subtitle: this.$t("Texto_tutorialsList_circuitoCompras"),
          tutorialName: "circuitoCompras",
          keys: "circuito compras",
        },
        {
          title: this.$t("metodoCobranca"),
          subtitle: this.$t("textoTutorialMetodoCobranca"),
          tutorialName: "metodoCobranca",
        },
        {
          title: this.$t("reducaoCargaHoraria"),
          subtitle: this.$t("textoTutorialReducaoCarga"),
          tutorialName: "periodoReducaoCH",
        },
        {
          title: this.$t("Dashboard_apuracoes_ponto_mod01"),
          subtitle: this.$t("textoTutorialDashApuracaoPonto"),
          tutorialName: "dashApuracaoPonto",
        },
        {
          title: this.$t("extrato.contabancaria"),
          subtitle: this.$t("textoTutorialExtratoBancario"),
          tutorialName: "movimentacoesBancarias",
        },
      ],

      stepsCadastraMovimentacoes: [
        {
          boxTitle: "Cadastrar movimentação",
          boxText: "Cadastrar um novo registro de movimentação",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Pessoal",
          boxText: "Clique aqui para acessar o gerenciamento de pessoal",
          selector: "#mdi-account-group .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Movimentacoes",
              "#mdi-transit-connection-variant",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Gerenciar movimentações",
          boxText: "Clique aqui para gerenciar os registros de movimentações",
          selector: "#mdi-transit-connection-variant",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Movimentacoes",
              "#mdi-transit-connection-variant",
              "#mdi-account-group",
              helpers
            );
          },
        },
        {
          type: "textOnly",
          boxTitle: "Cadastrar funcionário",
          boxText: "Clique aqui para cadastrar um novo funcionário",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "O que é ?",
                boxText:
                  "Funcionário movimentação é o registro de alteração, nas informações do cargo, local de trabalho, sindicato e/ou posto de trabalho de um determinado funcionário.",
              },
              {
                boxTitle: "Quando utilizar ?",
                boxText:
                  "Sempre que precisar alterar uma ou mais informações de cargo, local de trabalho, sindicato e/ou posto de trabalho de um determinado funcionário.",
              },
              {
                boxTitle: "Como fazer uma alteração ?",
                boxText: `Cada movimentação, representa uma mudança
                            em um ou mais dados registrados.<br> Para alterar qualquer informação,
                            cadastre um novo registro de funcionário-movimentação.`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "Cadastrar movimentação",
          boxText: "Clique aqui para cadastrar um novo registro",
          selector: "#btn--novaMovimentacao",
        },
        {
          type: "text",
          boxTitle: "Válido a partir de",
          boxText: "Insira a data que esta alteração passa a valer.",
          selector: "input[name=data]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Selecione o funcionário",
          boxText:
            "Ao encontrar o funcionário que deseja fazer mudanças, clique aqui para selecioná-lo.",
          selector: ".v-simple-checkbox",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "textOnly",
          boxTitle: "Preencha o formulário",
          boxText: "Insira as novas informações que deseja alterar.",
          infoBoxComponent: OpcoesMovimentacao,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastraUsuario: [
        {
          boxTitle: "Cadastrar usuário",
          boxText: "Siga as instruções para cadastrar um novo usuário.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          boxTitle: "Abrir menu",
          boxText: "Clique aqui e vá ao menu de usuários.",
          type: "text",
          selector: "#mdi-account-lock .v-list-item--link",
          callBeforeRender: (helpers) => {
            return this.pre_step__clickSubmenu(
              "Usuarios",
              "#mdi-account-cog",
              helpers
            );
          },
        },
        {
          boxTitle: "Passo 1",
          boxText: "Clique aqui e veja a página de usuários.",
          type: "text",
          selector: "#mdi-account-cog",
          callBeforeRender: (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Usuarios",
              "#mdi-account-cog",
              "#mdi-account-lock",
              helpers
            );
          },
        },
        {
          boxTitle: "Passo 2",
          boxText: "Clique aqui para abrir o formulário de cadastro",
          type: "text",
          selector: "#btn--novoUsuario",
        },
        {
          type: "textWaitInput",
          boxTitle: "Selecionar pessoa",
          boxText: `
              Busque o cadastro por nome ou CPF. Clique na opção e selecione.
              <br><br> Caso não encontre, faça um novo cadastro da pessoa. <br>
              Em caso de dúvida, utilize os tutoriais. `,
          selector: ".v-card--material .v-text-field__slot input",
        },
        {
          boxTitle: "Passo 3",
          boxText: "Preencha o formulário com as informações desejadas",
          type: "textOnly",
          selector: "#mdi-account-lock",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastraFuncionario: [
        {
          boxTitle: "Cadastrar funcionário",
          boxText: "Siga as instruções para cadastrar um novo funcionário",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: this.$t("Cadastrar_funcionario"),
          boxText: "Clique aqui e vá ao gerenciamento de funcionários.",
          selector: "#mdi-account-group .v-list-item--link",
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });
            return this.pre_step__clickSubmenu(
              "Funcionarios",
              "#mdi-account-hard-hat",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Gerenciar funcionários",
          boxText: "Clique aqui para gerenciar os registros de funcionários",
          selector: "#mdi-account-hard-hat",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Funcionarios",
              "#mdi-account-hard-hat",
              "#mdi-account-group",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Cadastrar funcionário",
          boxText: "Clique aqui para cadastrar um novo funcionário",
          selector: "#btn--cadastro",
        },
        // {
        //   type: "text",
        //   boxTitle: "Dados pessoais",
        //   boxText: "Clique aqui para selecionar a pessoa já cadastrada",
        //   selector: "#expansion-panel-dados-pessoais",
        // },
        // {
        //   type: "textWaitInput",
        //   boxTitle: "Selecionar pessoa",
        //   boxText: `
        //       Busque o cadastro de pessoa por nome ou CPF.
        //       <br><br>Encontrando, clique em cima para selecionar.
        //       No caso de dúvidas, consulte o tutorial.`,
        //   selector: ".v-card--material .v-text-field__slot input",
        // },

        {
          type: "textOnly",
          boxTitle: "Preencha o formulário",
          boxText:
            "Preencha os campos do formulário. Clique no botão SALVAR para finalizar o cadastro.",
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "Bom saber!",
                boxText: `Após cadastrar o funcionário o mesmo já estará apto a utilizar o aplicativo.<br><br>
                          Para vincular Escala de Trabalho, Posto de Trabalho, Sindicato e ou Cargo faça uma Movimentação.<br>
                          Tendo duvidas sobre como realizar uma movimentação, basta procurar por Movimentação na Lâmpada presente no canto superior direito da tela.`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastraPessoa: [
        {
          boxTitle: "Cadastrar pessoa",
          boxText: "Siga as instruções para cadastrar uma nova pessoa.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Cadastrar pessoa",
          boxText: "Clique aqui e vá ao gerenciamento de pessoas.",
          selector: "#mdi-account-group .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Pessoas",
              "#mdi-account-supervisor-circle",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Gerenciar pessoas",
          boxText: "Clique aqui para gerenciar o cadastro de pessoas",
          selector: "#mdi-account-supervisor-circle",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Pessoas",
              "#mdi-account-supervisor-circle",
              "#mdi-account-group",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Cadastrar pessoa",
          boxText: "Clique aqui para cadastrar uma nova pessoa",
          selector: "#btn--novaPessoa",
        },
        {
          type: "text",
          boxTitle: "Divisões do formulário",
          boxText:
            "Os formulários são divididos por seções, clique sobre o título para abrir uma seção.",
          selector: ".v-expansion-panel-header",
        },
        {
          type: "textOnly",
          boxTitle: "Preencha o formulário",
          boxText: "Preencha o formulário com as informações da pessoa.",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarCliente: [
        {
          boxTitle: "Cadastrar Cliente",
          boxText: "Siga as instruções para cadastrar um cliente.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Módulo Comercial",
          boxText: "Clique aqui para acessar o módulo Comercial.",
          selector: "#mdi-handshake-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Clientes",
          boxText: "Clique aqui para acessar a listagem de clientes.",
          selector: "#mdi-account-switch",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Clientes",
              "#mdi-account-switch",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Cadastrar Cliente",
          boxText: "Clique aqui para cadastrar um novo cliente.",
          selector: "#btn--cadastrarCliente",
        },

        {
          boxTitle: "Preencha o formulário",
          boxText:
            "Preencha o formulário de cadastro com as informações do cliente.",
          type: "textOnly",
        },

        {
          boxTitle: "SALVAR",
          boxText: "Clique aqui para salvar e finalizar o cadastro.",
          type: "text",
          selector: "#salvar",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarParametrizacaoFiscal: [
        {
          boxTitle: "Cadastrar Parametrização Fiscal",
          boxText:
            "Siga as instruções para cadastrar uma parametrização fiscal.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Módulo Comercial",
          boxText: "Clique aqui para acessar o módulo Comercial.",
          selector: "#mdi-handshake-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "ParametrizacaoFiscal",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Parametrização Fiscal",
          boxText:
            "Clique aqui para acessar a listagem de parametrizações fiscais.",
          selector: "#mdi-book",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "ParametrizacaoFiscal",
              "#mdi-book",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para cadastrar uma parametrização fiscal.",
          selector: "#btn--novaParametrizacao",
        },

        {
          boxTitle: "Preencha o formulário",
          boxText: "Preencha a descrição e selecione o tipo de tributação.",
          type: "textOnly",
        },

        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "ATENÇÃO!",
                boxText: `<center>Ao selecionar o tipo de tributação, será exibido uma nova opção. <br>
                  Selecione o CFOP de acordo com o tipo de tributação: Municipal ou Estadual.</center>`,
              },
            ],
          },
        },

        {
          type: "text",
          boxTitle: "Retenção",
          boxText: "Marque a opção caso haja retenção.",
          selector: "#retencao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "Criar Item",
          boxText: "Clique aqui para criar um item fiscal.",
          selector: "#btn--CriarItemSuperSet",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "",
                boxText: `<center>Selecione o item referente a esta parametrização e informe as demais tributações. <br>
                  Ao finalizar, clique em <strong>adicionar</strong>.</center>`,
              },
            ],
          },
        },

        {
          type: "text",
          boxTitle: "Salvar",
          boxText:
            "Após conferir os dados, clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarContrato: [
        {
          boxTitle: "Cadastrar Contrato",
          boxText: "Siga as instruções para cadastrar um contrato.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Módulo Comercial",
          boxText: "Clique aqui para acessar o módulo Comercial.",
          selector: "#mdi-handshake-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Contratos",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Contratos",
          boxText: "Clique aqui para acessar a listagem de contratos.",
          selector: "#mdi-file-document-edit-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Contratos",
              "#mdi-file-document-edit-outline",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para cadastrar um novo contrato.",
          selector: "#btn--novoContrato",
        },

        {
          boxTitle: "Preencha o formulário",
          boxText: "Preencha o formulário com os dados do contrato.",
          type: "textOnly",
        },

        {
          type: "text",
          boxTitle: "Cliente",
          boxText: "Selecione o cliente.",
          selector: ".v-simple-checkbox",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText:
            "Após conferir os dados, clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarEscopo: [
        {
          boxTitle: "Cadastrar Escopo de Nota Fiscal",
          boxText:
            "O objetivo do Escopo de Nota Fiscal é de cadastrar previamente as informações que estarão nas informações adicionais das notas fiscais. Siga as instruções para cadastrar um novo escopo de nota fiscal.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo Comercial.",
          selector: "#mdi-handshake-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "EscopoServicos",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar a listagem de Escopo Nota Fiscal.",
          selector: "#mdi-nas",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "EscopoServicos",
              "#mdi-nas",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para cadastrar um novo escopo.",
          selector: "#btn--novoEscopo",
        },
        {
          boxTitle: "PASSO 4",
          boxText: "Dê um nome para esse escopo de nota fiscal.",
          type: "textWaitInput",
          selector: "input[name='descricao']",
        },
        // {
        //   type: "textWaitInput",
        //   boxTitle: "PASSO 5",
        //   boxAlign: "left",
        //   boxText: "Coloque aqui as informações adicionais de sua nota fiscal. Você também poderá colocar um gerador de variável para que o sistema insira automaticamente informações relevantes, tais como: @iss = valor do ISS, @icms = valor do ICMS, @inss = valor do INSS, @baseInss = base do INSS, @ir = valor do imposto de renda, @pis = valor do PIS, @cofins = valor do COFINS, @csll = valor do CSLL, @vencimento = data de vencimento da nota fiscal, @competencia = mês de referência da nota fiscal, @dataInicio = data início dos serviços, @dataFim = data final dos serviços.",
        //   selector: "textarea[name='texto']",
        // },
        {
          type: "text",
          boxTitle: "",
          boxText: "",
          selector: "textarea[name='texto']",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "PASSO 5",
                boxText: `Coloque aqui as informações adicionais de sua nota fiscal. Você também poderá colocar um gerador de variável para que o sistema insira automaticamente informações relevantes, tais como: @iss = valor do ISS, @icms = valor do ICMS, @inss = valor do INSS, @baseInss = base do INSS, @ir = valor do imposto de renda, @pis = valor do PIS, @cofins = valor do COFINS, @csll = valor do CSLL, @vencimento = data de vencimento da nota fiscal, @competencia = mês de referência da nota fiscal, @dataInicio = data início dos serviços, @dataFim = data final dos serviços.</strong>.`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText:
            "Após preencher o formulário de cadastro, clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarParametrizacaoServicos: [
        {
          boxTitle: "Cadastrar Parametrização de Serviços.",
          boxText: "Siga as instruções para cadastrar uma nova parametrização.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Módulo Comercial",
          boxText: "Clique aqui para acessar o módulo Comercial.",
          selector: "#mdi-handshake-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxText: "Clique aqui para acessar o menu Parametrização Serviços.",
          selector: "#mdi-file-cog",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "ParametrizacaoServicos",
              "#mdi-file-cog",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxText: "Clique aqui para iniciar um novo cadastro.",
          selector: "#btn--novaParametrizacao",
        },
        {
          type: "text",
          boxTitle: "Informe a descrição",
          boxText: "Insira a identificação para essa parametrização.",
          selector: "input[name='descricao']",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Cliente",
          boxText: "Selecione o cliente referente a parametrização.",
          selector: "#selecionaCliente",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Endereço",
          boxText: "Selecione o endereço do cliente.",
          selector: "#selecionaEndereco",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Contrato",
          boxText: "Selecione o contrato referente a esta parametrização.",
          selector: "#selecionaContrato",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Escopo",
          boxText: "Selecione o escopo de serviço.",
          selector: "#selecionaEscopo",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Parametrização Fiscal",
          boxText: "Selecione a parametrização.",
          selector: "#selecionaParametrizacao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Método de Cobrança",
          boxText: "Selecione o método de cobrança.",
          selector: "#selecionaMetodoCobranca",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Condição de Pagamento",
          boxText: "Selecione a condição de pagamento.",
          selector: "#selecionaCondicaoPagamento",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Posto de Trabalho",
          boxText:
            "Clique aqui para preencher o formulário com as informações do posto de trabalho.",
          selector: "#btn--CriarItemSuperSet",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarCFOPS: [
        {
          boxTitle: "Cadastrar um CFOPS",
          boxText: "Siga as instruções para cadastrar um CFOPS.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Módulo Comercial",
          boxText: "Clique aqui para acessar o módulo Comercial.",
          selector: "#mdi-handshake-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Cfops",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "CFOPS",
          boxText: "Clique aqui para acessar a listagem de CFOP.",
          selector: "#mdi-file",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Cfops",
              "#mdi-file",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Cadastrar CFOPS",
          boxText: "Clique aqui para cadastrar um novo CFOPS.",
          selector: "#btn--novoCFOP",
        },

        {
          boxTitle: "Preencha o formulário",
          boxText: "Informe o CFOPS.",
          type: "textWaitInput",
          selector: "input[name=cfop]",
        },
        {
          boxTitle: "Preencha o formulário",
          boxText: "Informe a descrição do CFOPS.",
          type: "textWaitInput",
          selector: "input[name=descricao]",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarCFOPSCircuito: [
        {
          boxTitle: "Cadastrar um CFOPS",
          boxText: "Siga as instruções para cadastrar um CFOPS.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Módulo Comercial",
          boxText: "Clique aqui para acessar o módulo Comercial.",
          selector: "#mdi-handshake-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Cfops",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "CFOPS",
          boxText: "Clique aqui para acessar a listagem de CFOP.",
          selector: "#mdi-file",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Cfops",
              "#mdi-file",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Cadastrar CFOPS",
          boxText: "Clique aqui para cadastrar um novo CFOPS.",
          selector: "#btn--novoCFOP",
        },

        {
          boxTitle: "Preencha o formulário",
          boxText: "Informe o CFOPS.",
          type: "text",
          selector: "input[name=cfop]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          boxTitle: "Preencha o formulário",
          boxText: "Informe a descrição do CFOPS.",
          type: "text",
          selector: "input[name=descricao]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarCFOPMun: [
        {
          boxTitle: "Cadastrar CFOP Municipal",
          boxText: "Siga as instruções para cadastrar um CFOP Municipal.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Módulo Comercial",
          boxText: "Clique aqui para acessar o módulo Comercial.",
          selector: "#mdi-handshake-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Cfop_cidade",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "CFOP Municipal",
          boxText: "Clique aqui para acessar a listagem de CFOPS.",
          selector: "#mdi-map",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Cfop_cidade",
              "#mdi-map",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Cadastrar CFOP",
          boxText: "Clique aqui para cadastrar um novo CFOP.",
          selector: "#btn--novoCFOPMun",
        },

        {
          boxTitle: "Preencha o formulário",
          boxText: "Informe a cidade e os dados de tributação.",
          type: "textOnly",
        },

        {
          type: "text",
          boxTitle: "CFOP",
          boxText: "Após preencher os dados do contrato, selecione o CFOP.",
          selector: ".v-simple-checkbox",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarCFOPEst: [
        {
          boxTitle: "Cadastrar CFOP Estadual",
          boxText: "Siga as instruções para cadastrar um CFOP Estadual.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Módulo Comercial",
          boxText: "Clique aqui para acessar o módulo Comercial.",
          selector: "#mdi-handshake-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "CfopEstados",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "CFOP Municipal",
          boxText: "Clique aqui para acessar a listagem de CFOPS.",
          selector: "#mdi-map-marker",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "CfopEstados",
              "#mdi-map-marker",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Cadastrar CFOP",
          boxText: "Clique aqui para cadastrar um novo CFOP.",
          selector: "#btn--novoCFOPest",
        },
        {
          boxTitle: "Preencha o formulário",
          boxText: "Informe o estado e os dados de tributação.",
          type: "textOnly",
        },
        {
          type: "text",
          boxTitle: "CFOP",
          boxText: "Selecione o CFOP referente ao Estado.",
          selector: ".v-simple-checkbox",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsGestaoEfetivo: [
        {
          boxTitle: "Gestão Efetivo",
          boxText:
            "Siga as instruções para consultar o efetivo de um posto de trabalho.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "textOnly",
          boxTitle: "Gestão Efetivo",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "Bom saber!",
                boxText: `<center>O módulo Gestão de Efetivo, tem a finalidade de demonstrar o preenchimento de todos os postos, baseado nos quantitativos contratados.<br>
            Ao acessar vc terá uma visão geral e ao aprofundar a pesquisa, até saber exatamente quem são os colaboradores que deveriam estar presentes naquele POSTO DE TRABALHO e suas escalas.<br>
            É uma ferramenta fundamental para uso do Operacional, já que permite saber em tempo real se os colaboradores estão ou não baseados em seus respectivos postos, nos horários previamente estabelecidos.</center>`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "Pessoal",
          boxText: "Clique aqui e vá ao gerenciamento de pessoas.",
          selector: "#mdi-account-group .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Pessoas",
              "#mdi-account-supervisor-circle",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Abrir Gestão Efetivo",
          boxText: "Clique aqui para abrir o menu.",
          selector: "#mdi-account-multiple-check",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "#gestaoEfetivo",
              "#mdi-account-multiple-check",
              helpers
            );
          },
        },
        {
          type: "textWaitInput",
          boxTitle: "Selecionar período",
          boxText: "Insira a data para consulta e clique em CONFIRMAR.",
          selector: "input[name=data]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsPostoTrabalhos: [
        {
          boxTitle: "Cadastrar Posto de Trabalho",
          boxText: "Siga as instruções para cadastrar um posto de trabalho.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Módulo Comercial",
          boxText: "Clique aqui para acessar o módulo Comercial.",
          selector: "#mdi-handshake-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "PostoTrabalhos",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Postos de trabalho",
          boxText: "Clique aqui para acessar a listagem de postos de trabalho",
          selector: "#mdi-book-marker-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "PostoTrabalhos",
              "#mdi-book-marker-outline",
              "#mdi-handshake-outline",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para cadastrar um novo posto de trabalho",
          selector: "#btn--novoPostoTrabalho",
        },

        {
          type: "textOnly",
          boxTitle: "Cadastrar funcionário",
          boxText: "Clique aqui para cadastrar um novo funcionário",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "IMPORTANTE!",
                boxText:
                  "Para realizar o cadastro de um posto de trabalho, é necessário que você tenha o cadastro do Contrato e da Parametrização de Serviço",
              },
            ],
          },
        },

        {
          type: "text",
          boxTitle: "Descrição",
          boxText: "Informe a indentificação do posto",
          selector: "input[name=descricao]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "Cliente",
          boxText: "Selecione o cliente responsável pelo posto de trabalho",
          selector: "#selecionaCliente",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "Endereço",
          boxText: "Selecione o endereço do posto de trabalho",
          selector: "#selecionaEndereco",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "Item",
          boxText: "Selecione o item de contrato",
          selector: "#selecionaContrato",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "Escala",
          boxText: "Selecione a escala de trabalho referente ao posto",
          selector: "#selecionaEscala",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "Cargo",
          boxText: "Selecione o cargo do posto de trabalho",
          selector: "#selecionaCargo",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "Parametrizações",
          boxText:
            "Clique aqui para adicionar parametrizações ao posto de trabalho",
          selector: "#btn--CriarItemSuperSet",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "Salvar",
          boxText:
            "Após preencher todos os campos, clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsRegistrosPonto: [
        {
          boxTitle: "Consultar Registros de Ponto",
          boxText: "Siga as instruções consultar os registros de ponto.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "PONTO - RRP",
          boxText: "Clique aqui para acessar o módulo de registro de ponto.",
          selector: "#mdi-calendar-clock .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "ponto",
              "#mdi-calendar-clock",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Registros de Ponto",
          boxText: "Clique aqui para acessar o os registros de ponto.",
          selector: "#mdi-account-clock-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Registros_de_ponto",
              "#mdi-account-clock-outline",
              "#mdi-calendar-clock",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Selecione o periodo",
          boxText: "Clique aqui e selecione o período para consulta.",
          selector: ".v-data-table__checkbox.v-simple-checkbox",
        },
        {
          type: "text",
          boxTitle: "Confirmar",
          boxText: "Clique aqui para confirmar e abrir os relatórios do.",
          selector: "#btn-confirmar",
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "REGISTROS DE PONTO",
                boxText: `<center>Os registros dos coloboradores ativos estarão disponíveis para consulta.</center>`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "Alterar período",
          boxText:
            "Para alterar o período de consulta, basta clicar neste botão, selecionar o período e clicar em confirmar.",
          selector: "#periodo",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCalculoPonto: [
        {
          boxTitle: "Cálculo de Ponto",
          boxText:
            "Siga as instruções para calcular e consultar a folha de ponto.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Módulo de ponto",
          boxText: "Clique aqui para acessar o módulo de registro de ponto",
          selector: "#mdi-calendar-clock .v-list-item--link",
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "CalculoPonto",
              "#mdi-calculator-variant-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Calcular ponto",
          boxText: "Clique aqui para acessar o cálculo de ponto",
          selector: "#mdi-calculator-variant-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "CalculoPonto",
              "#mdi-calculator-variant-outline",
              "#mdi-calendar-clock",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Selecione o periodo",
          boxText: "Clique aqui e selecione o período para calcular.",
          selector: ".v-data-table__checkbox.v-simple-checkbox",
        },
        {
          type: "text",
          boxTitle: "Calcular",
          boxText: "Clique aqui para calcular",
          selector: "#btn-calcular-ponto",
        },
        {
          type: "textOnly",
          boxTitle: "Sucesso!",
          boxText: `<center>Ponto calculado com sucesso!<br>
          Todas as folhas de ponto estão disponíveis para consulta.</center>`,
        },
      ],

      stepsImprimirFolhaPonto: [
        {
          boxTitle: "Folha de Ponto",
          boxText: "Siga as instruções para imprimir a folha de ponto.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Módulo de ponto",
          boxText: "Clique aqui para acessar o módulo de registro de ponto",
          selector: "#mdi-calendar-clock .v-list-item--link",
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "CalculoPonto",
              "#mdi-calculator-variant-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Calcular ponto",
          boxText: "Clique aqui para acessar o cálculo de ponto",
          selector: "#mdi-calculator-variant-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "CalculoPonto",
              "#mdi-calculator-variant-outline",
              "#mdi-calendar-clock",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Selecione o periodo",
          boxText: "Clique aqui e selecione o período para calcular.",
          selector: ".v-data-table__checkbox.v-simple-checkbox",
        },
        {
          type: "text",
          boxTitle: "Calcular",
          boxText: "Clique aqui para calcular",
          selector: "#btn-calcular-ponto",
        },
        {
          type: "text",
          boxTitle: "Sucesso",
          boxText:
            "Essa mensagem indica que o ponto foi calculado com sucesso, clique aqui para continuar",
          selector: "button.swal2-confirm",
        },
        {
          type: "text",
          boxTitle: "Download",
          boxText: "Clique aqui para fazer o download da folha de ponto.",
          selector: "#btn-exportar-payroll",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsImprimirFolhaPontoCircuito: [
        {
          boxTitle: "Folha de Ponto",
          boxText:
            "Siga as instruções para baixar a folha de ponto dos colaboradores.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Download",
          boxText: "Clique aqui para fazer o download da folha de ponto.",
          selector: "#btn-exportar-payroll",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCriarPeriodoFolhaPonto: [
        {
          boxTitle: "Período de Folha de Ponto",
          boxText:
            "Siga as instruções para cadastrar um período de folha de ponto.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Módulo de ponto",
          boxText: "Clique aqui para acessar o módulo de registro de ponto.",
          selector: "#mdi-calendar-clock .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Periodo",
              "#mdi-map-clock-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Gerenciamento de períodos",
          boxText:
            "Clique aqui para acessar o gerenciamento de períodos de ponto.",
          selector: "#mdi-map-clock-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Periodo",
              "#mdi-map-clock-outline",
              "#mdi-calendar-clock",
              helpers
            );
          },
        },
        {
          type: "textOnly",
          boxTitle: "O que é um período de ponto ?",
          boxText:
            "Período de ponto, é o intervalo específico de tempo utilizado na realização do cálculo de ponto.",
        },
        {
          boxTitle: "Cadastrar período",
          boxText: "Clique aqui para abrir o formulário de cadastro",
          type: "text",
          selector: "#btn--novoPeriodo",
        },
        {
          boxTitle: "Preencha o formulário",
          boxText:
            "Preencha o formulário com a data de inicio e fim do período",
          type: "textOnly",
        },
        {
          boxTitle: "SALVAR",
          boxText: "Clique aqui para salvar o período e finalizar o cadastro.",
          type: "text",
          selector: "#salvar",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCriarPeriodoReducaoCH: [
        {
          boxTitle: "Redução de Carga Horária Coletiva",
          boxText: "Siga as instruções para cadastrar um período.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo.",
          selector: "#mdi-calendar-clock .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "FuncionarioPontoPeriodoReducaoCargaHorariaColetiva",
              "#mdi-map-clock-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar o gerenciamento de períodos.",
          selector: "#mdi-clock-alert-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "FuncionarioPontoPeriodoReducaoCargaHorariaColetiva",
              "#mdi-map-clock-outline",
              "#mdi-calendar-clock",
              helpers
            );
          },
        },
        {
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para abrir o formulário de cadastro",
          type: "text",
          selector: "#btn--novoPeriodo",
        },
        {
          boxTitle: "PASSO 4",
          boxText: "Informe a data de inicio.",
          type: "textWaitInput",
          selector: "#inicioPeriodoCH",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          boxTitle: "PASSO 5",
          boxText: "Informe a data final.",
          type: "textWaitInput",
          selector: "#finalPeriodoCH",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          boxTitle: "PASSO 6",
          boxText: "Informe a minutagem a ser reduzida da jornada de 8 horas.",
          type: "text",
          selector: "#reducaoMinutosJornada8hs",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          boxTitle: "PASSO 7",
          boxText: "Informe a minutagem a ser reduzida da jornada de 6 horas.",
          type: "text",
          selector: "#reducaoMinutosJornada6hs",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          boxTitle: "PASSO 8",
          boxText: "Informe a minutagem a ser reduzida da jornada de 4 horas.",
          type: "text",
          selector: "#reducaoMinutosJornada4hs",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          boxTitle: "PASSO 9",
          boxTitle: "SALVAR",
          boxText: "Clique aqui para salvar o período e finalizar o cadastro.",
          type: "text",
          selector: "#salvar",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsDashApuracaoPonto: [
        {
          boxTitle: "Dashboard de Apuração de Ponto",
          boxText:
            "Nesse módulo você pode consultar dados como a jornada de trabalho, intrajornada, projeção de horas, falta justificada e injustificada, atrasos, horas a compensar e o DSR de falta justificada.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo.",
          selector: "#mdi-calendar-clock .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "FuncionarioPontoPeriodoReducaoCargaHorariaColetiva",
              "#mdi-map-clock-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar o dashboard.",
          selector: "#mdi-chart-bar",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "FuncionarioPontoPeriodoReducaoCargaHorariaColetiva",
              "#mdi-map-clock-outline",
              "#mdi-calendar-clock",
              helpers
            );
          },
        },
        {
          boxTitle: "PASSO 3",
          boxText: "Selecione o período que deseja consultar.",
          type: "text",
          selector: ".v-simple-checkbox",
        },
        {
          boxTitle: "PASSO 4",
          boxText: "Clique aqui para ver os relatórios do período selecionado.",
          type: "text",
          selector: "#btn-ver-relatorios",
        },
        {
          type: "text",
          boxTitle: "Sucesso",
          boxText:
            "Essa mensagem indica que os relatórios foram calculados com sucesso, clique aqui para continuar.",
          selector: "button.swal2-confirm",
        },
      ],

      stepsNovaContaBancaria: [
        {
          boxTitle: "Cadastrar Conta Bancária.",
          boxText:
            "Siga as instruções para cadastrar uma nova conta bancária, para que as suas movimentações financeiras tenham um repositório de títulos a pagar e títulos a receber.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "FINANCEIRO - GFI",
          boxText: `PASSO 1 - Clique aqui para acessar o módulo financeiro.`,
          selector: "#mdi-cash .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "CONTAS BANCÁRIAS",
          boxText:
            "PASSO 2 - Clique aqui para acessar a listagem de contas bancárias",
          selector: "#mdi-credit-card-multiple-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Conta_bancarias",
              "#mdi-credit-card-multiple-outline",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "NOVA CONTA BANCÁRIA",
          boxText:
            "PASSO 3 - Clique aqui para cadastrar uma nova conta bancária",
          selector: "#btn--cadastroContaBancaria",
        },

        {
          boxTitle: "PREENCHA O FORMULÁRIO",
          boxText: "Preencha os campos do formulário e clique em SALVAR",
          type: "textOnly",
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "ATENÇÃO!",
                boxText: `Uma conta bancária não necessariamente está ligada a uma instituição financeira, você pode cadastrar caixas físicos e contas vinculadas.`,
              },
            ],
          },
        },
      ],

      stepsNovaContaBancariaCircuito: [
        {
          boxTitle: "Cadastrar Conta Bancária.",
          boxText:
            "Siga as instruções para cadastrar uma nova conta bancária, para que as suas movimentações financeiras tenham um repositório de títulos a pagar e títulos a receber.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "CONTAS BANCÁRIAS",
          boxText: "Clique aqui para acessar a listagem de contas bancárias",
          selector: "#mdi-credit-card-multiple-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Conta_bancarias",
              "#mdi-credit-card-multiple-outline",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "NOVA CONTA BANCÁRIA",
          boxText: "Clique aqui para cadastrar uma nova conta bancária",
          selector: "#btn--cadastroContaBancaria",
        },

        {
          boxTitle: "PREENCHA O FORMULÁRIO",
          boxText: "Preencha os campos do formulário.",
          type: "textOnly",
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "ATENÇÃO!",
                boxText: `Uma conta bancária não necessariamente está ligada a uma instituição financeira, você pode cadastrar caixas físicos e contas vinculadas.`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarCentroCusto: [
        {
          boxTitle: "CADASTRAR CENTRO DE CUSTO.",
          boxText:
            "Centro de custo é uma ferramenta utilizada para reunir informações (receitas e despesas) separadas por setores, contratos ou projetos, com o objetivo de entregar relatórios pontuais e aprofundar o conhecimento sobre as partes do negócio. Siga as instruções para cadastrar um novo centro de custo.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo financeiro.",
          selector: "#mdi-cash .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });
            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar a listagem de centros de custo.",
          selector: "#mdi-account-cash",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Centro_custos",
              "#mdi-account-cash",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para cadastrar um novo centro de custo",
          selector: "#btn--cadastroCentroCusto",
        },

        {
          type: "text",
          boxTitle: "PASSO 4",
          boxText:
            "Dê um nome para o centro de custo, exemplo: Contrato “X”, Projeto “Y”, Setor “Z”.",
          selector: "input[name=descricao]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 5",
          boxText:
            "Informe um código padrão (“01”, “01.01”, “01.01.01”, “01.01.01.01”, etc). O “.” Serve para “descer” um agrupamento na árvore de centro de custo, sendo possível cadastrar até 4 tipos de nível.",
          selector: "input[name=codigo]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 6",
          boxText:
            "Cadastre o tipo de acordo com o nível do código padrão. Os Agrupamentos 01, 02 e 03 são níveis sintéticos, servem para compor a estrutura da árvore, já o tipo Centro de Custo é do nível analítico, é nele que você irá alocar as receitas/despesas. Lembre-se: cada nível deverá ter a sua origem para formar uma árvore, exceto o Agrupamento 01, já que ele é o topo dos agrupamentos.",
          selector: "#tipoCC",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 7",
          boxText: "Após os preenchimentos, clique em salvar.",
          selector: "#salvar",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "BOM SABER!",
                boxText: `<center>O cadastro do Centro de Custo em árvore permitirá que você tenha uma análise estruturada de acordo com as particularidades da sua empresa. <br>
                  Funciona como análise em níveis, você pode ver desde o nível mais abrangente até o mais analítico. <br>
                  Por exemplo: Agrupamento 01 = Empresa, Agrupamento 02 = Clientes/Internos, Agrupamento 03 = Cliente X, Centro de Custo = Contrato Y.</center>`,
              },
            ],
          },
        },
      ],

      stepsCadastrarCentroCustoCircuito: [
        {
          boxTitle: "CADASTRAR CENTRO DE CUSTO.",
          boxText:
            "Centro de custo é uma ferramenta utilizada para reunir informações (receitas e despesas) separadas por setores, contratos ou projetos, com o objetivo de entregar relatórios pontuais e aprofundar o conhecimento sobre as partes do negócio. Siga as instruções para cadastrar um novo centro de custo.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Centros de Custo",
          boxText: "Clique aqui para acessar a listagem de centros de custo.",
          selector: "#mdi-account-cash",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Centro_custos",
              "#mdi-account-cash",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para cadastrar um novo centro de custo",
          selector: "#btn--cadastroCentroCusto",
        },

        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText:
            "Dê um nome para o centro de custo, exemplo: Contrato “X”, Projeto “Y”, Setor “Z”.",
          selector: "input[name=descricao]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText:
            "Informe um código padrão (“01”, “01.01”, “01.01.01”, “01.01.01.01”, etc). O “.” Serve para “descer” um agrupamento na árvore de centro de custo, sendo possível cadastrar até 4 tipos de nível.",
          selector: "input[name=codigo]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 4",
          boxText:
            "Cadastre o tipo de acordo com o nível do código padrão. Os Agrupamentos 01, 02 e 03 são níveis sintéticos, servem para compor a estrutura da árvore, já o tipo Centro de Custo é do nível analítico, é nele que você irá alocar as receitas/despesas. Lembre-se: cada nível deverá ter a sua origem para formar uma árvore, exceto o Agrupamento 01, já que ele é o topo dos agrupamentos.",
          selector: "#tipoCC",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "BOM SABER!",
                boxText: `<center>O cadastro do Centro de Custo em árvore permitirá que você tenha uma análise estruturada de acordo com as particularidades da sua empresa. <br>
                  Funciona como análise em níveis, você pode ver desde o nível mais abrangente até o mais analítico. <br>
                  Por exemplo: Agrupamento 01 = Empresa, Agrupamento 02 = Clientes/Internos, Agrupamento 03 = Cliente X, Centro de Custo = Contrato Y.</center>`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 5",
          boxText: "Após os preenchimentos, clique em salvar.",
          selector: "#salvar",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarFornecedor: [
        {
          boxTitle: "CADASTRAR FORNECEDOR.",
          boxText: `<center>Siga as instruções para cadastrar um novo fornecedor.<br>
            Ele será importante para o processo de títulos a pagar, convênios de benefícios e cotações/ordens de compras.</center>`,
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo financeiro.",
          selector: "#mdi-cash .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar a listagem de fornecedores.",
          selector: "#mdi-account-cog",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Fornecedores",
              "#mdi-account-cog",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para cadastrar um novo fornecedor.",
          selector: "#btn--cadastroFornecedor",
        },

        {
          boxTitle: "Preencha o formulário",
          boxText: `<center>Preencha os campos do formulário (campos obrigatórios: CNPJ/CPF, razão social/nome, nome fantasia e endereço) e logo em seguida clique em <strong>SALVAR</strong>.</center>`,
          type: "textOnly",
        },

        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "DICA!",
                boxText:
                  "<center>Você pode utilizar o cadastro de fornecedores para fazer de sua agenda telefônica, basta adicionar os números nos dados cadastrais.</center>",
              },
            ],
          },
        },
      ],

      stepsCadastrarTituloReceber: [
        {
          boxTitle: "Cadastrar Titulo a Receber.",
          boxText:
            "Os títulos a receber trazem os valores que sua empresa tem a receber, geralmente gerados pelo seu módulo de faturamento. Siga as instruções para cadastrar um novo título a receber.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo financeiro.",
          selector: "#mdi-cash .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-cash",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar a listagem de títulos a receber.",
          selector: "#mdi-credit-card-plus",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "TituloReceber",
              "#mdi-credit-card-plus",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para cadastrar um título a receber.",
          selector: "#btn--cadastroTituloReceber",
        },

        {
          boxTitle: "Preencha o formulário",
          boxText:
            "Preencha os campos do formulário de acordo com o cadastro que você já realizou em outras telas, logo em seguida clique em SALVAR.",
          type: "textOnly",
        },

        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "BOM SABER!",
                boxText:
                  "<center>Clareza na hora de preencher os campos é fundamental para uma melhor análise da sua gestão financeira.</center>",
              },
            ],
          },
        },
      ],

      stepsCadastrarTituloReceberCircuito: [
        {
          boxTitle: "Cadastrar Titulo a Receber.",
          boxText:
            "Os títulos a receber trazem os valores que sua empresa tem a receber, geralmente gerados pelo seu módulo de faturamento. Siga as instruções para cadastrar um novo título a receber.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Titulos a Receber",
          boxText: "Clique aqui para acessar a listagem de títulos a receber.",
          selector: "#mdi-credit-card-plus",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "TituloReceber",
              "#mdi-credit-card-plus",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Novo Titulo a Receber",
          boxText: "Clique aqui para cadastrar um título a receber.",
          selector: "#btn--cadastroTituloReceber",
        },

        {
          boxTitle: "Preencha o formulário",
          boxText:
            "Preencha os campos do formulário de acordo com o cadastro que você já realizou em outras telas.",
          type: "textOnly",
        },

        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "BOM SABER!",
                boxText:
                  "<center>Clareza na hora de preencher os campos é fundamental para uma melhor análise da sua gestão financeira.</center>",
              },
            ],
          },
        },

        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarTituloPagar: [
        {
          boxTitle: "Cadastrar Titulo a Pagar.",
          boxText:
            "Os títulos a pagar são os compromissos que a sua empresa deverá honrar, geralmente pagos a um fornecedor, parceiro, sócios, pessoas e outros. Siga as instruções para cadastrar um novo título a pagar.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo financeiro.",
          selector: "#mdi-cash .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-cash",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar a listagem de títulos a pagar.",
          selector: "#mdi-credit-card-minus",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "TituloPagar",
              "#mdi-credit-card-minus",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para cadastrar um título a pagar.",
          selector: "#btn--cadastroTituloPagar",
        },

        {
          boxTitle: "Preencha o formulário",
          boxText:
            "Preencha os campos do formulário de acordo com o cadastro que você já realizou em outras telas, logo em seguida clique em SALVAR.",
          type: "textOnly",
        },

        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "BOM SABER!",
                boxText:
                  "<center>Clareza na hora de preencher os campos é fundamental para uma melhor análise da sua gestão financeira.</center>",
              },
            ],
          },
        },
      ],

      stepsCadastrarTituloPagarCircuito: [
        {
          boxTitle: "Cadastrar Titulo a Pagar.",
          boxText:
            "Os títulos a pagar são os compromissos que a sua empresa deverá honrar, geralmente pagos a um fornecedor, parceiro, sócios, pessoas e outros. Siga as instruções para cadastrar um novo título a pagar.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Titulos a Pagar",
          boxText: "Clique aqui para acessar a listagem de títulos a pagar.",
          selector: "#mdi-credit-card-minus",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "TituloPagar",
              "#mdi-credit-card-minus",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Novo Titulo a Pagar",
          boxText: "Clique aqui para cadastrar um título a pagar.",
          selector: "#btn--cadastroTituloPagar",
        },

        {
          boxTitle: "Preencha o formulário",
          boxText:
            "Preencha os campos do formulário de acordo com o cadastro que você já realizou em outras telas.",
          type: "textOnly",
        },

        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "BOM SABER!",
                boxText:
                  "<center>Clareza na hora de preencher os campos é fundamental para uma melhor análise da sua gestão financeira.</center>",
              },
            ],
          },
        },

        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarRegistroR: [
        {
          boxTitle: "REGISTRAR RECEBIMENTO",
          boxText:
            "O registro de recebimento é a ação que você fará para dizer ao sistema que um título a receber foi baixado, ou seja, ele deixará de estar pendente para se tornar quitado. Siga as instruções para registrar um recebimento.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo financeiro.",
          selector: "#mdi-cash .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText:
            "Clique aqui para acessar a listagem de registros de recebimentos.",
          selector: "#mdi-bank-plus",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "RegistroRecebimentos",
              "#mdi-bank-plus",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para registrar um recebimento.",
          selector: "#btn--cadastroRegistroRecebimento",
        },

        {
          type: "textWaitInput",
          boxTitle: "PASSO 4",
          boxText:
            "Dê um nome para o seu registro de recebimento. Exemplo: “Lote de recebimento Banco do Brasil.”",
          selector: "input[name=historico]",
        },

        {
          type: "textWaitInput",
          boxTitle: "PASSO 5",
          boxText: "Digite a data correta da efetivação do(s) título(s).",
          selector: "input[name=data]",
        },

        {
          type: "text",
          boxTitle: "PASSO 6",
          boxText: "Selecione a conta bancária que o título foi efetivado.",
          selector: ".v-simple-checkbox",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 7",
          boxText: "Adicione os títulos que fazem parte desse registro.",
          selector: "#btn--CriarItemSuperSet",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 8",
          boxText: "Clique em SALVAR para dar baixa nos títulos selecionados.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "BOM SABER!",
                boxText:
                  "<center>A qualquer tempo você poderá editar este registro de recebimento, caso falte adicionar algum outro título.</center>",
              },
            ],
          },
        },
      ],

      stepsCadastrarRegistroRCircuito: [
        {
          boxTitle: "REGISTRAR RECEBIMENTO",
          boxText:
            "O registro de recebimento é a ação que você fará para dizer ao sistema que um título a receber foi baixado, ou seja, ele deixará de estar pendente para se tornar quitado. Siga as instruções para registrar um recebimento.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Registros de Recebimento",
          boxText:
            "Clique aqui para acessar a listagem de registros de recebimentos.",
          selector: "#mdi-bank-plus",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "RegistroRecebimentos",
              "#mdi-bank-plus",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Novo Registro",
          boxText: "Clique aqui para registrar um recebimento.",
          selector: "#btn--cadastroRegistroRecebimento",
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText:
            "Dê um nome para o seu registro de recebimento. Exemplo: “Lote de recebimento Banco do Brasil.”",
          selector: "input[name=historico]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Digite a data correta da efetivação do(s) título(s).",
          selector: "input[name=data]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Selecione a conta bancária que o título foi efetivado.",
          selector: ".v-simple-checkbox",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 4",
          boxText: "Adicione os títulos que fazem parte desse registro.",
          selector: "#btn--CriarItemSuperSet",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "BOM SABER!",
                boxText:
                  "<center>A qualquer tempo você poderá editar este registro de recebimento, caso falte adicionar algum outro título.</center>",
              },
            ],
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 5",
          boxText: "Clique em SALVAR para dar baixa nos títulos selecionados.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarRegistroP: [
        {
          boxTitle: "REGISTRAR PAGAMENTO",
          boxText:
            "O registro de pagamento é a ação que você fará para dizer ao sistema que um título a pagar foi baixado, ou seja, ele deixará de estar pendente para se tornar quitado. Siga as instruções para registrar um pagamento.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo financeiro.",
          selector: "#mdi-cash .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "financeiro",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText:
            "Clique aqui para acessar a listagem de registros de pagamentos.",
          selector: "#mdi-bank-remove",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "RegistroPagamentos",
              "#mdi-bank-remove",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para cadastrar um novo registro de pagamento.",
          selector: "#btn--cadastroRegistroPagamento",
        },

        {
          type: "textWaitInput",
          boxTitle: "PASSO 4",
          boxText:
            "Dê um nome para o seu registro de recebimento. Exemplo: “Lote de recebimento Banco do Brasil.”",
          selector: "input[name=historico]",
        },

        {
          type: "textWaitInput",
          boxTitle: "PASSO 5",
          boxText: "Digite a data correta da efetivação do(s) título(s).",
          selector: "input[name=data]",
        },

        {
          type: "text",
          boxTitle: "PASSO 6",
          boxText: "Selecione a conta bancária que o título foi efetivado.",
          selector: ".v-simple-checkbox",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 7",
          boxText: "Adicione os títulos que fazem parte desse registro.",
          selector: "#btn--CriarItemSuperSet",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 8",
          boxText: "Clique em SALVAR para dar baixa nos títulos selecionados.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "BOM SABER!",
                boxText:
                  "<center>A qualquer tempo você poderá editar este registro de recebimento, caso falte adicionar algum outro título.</center>",
              },
            ],
          },
        },
      ],

      stepsCadastrarRegistroPCircuito: [
        {
          boxTitle: "REGISTRAR PAGAMENTO",
          boxText:
            "O registro de pagamento é a ação que você fará para dizer ao sistema que um título a pagar foi baixado, ou seja, ele deixará de estar pendente para se tornar quitado. Siga as instruções para registrar um pagamento.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Registros de Pagamentos",
          boxText:
            "Clique aqui para acessar a listagem de registros de pagamentos.",
          selector: "#mdi-bank-remove",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "RegistroPagamentos",
              "#mdi-bank-remove",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para cadastrar um novo registro de pagamento.",
          selector: "#btn--cadastroRegistroPagamento",
        },

        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText:
            "Dê um nome para o seu registro de recebimento. Exemplo: “Lote de recebimento Banco do Brasil.”",
          selector: "input[name=historico]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Digite a data correta da efetivação do(s) título(s).",
          selector: "input[name=data]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 4",
          boxText: "Selecione a conta bancária que o título foi efetivado.",
          selector: ".v-simple-checkbox",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 5",
          boxText: "Adicione os títulos que fazem parte desse registro.",
          selector: "#btn--CriarItemSuperSet",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "BOM SABER!",
                boxText:
                  "<center>A qualquer tempo você poderá editar este registro de recebimento, caso falte adicionar algum outro título.</center>",
              },
            ],
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 6",
          boxText: "Clique em SALVAR para dar baixa nos títulos selecionados.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarContaFinanceira: [
        {
          boxTitle: "Cadastrar Conta Financeira.",
          boxText: `<center>O cadastro de Contas Financeiras existe para que sua empresa possa classificar/agrupar contas a receber, recebimentos, contas a pagar e pagamentos no módulo financeiro.<br>
              Siga as instruções para cadastrar uma nova conta financeira.</center>`,
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo financeiro.",
          selector: "#mdi-cash .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar a listagem de Contas Financeiras.",
          selector: "#mdi-notebook-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Conta_financeira",
              "#mdi-notebook-outline",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para cadastrar uma nova conta financeira.",
          selector: "#btn--cadastroContaFinanceira",
        },

        {
          type: "text",
          boxTitle: "PASSO 4",
          boxText:
            "Dê um nome para a conta financeira. Lembre-se de escolher uma descrição que seja facilmente compreendida pelo setor financeiro da empresa, evitando assim falhas na classificação dos lançamentos.",
          selector: "input[name=descricao]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 5",
          boxText: "Informe um código para a conta financeira.",
          selector: "input[name=codigo]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 6",
          boxText:
            "Cadastre o tipo da conta financeira. Os Agrupamentos primários, 01, 02 e 03 são níveis sintéticos, servem para compor a estrutura da árvore, já o tipo Conta é o nível analítico, é nele que você irá classificar os lançamentos. Lembre-se: cada nível deverá ter a sua origem para formar uma árvore, exceto o Agrupamento primário, já que ele é o topo dos agrupamentos.",
          selector: "#selectContaFinanceira",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 7",
          boxText: "Após os preenchimentos, clique em salvar.",
          selector: "#salvar",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "BOM SABER!",
                boxText: `<center>O cadastro de Conta Financeira em árvore permitirá que você tenha uma análise estruturada.<br>
                    Um bom cadastro do plano de contas deve ter a perspectiva do Demonstrativo de Fluxo de Caixa.</center>`,
              },
            ],
          },
        },
      ],

      stepsCadastrarContaFinanceiraCircuito: [
        {
          boxTitle: "Cadastrar Conta Financeira.",
          boxText: `<center>O cadastro de Contas Financeiras existe para que sua empresa possa classificar/agrupar contas a receber, recebimentos, contas a pagar e pagamentos no módulo financeiro.<br>
              Siga as instruções para cadastrar uma nova conta financeira.</center>`,
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Contas Financeiras",
          boxText: "Clique aqui para acessar a listagem de Contas Financeiras.",
          selector: "#mdi-notebook-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Conta_financeira",
              "#mdi-notebook-outline",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para cadastrar uma nova conta financeira.",
          selector: "#btn--cadastroContaFinanceira",
        },

        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText:
            "Dê um nome para a conta financeira. Lembre-se de escolher uma descrição que seja facilmente compreendida pelo setor financeiro da empresa, evitando assim falhas na classificação dos lançamentos.",
          selector: "input[name=descricao]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Informe um código para a conta financeira.",
          selector: "input[name=codigo]",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 4",
          boxText:
            "Cadastre o tipo da conta financeira. Os Agrupamentos primários, 01, 02 e 03 são níveis sintéticos, servem para compor a estrutura da árvore, já o tipo Conta é o nível analítico, é nele que você irá classificar os lançamentos. Lembre-se: cada nível deverá ter a sua origem para formar uma árvore, exceto o Agrupamento primário, já que ele é o topo dos agrupamentos.",
          selector: "#selectContaFinanceira",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "BOM SABER!",
                boxText: `<center>O cadastro de Conta Financeira em árvore permitirá que você tenha uma análise estruturada.<br>
                    Um bom cadastro do plano de contas deve ter a perspectiva do Demonstrativo de Fluxo de Caixa.</center>`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 5",
          boxText: "Após os preenchimentos, clique em salvar.",
          selector: "#salvar",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsConsultarFluxoDiario: [
        {
          boxTitle: "Consultar Fluxo Diário.",
          boxText: "Siga as instruções para consultar seu fluxo diário.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo financeiro.",
          selector: "#mdi-cash .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para expandir as opções de análises.",
          selector: "#mdi-chevron-down .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Analises",
              "#mdi-chevron-down",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para acessar o histórico.",
          selector: "#mdi-tablet-dashboard",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "fluxoDiario",
              "#mdi-tablet-dashboard",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Sucesso",
          boxText:
            "Essa mensagem indica que a análise foi gerada, clique aqui para continuar.",
          selector: "button.swal2-confirm",
        },
        {
          type: "text",
          boxText: "Informe a data inicial do período.",
          selector: "#dataInicial",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxText: "Informe a data final do período.",
          selector: "#dataFinal",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxText: "Selecione o tipo Corporativo ou Unitário.",
          selector: "#tipo",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxText: `Você também pode visualizar as movimentações do dia.
          Basta clicar aqui.`,
          selector: "#movimentacoes",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsConsultarFluxoDiarioCircuito: [
        {
          boxTitle: "Consultar Fluxo Diário.",
          boxText: "Siga as instruções para consultar seu fluxo diário.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Análises",
          boxText: "Clique aqui para expandir as opções de análises.",
          selector: "#mdi-chevron-down .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Analises",
              "#mdi-chevron-down",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Fluxo Diário",
          boxText: "Clique aqui para acessar o histórico.",
          selector: "#mdi-tablet-dashboard",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "fluxoDiario",
              "#mdi-tablet-dashboard",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxText: "Informe a data inicial do período.",
          selector: "#dataInicial",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxText: "Informe a data final do período.",
          selector: "#dataFinal",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsConsultarDFCDireto: [
        {
          boxTitle: "DFC Método direto.",
          boxText:
            "Siga as instruções para consultar a demonstração de fluxo de caixa.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Módulo Financeiro - GFI",
          boxText: "Clique aqui para acessar o módulo financeiro.",
          selector: "#mdi-cash .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Análises",
          boxText: "Clique aqui para expandir as opções de análises.",
          selector: "#mdi-chevron-down .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Analises",
              "#mdi-chevron-down",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "DFC Método direto",
          boxText: "Clique aqui para iniciar a consulta.",
          selector: "#mdi-credit-card-edit",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "dfc.direto",
              "#mdi-credit-card-edit",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxText: "Informe a competência para consulta.",
          selector: "#competencia",
        },
      ],

      stepsConsultarDFCDiretoCircuito: [
        {
          boxTitle: "DFC Método direto.",
          boxText:
            "Siga as instruções para consultar a demonstração de fluxo de caixa.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "DFC Método direto",
          boxText: "Clique aqui para iniciar a consulta.",
          selector: "#mdi-credit-card-edit",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "dfc.direto",
              "#mdi-credit-card-edit",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxText: "Informe a competência para consulta.",
          selector: "#competencia",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsAnaliseCC: [
        {
          boxTitle: "Análise Centro de Custo.",
          boxText:
            "Siga as instruções para consultar a análise de centro de custo.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Módulo Financeiro - GFI",
          boxText: "Clique aqui para acessar o módulo financeiro.",
          selector: "#mdi-cash .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Análises",
          boxText: "Clique aqui para expandir as opções de análises.",
          selector: "#mdi-chevron-down .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Analises",
              "#mdi-chevron-down",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Análise Centro de Custo Realizado",
          boxText: "Clique aqui para iniciar a consulta.",
          selector: "#mdi-credit-card-marker",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "analiseCC.realizado",
              "#mdi-credit-card-marker",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxText: "Informe a competência para consulta.",
          selector: "#competencia",
        },
      ],

      stepsAnaliseCCcircuito: [
        {
          boxTitle: "Análise Centro de Custo.",
          boxText:
            "Siga as instruções para consultar a análise de centro de custo.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Análise Centro de Custo Realizado",
          boxText: "Clique aqui para iniciar a consulta.",
          selector: "#mdi-credit-card-marker",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "analiseCC.analise",
              "#mdi-credit-card-marker",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxText: "Informe a competência para consulta.",
          selector: "#competencia",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarAfastamento: [
        {
          boxTitle: "Cadastrar Afastamento.",
          boxText: "Siga as instruções para cadastrar um afastamento.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Pessoal",
          boxText: "Clique aqui para acessar o gerenciamento de pessoal",
          selector: "#mdi-account-group .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Movimentacoes",
              "#mdi-transit-connection-variant",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Afastamentos",
          boxText: "Clique aqui para acessar a listagem de afastamentos.",
          selector: "#mdi-account-cancel-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Afastamentos",
              "#mdi-account-cancel-outline",
              "#mdi-account-group",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para cadastrar um novo afastamento.",
          selector: "#btn--novoAfastamento",
        },
        {
          type: "text",
          boxTitle: "Selecione o tipo de afastamento",
          boxText: "Clique aqui para selecionar o motivo do afastamento.",
          selector: "#tipoAfastamento",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "textOnly",
          boxTitle: "Preencha o formulário",
          boxText:
            "Preencha o formulário com as demais informações do afastamento.",
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarTrocaPlantao: [
        {
          boxTitle: "Cadastrar Troca de Plantão.",
          boxText: "Siga as instruções para cadastrar uma troca de plantão.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Pessoal",
          boxText: "Clique aqui para acessar o gerenciamento de pessoal",
          selector: "#mdi-account-group .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Movimentacoes",
              "#mdi-transit-connection-variant",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Afastamentos",
          boxText: "Clique aqui para acessar a listagem de afastamentos.",
          selector: "#mdi-account-cancel-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Afastamentos",
              "#mdi-account-cancel-outline",
              "#mdi-account-group",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para cadastrar um novo afastamento.",
          selector: "#btn--cadastro",
        },

        {
          type: "textOnly",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "Como preencher essa página?",
                boxText:
                  "Selecione o tipo 'TROCA DE PLANTÃO'. Insira a data de inicio, procure e selecione o funcionário em questão e clique em SALVAR para finalizar o cadastro.",
              },
            ],
          },
        },
      ],

      stepsCadastrarBancoHoras: [
        {
          boxTitle: "Cadastrar Banco de Horas.",
          boxText: "Siga as instruções para cadastrar um banco de horas.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Pessoal",
          boxText: "Clique aqui para acessar o gerenciamento de pessoal",
          selector: "#mdi-account-group .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Movimentacoes",
              "#mdi-transit-connection-variant",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Banco de Horas",
          boxText: "Clique aqui para acessar a listagem de banco de horas.",
          selector: "#mdi-database-clock-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "FuncionarioBancoHoras",
              "#mdi-database-clock-outline",
              "#mdi-account-group",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para cadastrar um novo banco de horas.",
          selector: "#btn--cadastro",
        },

        {
          type: "textWaitInput",
          boxTitle: "Passo 1",
          boxText: "Informe a data inicial.",
          selector: "#dataInicio",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "textWaitInput",
          boxTitle: "Passo 2",
          boxText: "Informe a data final.",
          selector: "#dataFinal",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "Salvar",
          boxText:
            "Após conferir os dados, clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarCargo: [
        {
          boxTitle: "Cadastrar Cargo.",
          boxText: "Siga as instruções para cadastrar um cargo.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Pessoal - MGP",
          boxText: "Clique aqui para acessar o gerenciamento de pessoal",
          selector: "#mdi-account-group .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Movimentacoes",
              "#mdi-transit-connection-variant",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Cadastros",
          boxText: "Clique aqui para expandir as opções de cadastro.",
          selector: "#mdi-account-switch .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Cadastros",
              "#mdi-account-switch",
              "#mdi-account-group",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Cargo",
          boxText: "Clique aqui para acessar a listagem de cargos.",
          selector: "#mdi-account-cog",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Cargo",
              "#mdi-account-cog",
              "#mdi-account-switch",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para realizar um novo cadastro.",
          selector: "#btn--novoCargo",
        },
        {
          boxTitle: "Preencha o formulário de cadastro",
          boxText: `Preencha o formulário com as informações do cargo.`,
          type: "textOnly",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarSindicato: [
        {
          boxTitle: "Cadastrar Sindicato.",
          boxText: "Siga as instruções para cadastrar um sindicato.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Pessoal",
          boxText: "Clique aqui para acessar o gerenciamento de pessoal",
          selector: "#mdi-account-group .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Movimentacoes",
              "#mdi-transit-connection-variant",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Cadastros",
          boxText: "Clique aqui para expandir as opções de cadastro.",
          selector: "#mdi-account-switch .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Cadastros",
              "#mdi-account-switch",
              "#mdi-account-group",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Sindicato",
          boxText: "Clique aqui para acessar a listagem de Sindicatos.",
          selector: "#mdi-card-account-details-star",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Sindicato",
              "#mdi-card-account-details-star",
              "#mdi-account-group",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para realizer um novo cadastro.",
          selector: "#btn--cadastro",
        },
        {
          type: "text",
          boxTitle: "Descrição",
          boxText: "Informe o nome do Sindicato.",
          selector: "#descricao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarSindicatoCircuito: [
        {
          boxTitle: "Cadastrar Sindicato.",
          boxText: "Siga as instruções para cadastrar um sindicato.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Sindicato",
          boxText: "Clique aqui para acessar a listagem de Sindicatos.",
          selector: "#mdi-card-account-details-star",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Sindicato",
              "#mdi-card-account-details-star",
              "#mdi-account-group",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para realizer um novo cadastro.",
          selector: "#btn--cadastro",
        },
        {
          type: "text",
          boxTitle: "Descrição",
          boxText: "Informe o nome do Sindicato.",
          selector: "#descricao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarEscalaTrabalho: [
        {
          boxTitle: "Cadastrar Escala de Trabalho.",
          boxText: "Siga as instruções para cadastrar uma jornada de trabalho.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Pessoal - MGP",
          boxText: "Clique aqui para acessar o gerenciamento de pessoal",
          selector: "#mdi-account-group .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Movimentacoes",
              "#mdi-transit-connection-variant",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Cadastros",
          boxText: "Clique aqui para expandir as opções de cadastro.",
          selector: "#mdi-account-switch .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Cadastros",
              "#mdi-account-switch",
              "#mdi-account-group",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Escala de Trabalho",
          boxText: "Clique aqui para acessar a listagem de cargos.",
          selector: "#mdi-file-clock",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Escala_de_trabalho",
              "#mdi-file-clock",
              "#mdi-account-switch",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para realizar um novo cadastro.",
          selector: "#btn--cadastro",
        },
        {
          boxTitle: "Preencha o formulário de cadastro",
          boxText: "Preencha o formulário com as informações da escala.",
          type: "textOnly",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarEscalaTrabalhoCircuito: [
        {
          boxTitle: "Cadastrar Escala de Trabalho.",
          boxText: "Siga as instruções para cadastrar uma jornada de trabalho.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Escala de Trabalho",
          boxText: "Clique aqui para acessar a listagem de cargos.",
          selector: "#mdi-file-clock",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Escala_de_trabalho",
              "#mdi-file-clock",
              "#mdi-account-switch",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para realizar um novo cadastro.",
          selector: "#btn--cadastro",
        },
        {
          boxTitle: "Preencha o formulário de cadastro",
          boxText: "Preencha o formulário com as informações da escala.",
          type: "textOnly",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarPeriodoFP: [
        {
          boxTitle: "Cadastrar período Folha de Pagamento.",
          boxText:
            "Siga as instruções para cadastrar um período da folha de pagamento.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Pessoal - MGP",
          boxText: "Clique aqui para acessar o gerenciamento de pessoal",
          selector: "#mdi-account-group .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Movimentacoes",
              "#mdi-transit-connection-variant",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Cadastros",
          boxText: "Clique aqui para expandir as opções de cadastro.",
          selector: "#mdi-account-switch .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Cadastros",
              "#mdi-account-switch",
              "#mdi-account-group",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Período folha de pagamento",
          boxText: "Clique aqui para acessar a listagem de períodos.",
          selector: "#mdi-map-clock-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "PeriodoFolhaPagamento",
              "#mdi-map-clock-outline",
              "#mdi-account-switch",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para realizar um novo cadastro.",
          selector: "#btn--novoPeriodo",
        },
        {
          boxTitle: "Preencha o formulário de cadastro",
          boxText:
            "Preencha o formulário com as datas de inicio e final do período.",
          type: "textOnly",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarPeriodoFPCircuito: [
        {
          boxTitle: "Cadastrar período Folha de Pagamento.",
          boxText:
            "Siga as instruções para cadastrar um período da folha de pagamento.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Período folha de pagamento",
          boxText: "Clique aqui para acessar a listagem de períodos.",
          selector: "#mdi-map-clock-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "PeriodoFolhaPagamento",
              "#mdi-map-clock-outline",
              "#mdi-account-switch",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para realizar um novo cadastro.",
          selector: "#btn--novoPeriodo",
        },
        {
          boxTitle: "Preencha o formulário de cadastro",
          boxText:
            "Preencha o formulário com as datas de inicio e final do período.",
          type: "textOnly",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarRubrica: [
        {
          boxTitle:
            "Parametrização de Rubricas para integração com Folha de pagamento.",
          boxText: "Siga as instruções para parametrizar uma rúbrica.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "Pessoal",
          boxText: "Clique aqui para acessar o gerenciamento de pessoal",
          selector: "#mdi-account-group .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Movimentacoes",
              "#mdi-transit-connection-variant",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Cadastros",
          boxText: "Clique aqui para expandir as opções de cadastro.",
          selector: "#mdi-account-switch .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Cadastros",
              "#mdi-account-switch",
              "#mdi-account-group",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Sindicato",
          boxText: "Clique aqui para acessar a listagem de Sindicatos.",
          selector: "#mdi-card-account-details-star",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Sindicato",
              "#mdi-card-account-details-star",
              "#mdi-account-group",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Editar",
          boxText: "Clique aqui para editar um Sindicato.",
          selector: "#edit",
        },
        {
          type: "text",
          boxTitle: "Rúbricas",
          boxText: "Clique em Criar Item para cadastrar uma rúbrica.",
          selector: "#btn--CriarItemSuperSet",
        },
        {
          type: "text",
          boxTitle: "Tipo",
          boxText: "Seleciona o tipo de rúbrica.",
          selector: "#tipo",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Apuração",
          boxText: "Seleciona o tipo de apuração, sendo Dia ou Hora.",
          selector: "#tipo_apuracao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Rúbrica",
          boxText: "Informe o código da rúbrica/verba.",
          selector: "#rubrica_cod",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxText: "Clique aqui para adicionar a rúbrica.",
          selector: "#btn--adicionar",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "textOnly",
          selector: 'button[type="submit"]',
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxText:
                  "<center>Após adicionar todas as verbas, clique em <strong>SALVAR</strong> no canto inferior direito da página para concluir a parametrização.</center>",
              },
            ],
          },
        },
      ],

      stepsCadastrarBeneficioConvenio: [
        {
          boxTitle: "Cadastrar Benefícios",
          boxText: "Siga as instruções para cadastrar um convênio.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Benefício - BEN",
          boxText: "Clique aqui para acessar o gerenciamento de benefícios.",
          selector: "#mdi-account-cash-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Beneficio",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Benefício convênios",
          boxText: "Clique aqui para acessar a listagem de convênios.",
          selector: "#mdi-account-network-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "BeneficioConvenios",
              "#mdi-account-network-outline",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para realizer um novo cadastro.",
          selector: "#btn--novoConvenio",
        },
        {
          boxTitle: "Preencha o formulário de cadastro",
          boxText: "Preencha o formulário com as informações do convênio.",
          type: "textOnly",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para dar finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarBeneficioOptante: [
        {
          boxTitle: "Cadastrar Optante",
          boxText:
            "Siga as instruções para vincular um funcionário à um benefício.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Benefício - BEN",
          boxText: "Clique aqui para acessar o gerenciamento de benefícios.",
          selector: "#mdi-account-cash-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Beneficio",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Benefício convênios optantes",
          boxText: "Clique aqui para acessar a listagem de optantes.",
          selector: "#mdi-account-child-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "BeneficioConvenioOptantes",
              "#mdi-account-child-outline",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para realizer um novo cadastro.",
          selector: "#btn--novoOptante",
        },
        {
          boxTitle: "Preencha o formulário de cadastro",
          boxText: "Preencha o formulário com as informações solicitadas.",
          type: "textOnly",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para dar finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarBeneficioOptanteCircuito: [
        {
          boxTitle: "Cadastrar Optante",
          boxText:
            "Siga as instruções para vincular um funcionário à um benefício.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Benefício convênios optantes",
          boxText: "Clique aqui para acessar a listagem de optantes.",
          selector: "#mdi-account-child-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "BeneficioConvenioOptantes",
              "#mdi-account-child-outline",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para realizer um novo cadastro.",
          selector: "#btn--novoOptante",
        },
        {
          boxTitle: "Preencha o formulário de cadastro",
          boxText: "Preencha o formulário com as informações solicitadas.",
          type: "textOnly",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para dar finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsConsultarBeneficioPeriodoApuracao: [
        {
          boxTitle: "Consultar Período de apuração",
          boxText: "Siga as instruções para realizar a consulta.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Benefício - BEN",
          boxText: "Clique aqui para acessar o gerenciamento de benefícios.",
          selector: "#mdi-account-cash-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Beneficio",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Benefício período de apurações",
          boxText:
            "Clique aqui para acessar a listagem de período de apurações.",
          selector: "#mdi-account-clock-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "BeneficioPeriodoApuracao",
              "#mdi-account-clock-outline",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para realizer um novo cadastro.",
          selector: "#btn--novoPeriodo",
        },
        {
          boxTitle: "Preencha o formulário de cadastro",
          boxText: "Preencha o formulário com as informações solicitadas.",
          type: "textOnly",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para dar finalizar o cadastro.",
          selector: 'button[type="submit"]',
        },
      ],

      stepsConsultarBeneficioPeriodoApuracaoCircuito: [
        {
          boxTitle: "Consultar Período de apuração",
          boxText: "Siga as instruções para realizar a consulta.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Benefício período de apurações",
          boxText:
            "Clique aqui para acessar a listagem de período de apurações.",
          selector: "#mdi-account-clock-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "BeneficioPeriodoApuracao",
              "#mdi-account-clock-outline",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo Cadastro",
          boxText: "Clique aqui para realizer um novo cadastro.",
          selector: "#btn--novoPeriodo",
        },
        {
          boxTitle: "Preencha o formulário de cadastro",
          boxText: "Preencha o formulário com as informações solicitadas.",
          type: "textOnly",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para dar finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsConsultarBeneficioApuracao: [
        {
          boxTitle: "Consultar período de apuração",
          boxText:
            "Siga as instruções para consultar, calcular e exportar um período de apuração.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Benefício - BEN",
          boxText: "Clique aqui para acessar o gerenciamento de benefícios.",
          selector: "#mdi-account-cash-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Beneficio",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Benefício período de apurações",
          boxText:
            "Clique aqui para acessar a listagem de período de apurações.",
          selector: "#mdi-account-cog-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "BeneficioApuracoes",
              "#mdi-account-cog-outline",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Período",
          boxText: "Selecione o período de apuração para consulta.",
          selector: ".v-data-table__checkbox.v-simple-checkbox",
        },
        {
          type: "text",
          // boxTitle: "Período",
          boxText: "Clique aqui para confirmar.",
          selector: "#btn-calcular-ponto",
        },
        {
          type: "text",
          boxTitle: "Exportar",
          boxText:
            "Clique aqui para exportar as apurações do período selecionado.",
          selector: "#btn-exportar-tabela",
          clickTarget: true,
        },
        {
          type: "text",
          boxTitle: "Calcular Período",
          boxText: "Clique aqui realizar um cálculo geral do período.",
          selector: "#btn-calcular-all",
          clickTarget: true,
        },
        {
          type: "text",
          boxTitle: "Alterar Período",
          boxText: "Clique aqui alterar o período de apuração.",
          selector: "#btn-alterar-periodo",
          clickTarget: true,
        },
      ],

      stepsConsultarBeneficioApuracaoCircuito: [
        {
          boxTitle: "Consultar período de apuração",
          boxText:
            "Siga as instruções para consultar, calcular e exportar um período de apuração.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Benefício período de apurações",
          boxText:
            "Clique aqui para acessar a listagem de período de apurações.",
          selector: "#mdi-account-cog-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "BeneficioApuracoes",
              "#mdi-account-cog-outline",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Período",
          boxText: "Selecione o período de apuração para consulta.",
          selector: ".v-data-table__checkbox.v-simple-checkbox",
        },
        {
          type: "text",
          // boxTitle: "Período",
          boxText: "Clique aqui para confirmar.",
          selector: "#btn-calcular-ponto",
        },
        {
          type: "text",
          boxTitle: "Exportar",
          boxText:
            "Clique aqui para exportar as apurações do período selecionado.",
          selector: "#btn-exportar-tabela",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Calcular Período",
          boxText: "Clique aqui realizar um cálculo geral do período.",
          selector: "#btn-calcular-all",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Alterar Período",
          boxText: "Clique aqui alterar o período de apuração.",
          selector: "#btn-alterar-periodo",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsConsultarBeneficioAnaliseCliente: [
        {
          boxTitle: "Análise por cliente",
          boxText:
            "Siga as instruções para consultar e gerar uma análise de benefício por cliente.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Benefício - BEN",
          boxText: "Clique aqui para acessar o gerenciamento de benefícios.",
          selector: "#mdi-account-cash-outline .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "Beneficio",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Análise de benefícios por cliente",
          boxText: "Clique aqui para acessar o módulo de análise.",
          selector: "#mdi-account-search-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "BeneficioAnaliseCliente",
              "#mdi-account-search-outline",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Período",
          boxText: "Selecione o período.",
          selector: ".v-data-table__checkbox.v-simple-checkbox",
        },
        {
          type: "text",
          boxTitle: "Período",
          boxText: "Clique aqui gerar a análise do período selecionado.",
          selector: "#btn-calcular-ponto",
        },
      ],

      stepsConsultarBeneficioAnaliseClienteCircuito: [
        {
          boxTitle: "Análise por cliente",
          boxText:
            "Siga as instruções para consultar e gerar uma análise de benefício por cliente.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Análise de benefícios por cliente",
          boxText: "Clique aqui para acessar o módulo de análise.",
          selector: "#mdi-account-search-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "BeneficioAnaliseCliente",
              "#mdi-account-search-outline",
              "#mdi-account-cash-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Período",
          boxText: "Selecione o período.",
          selector: ".v-data-table__checkbox.v-simple-checkbox",
        },
        {
          type: "text",
          boxTitle: "Período",
          boxText: "Clique aqui gerar a análise do período selecionado.",
          selector: "#btn-calcular-ponto",
        },
      ],

      stepsConsultarCalculoFaturamento: [
        {
          boxTitle: "FATURAMENTO",
          boxText:
            "Siga as instruções para consultar, calcular e gerar o faturamento.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Faturamento",
          boxText: "Clique aqui para acessar o menu de faturamento.",
          selector: "#mdi-currency-usd .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "faturamento",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Cálculo de faturamento",
          boxText: "Clique aqui para acessar a listagem de faturamento.",
          selector: "#mdi-cash-multiple",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "CalculoFaturamento",
              "#mdi-cash-multiple",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Calcular",
          boxText: "Clique aqui para calcular o faturamento.",
          selector: "#btn-exportar-tabela",
        },
        {
          type: "textWaitInput",
          boxTitle: "Data inicio",
          boxText: "Informe o período de início.",
          selector: "input[name='dataInicio']",
        },
        {
          type: "textWaitInput",
          boxTitle: "Data Final",
          boxText:
            "Informe o período final e clique em CONFIRMAR para realizar o cálculo.",
          selector: "input[name='dataFim']",
        },
        {
          type: "text",
          boxTitle: "Calcular",
          boxText:
            "Clique aqui para confirmar o período e calcular o faturamento.",
          selector: "#btn-calcular-ponto",
        },
      ],

      stepsGerarDocFat: [
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "ATENÇÃO!",
                boxText: `<center>Esse processo deve ser realizado após o cálculo do faturamento!</center>`,
              },
            ],
          },
        },
        {
          boxTitle: "FATURAMENTO",
          boxText: "Siga as instruções para gerar o documento de faturamento.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Faturamento",
          boxText: "Clique aqui para acessar o menu de faturamento.",
          selector: "#mdi-currency-usd .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "faturamento",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Cálculo de faturamento",
          boxText: "Clique aqui para acessar a listagem de faturamento.",
          selector: "#mdi-cash-multiple",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "CalculoFaturamento",
              "#mdi-cash-multiple",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Gerar documento",
          boxText: `Clique no ícone para gerar o documento de faturamento.`,
          selector: "#gerarDocumento",
        },
      ],

      stepsGerarDocFiscal: [
        {
          boxTitle: "DOCUMENTO FISCAL",
          boxText:
            "Siga o passo a passo de como editar e gerar o documento fiscal.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Faturamento",
          boxText: "Clique aqui para acessar o menu de faturamento.",
          selector: "#mdi-currency-usd .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "faturamento",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Cálculo de faturamento",
          boxText: "Clique aqui para acessar a listagem de faturamento.",
          selector: "#mdi-text-box-multiple-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "DocumentoFiscal",
              "#mdi-text-box-multiple-outline",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "ATENÇÃO!",
                boxText: `<center>Clique no ícone editar caso precise alterar alguma informação no documento!</center>`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "Gerar documento",
          boxText:
            "Clique no ícone para gerar o documento fiscal, confirme o envio e escolha o local onde deseja salvar o arquivo.",
          selector: "#gerarDoc",
        },
        {
          type: "text",
          boxTitle: "Confimar",
          boxText: `Confirme o envio do documento.`,
          selector: "#btn--confirmarEnvio",
        },
      ],

      stepsCadastrarPracaFiscal: [
        {
          boxTitle: "PRAÇA FATURAMENTO",
          boxText:
            "Siga o passo a passo de como cadastrar uma praça de faturamento.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Faturamento",
          boxText: "Clique aqui para acessar o menu de faturamento.",
          selector: "#mdi-currency-usd .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "faturamento",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Praça faturamento",
          boxText: "Clique aqui para acessar a listagem de praças.",
          selector: "#mdi-dots-square",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "PracaFiscal",
              "#mdi-dots-square",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo cadastro",
          boxText: "Clique aqui para iniciar um novo cadastro.",
          selector: "#btn--cadastro",
        },
        {
          type: "textOnly",
          boxTitle: "Preencha o formulário",
          boxText:
            "Preencha com as informações referente a praça de faturamento.",
        },
      ],

      stepsMetodoCobranca: [
        {
          boxTitle: "MÉTODO DE COBRANÇA",
          boxText: `<center>Siga as instruções para cadastrar um método de cobrança.</center> <br>
            Esse passo a passo é baseado no tipo de cobrança <strong>Boleto ASAAS</strong>. Para o tipo 'Externo do Sistema', basta selecioná-lo e salvar o cadastro.`,
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o menu de faturamento.",
          selector: "#mdi-currency-usd .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "faturamento",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar o módulo.",
          selector: "#mdi-cash-register",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "MetodoCobrança",
              "#mdi-cash-register",
              "#mdi-currency-usd",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para iniciar um novo cadastro.",
          selector: "#btn--metodoCobranca",
        },
        {
          type: "textWaitInput",
          boxTitle: "PASSO 4",
          boxText: "Dê um nome ao método",
          selector: "input[name='descricao']",
          infoBoxComponent: OkInfoBox,
        },
        {
          type: "text",
          boxTitle: "PASSO 5",
          boxText:
            "<center>Selecione o tipo de método de cobrança entre os tipos Externo ao Sistema ou Boleto ASAAS.</center>",
          selector: "#tipoMetodo",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 6",
          boxText: "Selecione a conta bancária ao método de cobrança.",
          selector: "#tipoContaBancaria",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 7",
          boxText: "Informe o nome do beneficiário.",
          selector: "input[name='beneficiario']",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 8",
          boxText: "Informe o cedente.",
          selector: "input[name='cedente']",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 9",
          boxText: "Insira o percentual de juros ao mês.",
          selector: "input[name='percentualJurosMes']",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 10",
          boxText: "Insira o percentual de juros multa.",
          selector: "#percentualJurosMes",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 11",
          boxText: "Insira o percentual de multa.",
          selector: "#percentualMulta",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 12",
          boxText: "Informe o limite de dias para desconto.",
          selector: "#diasLimiteDesconto",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 13",
          boxText: "Informe o percentual de desconto.",
          selector: "#percentualDesconto",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 14",
          boxText: "Informe a sequência do método de cobrança.",
          selector: "#sequencia",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 15",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarProduto: [
        {
          boxTitle: "PRODUTOS",
          boxText: "Siga o passo a passo de como cadastrar um produto.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Recursos",
          boxText: "Clique aqui para acessar o menu de recursos.",
          selector: "#mdi-bookshelf .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "recursos",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Produtos",
          boxText: "Clique aqui para acessar a listagem de produtos.",
          selector: "#mdi-barcode-scan",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Produtos",
              "#mdi-barcode-scan",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo cadastro",
          boxText: "Clique aqui para iniciar um novo cadastro.",
          selector: "#btn--cadastro",
        },
        {
          type: "textOnly",
          boxTitle: "Preencha o formulário",
          boxText: "Preencha com as informações referente ao produto.",
        },
        {
          type: "text",
          boxTitle: "SALVAR",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarPedidoMateriais: [
        {
          boxTitle: "SOLICITAÇÃO DE MATERIAL",
          boxText:
            "Siga o passo a passo de como cadastrar uma solicitação de consumível.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Recursos",
          boxText: "Clique aqui para acessar o menu de recursos.",
          selector: "#mdi-bookshelf .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "recursos",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Solicitação Materiais",
          boxText: "Clique aqui para acessar a listagem de solicitações.",
          selector: "#mdi-order-bool-ascending-variant",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "SolicitacaoMateriais",
              "#mdi-order-bool-ascending-variant",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Nova Solicitação",
          boxText:
            "Clique aqui para iniciar o cadastro de uma nova solicitação.",
          selector: "#btn--cadastrarPedido",
        },
        {
          type: "text",
          boxTitle: "Evento",
          boxText: "Informe o nome do evento.",
          selector: "#evento",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Centro de Custo",
          boxText: "Selecione o centro de custo referente a esta solicitação.",
          selector: "#selecionaEscopo",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Data",
          boxText: "Informe a data da solicitação.",
          selector: "#dataPedido",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Data retorno",
          boxText: "Informe a data prevista em que o material será retornado.",
          selector: "#dataRetorno",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Descrição",
          boxText: "Coloque informações sobre a solicitação.",
          selector: "#descricao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Produtos",
          boxText: "Pesquise os produtos que deseja adicionar a solicitação.",
          selector: "#procurarProduto",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "ATENÇÃO!",
                boxText: `<center>Clique sobre o produto e ao abrir uma nova janela, informe a quantidade e clique em adicionar.</center>`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "SALVAR",
          boxText:
            "Confira o resumo da solicitação e então clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarPedidoMateriaisCircuito: [
        {
          boxTitle: "SOLICITAÇÃO DE MATERIAL",
          boxText:
            "Siga o passo a passo de como cadastrar uma solicitação de consumível.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Solicitação Materiais",
          boxText: "Clique aqui para acessar a listagem de solicitações.",
          selector: "#mdi-order-bool-ascending-variant",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "SolicitacaoMateriais",
              "#mdi-order-bool-ascending-variant",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Nova Solicitação",
          boxText:
            "Clique aqui para iniciar o cadastro de uma nova solicitação.",
          selector: "#btn--cadastrarPedido",
        },
        {
          type: "text",
          boxTitle: "Evento",
          boxText: "Informe o nome do evento.",
          selector: "#evento",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Centro de Custo",
          boxText: "Selecione o centro de custo referente a esta solicitação.",
          selector: "#selecionaEscopo",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Data",
          boxText: "Informe a data da solicitação.",
          selector: "#dataPedido",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Data retorno",
          boxText: "Informe a data prevista em que o material será retornado.",
          selector: "#dataRetorno",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Descrição",
          boxText: "Coloque informações sobre a solicitação.",
          selector: "#descricao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Produtos",
          boxText: "Pesquise os produtos que deseja adicionar a solicitação.",
          selector: "#procurarProduto",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "ATENÇÃO!",
                boxText: `<center>Clique sobre o produto e ao abrir uma nova janela, informe a quantidade e clique em adicionar.</center>`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "SALVAR",
          boxText:
            "Confira o resumo da solicitação e então clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarPedidoUniformes: [
        {
          boxTitle: "SOLICITAÇÃO DE UNIFORMES",
          boxText:
            "Siga o passo a passo de como cadastrar um solicitação de uniformes.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Recursos",
          boxText: "Clique aqui para acessar o menu de recursos.",
          selector: "#mdi-bookshelf .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "recursos",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Solicitação Uniformes",
          boxText: "Clique aqui para acessar a listagem de solicitações.",
          selector: "#mdi-account-tie-hat",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "SolicitacaoUniformes",
              "#mdi-account-tie-hat",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Nova Solicitação",
          boxText:
            "Clique aqui para iniciar o cadastro de uma nova solicitação.",
          selector: "#btn--cadastrarPedido",
        },
        {
          type: "text",
          boxTitle: "Data",
          boxText: "Informe a data da solicitação.",
          selector: "#dataPedido",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Observação",
          boxText: "Acrescente uma observação sobre a solicitação.",
          selector: "#observacao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Funcionarios",
          boxText:
            "Ao encontrar o funcionário que deseja, clique aqui para selecioná-lo.",
          selector: ".v-simple-checkbox",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Produtos",
          boxText: "Pesquise os produtos que deseja adicionar a solicitação.",
          selector: "#procurarProduto",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "ATENÇÃO!",
                boxText: `<center>Clique sobre o produto e ao abrir uma nova janela, informe a quantidade e clique em adicionar.</center>`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "SALVAR",
          boxText:
            "Confira o resumo da solicitação e então clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarPedidoUniformesCircuito: [
        {
          boxTitle: "SOLICITAÇÃO DE UNIFORMES",
          boxText:
            "Siga o passo a passo de como cadastrar uma solicitação de uniformes.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Solicitação de Uniformes",
          boxText: "Clique aqui para acessar a listagem de solicitações.",
          selector: "#mdi-account-tie-hat",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "SolicitacaoUniformes",
              "#mdi-account-tie-hat",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Nova Solicitação",
          boxText:
            "Clique aqui para iniciar o cadastro de uma nova solicitação.",
          selector: "#btn--cadastrarPedido",
        },
        {
          type: "text",
          boxTitle: "Data",
          boxText: "Informe a data da solicitação.",
          selector: "#dataPedido",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Observação",
          boxText: "Acrescente uma observação sobre a solicitação.",
          selector: "#observacao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Funcionarios",
          boxText:
            "Ao encontrar o funcionário que deseja, clique aqui para selecioná-lo.",
          selector: ".v-simple-checkbox",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Produtos",
          boxText: "Pesquise os produtos que deseja adicionar a solicitação.",
          selector: "#procurarProduto",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "ATENÇÃO!",
                boxText: `<center>Clique sobre o produto e ao abrir uma nova janela, informe a quantidade e clique em adicionar.</center>`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "SALVAR",
          boxText:
            "Confira o resumo da solicitação e então clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarPedidoConsumivel: [
        {
          boxTitle: "SOLICITAÇÃO DE CONSUMÍVEIS",
          boxText:
            "Siga o passo a passo de como cadastrar uma solicitação de consumível.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Recursos",
          boxText: "Clique aqui para acessar o menu de recursos.",
          selector: "#mdi-bookshelf .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "recursos",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Solicitação Consumíveis",
          boxText: "Clique aqui para acessar a listagem de solicitações.",
          selector: "#mdi-basket-fill",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "SolicitacaoConsumiveis",
              "#mdi-basket-fill",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Nova Solicitação",
          boxText:
            "Clique aqui para iniciar o cadastro de uma nova solicitação.",
          selector: "#btn--cadastrarPedido",
        },
        {
          type: "text",
          boxTitle: "Data",
          boxText: "Informe a data da operação.",
          selector: "#dataPedido",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Observação",
          boxText:
            "Adiciona informações sobre a solicitação, caso ache necessário.",
          selector: "#observacao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Centro de Custo",
          boxText: "Selecione o centro de custo referente a esta solicitação.",
          selector: "#selecionaEscopo",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Produtos",
          boxText: "Pesquise os produtos que deseja adicionar a solicitação.",
          selector: "#procurarProduto",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "ATENÇÃO!",
                boxText: `<center>Clique sobre o produto e ao abrir uma nova janela, informe a quantidade e clique em adicionar.</center>`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "SALVAR",
          boxText:
            "Confira o resumo da solicitação e então clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarPedidoConsumivelCircuito: [
        {
          boxTitle: "SOLICITAÇÃO DE CONSUMÍVEIS",
          boxText:
            "Siga o passo a passo de como cadastrar uma solicitação de consumível.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Solicitação Consumíveis",
          boxText: "Clique aqui para acessar a listagem de solicitações.",
          selector: "#mdi-basket-fill",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "SolicitacaoConsumiveis",
              "#mdi-basket-fill",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Nova Solicitação",
          boxText:
            "Clique aqui para iniciar o cadastro de uma nova solicitação.",
          selector: "#btn--cadastrarPedido",
        },
        {
          type: "text",
          boxTitle: "Data",
          boxText: "Informe a data da operação.",
          selector: "#dataPedido",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Observação",
          boxText:
            "Adiciona informações sobre a solicitação, caso ache necessário.",
          selector: "#observacao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Centro de Custo",
          boxText: "Selecione o centro de custo referente a esta solicitação.",
          selector: "#selecionaEscopo",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Produtos",
          boxText: "Pesquise os produtos que deseja adicionar a solicitação.",
          selector: "#procurarProduto",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "ATENÇÃO!",
                boxText: `<center>Clique sobre o produto e ao abrir uma nova janela, informe a quantidade e clique em adicionar.</center>`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "SALVAR",
          boxText:
            "Confira o resumo da solicitação e então clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarImobilizado: [
        {
          boxTitle: "IMOBILIZADOS",
          boxText: "Siga o passo a passo de como cadastrar um imobilizado.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Recursos",
          boxText: "Clique aqui para acessar o menu de recursos.",
          selector: "#mdi-bookshelf .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "recursos",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Imobilizados",
          boxText: "Clique aqui para acessar a listagem de produtos.",
          selector: "#mdi-file-cabinet",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Imobilizados",
              "#mdi-file-cabinet",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo cadastro",
          boxText: "Clique aqui para iniciar um novo cadastro.",
          selector: "#btn--cadastro",
        },
        {
          type: "textOnly",
          boxTitle: "Preencha o formulário",
          boxText: "Preencha com as informações referente do imobilizado.",
        },
        {
          type: "text",
          boxTitle: "SALVAR",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarImobilizadoCircuito: [
        {
          boxTitle: "IMOBILIZADOS",
          boxText: "Siga o passo a passo de como cadastrar um imobilizado.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Imobilizados",
          boxText: "Clique aqui para acessar a listagem de produtos.",
          selector: "#mdi-file-cabinet",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Imobilizados",
              "#mdi-file-cabinet",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo cadastro",
          boxText: "Clique aqui para iniciar um novo cadastro.",
          selector: "#btn--cadastro",
        },
        {
          type: "textOnly",
          boxTitle: "Preencha o formulário",
          boxText: "Preencha com as informações referente do imobilizado.",
        },
        {
          type: "text",
          boxTitle: "SALVAR",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarImobilizadoMov: [
        {
          boxTitle: "MOVIMENTAÇÃO DE IMOBILIZADO",
          boxText:
            "Siga o passo a passo de como cadastrar uma movimentação de imobilizado.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Recursos",
          boxText: "Clique aqui para acessar o menu de recursos.",
          selector: "#mdi-bookshelf .v-list-item--link",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenu(
              "recursos",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Imobilizado movimentações",
          boxText: "Clique aqui para acessar a listagem de solicitações.",
          selector: "#mdi-file-sync",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Imobilizado_movimentacoes",
              "#mdi-file-sync",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo cadastro",
          boxText: "Clique aqui para iniciar um novo cadastro.",
          selector: "#btn--cadastro",
        },
        {
          type: "text",
          boxTitle: "Descrição",
          boxText: "Descrição do local do imobilizado.",
          selector: "#descricao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Data",
          boxText: "Informe a data da solicitação.",
          selector: "#dataPedido",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Data retorno",
          boxText: "Informe a data prevista em que o material será retornado.",
          selector: "#dataRetorno",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Observação",
          boxText: "Acrescente uma observação, caso necessário.",
          selector: "#observacao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Produtos",
          boxText: "Pesquise o imobilizado que deseja adicionar a solicitação.",
          selector: "#procurarImob",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "ATENÇÃO!",
                boxText: `<center>Clique sobre o imobilizado para adicionar a solicitação.</center>`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "SALVAR",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsCadastrarImobilizadoMovCircuito: [
        {
          boxTitle: "MOVIMENTAÇÃO DE IMOBILIZADO",
          boxText:
            "Siga o passo a passo de como cadastrar uma movimentação de imobilizado.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Imobilizado movimentações",
          boxText: "Clique aqui para acessar a listagem de solicitações.",
          selector: "#mdi-file-sync",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Imobilizado_movimentacoes",
              "#mdi-file-sync",
              "#mdi-bookshelf",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "Novo cadastro",
          boxText: "Clique aqui para iniciar um novo cadastro.",
          selector: "#btn--cadastro",
        },
        {
          type: "text",
          boxTitle: "Descrição",
          boxText: "Descrição do local do imobilizado.",
          selector: "#descricao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Data",
          boxText: "Informe a data da solicitação.",
          selector: "#dataPedido",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Data retorno",
          boxText: "Informe a data prevista em que o material será retornado.",
          selector: "#dataRetorno",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Observação",
          boxText: "Acrescente uma observação, caso necessário.",
          selector: "#observacao",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Produtos",
          boxText: "Pesquise o imobilizado que deseja adicionar a solicitação.",
          selector: "#procurarImob",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "ATENÇÃO!",
                boxText: `<center>Clique sobre o imobilizado para adicionar a solicitação.</center>`,
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "SALVAR",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsTransferenciaContas: [
        {
          boxTitle: "TRANSFERÊNCIAS ENTRE CONTAS",
          boxText:
            "A transferência entre contas serve para registrar as movimentações financeiras entre contas da sua empresa. Por exemplo: transferência do banco A para o banco B para compor saldo. Ou até mesmo transferência da Filial A para Filial B. Siga as instruções para registrar uma transferência entre contas.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo financeiro.",
          selector: "#mdi-cash .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText:
            "Clique aqui para acessar a listagem de transferência entre contas.",
          selector: "#mdi-bank-transfer",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Conta_bancaria_transferencias",
              "#mdi-bank-transfer",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para registrar uma nova transferência.",
          selector: "#btn--cadastroTransferencia",
        },

        {
          type: "textOnly",
          boxTitle: "PREENCHA O FORMULÁRIO",
          boxText:
            "Preencha os campos do formulário e logo em seguida clique em SALVAR.",
        },
      ],

      stepsExtratoBancario: [
        {
          boxTitle: "MOVIMENTAÇÕES BANCÁRIAS",
          boxText:
            "Nesse módulo você pode consultar o extrato de suas contas, podendo também gerar um documento contendo todos os dados das movimentações referentes ao período informado.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo financeiro.",
          selector: "#mdi-cash .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });

            return this.pre_step__clickSubmenu(
              "Clientes",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar as movimentações bancárias.",
          selector: "#mdi-bank-transfer-in",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Conta_bancaria_transferencias",
              "#mdi-bank-transfer-in",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: `Selecione a conta bancária que deseja consultar.
                    As movimentações são exibidas de forma automática de acordo com o período informado.`,
          selector: "#contaBancaria",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxText:
            "Ao selecionar a conta, o saldo atual da mesma ficará disponível para visualização",
          selector: "#saldo",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 4",
          boxText: "Informe a data de inicio do período.",
          selector: "input[name='inicioPeriodo']",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 5",
          boxText: "Informe a data final do período de consulta.",
          selector: "#finalPeriodoMB",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          type: "text",
          boxTitle: "PASSO 6",
          boxText:
            "Clique aqui para gerar um documento com os dados das movimentações do período informado.",
          selector: "#btn-imprimir",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsTransferenciaContasCircuito: [
        {
          boxTitle: "TRANSFERÊNCIAS ENTRE CONTAS",
          boxText:
            "A transferência entre contas serve para registrar as movimentações financeiras entre contas da sua empresa. Por exemplo: transferência do banco A para o banco B para compor saldo. Ou até mesmo transferência da Filial A para Filial B. Siga as instruções para registrar uma transferência entre contas.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "Transferência entre Contas",
          boxText:
            "Clique aqui para acessar a listagem de transferência entre contas.",
          selector: "#mdi-bank-transfer",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Conta_bancaria_transferencias",
              "#mdi-bank-transfer",
              "#mdi-currency-usd",
              helpers
            );
          },
        },

        {
          type: "text",
          boxTitle: "Nova Transferência",
          boxText: "Clique aqui para registrar uma nova transferência.",
          selector: "#btn--cadastroTransferencia",
        },

        {
          type: "textOnly",
          boxTitle: "PREENCHA O FORMULÁRIO",
          boxText: "Preencha os campos do formulário.",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText:
            "Após conferir os dados da transferência, clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsPedidoCompra: [
        {
          boxTitle: "PEDIDO DE COMPRA",
          boxText: "Siga as instruções para cadastrar um pedido de compra.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo de compras.",
          selector: "#mdi-shopping-outline .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });
            return this.pre_step__clickSubmenu(
              "compras",
              "#mdi-shopping-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar a listagem de pedidos.",
          selector: "#mdi-shopping-search-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Compras",
              "#mdi-shopping-outline",
              "#mdi-shopping-search-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para cadastrar um novo pedido.",
          selector: "#btn--cadastro",
        },
        {
          type: "textWaitInput",
          boxTitle: "PASSO 4",
          boxText: "Insira a identificação para essa parametrização.",
          selector: "input[name='descricao']",
        },
        {
          type: "textWaitInput",
          boxTitle: "PASSO 5",
          boxText: "Insira a descrição detalhada para este pedido.",
          selector: "textarea[name='detalhes']",
        },
        {
          type: "text",
          boxTitle: "PASSO 6",
          boxText: "Selecione a conta financeira.",
          selector: "#contaFin",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 7",
          boxText:
            "Clique aqui para inserir o centro de custo e o rateio para este pedido de compra.",
          selector: "#btn--CriarItemSuperSet",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 8",
          boxText: "Clique aqui para adicionar os produtos para o pedido.",
          selector: "#produtos",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsOrcamentoCompra: [
        {
          boxTitle: "ORÇAMENTO DE COMPRA",
          boxText: "Siga as instruções para cadastrar um orçamento de compra.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo de compras.",
          selector: "#mdi-shopping-outline .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });
            return this.pre_step__clickSubmenu(
              "compras",
              "#mdi-shopping-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar a listagem de orçamentos.",
          selector: "#mdi-store-check-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Compras",
              "#mdi-shopping-outline",
              "#mdi-store-check-outline",
              helpers
            );
          },
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "IMPORTANTE!",
                boxText:
                  "<center>Para cadastrar um orçamento de compra, é preciso ter o pedido de compra cadastrado.</center>",
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para cadastrar um novo orçamento.",
          selector: "#btn--cadastro",
        },
        {
          type: "text",
          boxTitle: "PASSO 4",
          boxText: "Selecione o pedido de compra cadastrado.",
          selector: "#pedidoCompra",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 5",
          boxText: "Selecione o fornecedor.",
          selector: "#fornecedorCompra",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 6",
          boxText: "Informe a data do orçamento.",
          selector: "#dataOrcamento",
        },
        {
          type: "textWaitInput",
          boxTitle: "PASSO 7",
          boxText: "Insira a descrição detalhada para este orçamento.",
          selector: "textarea[name='detalhes']",
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],

      stepsOrdensCompra: [
        {
          boxTitle: "ORDENS DE COMPRA",
          boxText: "Siga as instruções para receber um pedido de compra.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo de compras.",
          selector: "#mdi-shopping-outline .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });
            return this.pre_step__clickSubmenu(
              "compras",
              "#mdi-shopping-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar a listagem de ordens.",
          selector: "#mdi-cart-arrow-down",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Compras",
              "#mdi-shopping-outline",
              "#mdi-cart-arrow-down",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 4",
          boxText: "Clique neste botão para iniciar o processo de recepção.",
          selector: "#receberCompra",
        },
        {
          type: "textWaitInput",
          boxTitle: "PASSO 5",
          boxText: "Insira a identificação da nota fiscal de compra.",
          selector: "input[name='notaFiscal']",
        },
        {
          type: "text",
          boxTitle: "PASSO 6",
          boxText: "Clique aqui para confirmar.",
          selector: "#btn-receive",
          // infoBoxComponent: OkInfoBox,
          // clickTarget: false,
        },
      ],

      stepsCircuitoCompras: [
        {
          boxTitle: "PEDIDO DE COMPRA",
          boxText: "Siga as instruções para cadastrar um pedido de compra.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar o módulo de compras.",
          selector: "#mdi-shopping-outline .v-list-item--link",
          clickTarget: true,
          callBeforeRender: async (helpers) => {
            this.$router.push({ name: "Welcome" });
            return this.pre_step__clickSubmenu(
              "compras",
              "#mdi-shopping-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para acessar a listagem de pedidos.",
          selector: "#mdi-shopping-search-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Compras",
              "#mdi-shopping-outline",
              "#mdi-shopping-search-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Clique aqui para cadastrar um novo pedido.",
          selector: "#btn--cadastro",
        },
        {
          type: "text",
          boxTitle: "PASSO 4",
          boxText: "Insira a identificação para essa parametrização.",
          selector: "input[name='descricao']",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 5",
          boxText: "Insira a descrição detalhada para este pedido.",
          selector: "textarea[name='detalhes']",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 6",
          boxText: "Selecione a conta financeira.",
          selector: "#contaFin",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 7",
          boxText:
            "Clique aqui para inserir o centro de custo e o rateio para este pedido de compra.",
          selector: "#btn--CriarItemSuperSet",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 8",
          boxText: "Clique aqui para adicionar os produtos para o pedido.",
          selector: "#produtos",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          boxTitle: "ORÇAMENTO DE COMPRA",
          boxText: "Siga as instruções para cadastrar um orçamento de compra.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "textOnly",
          boxTitle: "",
          boxText: "",
          infoBoxComponent: MultiText,
          componentProps: {
            steps: [
              {
                boxTitle: "IMPORTANTE!",
                boxText:
                  "<center>Para cadastrar um orçamento de compra, é preciso ter o pedido de compra cadastrado.</center>",
              },
            ],
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar a listagem de orçamentos.",
          selector: "#mdi-store-check-outline",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Compras",
              "#mdi-shopping-outline",
              "#mdi-store-check-outline",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique aqui para cadastrar um novo orçamento.",
          selector: "#btn--cadastro",
        },
        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Selecione o pedido de compra cadastrado.",
          selector: "#pedidoCompra",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 4",
          boxText: "Selecione o fornecedor.",
          selector: "#fornecedorCompra",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 5",
          boxText: "Informe a data do orçamento.",
          selector: "#dataOrcamento",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 6",
          boxText: "Insira a descrição detalhada para este orçamento.",
          selector: "textarea[name='detalhes']",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "Salvar",
          boxText: "Clique aqui para finalizar o cadastro.",
          selector: 'button[type="submit"]',
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },

        {
          boxTitle: "ORDENS DE COMPRA",
          boxText: "Siga as instruções para receber um pedido de compra.",
          type: "textOnly",
          callBeforeRender: async (helpers) => {
            return this.pre_step__close_modal_sucesso(helpers);
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 1",
          boxText: "Clique aqui para acessar a listagem de ordens.",
          selector: "#mdi-cart-arrow-down",
          callBeforeRender: async (helpers) => {
            return this.pre_step__clickSubmenuItem(
              "Compras",
              "#mdi-shopping-outline",
              "#mdi-cart-arrow-down",
              helpers
            );
          },
        },
        {
          type: "text",
          boxTitle: "PASSO 2",
          boxText: "Clique neste botão para iniciar o processo de recepção.",
          selector: "#receberCompra",
        },
        {
          type: "text",
          boxTitle: "PASSO 3",
          boxText: "Insira a identificação da nota fiscal de compra.",
          selector: "input[name='notaFiscal']",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
        {
          type: "text",
          boxTitle: "PASSO 4",
          boxText: "Clique aqui para confirmar.",
          selector: "#btn-receive",
          infoBoxComponent: OkInfoBox,
          clickTarget: false,
        },
      ],
    };
  },

  methods: {
    awaitTimeout(timeout) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, timeout);
      });
    },

    /******
     * Logica de passos para clicar em um item de um submenu
     *
     * @param {string} stringMenuI18N string I18N contida no elemento pai/grupo
     *
     * @param {string} itemId #id do elemento (passar com #)
     *
     * @param {string} groupId #id do elemento pai/grupo (passar com #)
     *  */
    async pre_step__clickSubmenuItem(stringMenuI18N, itemId, groupId, helpers) {
      await this.awaitTimeout(200);
      let valueMenu = this.$t(stringMenuI18N);
      let stringProcurar = this.$t("Procurar");

      let menuEstaAberto = helpers.contains(stringProcurar, "#pesquisa-menu");
      let submenuEstaAberto = helpers.contains(valueMenu, itemId);

      if (!menuEstaAberto) {
        return [
          {
            type: "text",
            boxTitle: "Abrir o menu",
            boxText: "Clique aqui para abrir o menu",
            selector: ".mdi-dots-vertical",
          },
        ];
      }

      if (!submenuEstaAberto) {
        document.querySelector(`${groupId} .v-list-item--link`).click();
      }
    },

    /******
     * Logica de passos para clicar em um submenu
     *
     * @param {string} stringChildI18N string I18N contida em um elemento filho desse grupo
     *
     * @param {string} itemId #id do elemento (passar com #)
     *
     *  */
    async pre_step__clickSubmenu(stringChildI18N, itemId, helpers) {
      await this.awaitTimeout(200);
      let valueMenu = this.$t(stringChildI18N);
      let stringProcurar = this.$t("Procurar");

      let menuEstaAberto = helpers.contains(stringProcurar, "#pesquisa-menu");
      let submenuEstaAberto = helpers.contains(valueMenu, itemId);
      if (!menuEstaAberto) {
        return [
          {
            type: "text",
            boxTitle: "Abrir o menu",
            boxText: "Clique aqui para abrir o menu",
            selector: ".mdi-dots-vertical",
          },
        ];
      }

      if (submenuEstaAberto || window.innerWidth < 961) {
        return "breakThis";
      }
    },

    async pre_step__close_modal_sucesso(helpers) {
      await this.awaitTimeout(200);

      let btn = document.querySelector("#btn-modal-sucesso-nao");
      console.log("btn close_modal", btn);
      if (btn) {
        return [
          {
            type: "text",
            selector: "#btn-modal-sucesso-nao",
            boxTitle: "Sucesso",
            boxText: "Clique aqui para continuar",
          },
        ];
      } else {
        return null;
      }
    },

    async launchTutorial(option) {
      this.dialog = false;
      if (option == "Cadastrar_usuario") {
        /*   this.$store.dispatch("SET_PENDING_TUTORIAL", {
          name: "Cadastrar_usuario",
          steps: this.stepsCadastraUsuario,
        });
 */
        try {
          startTutorial(this.stepsCadastraUsuario);
        } catch (err) {
          console.log(err);
        }
      }

      if (option === "Cadastrar_pessoa") {
        startTutorial(this.stepsCadastraPessoa);
      }

      if (option === "Cadastrar_funcionario") {
        startTutorial(this.stepsCadastraFuncionario);
      }

      if (option === "CalculoPonto") {
        startTutorial(this.stepsCalculoPonto);
      }

      if (option === "Imprimir_folha_de_ponto") {
        startTutorial(this.stepsImprimirFolhaPonto);
      }

      if (option === "ImprimirFolhaPontoCircuito") {
        startTutorial(this.stepsImprimirFolhaPontoCircuito);
      }

      if (option === "RegistrosPonto") {
        startTutorial(this.stepsRegistrosPonto);
      }

      if (option === "Criar_periodo_folha_de_ponto") {
        startTutorial(this.stepsCriarPeriodoFolhaPonto);
      }

      if (option === "Encerrar_periodo_folha_de_ponto") {
        startTutorial([
          {
            type: "text",
            boxTitle: "Módulo de ponto",
            boxText: "Clique aqui para acessar o módulo de registro de ponto",
            selector: "#mdi-calendar-clock .v-list-item--link",
            callBeforeRender: async (helpers) => {
              return this.pre_step__clickSubmenu(
                "Periodo",
                "#mdi-map-clock-outline",
                helpers
              );
            },
          },
          {
            type: "text",
            boxTitle: "Gerenciamento de períodos",
            boxText:
              "Clique aqui para acessar o gerenciamento de períodos de ponto",
            selector: "#mdi-map-clock-outline",
            callBeforeRender: async (helpers) => {
              return this.pre_step__clickSubmenuItem(
                "Periodo",
                "#mdi-map-clock-outline",
                "#mdi-calendar-clock",
                helpers
              );
            },
          },
          {
            type: "text",
            boxTitle: "Editar o período",
            boxText:
              "Encontre o período que deseja encerrar e clique aqui para realizar a alteração.",
            selector: ".ni-ruler-pencil",
            callBeforeRender: () => {
              this.$router.push({ name: "TutorialFuncionarioPontoPeriodo" });
            },
          },
          {
            boxTitle: "Encerrar",
            boxText: "Clique aqui e marque este período como encerrado.",
            type: "text",
            selector: "[role=checkbox]",
            callBeforeRender: () => {
              this.$router.push({
                name: "tutorial.editar.funcionariopontoperiodo",
                params: { id: 1 },
              });
            },
          },
          {
            boxTitle: "Enviar",
            boxText: `<b style="color: #ffcc00">Esta operação não produzirá nenhum efeito!</b><br><br>
            Clique aqui para enviar.
            `,
            type: "text",
            selector: `button[type="submit"]`,
          },
        ]);
      }

      if (option === "Funcionario_movimentacoes") {
        startTutorial(this.stepsCadastraMovimentacoes);
      }

      if (option == "Introducao_gerenciamento_de_pessoas") {
        this.$store.dispatch("SET_PENDING_TUTORIAL", {
          // name: "Introducao_gerenciamento_de_pessoas",
          // steps: this.stepsCadastraUsuario,
          // routeName: "Cadastro.Pessoa",
          // next: {
          name: "Introducao_gerenciamento_de_pessoas",
          steps: this.stepsCadastraFuncionario,
          routeName: "cadastro.usuario",
          // next: {
          //   name: "Introducao_gerenciamento_de_pessoas",
          //   steps: this.stepsCadastraMovimentacoes,
          //   routeName: "Cadastro.Funcionario",
          // },
          //},
        });

        await startTutorial(this.stepsCadastraFuncionario);
        /*   await startTutorial(this.stepsCadastraUsuario)
        await startTutorial(this.stepsCadastraFuncionario)
        await startTutorial(this.stepsCadastraMovimentacoes) */
      }

      if (option === "PostoTrabalhos") {
        startTutorial(this.stepsPostoTrabalhos);
      }

      if (option === "gestaoEfetivo") {
        startTutorial(this.stepsGestaoEfetivo);
      }

      if (option === "Cadastrar_Cliente") {
        startTutorial(this.stepsCadastrarCliente);
      }

      if (option === "Cadastrar_Contrato") {
        startTutorial(this.stepsCadastrarContrato);
      }

      if (option === "Cadastrar_CFOPS") {
        startTutorial(this.stepsCadastrarCFOPS);
      }

      if (option === "Cadastrar_CFOPMun") {
        startTutorial(this.stepsCadastrarCFOPMun);
      }

      if (option === "Cadastrar_CFOPEst") {
        startTutorial(this.stepsCadastrarCFOPEst);
      }

      if (option === "Cadastrar_Escopo") {
        startTutorial(this.stepsCadastrarEscopo);
      }

      if (option === "Cadastrar_ParametrizacaoFiscal") {
        startTutorial(this.stepsCadastrarParametrizacaoFiscal);
      }
      if (option === "Cadastrar_ParametrizacaoServicos") {
        startTutorial(this.stepsCadastrarParametrizacaoServicos);
      }

      if (option === "Cadastrar_ContaBancaria") {
        startTutorial(this.stepsNovaContaBancaria);
      }

      if (option === "Cadastrar_CentroCusto") {
        startTutorial(this.stepsCadastrarCentroCusto);
      }

      if (option === "Cadastrar_Fornecedor") {
        startTutorial(this.stepsCadastrarFornecedor);
      }

      if (option === "Cadastrar_TituloReceber") {
        startTutorial(this.stepsCadastrarTituloReceber);
      }

      if (option === "Cadastrar_TituloPagar") {
        startTutorial(this.stepsCadastrarTituloPagar);
      }

      if (option === "Cadastrar_RegistroR") {
        startTutorial(this.stepsCadastrarRegistroR);
      }

      if (option === "Cadastrar_RegistroP") {
        startTutorial(this.stepsCadastrarRegistroP);
      }
      if (option === "Cadastrar_contaFinanceira") {
        startTutorial(this.stepsCadastrarContaFinanceira);
      }

      if (option === "fluxoDiario") {
        startTutorial(this.stepsConsultarFluxoDiario);
      }

      if (option === "dfcDireto") {
        startTutorial(this.stepsConsultarDFCDireto);
      }

      if (option === "analiseCC") {
        startTutorial(this.stepsAnaliseCC);
      }

      if (option === "Cadastrar_afastamento") {
        startTutorial(this.stepsCadastrarAfastamento);
      }

      if (option === "Cadastrar_trocaPlantao") {
        startTutorial(this.stepsCadastrarTrocaPlantao);
      }

      if (option === "Cadastrar_bancoHoras") {
        startTutorial(this.stepsCadastrarBancoHoras);
      }

      if (option === "periodoReducaoCH") {
        startTutorial(this.stepsCriarPeriodoReducaoCH);
      }
      if (option === "dashApuracaoPonto") {
        startTutorial(this.stepsDashApuracaoPonto);
      }
      if (option === "movimentacoesBancarias") {
        startTutorial(this.stepsExtratoBancario);
      }

      //       if (option === "Cadastrar_saldoBancoHoras") {
      //   startTutorial([
      //     {
      //       boxTitle: "Adicionar saldo de horas.",
      //       boxText: "Siga as instruções para adicionar saldo para um funcionário.",
      //       type: "textOnly",
      //       callBeforeRender: async (helpers) => {
      //         return this.pre_step__close_modal_sucesso(helpers);
      //       },
      //     },

      //     {
      //       type: "text",
      //       boxTitle: "Pessoal",
      //       boxText: "Clique aqui para acessar o gerenciamento de pessoal",
      //       selector: "#mdi-account-group .v-list-item--link",
      //       callBeforeRender: async (helpers) => {
      //         return this.pre_step__clickSubmenu(
      //           "Movimentacoes",
      //           "#mdi-transit-connection-variant",
      //           helpers
      //         );
      //       },
      //     },

      //     {
      //       type: "text",
      //       boxTitle: "Banco de Horas",
      //       boxText: "Clique aqui para acessar a listagem de banco de horas.",
      //       selector: "#mdi-database-clock-outline",
      //       callBeforeRender: async (helpers) => {
      //         return this.pre_step__clickSubmenuItem(
      //           "FuncionarioBancoHoras",
      //           "#mdi-database-clock-outline",
      //           "#mdi-account-group",
      //           helpers
      //         );
      //       },
      //     },

      //     {
      //       type: "text",
      //       boxTitle: "Adicionar Saldo",
      //       boxText: "Clique aqui para iniciar a operação.",
      //       selector: "#btn--cadastro-saldo",
      //     },
      //     {
      //       type: "text",
      //       boxTitle: "Criar item",
      //       boxText: "Clique aqui para selecionar o funcionário.",
      //       selector: "#btn--CriarItemSuperSet"
      //     },
      //     {
      //       type: "text",
      //       boxTitle: "Funcionário",
      //       boxText: "elecione o funcionário que irá receber o saldo de horas.",
      //       selector: "#nome"
      //     },
      //     {
      //       type: "text",
      //       boxTitle: "Informe a justificativa",
      //       boxText: "Escreva o motivo da adição do saldo para o funcionário.",
      //       selector: "#justificativa"
      //     },
      //   ]);
      // }

      if (option === "Cadastrar_cargo") {
        startTutorial(this.stepsCadastrarCargo);
      }

      if (option === "Cadastrar_sindicato") {
        startTutorial(this.stepsCadastrarSindicato);
      }

      if (option === "Cadastrar_escalaTrabalho") {
        startTutorial(this.stepsCadastrarEscalaTrabalho);
      }

      if (option === "Cadastrar_periodoFP") {
        startTutorial(this.stepsCadastrarPeriodoFP);
      }

      if (option === "Cadastrar_rubrica") {
        startTutorial(this.stepsCadastrarRubrica);
      }

      if (option === "Cadastrar_beneficioConvenio") {
        startTutorial(this.stepsCadastrarBeneficioConvenio);
      }

      if (option === "Cadastrar_beneficioOptante") {
        startTutorial(this.stepsCadastrarBeneficioOptante);
      }

      if (option === "Cadastrar_beneficioPeriodoApuracao") {
        startTutorial(this.stepsConsultarBeneficioPeriodoApuracao);
      }

      if (option === "Cadastrar_beneficioApuracao") {
        startTutorial(this.stepsConsultarBeneficioApuracao);
      }

      if (option === "Cadastrar_beneficioAnaliseCliente") {
        startTutorial(this.stepsConsultarBeneficioAnaliseCliente);
      }

      if (option === "Cadastrar_calculoFaturamento") {
        startTutorial(this.stepsConsultarCalculoFaturamento);
      }

      if (option === "Gerar_docFat") {
        startTutorial(this.stepsGerarDocFat);
      }

      if (option === "Gerar_docFiscal") {
        startTutorial(this.stepsGerarDocFiscal);
      }

      if (option === "Cadastrar_pracaFiscal") {
        startTutorial(this.stepsCadastrarPracaFiscal);
      }

      if (option === "Cadastrar_produto") {
        startTutorial(this.stepsCadastrarProduto);
      }

      if (option === "Cadastrar_pedidoMateriais") {
        startTutorial(this.stepsCadastrarPedidoMateriais);
      }

      if (option === "Cadastrar_pedidoUniformes") {
        startTutorial(this.stepsCadastrarPedidoUniformes);
      }

      if (option === "Cadastrar_pedidoConsumivel") {
        startTutorial(this.stepsCadastrarPedidoConsumivel);
      }

      if (option === "Cadastrar_imobilizado") {
        startTutorial(this.stepsCadastrarImobilizado);
      }

      if (option === "Cadastrar_imobilizadoMov") {
        startTutorial(this.stepsCadastrarImobilizadoMov);
      }

      if (option === "transferenciaContas") {
        startTutorial(this.stepsTransferenciaContas);
      }

      if (option === "Cadastrar_compraPedido") {
        startTutorial(this.stepsPedidoCompra);
      }

      if (option === "Cadastrar_compraOrcamento") {
        startTutorial(this.stepsOrcamentoCompra);
      }

      if (option === "Cadastrar_compraOrdens") {
        startTutorial(this.stepsOrdensCompra);
      }

      if (option === "metodoCobranca") {
        startTutorial(this.stepsMetodoCobranca);
      }

      if (option == "circuitoPonto") {
        this.$store.dispatch("SET_PENDING_TUTORIAL", {
          // name: "RegistrosPonto",
          // steps: this.stepsRegistrosPonto,
          // routeName: "funcionariopontos",
        });

        await startTutorial(this.stepsRegistrosPonto);
        await startTutorial(this.stepsCalculoPonto);
        await startTutorial(this.stepsImprimirFolhaPontoCircuito);
        await startTutorial(this.stepsCriarPeriodoFolhaPonto);
        await startTutorial(this.stepsRelatorioFolhaPonto);
      }

      if (option == "circuitoComercial") {
        this.$store.dispatch("SET_PENDING_TUTORIAL", {});

        await startTutorial(this.stepsCadastrarCliente);
        await startTutorial(this.stepsCadastrarParametrizacaoFiscal);
        await startTutorial(this.stepsCadastrarContrato);
        await startTutorial(this.stepsCadastrarEscopo);
        await startTutorial(this.stepsCadastrarParametrizacaoServicos);
        await startTutorial(this.stepsPostoTrabalhos);
        await startTutorial(this.stepsCadastrarCFOPSCircuito);
        await startTutorial(this.stepsCadastrarCFOPMun);
        await startTutorial(this.stepsCadastrarCFOPEst);
      }

      if (option == "circuitoFaturamento") {
        this.$store.dispatch("SET_PENDING_TUTORIAL", {});

        await startTutorial(this.stepsConsultarCalculoFaturamento);
        await startTutorial(this.stepsGerarDocFiscal);
        await startTutorial(this.stepsCadastrarPracaFiscal);
      }

      if (option == "circuitoPessoal") {
        this.$store.dispatch("SET_PENDING_TUTORIAL", {});

        await startTutorial(this.stepsCadastraFuncionario);
        await startTutorial(this.stepsGestaoEfetivo);
        await startTutorial(this.stepsCadastraMovimentacoes);
        await startTutorial(this.stepsCadastraPessoa);
        await startTutorial(this.stepsCadastrarAfastamento);
        await startTutorial(this.stepsCadastrarBancoHoras);
        await startTutorial(this.stepsCadastrarCargo);
        await startTutorial(this.stepsCadastrarSindicatoCircuito);
        await startTutorial(this.stepsCadastrarEscalaTrabalhoCircuito);
        await startTutorial(this.stepsCadastrarPeriodoFPCircuito);
      }

      if (option == "circuitoBeneficio") {
        this.$store.dispatch("SET_PENDING_TUTORIAL", {});

        await startTutorial(this.stepsCadastrarBeneficioConvenio);
        await startTutorial(this.stepsCadastrarBeneficioOptanteCircuito);
        await startTutorial(
          this.stepsConsultarBeneficioPeriodoApuracaoCircuito
        );
        await startTutorial(this.stepsConsultarBeneficioApuracaoCircuito);
        await startTutorial(this.stepsConsultarBeneficioAnaliseClienteCircuito);
      }

      if (option == "circuitoRecursos") {
        this.$store.dispatch("SET_PENDING_TUTORIAL", {});

        await startTutorial(this.stepsCadastrarProduto);
        await startTutorial(this.stepsCadastrarPedidoMateriaisCircuito);
        await startTutorial(this.stepsCadastrarPedidoUniformesCircuito);
        await startTutorial(this.stepsCadastrarPedidoConsumivelCircuito);
        await startTutorial(this.stepsCadastrarImobilizadoCircuito);
        await startTutorial(this.stepsCadastrarImobilizadoMovCircuito);
      }

      if (option == "circuitoFinanceiro") {
        this.$store.dispatch("SET_PENDING_TUTORIAL", {});

        await startTutorial(this.stepsCadastrarFornecedor);
        await startTutorial(this.stepsCadastrarCentroCustoCircuito);
        await startTutorial(this.stepsCadastrarContaFinanceiraCircuito);
        await startTutorial(this.stepsNovaContaBancariaCircuito);
        await startTutorial(this.stepsCadastrarTituloReceberCircuito);
        await startTutorial(this.stepsCadastrarTituloPagarCircuito);
        await startTutorial(this.stepsCadastrarRegistroRCircuito);
        await startTutorial(this.stepsCadastrarRegistroPCircuito);
        await startTutorial(this.stepsTransferenciaContasCircuito);
        await startTutorial(this.stepsConsultarFluxoDiarioCircuito);
        await startTutorial(this.stepsConsultarDFCDiretoCircuito);
        await startTutorial(this.stepsAnaliseCCcircuito);
      }

      if (option == "circuitoCompras") {
        this.$store.dispatch("SET_PENDING_TUTORIAL", {});
        await startTutorial(this.stepsCircuitoCompras);
      }
    },
  },
};
</script>

<style>
.text-h4 {
  text-align: center;
  /* text-align: inherit; */
}

#btnTexto {
  height: 30px;
  width: 30px;
}
</style>
