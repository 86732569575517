import Axios from "axios";

class ClienteDataService {

  criaEndereco(params) {
    return Axios.post('/clienteEnderecos', params)
  }

  getAll() {
    return Axios.get('clientes');
  }

  getPage(params) {
    return Axios.get('clientes/page', { params })
  }

  create(params) {
    return Axios.post('clientes', params)
  }

  update(params) {
    return Axios.put('clientes/' + (params.id), params)
  }

  find(id) {
    return Axios.get(`clientes/${id}`)
  }

  delete(id) {
    return Axios.delete(`clientes/${id}`);
  }

  getUser() {
    return Axios.get(`usuarios/dados_Usuario`)
  }

  getPermissoes() {
    return Axios.get(`usuarios/permissoes`)
  }

  getCidades() {
    return Axios.get(`cidades`)
  }

  updateEnderecoCliente(params) {
    console.log(params)
    return Axios.put(`clienteEnderecos/${params.id}`, params)
  }

  deleteEnderecoCliente(id) {
    return Axios.delete(`clienteEnderecos/${id}`)
  }

  buscaCep(value) {
    return Axios.get(`http://cep.republicavirtual.com.br/web_cep.php?cep=${value}&formato=json`, {
      transformRequest: (data, headers) => {
        delete headers.common['Authorization'];
      }
    });
  }

  export(params) {
    return Axios.put("clientes/export", params);
  }

  importContaContabil(params) {
    return Axios.post("clientes/importContaContabil", params);
  }
}

export default new ClienteDataService()
