<template>
  <ListagemBase
    nomePlural="Usuarios"
    nomeSingular="Usuario"
    :headers="headers"
    stringCadastro="Novo_usuario"
    :buscaSimplificada="false"
    :pesquisaPessoa="true"
  >
    <template v-slot:upload> </template>
  </ListagemBase>
</template>

<script>
import DataService from "@/services/acessos/UsuarioDataService";

import ListagemBase from "@/components/base/Crud/ListagemBase";
export default {
  name: "ListagemUsuarios",
  components: { ListagemBase },

  data() {
    return {
      DataService: DataService,

      headers: [
        { text: this.$t("Nome"), value: "pessoa.nome", sortable: "false" },
        { text: this.$t("Email_ou_cpf"), value: "email", sortable: "false" },

        { text: this.$t("Acoes"), value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style>
</style>
