<template>
  <v-container fluid>
    <slot name="modals"> </slot>
    <ErrorAlert :errors="erros"></ErrorAlert>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="#67748e"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-if="!loading">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-600 text-h3 text-typo mb-0">
            {{ $t("editar." + this.nomeSingular.toLowerCase()) }}</span
          >
        </div>

        <v-row justify="center">
          <v-col cols="12" md="12">
            <v-form @submit.prevent="submitForm" v-if="!submited">
              <v-container>
                <v-row>
                  <slot> </slot>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      id="salvar"
                      style="z-index: 2"
                      @click="closeModal"
                      :loading="loading"
                      class="ma-1 font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
                      height="39"
                    >
                      {{ $t("Voltar") }}
                    </v-btn>
                    <v-btn
                      id="salvar"
                      style="z-index: 2"
                      type="submit"
                      :loading="loading"
                      class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                      height="39"
                      :disabled="disableSubmit"
                      v-if="this.nomeSingular !== 'ProdutoCentroEstoque'"
                    >
                      {{ $t("Enviar") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>


<script>
export default {
  name: "EditarBase",
  props: {
    nomePlural: { type: String, required: true },
    nomeSingular: { type: String, required: true },
    submitPersonalizado: { type: Boolean, required: false, default: false }, //Se true usa o método submitForm do parent
    disableSubmit: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      submited: false,
      loading: false,
      erros: [],
      recurso: {},
    };
  },
  created() {
    this.getRecurso();
  },
  watch: {
    itemAtual: {
      handler(newValue) {
        this.recurso = { ...newValue };
      },
      immediate: true,
    },
  },
  methods: {
    submitForm() {
      this.loading = true;
      console.log("editarBase submitType", this.submitPersonalizado);
      //Usa método do componente pai se prop submitPersonalizado for true
      if (this.submitPersonalizado) {
        this.$parent
          .submitForm()
          .then(() => {
            this.erros = [];
            this.submited = true;
            this.loading = false;
            this.$emit("submitSuccess");

            this.checkPendingTutorialSucess();
          })
          .catch((erros) => {
            this.erros = erros;
            this.loading = false;
            this.$emit("submitError");
          });
      } else {
        console.log(
          this.$parent.recurso,
          this.limpaMascara(this.$parent.recurso)
        );
        this.$parent.DataService.update(this.limpaMascara(this.$parent.recurso))
          .then((response) => {
            this.erros = [];
            this.submited = true;
            this.loading = false;
            this.$emit("submitSuccess");
            this.checkPendingTutorialSucess();
          })
          .catch((error) => {
            this.loading = false;
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            this.$emit("submitError");
            console.log(
              " error.response.data.erros",
              error.response.data.errors
            );
            this.erros = error.response.data.errors;
          });
      }
    },
    getRecurso() {
      if (this.nomeSingular === "configuracoes.inquilino") {
        this.loading = true;
        this.$parent.DataService.find(this.$route.params.id)
          .then((response) => {
            console.log("entrou aqui", response.data);
            this.$emit("recurso", response.data);
            this.loading = false;
          })
          .catch((error) => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            console.log(error);
            this.loading = false;
            this.erros.push({
              field: "ID",
              message: "Ocorreu um erro, tente novamente!",
            });
          });
      }
    },
    closeModal() {
      this.$emit("closeModalEdit");
    },
  },
};
document.addEventListener("keydown", function (event) {
  if (event.key === "Enter") {
    //Criar verficador que encontra qual elemento tem o foco
    var elem = document.activeElement;

    //Se o nome do campo tiver  a palavra cBarras faça...
    if (elem.name.indexOf("cBarras") != -1) {
      //impedir o formulário de ser enviado
      console.log("Prevent cBarras");
      event.preventDefault();
      return false;
    }

    if (
      elem.name.indexOf("searchProdutos") != -1 ||
      elem.name.indexOf("searchProdutosPedido") != -1
    ) {
      //impedir o formulário de ser enviado
      console.log("Prevent searchProdutos");
      event.preventDefault();
      return false;
    }
  }
});
</script>

<style scoped></style>
