var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EditarBase',{attrs:{"nomeSingular":"FuncionarioSindicato","nomePlural":"FuncionarioSindicatos","submitPersonalizado":true},on:{"recurso":function($event){_vm.recurso = $event},"submitSuccess":_vm.emitSubmitSuccess,"submitError":_vm.emitSubmitError,"closeModalEdit":_vm.closeModalEdit}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-span',{staticClass:"opacity-5 text-dark"},[_vm._v(" "+_vm._s(_vm.$t("Descricao"))+" ")]),_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"label":_vm.$t('Descricao'),"name":"descricao","dense":"","flat":"","filled":"","solo":""},model:{value:(_vm.recurso.descricao),callback:function ($$v) {_vm.$set(_vm.recurso, "descricao", $$v)},expression:"recurso.descricao"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CriaSuperSet',{attrs:{"podeEditarRecursoFinal":true,"payload":_vm.recurso.rubricas,"usaRecursoBase":false,"tituloFinais":"Rubricas_exportacao_folha_pagamento","subtituloFinais":"Clique_para_adicionar","headersFinais":[
            {
              text: this.$t('Rubrica'),
              value: 'rubrica',
              sortable: 'true',
            },
            {
              text: this.$t('Tipo'),
              value: 'tipo',
              sortable: 'true',
            },

            {
              text: this.$t('Tipo_apuracao'),
              value: 'tipoApuracao',
              sortable: 'true',
            },

            { text: this.$t('Acoes'), value: 'actions', sortable: 'false' },
          ],"textoBotaoCadastro":"Criar_item"},on:{"recursos":function($event){_vm.recurso.rubricas = $event}},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"items":_vm.itensParametrizacoes,"label":_vm.$t('Tipo'),"id":"tipo","dense":"","flat":"","filled":"","solo":""},model:{value:(slotProps.recursoFinalAtual.tipo),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "tipo", $$v)},expression:"slotProps.recursoFinalAtual.tipo"}})],1),(
                _vm.deveMostrarCampo(
                  'tipo_apuracao',
                  slotProps.recursoFinalAtual.tipo
                )
              )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"items":[
                  {
                    text: _vm.$t('Dia'),
                    value: 'DIA',
                  },
                  {
                    text: _vm.$t('Hora'),
                    value: 'HORA',
                  },
                ],"label":_vm.$t('Tipo_apuracao'),"id":"tipo_apuracao","dense":"","flat":"","filled":"","solo":""},model:{value:(slotProps.recursoFinalAtual.tipoApuracao),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "tipoApuracao", $$v)},expression:"slotProps.recursoFinalAtual.tipoApuracao"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"rules":_vm.rule_required,"label":_vm.$t('Rubrica'),"name":"rubrica","id":"rubrica_cod"},model:{value:(slotProps.recursoFinalAtual.rubrica),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "rubrica", $$v)},expression:"slotProps.recursoFinalAtual.rubrica"}})],1)]}},{key:"formEditaRecursoFinal",fn:function(slotProps){return [_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"items":_vm.itensParametrizacoes,"label":_vm.$t('Tipo'),"dense":"","flat":"","filled":"","solo":""},model:{value:(slotProps.recursoFinalAtual.tipo),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "tipo", $$v)},expression:"slotProps.recursoFinalAtual.tipo"}})],1),(
                _vm.deveMostrarCampo(
                  'tipo_apuracao',
                  slotProps.recursoFinalAtual.tipo
                )
              )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"items":[
                  {
                    text: _vm.$t('Dia'),
                    value: 'DIA',
                  },
                  {
                    text: _vm.$t('Hora'),
                    value: 'HORA',
                  },
                ],"label":_vm.$t('Tipo_apuracao'),"dense":"","flat":"","filled":"","solo":""},model:{value:(slotProps.recursoFinalAtual.tipoApuracao),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "tipoApuracao", $$v)},expression:"slotProps.recursoFinalAtual.tipoApuracao"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"rules":_vm.rule_required,"label":_vm.$t('Rubrica'),"name":"rubrica","dense":"","flat":"","filled":"","solo":""},model:{value:(slotProps.recursoFinalAtual.rubrica),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "rubrica", $$v)},expression:"slotProps.recursoFinalAtual.rubrica"}})],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CriaSuperSet',{attrs:{"podeEditarRecursoFinal":true,"usaRecursoBase":false,"payload":_vm.recurso.parametrizacoes,"tituloFinais":"Parametrizacoes_calculo_ponto","subtituloFinais":"Clique_para_adicionar","headersFinais":[
            {
              text: this.$t('Tipo'),
              value: 'tipo',
              sortable: 'true',
            },
            {
              text: this.$t('Referencia'),
              value: 'referencia',
              sortable: 'true',
            },

            {
              text: this.$t('Ativo'),
              value: 'ativo',
              sortable: 'true',
            },

            { text: this.$t('Acoes'), value: 'actions', sortable: 'false' },
          ],"textoBotaoCadastro":"Criar_item"},on:{"recursos":function($event){_vm.recurso.parametrizacoes = $event}},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"items":[
                  { text: _vm.$t('COMPENSARHORAS'), value: 'COMPENSARHORAS' },
                  // { text: $t('BANCOHORAS'), value: 'BANCOHORAS' },
                  {
                    text: _vm.$t('HORAEXTRAFERIADOINTERMITENTE'),
                    value: 'HORAEXTRAFERIADOINTERMITENTE',
                  },
                  {
                    text: _vm.$t('HORAEXTRA100INTERMITENTE'),
                    value: 'HORAEXTRA100INTERMITENTE',
                  },
                  {
                    text: _vm.$t('CONVERTERCOMPENSAREMATRASO'),
                    value: 'CONVERTERCOMPENSAREMATRASO',
                  },
                  {
                    text: _vm.$t('ADICIONARABONOEMHORAEXTRA'),
                    value: 'ADICIONARABONOEMHORAEXTRA',
                  },
                  {
                    text: _vm.$t('FOLGAFERIADOINTERMITENTEEXCETO12X36'),
                    value: 'FOLGAFERIADOINTERMITENTEEXCETO12X36',
                  },
                  {
                    text: _vm.$t('APURARHSNOTURNAPRORROGADA'),
                    value: 'APURARHSNOTURNAPRORROGADA',
                  },
                  {
                    text: _vm.$t('APURARVAVTJORNADAMENORQUE6HORAS'),
                    value: 'APURARVAVTJORNADAMENORQUE6HORAS',
                  },
                  {
                    text: _vm.$t('ABATERINTRAJORNADAEMHE'),
                    value: 'ABATERINTRAJORNADAEMHE',
                  },
                  {
                    text: _vm.$t('ABATERINTRAJORNADAEMHE100'),
                    value: 'ABATERINTRAJORNADAEMHE100',
                  },
                  {
                    text: _vm.$t('EXTENSAOINTRAJORNADAMAXIMAMINUTOS'),
                    value: 'EXTENSAOINTRAJORNADAMAXIMAMINUTOS',
                  },
                  {
                    text: _vm.$t(
                      'APURARFALTAINJUSTIFICADAHORASQUANDOAPENASHORASABONADAS'
                    ),
                    value:
                      'APURARFALTAINJUSTIFICADAHORASQUANDOAPENASHORASABONADAS',
                  },
                  {
                    text: _vm.$t('ACRESCIMOAPURACAOVACOM2HE'),
                    value: 'ACRESCIMOAPURACAOVACOM2HE',
                  },
                  {
                    text: _vm.$t('APURARHORANOTURNACHEIA'),
                    value: 'APURARHORANOTURNACHEIA',
                  },
                  {
                    text: _vm.$t('JORNADAMINIMAPARAINTRAJORNADAMINUTOS'),
                    value: 'JORNADAMINIMAPARAINTRAJORNADAMINUTOS',
                  },
                  {
                    text: _vm.$t('HORAEXTRATIPO160'),
                    value: 'HORAEXTRATIPO160',
                  },
                  {
                    text: _vm.$t('HORAEXTRATIPO170'),
                    value: 'HORAEXTRATIPO170',
                  },
                  {
                    text: _vm.$t('HORAEXTRATIPO160SABADOS'),
                    value: 'HORAEXTRATIPO160SABADOS',
                  },
                  {
                    text: _vm.$t('REMOVERCOMPENSACAOSOBREAVISO'),
                    value: 'REMOVERCOMPENSACAOSOBREAVISO',
                  },
                  {
                    text: _vm.$t('REMOVERCOMPENSACAOATRASO'),
                    value: 'REMOVERCOMPENSACAOATRASO',
                  },
                  {
                    text: _vm.$t('HEEXTRASEMPREBH'),
                    value: 'HEEXTRASEMPREBH',
                  },
                  {
                    text: _vm.$t('DESABILITARHEINCOMPENSAVEL'),
                    value: 'DESABILITARHEINCOMPENSAVEL',
                  },
                  {
                    text: _vm.$t('DESABILITARTOLERANCIA10MINHORANOTURNA'),
                    value: 'DESABILITARTOLERANCIA10MINHORANOTURNA',
                  },
                  {
                    text: _vm.$t('HABILITARDSRSOBREFALTA12X36'),
                    value: 'HABILITARDSRSOBREFALTA12X36',
                  },
                ],"label":_vm.$t('Tipo'),"dense":"","flat":"","filled":"","solo":""},model:{value:(slotProps.recursoFinalAtual.tipo),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "tipo", $$v)},expression:"slotProps.recursoFinalAtual.tipo"}})],1),(
                _vm.deveMostrarCampo(
                  'referencia',
                  slotProps.recursoFinalAtual.tipo
                )
              )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"rules":_vm.rule_required,"label":_vm.$t('Referencia'),"name":"referencia","type":"number","dense":"","flat":"","filled":"","solo":""},model:{value:(slotProps.recursoFinalAtual.referencia),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "referencia", $$v)},expression:"slotProps.recursoFinalAtual.referencia"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("Ativo"))+" ")]},proxy:true}],null,true),model:{value:(slotProps.recursoFinalAtual.ativo),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "ativo", $$v)},expression:"slotProps.recursoFinalAtual.ativo"}})],1)]}},{key:"formEditaRecursoFinal",fn:function(slotProps){return [_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"items":[
                  { text: _vm.$t('COMPENSARHORAS'), value: 'COMPENSARHORAS' },
                  { text: _vm.$t('BANCOHORAS'), value: 'BANCOHORAS' },
                ],"label":_vm.$t('Tipo'),"dense":"","flat":"","filled":"","solo":""},model:{value:(slotProps.recursoFinalAtual.tipo),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "tipo", $$v)},expression:"slotProps.recursoFinalAtual.tipo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"rules":_vm.rule_required,"label":_vm.$t('Referencia'),"name":"referencia","type":"number","dense":"","flat":"","filled":"","solo":""},model:{value:(slotProps.recursoFinalAtual.referencia),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "referencia", $$v)},expression:"slotProps.recursoFinalAtual.referencia"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{attrs:{"value":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("Ativo"))+" ")]},proxy:true}],null,true),model:{value:(slotProps.recursoFinalAtual.ativo),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "ativo", $$v)},expression:"slotProps.recursoFinalAtual.ativo"}})],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CriaSuperSet',{attrs:{"payload":_vm.recurso.apuracoesBh,"podeEditarRecursoFinal":true,"usaRecursoBase":false,"tituloFinais":"Apuracoes_banco_horas","subtituloFinais":"Clique_para_adicionar","headersFinais":[
            {
              text: this.$t('Tipo'),
              value: 'tipo',
              sortable: 'true',
            },

            { text: this.$t('Acoes'), value: 'actions', sortable: 'false' },
          ],"textoBotaoCadastro":"Criar_item"},on:{"recursos":function($event){_vm.recurso.apuracoesBh = $event}},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"items":_vm.itensParametrizacoes,"item-text":"text","item-value":"value","label":"Tipo","single-line":"","dense":"","flat":"","filled":"","solo":""},model:{value:(slotProps.recursoFinalAtual.tipo),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "tipo", $$v)},expression:"slotProps.recursoFinalAtual.tipo"}})],1)]}},{key:"formEditaRecursoFinal",fn:function(slotProps){return [_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"items":_vm.itensParametrizacoes,"item-text":"text","item-value":"value","label":"Tipo","single-line":"","dense":"","flat":"","filled":"","solo":""},model:{value:(slotProps.recursoFinalAtual.tipo),callback:function ($$v) {_vm.$set(slotProps.recursoFinalAtual, "tipo", $$v)},expression:"slotProps.recursoFinalAtual.tipo"}})],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }