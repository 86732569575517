<template>
  <ListagemBase
    nomePlural="FuncionarioFolhaPagamentoVerbas"
    nomeSingular="FuncionarioFolhaPagamentoVerba"
    :headers="headers"
  >
  </ListagemBase>
</template>

<script>
import FuncionarioFolhaPagamentoVerbaDataService from "@/services/pessoal/FuncionarioFolhaPagamentoVerbaDataService";
const DataService = FuncionarioFolhaPagamentoVerbaDataService;

import ListagemBase from "@/components/base/Crud/ListagemBase";

export default {
  name: "ListagemFuncionarioFolhaPagamentoVerbas",
  components: { ListagemBase },
  data() {
    return {
      DataService: DataService,

      headers: [
        { text: this.$t("Verba"), value: "verba", sortable: "false" },
        {
          text: this.$t("Descricao"),
          value: "descricaoVerba",
          sortable: "false",
        },
        { text: this.$t("Tipo"), value: "tipoVerba", sortable: "false" },
        { text: this.$t("Responsavel"), value: "responsavel.nome" },

        { text: this.$t("Acoes"), value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style>
</style>
