<template>
  <span class="zindex">
    <v-dialog v-model="modal" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ this.$t("Tem_certeza?") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            height="39"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            text
            @click="modal = false"
          >
            {{ this.$t("nao") }}
          </v-btn>
          <v-btn
            class="
              ma-1
              font-weight-bold
              text-none
              btn-primary
              bg-gradient-primary
              py-3
              px-6
              ms-3
            "
            height="39"
            text
            @click="confirmAction()"
            >{{ this.$t("sim") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <span style="display: inline" @click.prevent="openModal()">
      <slot> </slot>
    </span>
  </span>
</template>

<script>
export default {
  name: "ConfirmaAcao",

  props: {
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      modal: false,
    };
  },

  methods: {
    openModal() {
      console.log("clicou");
      if (!this.disabled) this.modal = true;
    },
    confirmAction() {
      this.modal = false;
      this.$emit("confirm");
    },
  },
};
</script>

<style>
.zindex {
  z-index: 999999;
}
</style>
