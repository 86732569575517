<template>
  <div>
    <div class="card-header-padding">
      <div>
        <h5 class="font-weight-bold text-h5 text-typo mb-0">
          {{ $t(titulo) }}
        </h5>
        <p class="text-sm text-body mb-0">
          {{ $t("Clique_para_selecionar") }}
        </p>
      </div>
      <div class="d-flex align-center">
        <v-col cols="12" md="5">
          <v-text-field
            hide-details
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
            height="39"
            v-model="search"
            :label="$t('Procurar')"
          >
            <template slot="prepend-inner">
              <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
            </template></v-text-field
          >
        </v-col>
      </div>
    </div>

    <v-card-text class="px-0 py-0">
      <v-data-table
        :headers="headers"
        :items="tableItems"
        :search="search"
        show-select
        item-key="id"
        :single-select="!this.selectMultiple"
        v-model="recurso"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        mobile-breakpoint="0"
        class="table"
      >
        <template v-slot:[`item.inicioVigencia`]="{ item }">
          <span>{{ item.inicioVigencia | dataParaLeitura }}</span>
        </template>
        <template v-slot:[`item.finalVigencia`]="{ item }">
          <span>{{ item.finalVigencia | dataParaLeitura }}</span>
        </template>

        <template v-slot:[`item.cpf`]="{ item }">
          <span>{{ item.cpf | VMask("###.###.###-##") }}</span>
        </template>

        <template v-slot:[`item.published`]="{ item }">
          <span>{{ item.published | dataParaLeitura }}</span>
        </template>
        <template v-slot:[`item.inicioPeriodo`]="{ item }">
          {{ item.inicioPeriodo | dataParaLeitura }}
        </template>
        <template v-slot:[`item.finalPeriodo`]="{ item }">
          {{ item.finalPeriodo | dataParaLeitura }}
        </template>
        <template v-slot:[`item.updated`]="{ item }">
          <span>{{ item.updated | dataParaLeitura }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editPessoa(item.id)"
            >ni-ruler-pencil</v-icon
          >
        </template>

        <template v-slot:[`item.valor`]="{ item }">
          <span>{{ item.valor | formatoMonetario }}</span>
        </template>

        <template v-slot:[`item.situacao`]="{ item }">
          {{ $t(item.situacao) }}
        </template>

        <template v-slot:[`item.status`]="{ item }">
          {{ $t(item.status) }}
        </template>

        <template v-slot:[`item.tipo`]="{ item }">
          {{ $t(item.tipo) }}
        </template>

        <template v-slot:[`item.vencimento`]="{ item }">
          <span>{{ item.vencimento | dataParaLeitura }}</span>
        </template>

        <template v-slot:[`item.saldo`]="{ item }">
          <span>{{ item.saldo | formatoMonetario }}</span>
        </template>

        <template v-slot:[`item.inconsistencia`]="{ item }">
          <span>{{ $t(item.inconsistencia ? "sim" : "nao") }}</span>
        </template>

        <template v-slot:[`item.retencao`]="{ item }">
          <span>{{ $t(item.retencao) ? $t("sim") : $t("nao") }}</span>
        </template>

        <template v-slot:[`item.escalaIntermitente`]="{ item }">
          <span>{{ $t(item.escalaIntermitente) ? $t("sim") : $t("nao") }}</span>
        </template>

        <template v-slot:[`item.disponivel`]="{ item }">
          <span>{{ $t(item.disponivel) ? $t("sim") : $t("nao") }}</span>
        </template>

        <template v-slot:[`item.statusComum`]="{ item }">
          {{ $t(item.statusComum) }}
        </template>

        <template v-slot:[`item.nRegistro`]="{ item }">
          <span v-if="item.nRegistro">{{
            item.nRegistro
              | VMask(
                item.nRegistro.length > 11 ? $t("maskCNPJ") : $t("maskCPF")
              )
          }}</span>
        </template>

        <template> </template>
      </v-data-table>
    </v-card-text>

    <!-- <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="6" class="d-flex align-center">
            <span class="text-body me-3 text-sm"
              >{{ $t("Itens_por_pagina") }}:</span
            >
            <v-col cols="3" sm="3">
              <v-select
                v-model="pageSize"
                :items="pageSizes"
                @change="handlePageSizeChange"
                :disabled="this.isSearching"
                :item-value="pageSize"
                class="input-style font-size-input text-light-input"
              ></v-select>
            </v-col>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#516a78ee"
              v-model="page"
              :length="isSearching ? filteredItems.length : totalPages"
              @input="handlePageChange"
              :disabled="isSearching"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions> -->
  </div>
</template>

<script>
import DataService from "@/services/ComumDataService";

import createNumberMask from "text-mask-addons/dist/createNumberMask";

const currencyMask = createNumberMask({
  prefix: "R$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "TabelaSelecionaRecurso",

  props: {
    titulo: { type: String, required: false, default: "Pessoa" },
    payload: {
      default() {
        return {};
      },
    },
    editar: { type: Boolean, default: false },
    url: { type: String, required: false },
    headers: { type: Array, required: true },
    selectMultiple: { type: Boolean, default: false },
  },

  data() {
    try {
      return {
        itemsPerPage: 5,
        totalPages: 0,

        idEditado: "",
        formEditPessoa: false,
        recursos: [],
        recurso: this.payload.length ? this.payload : [this.payload],
        search: "",

        mask: currencyMask,
      };
    } catch (e) {
      return {
        itemsPerPage: 5,
        totalPages: 0,

        idEditado: "",
        formEditPessoa: false,
        recursos: [],
        // recurso: this.payload.length ? this.payload : [this.payload],
        search: "",

        mask: currencyMask,
      };
    }
  },

  computed: {
    recursosNoDuplicates() {
      let recursos = this.recursos;

      console.log(
        recursos.find((item) => {
          item.id == this.recu;
        })
      );

      return recursos;
    },
  },

  mounted() {
    /* this.getRecursos().then(() => {
      if (this.payload.length) {
        console.log("entrou aqui");

        this.payload.forEach(item => {
          //Remove os itens que estão no payload do array de recurso
          this.recursos = this.recursos.filter(rec => {
            return item.id !== rec.id;
          });

          //Adiciona o item removido no inicio do mesmo array
          this.recursos.unshift(item);
        });

        //Quantidade de itens por página = payload.length pra mostrar todos os itens selecionados na primeira pagina
        this.itemsPerPage = this.payload.length;
      } else {
        this.recurso.push(
          this.recursos.find(item => item.id === this.payload.id) || {}
        );

        this.recursos = this.recursos.filter(rec => {
          return rec !== this.payload;
        });
      }
    }); */
    if (this.recurso === null) {
      this.recurso = {};
    }
    this.getRecursos();
  },

  computed: {
    tableItems() {
      try {
        if (this.payload.length) {
          return this.recursos.sort((a, b) => {
            if (this.payload.includes(a)) {
              return -1;
            } else {
              return 1;
            }
          });
        }
      } catch (e) {
        console.log(e);
        return this.recursos;
      }

      try {
        if (typeof this.payload == "object" && this.payload) {
          return this.recursos.sort((a, b) => {
            if (a.id == this.payload.id) {
              return -1;
            } else {
              return 1;
            }
          });
        }
      } catch (e) {
        console.log(e);
        return this.recursos;
      }
      return this.recursos || [];
    },
  },

  methods: {
    handlePageChange(value) {
      this.page = value;
      this.retrieveData();
    },

    getRecursos() {
      return new Promise((resolve, reject) => {
        DataService.getRecursos(this.url)
          .then((response) => {
            this.recursos = response.data;
            resolve();
          })
          .catch((error) => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            console.log(error, "error");
          });
      });
    },

    editRecurso(id) {
      this.idEditado = id;
      this.formEditPessoa = true;
    },
  },

  watch: {
    //Emite evento com valor de recurso quando o valor muda
    recurso: function (newValue) {
      if (this.recurso.length > 0) {
        //Se não for pra selecionar mais de um manda o array inteiro
        if (this.selectMultiple) {
          this.$emit("recurso", newValue);
        } else {
          this.$emit("recurso", newValue[0]);
        }
      }
    },
    payload: function (newValue) {
      if (newValue) {
        this.recurso.push(newValue);
      }
    },
  },
};
</script>

<style></style>
