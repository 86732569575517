<template>
  <div>
    <button class="change-tenant-button" @click.stop="abreModal()">
      <p>
        {{ this.text }}
      </p>
      <v-icon right v-if="inquilinos.length > 1"> mdi-cached </v-icon>
    </button>

    <v-dialog v-model="modal" max-width="600">
      <v-card>
        <v-card-title class="headline">
          <div class="search-container">
            <span>{{ $t("Acessar_empresa") }}</span>
          </div>
          <div>
            <v-text-field
              v-model="procurar"
              :placeholder="$t('Procurar_empresa')"
              prepend-icon="mdi-magnify"
              hide-details
              dense
              class="search-field"
            ></v-text-field>
          </div>
        </v-card-title>
        <divider></divider>
        <v-card-text>
          <v-list>
            <v-list-item-group active-class="black--text">
              <template v-for="item in inquilinosFiltrados"
                ><v-list-item :key="item.id" @click="changeTenant(item)">
                  <v-list-item-avatar contain>
                    <v-img :src="item.inquilino.imagem"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      item.inquilino.fantasia
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="grey"> mdi-login </v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-divider :key="item.id"> </v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            height="39"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            text
            @click="modal = false"
          >
            {{ $t("Cancelar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UsuarioDataService from "@/services/acessos/UsuarioDataService";
import ComumDataService from "@/services/ComumDataService";
export default {
  name: "ChangeTenant",
  props: {
    text: { type: String, required: true },
  },

  mounted() {
    UsuarioDataService.getUser().then((response) => {
      console.log("mounted changeTenant", response.data);
      this.inquilinos = response.data.inquilinos;
      this.inquilinosFiltrados = response.data.inquilinos;
    });
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  data() {
    return {
      modal: false,
      inquilinos: [],
      inquilinosFiltrados: [],
      procurar: "",
    };
  },

  methods: {
    changeTenant(item) {
      ComumDataService.put(`usuarioInquilino/${item.id}`, item).then(
        (response) => {
          this.$store.dispatch("USER_REQUEST");
          this.$router.push("/dashboard");
          this.$swal.fire("Sucesso", "", "success");
          this.modal = false;
        }
      );
      this.procurar = "";
    },

    abreModal() {
      if (this.inquilinos.length > 1) {
        console.log("inquilinos", this.inquilinos);
        this.modal = true;
      }
    },
  },

  watch: {
    procurar: function (novaProcura) {
      this.inquilinosFiltrados = this.inquilinos.filter((item) => {
        return item.inquilino.fantasia
          .toLowerCase()
          .includes(novaProcura.toLowerCase());
      });
    },
  },
};
</script>

<style scoped>
.change-tenant-button {
  border: 1px solid #fff;
  padding: 5px 15px;
  font-size: 1rem;

  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  text-align: left;
}

.search-field {
  display: inline-flex;
  position: relative;
  top: 0px;
  left: 180px;
  margin-left: auto;
}

p {
  margin: 0;
}
</style>
