<template>
  <CadastroBase
    nomePlural="FuncionarioAvisoPrevios"
    nomeSingular="FuncionarioAvisoPrevio"
    :submitPersonalizado="true"
    :submited="submited"
    @submitSuccessInsert="emitSubmitSuccess"
    @submitErrorInsert="emitSubmitError"
    @closeModalInsert="closeModal"
  >
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <TabelaSelecionaRecurso
            @recurso="recurso.funcionario = $event"
            url="funcionarios/active"
            titulo="Funcionario"
            :headers="[
              {
                text: this.$t('Matricula'),
                value: 'matricula',
                sortable: 'false',
              },
              {
                text: this.$t('Nome'),
                value: 'pessoa.nome',
                sortable: 'false',
              },
              {
                text: this.$t('Sindicato'),
                value: 'sindicato.descricao',
                sortable: 'false',
              },
              {
                text: this.$t('Cargo'),
                value: 'cargo.descricao',
                sortable: 'false',
              },
              {
                text: this.$t('PostoTrabalho'),
                value: 'postoTrabalho.descricao',
                sortable: 'true',
              },
            ]"
          />
        </v-col>

        <v-col cols="12" md="4" id="campo-dt-inicio">
          <CampoData
            @date="recurso.inicio = $event"
            label="Inicio_do_periodo"
          />
        </v-col>
        <v-col cols="12" md="4" id="campo-dt-encerramento">
          <CampoData
            @date="recurso.encerramento = $event"
            label="Final_do_periodo"
          />
        </v-col>
        <v-col class="d-flex" cols="12" md="4">
          <v-select
            id="origemAvisoPrevio"
            v-model="recurso.origemAvisoPrevio"
            :items="tiposOrigemAvisoPrevio"
            :label="$t('origemAvisoPrevio')"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" md="4">
          <v-select
            id="tipoAvisoPrevio"
            v-model="recurso.tipoAvisoPrevio"
            :items="tiposAvisoPrevio"
            :label="$t('tipoAvisoPrevio')"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-select>
        </v-col>

        <v-col
          class="d-flex"
          cols="12"
          md="4"
          v-if="
            recurso.origemAvisoPrevio === 'EMPRESA' &&
            recurso.tipoAvisoPrevio === 'TRABALHADO'
          "
        >
          <v-select
            id="beneficioAvisoPrevio"
            v-model="recurso.beneficioAvisoPrevio"
            :items="tiposBeneficioAvisoPrevio"
            :label="$t('beneficioAvisoPrevio')"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-select>
        </v-col>

        <v-col cols="12" class="">
          <div>
            <v-text-field
              v-model="recurso.observacao"
              :label="$t('Observacao')"
              name="Observacao"
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
            >
            </v-text-field>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </CadastroBase>
</template>

<script>
import CadastroBase from "@/components/base/Crud/CadastroBase";
import TabelaSelecionaRecurso from "@/components/base/proprios/TabelaSelecionaRecurso";
import FuncionarioAvisoPrevioDataService from "@/services/pessoal/FuncionarioAvisoPrevioDataService";
const DataService = FuncionarioAvisoPrevioDataService;

import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "R$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  components: { CadastroBase, TabelaSelecionaRecurso },
  name: "CadastroFuncionarioAvisoPrevio",

  data() {
    return {
      DataService: DataService,
      submited: false,
      submited: false,
      mask: currencyMask,

      recurso: {
        funcionario: {},
        matricula: "",
        inicio: "",
        encerramento: "",
        tipoAvisoPrevio: null,
        beneficioAvisoPrevio: null,
        origemAvisoPrevio: null,
        observacao: null,
      },

      tiposAvisoPrevio: [
        { text: this.$t("TRABALHADO"), value: "TRABALHADO" },
        { text: this.$t("INDENIZADO"), value: "INDENIZADO" },
      ],
      tiposBeneficioAvisoPrevio: [
        { text: this.$t("REDUCAODUASHORAS"), value: "REDUCAODUASHORAS" },
        { text: this.$t("REDUCAOSETEDIAS"), value: "REDUCAOSETEDIAS" },
      ],

      tiposOrigemAvisoPrevio: [
        { text: this.$t("EMPRESA"), value: "EMPRESA" },
        { text: this.$t("COLABORADOR"), value: "COLABORADOR" },
      ],
      errors: [],
    };
  },
  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccessInsert");
    },
    emitSubmitError() {
      this.$emit("submitErrorInsert");
    },
    closeModal() {
      this.$emit("closeModalInsert");
    },
    submitForm() {
      return new Promise((resolve, reject) => {
        DataService.create({
          matricula: this.recurso.funcionario.matricula,
          inicio: this.recurso.inicio,
          encerramento: this.recurso.encerramento,
          tipoAvisoPrevio: this.recurso.tipoAvisoPrevio,
          beneficioAvisoPrevio: this.recurso.beneficioAvisoPrevio,
          origemAvisoPrevio: this.recurso.origemAvisoPrevio,
          observacao: this.recurso.observacao,
        })
          .then((response) => {
            this.erros = [];
            this.submited = true;
            this.recurso = {
              matricula: "",
              inicio: "",
              encerramento: "",
              tipoAvisoPrevio: "",
              beneficioAvisoPrevio: "",
              origemAvisoPrevio: "",
            };
            resolve();

            if (this.$store.state.pendingTutorial == "Cadastrar_usuario") {
              startTutorial([{}]);
            }
          })
          .catch((error) => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            reject();
            this.errors = error.response.data.errors;

            if (
              this.$store.state.pendingTutorial == "Cadastrar_usuario" &&
              error.response.status == 422
            ) {
              startTutorial([{}]);
            }
          });
      });
    },
  },
};
</script>

<style scoped>
#app .INATIVO {
  color: red;
}
</style>
