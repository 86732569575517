import Axios from "axios";

class PedidoMaterialDataService {

  getAll() {
    return Axios.get('pedidoMaterial');
  }

  getPage(params) {
    return Axios.get('pedidoMaterial/page', { params })
  }

  create(params) {
    return Axios.post('pedidoMaterial', params)
  }

  update(params) {
    return Axios.put('pedidoMaterial/' + (params.id), params)
  }

  find(id) {
    return Axios.get(`pedidoMaterial/${id}`)
  }

  delete(id) {
    return Axios.delete(`pedidoMaterial/${id}`);
  }

  getUser() {
    return Axios.get(`usuarios/dados_Usuario`)
  }

  getPermissoes() {
    return Axios.get(`usuarios/permissoes`)
  }

  getProdutos() {
    return Axios.get('produtos/ativos/tipoProduto/1')
  }

  getProdutosEstoque() {
    return Axios.get('produtos/estoque/tipoProduto/1')
  }

  getPrintSingle(id, params) {
    return Axios.get(`pedidoMaterial/romaneio/${id}`, { params })
  }

  get(url, params = null) {
    return Axios.get(url, params)
  }

  separacao(params) {
    return Axios.put('pedidoMaterial/separacao/' + (params.id), params)
  }

  disponibilizado(params) {
    return Axios.put('pedidoMaterial/disponibilizado/' + (params.id), params)
  }

  retiradoTerceiro(params) {
    return Axios.put('pedidoMaterial/retiradoTerceiro/' + (params.id), params)
  }

  recebido(params) {
    return Axios.put('pedidoMaterial/recebido/' + (params.id), params)
  }

  entregaPendente(params) {
    return Axios.put('pedidoMaterial/entregaPendente/' + (params.id), params)
  }

  entregaTotal(params) {
    return Axios.put('pedidoMaterial/entregaTotal/' + (params.id), params)
  }

  entregaParcial(params) {
    return Axios.put('pedidoMaterial/entregaParcial/' + (params.id), params)
  }
}

export default new PedidoMaterialDataService()
