<template>
  <EditarBase
    nomeSingular="FuncionarioFolhaPagamentoVerba"
    nomePlural="FuncionarioFolhaPagamentoVerbas"
    @recurso="recurso = $event"
    @submitSuccess="emitSubmitSuccess"
    @submitError="emitSubmitError"
    @closeModalEdit="closeModalEdit"
  >
    <v-col cols="12">
      <v-row>
        <v-col cols="2" md="2">
          <v-span class="opacity-5 text-dark">
            {{ $t("Verba") }}
          </v-span>
          <v-text-field
            v-model="recurso.verba"
            :label="$t('Verba')"
            name="descricao"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>
        <v-col cols="10" md="10">
          <v-span class="opacity-5 text-dark">
            {{ $t("Descricao") }}
          </v-span>
          <v-text-field
            v-model="recurso.descricaoVerba"
            :label="$t('Descricao')"
            name="descricao"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-span class="opacity-5 text-dark">
            {{ $t("Tipo") }}
          </v-span>
          <v-select
            v-model="recurso.tipoVerba"
            :items="itensTipoVerba"
            :label="$t('Tipo')"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-select>
        </v-col>

        <v-col cols="12" md="3">
          <v-checkbox v-model="recurso.base13">
            <template v-slot:label>
              {{ $t("base13") }}
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="3">
          <v-checkbox v-model="recurso.baseFerias">
            <template v-slot:label>
              {{ $t("baseFerias") }}
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="3">
          <v-checkbox v-model="recurso.baseAvisoPrevio">
            <template v-slot:label>
              {{ $t("baseAvisoPrevio") }}
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" md="12">
          <CriaSuperSet
            :podeEditarRecursoFinal="true"
            :podeExcluirRecursoFinal="false"
            @recursos="recurso.contasContabil = $event"
            :usaRecursoBase="false"
            :payload="recurso.contasContabil"
            :pesquisa="false"
            textoBotaoCadastro="Nova_conta_contabil"
            tituloFinais="Conta_contabil"
            subtituloFinais="Clique_para_adicionar"
            :headersFinais="[
              {
                text: this.$t('Conta_contabil'),
                value: 'contaContabil',
                sortable: 'true',
              },
              {
                text: this.$t('Tipo'),
                value: 'tipoContabil',
                sortable: 'true',
              },
              {
                text: this.$t('Responsavel'),
                value: 'reponsavel.nome',
                sortable: 'true',
              },

              {
                text: this.$t('Data_de_alteracao'),
                value: 'dtAlteracao',
                sortable: 'true',
              },

              { text: this.$t('Acoes'), value: 'actions', sortable: 'false' },
            ]"
          >
            <template v-slot:default="slotProps">
              <!-- Form cria recurso final -->

              <v-col cols="12" md="12">
                <v-text-field
                  :rules="rule_required"
                  v-model="slotProps.recursoFinalAtual.contaContabil"
                  :label="$t('Conta_contabil')"
                  name="contasContabil"
                  type="text"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-span class="opacity-5 text-dark">
                  {{ $t("Tipo") }}
                </v-span>
                <v-select
                  v-model="slotProps.recursoFinalAtual.tipoContabil"
                  :items="itensTipoContabil"
                  :label="$t('Tipo')"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>
            </template>
            <template v-slot:formEditaRecursoFinal="slotProps">
              <v-col cols="12" md="12">
                <v-text-field
                  :rules="rule_required"
                  v-model="slotProps.recursoFinalAtual.contaContabil"
                  :label="$t('Conta_contabil')"
                  name="contasContabil"
                  type="text"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-span class="opacity-5 text-dark">
                  {{ $t("Tipo") }}
                </v-span>
                <v-select
                  v-model="slotProps.recursoFinalAtual.tipoContabil"
                  :items="itensTipoContabil"
                  :label="$t('Tipo')"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>
            </template>
          </CriaSuperSet>
        </v-col>
      </v-row>
    </v-col>
  </EditarBase>
</template>

<script>
import EditarBase from "@/components/base/Crud/EditarBase";

import CriaSuperSet from "@/components/base/proprios/CriaSuperset.vue";
import FuncionarioFolhaPagamentoVerbaDataService from "@/services/pessoal/FuncionarioFolhaPagamentoVerbaDataService";
const DataService = FuncionarioFolhaPagamentoVerbaDataService;

import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "R$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "EditarFuncionarioFolhaPagamentoVerba",
  components: { EditarBase, CriaSuperSet },
  props: {
    recurso: Object,
  },
  data() {
    return {
      DataService: DataService,
      submited: false,
      mask: currencyMask,

      recurso: {
        id: null,
        descricao: "",
        statusComum: "",
        salarioBase: "",
      },
      salBase: "12",
      errors: [],

      itensTipoVerba: [
        { text: this.$t("PROVENTO"), value: "PROVENTO" },
        { text: this.$t("DESCONTO"), value: "DESCONTO" },
      ],

      itensTipoContabil: [
        { text: this.$t("CREDITO"), value: "CREDITO" },
        { text: this.$t("DEBITO"), value: "DEBITO" },
      ],
    };
  },

  computed: {
    salarioBaseSemMascara: function () {
      let str = this.recurso.salarioBase;

      str = str.toString();
      str = str.replace("$", "");
      str = str.replace(/\,/gi, "");
      str = str.replace(/([A-z])/, "");

      console.log(str);
      return str;
    },
  },

  mounted() {
    //this.getRecurso();
  },

  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccess");
    },
    emitSubmitError() {
      this.$emit("submitError");
    },
    closeModalEdit() {
      this.$emit("closeModalEdit");
    },
    submitForm(event) {
      DataService.update({
        descricao: this.recurso.descricao,
        statusComum: this.recurso.statusComum,
        salarioBase: this.salarioBaseSemMascara,
        id: this.recurso.id,
      })
        .then((response) => {
          this.erros = [];
          this.submited = true;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          this.errors = error.response.data.errors;
        });
    },

    getRecurso() {
      DataService.find(this.recurso.id)
        .then((response) => {
          this.recurso.id = response.data.id;
          this.recurso.salarioBase = response.data.salarioBase;
          this.recurso.descricao = response.data.descricao;
          this.recurso.statusComum = response.data.statusComum;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
          this.errors.push({ field: "ID", message: "Usuário não encontrado" });
        });
    },

    mascaraMonetaria(val) {
      val = this.$t("R$") + " " + val;
    },
  },
};
</script>

<style scoped>
</style>
