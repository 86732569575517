import Axios from "axios";

class ComumDataService {
  put(url, payload, config = {}) {
    return Axios.put(url, payload, config)
  }

  post(url, params = null, config) {
    return Axios.post(url, params, config)
  }

  get(url, params = null) {
    return Axios.get(url, params)
  }

  getRecursos(url) {
    return Axios.get(url)
  }


  createTrajeto(params) {
    return Axios.post('/roteiroTrajetos', params)
  }

  removeTrajeto(id) {
    return Axios.delete(`/roteiroTrajetos/${id}`)
  }

  delete(url, params) {
    return Axios.delete(url, params)
  }

  getVeiculos() {
    return Axios.get('/imobilizados/veiculos')
  }

  getVeiculosCarroForte() {
    return Axios.get('/imobilizados/carroForte')
  }

  getPessoas() {
    return Axios.get('/pessoas')
  }

  buscaCPF(params) {
    return Axios.get("pessoas/findCpf", { params: { cpf: params } });
  }

  buscaCep(value) {
    return Axios.get(
      `https://viacep.com.br/ws/${value}/json/`,
      {
        transformRequest: (data, headers) => {
          delete headers.common["Authorization"];
        }
      }
    );
  }

  buscaGeoCode(address) {

    return Axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json`,
      {
        transformRequest: (data, headers) => {
          delete headers.common["Authorization"];
        },
        params: {
          key: 'AIzaSyDZSVtVJNqlO3RJ96MNiJKc3-C5CPx_WGM',
          address: address
        }
      }
    );
  }

  findCidade(params) {
    console.log(params)
    return Axios.get("cidades/findCidadeUF", { params: { cidade: params.cidade, uf: params.uf } })
  }

  getCidades() {
    return Axios.get("cidades");
  }

  getCargos() {
    return Axios.get("/funcionarioCargos");
  }

  getFuncionarios() {
    return Axios.get('funcionarios')
  }


  uploadInquilino(params) {

    var formData = new FormData();
    formData.append("file", params.file);

    console.log(formData)


    console.log(params.file)
    return Axios.post(`/inquilinos/image/${params.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}

export default new ComumDataService();
