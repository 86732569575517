import Axios from "axios";

class ModeloDocumentacaoAcessoriaDataService {

  getAll() {
    return Axios.get('modeloDocumentacaoAcessoria');
  }

  getPage(params) {
    return Axios.get('modeloDocumentacaoAcessoria/page', { params })
  }

  create(params) {
    return Axios.post('modeloDocumentacaoAcessoria', params)
  }

  update(params) {
    console.log(params)
    return Axios.put('modeloDocumentacaoAcessoria/' + (params.id), params)
  }

  find(id) {
    return Axios.get(`modeloDocumentacaoAcessoria/${id}`)
  }

  delete(id) {
    return Axios.delete(`modeloDocumentacaoAcessoria/${id}`);
  }

  // print(id) {
  //   return Axios.get(`modeloDocumentacaoAcessoria/test/${id}`)
  // }

  print(id, params = null) {
    return Axios.get("modeloDocumentacaoAcessoria/test/" + id, params);
  }

  get(url, params = null) {
    return Axios.get(url, params)
  }

  disponibilizarDocumentoFuncionarios(id, params = null) {
    return Axios.put("modeloDocumentacaoAcessoria/makeAvailable/" + id, params);
  }


}

export default new ModeloDocumentacaoAcessoriaDataService()
