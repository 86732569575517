import Axios from "axios";

class AssistenciaTecnicaTipoDataService {

  getAll() {
    return Axios.get('assistenciaTecnicaTipos');
  }

  getActive() {
    return Axios.get('assistenciaTecnicaTipos/active');
  }


  getPage(params) {
    return Axios.get('assistenciaTecnicaTipos/page', { params })
  }

  create(params) {
    return Axios.post('assistenciaTecnicaTipos', params)
  }

  update(params) {
    console.log(params)
    return Axios.put('assistenciaTecnicaTipos/' + (params.id), params)
  }

  find(id) {
    return Axios.get(`assistenciaTecnicaTipos/${id}`)
  }

  delete(id) {
    return Axios.delete(`assistenciaTecnicaTipos/${id}`);
  }
}

export default new AssistenciaTecnicaTipoDataService()
