import Axios from "axios";

class PaisDataService{

  getAll(){
    return Axios.get('paises');
  }

  getPage(params){
    return Axios.get('paises/page', {params})
  }

  create(params){
    return Axios.post('paises', params)
  }

  update(params){
    return Axios.put('paises/' + (params.id), params)
  }

  find(id){
    return Axios.get(`paises/${id}`)
  }

  delete(id){
    return Axios.delete(`paises/${id}`);
  }

}

export default new PaisDataService()
