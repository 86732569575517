<template>
  <div class="mtxt-container">
    <div
      class="mtxt-content"
      v-for="(item, index) in propobject.steps"
      :key="index"
      v-show="currentStep == index"
    >
      <h3 style="text-align: center;">{{ item.boxTitle }}</h3>
      <p class="" v-html="item.boxText">{{ item.boxText }}</p>
    </div>
    <div class="mtxt-nav">
      <button
        v-if="currentStep > 0"
        class="mtxt-btn mtxt-btn--back"
        @click="back()"
      >
        &#8592;
      </button>

      <button
        class="mtxt-btn"
        id="mtxt-next-btn"
        v-if="currentStep + 1 < propobject.steps.length"
        @click="next()"
      >
        &#8594;
      </button>
      <button id="mtxt-next-btn" class="mtxt-btn" v-else @click="next()">
        OK
      </button>
    </div>
  </div>
</template>

<script>
import { dispatchAction } from "../main.js";

export default {
  name: "MultiText",
  props: {
    propobject: { required: true }
  },

  mounted() {
    window.addEventListener("tutorial-overlay-click", () => {
      let btn = document.getElementById("mtxt-next-btn");

      if (btn) {
        btn.classList.add("mtxt-btn-grow");

        setTimeout(() => {
          btn.classList.remove("mtxt-btn-grow");
        }, 300);
      }
    });
  },
  data() {
    return {
      currentStep: 0
    };
  },

  methods: {
    back() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },

    next() {
      if (this.currentStep + 1 >= this.propobject.steps.length) {
        this.resolveStep();
      } else {
        this.currentStep++;
      }
    },

    resolveStep() {
      if (this.propobject.clickTarget) {
        dispatchAction("clickTarget");
      } else {
        dispatchAction("resolve");
      }
    }
  }
};
</script>

<style>
.mtxt-container {
  width: 80vw;
  max-width: 100%;
  background: #fff;

  border-radius: 10px;

  padding: 1rem 0.5rem;
}
.mtxt-btn {
  border-radius: 4px;
  padding: 1px 1rem;

  background: #4caf50;
  transition: transform 0.2s;
  color: #fff;

  font-weight: bold;
}

 #mtxt-next-btn{
   grid-column: -1;
 }

.mtxt-btn--back {
  color: #080808;

  background: #f6f6f7;

  border: 1px solid #9f9fa4;
}
.mtxt-btn-grow {
  transform: scale(1.05) translateY(-5px);
}
.mtxt-content {
  margin-bottom: 1rem;
}
.mtxt-nav {
  display: grid;
  grid-template-columns: 50px 1fr 50px;
}

h3 {
  text-align: center;
  margin-bottom: 0.5rem;
}

p {
  word-break: break-word;
  line-height: 1;
}
</style>
