<template>
  <v-autocomplete
    @change="emit"
    :label="$t(label)"
    outlined
    v-model="selecionado"
    :items="recursos"
    :item-text="
      (item) =>
        item.cidade.nome +
        '/' +
        item.cidade.estado.sigla +
        ' - ' +
        item.cfop.cfop +
        ' - ' +
        item.codigoTributacao
    "
    return-object
    class="
      input-style
      font-size-input
      text-light-input
      placeholder-light
      input-icon
    "
    dense
    flat
    filled
    solo
  >
  </v-autocomplete>
</template>

<script>
import DataService from "@/services/ComumDataService";
export default {
  name: "CampoCfopCidade",

  props: {
    label: { type: String, default: "Cfop_cidade" },
    payload: { type: Object, required: false },
    name: { type: String, required: true },
  },

  data() {
    return {
      recursos: [],
      selecionado: {},
    };
  },

  mounted() {
    this.getRecurso();

    setTimeout(() => {
      if (this.payload) {
        this.selecionado = this.payload;
      }
    }, 700);
  },

  methods: {
    getRecurso() {
      DataService.get("cfopscidades").then((response) => {
        this.recursos = response.data;
      });
    },

    emit() {
      this.$emit("return", this.selecionado);
    },
  },
};
</script>

<style></style>
