<template>
  <EditarBase
    nomeSingular="FuncionarioPonto"
    nomePlural="FuncionarioPontos"
    @recurso="recurso = $event"
    @submitSuccess="emitSubmitSuccess"
    @submitError="emitSubmitError"
    @closeModalEdit="closeModalEdit"
  >
    <v-col cols="12">
      <base-material-card
        icon="mdi-clipboard-text"
        :title="$t('Dados_FuncionarioPonto')"
        class="px-5 py-3"
      >
        <v-row>
          <v-col cols="12">
            <div class="font-weight-light rounded">
              <v-text-field
                v-if="recurso.funcionario.pessoa"
                disabled
                v-model="recurso.funcionario.pessoa.nome"
                :label="$t('Nome')"
                name="nome"
                class="purple-input"
              >
              </v-text-field>
            </div>
          </v-col>

          <v-col cols="12" md="6" class="d-flex">
            <div class="ma-4 font-weight-light rounded">
              <label for="competencia" class="mr-2"
                >{{ $t("Competencia") }}:
              </label>
              <input
                type="date"
                id="competencia"
                name="competencia"
                v-model="recurso.competencia"
              />
            </div>
          </v-col>

          <v-col cols="12" md="6" class="d-flex">
            <div class="font-weight-light rounded">
              <v-text-field
                type="number"
                v-model="recurso.jornada"
                :label="$t('Jornada')"
                name="Jornada"
                class="purple-input"
              >
              </v-text-field>
            </div>
          </v-col>

          <EditaDataFuncionarioPontoDetalhes
            v-if="recurso.id"
            :payload="recurso"
            @FuncionarioPonto="recurso = $event"
            @inicio="recurso.inicio = $event"
          >
          </EditaDataFuncionarioPontoDetalhes>
        </v-row>
      </base-material-card>
    </v-col>
  </EditarBase>
</template>

<script>
import DataService from "@/services/pessoal/FuncionarioPontoDataService";

import EditarBase from "@/components/base/Crud/EditarBase";
import EditaDataFuncionarioPontoDetalhes from "@/components/pessoal/funcionario-ponto/EditaDataFuncionarioPontoDetalhes";

export default {
  name: "EditarFuncionarioPonto",
  components: { EditarBase, EditaDataFuncionarioPontoDetalhes },
  props: {
    recurso: Object,
  },
  data() {
    return {
      DataService: DataService,

      recurso: {
        funcionario: {},
        justificativa: "",
        //imagem: "",
        // latitude: 123,
        // longitude: 1234,
        matricula: "",
        // funcionarioPontoDetalhes: [],
        inicio: "",
        encerramento: "",
        jornada: "",
        competencia: "",
        justificativaEncerramento: " ",
        justificativaInicio: " ",
      },
      todosFuncionarios: [],
      errors: [],
    };
  },

  computed: {
    agora: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate().toString().padStart(2, "0");

      let minutes = toString(today.getMinutes());
      minutes = minutes.padStart(2, "0");
      const time = today.getHours() + ":" + minutes;
      const dateTime = date + "T" + time;

      return date + "T00:00";
    },
  },

  mounted() {
    this.getFuncionarios();
  },

  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccess");
    },
    emitSubmitError() {
      this.$emit("submitError");
    },
    closeModalEdit() {
      this.$emit("closeModalEdit");
    },
    getFuncionarios() {
      DataService.getFuncionarios()
        .then((response) => {
          console.log(response.data);
          this.todosFuncionarios = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
#competencia {
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 99px;
}

.mdl-textfield:not(.is-dirty) input::-webkit-datetime-edit {
  color: transparent;
}

input:focus::-webkit-datetime-edit {
  color: rgba(255, 255, 255, 0.46) !important;
}
</style>
