<template>
  <v-row>
    <ErrorAlert :errors="errors"></ErrorAlert>

    <v-dialog
      v-model="formCriaTrajeto"
      persistent
      max-width="500"
      hide-overlay
      absolute
    >
      <v-card>
        <validation-observer v-slot="{ invalid }">
          <v-form @submit.prevent="criaTrajeto">
            <v-card-title class="headline">
              <v-btn class="ma-2">
                {{ $t("Pedido") + " -  #" + pedidoAtual.id }}
              </v-btn>
              {{ $t("Origem") + ": " + pedidoAtual.origem.descricao }}<br />
              {{ $t("Janela") + ": " }}
              {{ pedidoAtual.janelaOrigemInicio | dataParaLeitura }} a
              {{ pedidoAtual.janelaOrigemFinal | dataParaLeitura }}

              <div v-if="pedidoAtual.classificacao !== 'ASSISTENCIA'">
                <br />

                {{ $t("Destino") + ": " + pedidoAtual.destino.descricao }}<br />
                {{ $t("Janela") + ": " }}
                {{ pedidoAtual.janelaDestinoInicio | dataParaLeitura }} a
                {{ pedidoAtual.janelaDestinoFinal | dataParaLeitura }}
              </div>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <div
                    v-if="formCriaTrajeto"
                    class="ma-4 font-weight-light rounded"
                  >
                    <!-- <validation-provider rules="required" v-slot="{ errors }">
                      <input
                        type="datetime-local"
                        id="previsaoAtendimento"
                        name="previsaoAtendimento"
                        class="inputData"
                        step="1"
                        v-model="novoTrajeto.previsaoAtendimento"
                      />
                      <div class="pt-2" style="color: red">
                        {{ errors[0] }}
                      </div>
                    </validation-provider> -->

                    <CampoHorario
                      @date="novoTrajeto.previsaoAtendimentoOrigem = $event"
                      name="previsaoAtendimentoOrigem"
                      :label="$t('PrevisaoAtendimentoOrigem')"
                      :payload="novoTrajeto.previsaoAtendimentoOrigem"
                    />
                    <br />
                    <CampoHorario
                      v-if="pedidoAtual.classificacao !== 'ASSISTENCIA'"
                      @date="novoTrajeto.previsaoAtendimentoDestino = $event"
                      name="previsaoAtendimentoDestino"
                      :label="$t('PrevisaoAtendimentoDestino')"
                      :payload="novoTrajeto.previsaoAtendimentoDestino"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                height="39"
                class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
                @click="limpaFormTrajeto"
              >
                {{ $t("Cancelar") }}
              </v-btn>

              <v-btn
                type="submit"
                :disabled="invalid"
                @click.prevent="addTrajeto"
                class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
              >
                {{ $t("Adicionar") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modalCriaTrajeto"
      class="dialog-deletado"
      max-width="1200"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t("Roteirizar_pedidos") }}
        </v-card-title>
        <v-card-text>
          <v-col cols="12" md="12">
            <template v-slot:heading>
              <div class="display-2 font-weight-light">
                {{ $t("Listagem_de_pedidos") }}
              </div>

              <div class="subtitle-1 font-weight-light">
                {{ $t("Selecione_e_defina_para_criar_um_trajeto") }}
              </div>
            </template>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('Procurar')"
              single-line
              hide-details
            ></v-text-field>

            <v-data-table
              :headers="headersPedido"
              :items="pedidos"
              :search="search"
              @click:row="mostraFormTrajeto"
            >
              <template v-slot:[`item.previsaoAtendimento`]="props">
                <td v-if="pedidosSelecionados.includes(props.item)">
                  <v-btn
                    color="red lighten-2"
                    dark
                    @click.prevent="mostraFormTrajeto(props.item)"
                    x-small
                  >
                    {{ $t("Definir_previsao") }}
                  </v-btn>
                </td>
                <p class="my-2" v-else>
                  {{ $t("Nao_definido") }}
                </p>
              </template>

              <template v-slot:[`item.data`]="{ item }">
                {{ item.data | dataParaLeitura }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-row class="text-left ma-2">
                    <v-col cols="12" md="6">
                      <v-col cols="12" md="12">
                        {{ $t("Data") + ": " }}
                        {{ item.data | dataParaLeitura }}
                      </v-col>

                      <v-col cols="12" md="12">
                        {{ $t("Status") + ": " + item.statusPedido }}
                      </v-col>
                    </v-col>

                    <v-col cols="12" md="6">
                      <div class="text-center">
                        <h3>{{ $t("Itens") }}</h3>
                      </div>
                      <v-data-table
                        :headers="[
                          { text: $t('Produto'), value: 'produto.descricao' },
                          { text: $t('Preco'), value: 'preco' },
                          { text: $t('Quantidade'), value: 'quantidade' },
                        ]"
                        :items="item.itens"
                        hide-default-footer
                        class="elevation-1"
                      >
                      </v-data-table>
                    </v-col>
                  </v-row>
                </td>
              </template>
              <template v-slot:[`item.janelaOrigemInicio`]="{ item }">
                <span>{{ item.janelaOrigemInicio | dataParaLeitura }}</span>
              </template>
              <template v-slot:[`item.janelaOrigemFinal`]="{ item }">
                <span>{{ item.janelaOrigemFinal | dataParaLeitura }}</span>
              </template>
              <template v-slot:[`item.janelaDestinoInicio`]="{ item }">
                <span>{{ item.janelaDestinoInicio | dataParaLeitura }}</span>
              </template>
              <template v-slot:[`item.janelaDestinoFinal`]="{ item }">
                <span>{{ item.janelaDestinoFinal | dataParaLeitura }}</span>
              </template>
              <template v-slot:[`item.valorTotal`]="{ item }">
                <span>{{ item.valorTotal | formatoMonetario }}</span>
              </template>
              <template v-slot:[`item.statusPedido`]="{ item }">
                {{ $t(item.statusPedido) }}
              </template>
              <template v-slot:[`item.classificacao`]="{ item }">
                {{ $t(item.classificacao) }}
              </template>
            </v-data-table>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <!-- <v-checkbox
            v-model="apenasPedidosSelecionados"
            :label="$t('Apenas_pedidos_selecionados')"
          ></v-checkbox> -->
          <v-spacer></v-spacer>

          <v-btn
            class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
            height="39"
            style="z-index: 2"
            @click="modalCriaTrajeto = false"
          >
            {{ $t("Fechar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="formRemoveTrajeto"
      persistent
      max-width="350"
      class="dialog-deletado"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t("Confirma_exclusao?") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
            height="39"
            text
            @click="formRemoveTrajeto = false"
            :loading="loading"
          >
            {{ $t("nao") }}
          </v-btn>

          <v-btn
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            height="39"
            text
            @click="removeTrajeto()"
            :loading="loading"
          >
            {{ $t("Confirmar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalDetalhes" max-width="500" class="dialog-deletado">
      <v-card>
        <v-card-title class="headline">{{
          $t("Detalhes_do_pedido")
        }}</v-card-title>
        <v-card-text>
          <v-row> </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
            height="39"
            text
            @click="modalDetalhes = false"
          >
            {{ $t("nao") }}
          </v-btn>

          <v-btn color="red darken-1" text @click="modalDetalhes = false">
            {{ $t("Confirmar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col cols="12" md="12">
      <template v-slot:heading>
        <div class="display-2 font-weight-light">
          {{ $t("Listagem_de_trajetos") }}
        </div>
      </template>

      <v-text-field
        v-model="searchTrajetos"
        append-icon="mdi-magnify"
        :label="$t('Procurar')"
        hide-details
        class="input-style font-size-input text-light-input placeholder-light input-icon"
        dense
        flat
        filled
        solo
        height="39"
      ></v-text-field>

      <v-data-table
        :headers="[
          { text: $t('previsaoAtendimento'), value: 'previsaoAtendimento' },
          { text: $t('Classificacao'), value: 'classificacao' },
          { text: $t('Local'), value: 'local.descricao' },
          { text: $t('Status'), value: 'statusAtendimento' },
          { text: $t('valorDeclarado'), value: 'valorDeclarado' },
          { text: $t('inicioAtendimento'), value: 'inicioAtendimento' },
          { text: $t('finalAtendimento'), value: 'finalAtendimento' },
          { text: '', value: 'data-table-expand' },
          this.isListagem ? {} : { text: $t('Acoes'), value: 'actions' },
        ]"
        :items="trajetos"
        disable-pagination
        :search="searchTrajetos"
        show-expand
        single-expand
        item-class="statusComum"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="confirmaRemoveTrajeto(item)"
            >ni-fat-remove</v-icon
          >
        </template>
        <template v-slot:[`item.classificacao`]="{ item }">
          {{ $t(item.classificacao) }}
        </template>
        <template v-slot:[`item.statusAtendimento`]="{ item }">
          {{ $t(item.statusAtendimento) }}
        </template>
        <template v-slot:[`item.valorDeclarado`]="{ item }">
          <span>{{ item.valorDeclarado | formatoMonetario }}</span>
        </template>

        <template v-slot:[`item.pedido.data`]="{ item }">
          {{ item.pedido.data | dataParaLeitura }}
        </template>

        <template v-slot:[`item.inicioAtendimento`]="{ item }">
          {{ item.inicioAtendimento | dataParaLeitura }}
        </template>
        <template v-slot:[`item.finalAtendimento`]="{ item }">
          {{ item.finalAtendimento | dataParaLeitura }}
        </template>
        <template v-slot:[`item.previsaoAtendimento`]="{ item }">
          {{ item.previsaoAtendimento | dataParaLeitura }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row class="text-left ma-2">
              <v-col cols="12" md="6">
                <!-- <v-col cols="12" md="12">
                  {{ $t("Data") + ": " }} {{ item.data | dataParaLeitura }}
                </v-col> -->
                <!-- <v-col cols="12" md="12">
                    {{
                      $t("Endereco") +
                      ": " +
                      item.pedido.endereco.bairro +
                      ", " +
                      item.pedido.endereco.logradouro +
                      ", " +
                      item.pedido.endereco.numero +
                      " - " +
                      item.pedido.endereco.cidade.nome +
                      ", " +
                      item.pedido.endereco.cep
                    }}
                  </v-col> -->
                <v-col cols="12" md="12">
                  {{ $t("Status") + ": " + item.pedido.statusPedido }}
                </v-col>
              </v-col>

              <!-- <v-col cols="12" md="6">
                <div class="text-center">
                  <h3>{{ $t("Itens") }}</h3>
                </div>
                <v-data-table
                  :headers="[
                    { text: $t('Moeda'), value: 'tipoMoeda' },

                    { text: $t('Quantidade'), value: 'quantidade' },
                  ]"
                  :items="item.pedido.composicao"
                  hide-default-footer
                  class="elevation-1"
                ></v-data-table>
              </v-col> -->
            </v-row>
          </td>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn
          class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
          height="39"
          @click.prevent="modalCriaTrajeto = true"
          v-if="!isListagem"
        >
          <v-icon left dark> mdi-plus-circle </v-icon>
          {{ $t("Adicionar_trajeto") }}
        </v-btn>
      </v-card-actions>
    </v-col>
  </v-row>
</template>

<script>
import CampoHorario from "@/components/base/proprios/CampoHorario.vue";
import DataService from "@/services/operacoes/RoteiroDataService";
export default {
  name: "TabelaAdicionaTrajeto",
  components: { CampoHorario },
  props: {
    payload: {
      type: Object,
      default() {
        return {};
      },
    },
    // roteiro: {
    //   type: Number,
    //   default: 0,
    // },
    isListagem: { type: Boolean, default: false },
  },

  data() {
    return {
      dialog: false,
      apenasPedidosSelecionados: false,

      search: "",
      pedidos: [],
      roteiro: {},
      pedidoAtual: {},
      formCriaTrajeto: false,
      searchTrajetos: "",
      modalCriaTrajeto: false,

      novoTrajeto: {},
      trajetos: [],

      formRemoveTrajeto: false,
      trajetoAtual: {},

      modalDetalhes: false,
      errors: [],

      pedidosSelecionados: [],

      headers: [
        {
          text: this.$t("Valor_total"),
          value: "valorTotal",
          sortable: "false",
        },
        { text: this.$t("Data"), value: "data", sortable: "false" },
        {
          text: this.$t("previsaoAtendimento"),
          value: "previsaoAtendimento",
        },
        { text: this.$t("bairro"), value: "endereco.bairro", align: " d-none" },
        { text: this.$t("cep"), value: "endereco.cep", align: " d-none" },
        {
          text: this.$t("Cidade"),
          value: "endereco.cidade.nome",
          align: " d-none",
        },
        {
          text: this.$t("Logradouro"),
          value: "endereco.logradouro",
          align: " d-none",
        },

        { text: "", value: "data-table-expand" },
      ],

      headersPedido: [
        {
          text: this.$t("Classificacao"),
          value: "classificacao",
          sortable: "false",
        },
        { text: this.$t("Status"), value: "statusPedido", sortable: "false" },
        {
          text: this.$t("Origem"),
          value: "origem.descricao",
          sortable: "false",
        },
        {
          text: this.$t("janelaOrigemInicio"),
          value: "janelaOrigemInicio",
          sortable: "false",
        },
        {
          text: this.$t("janelaOrigemFinal"),
          value: "janelaOrigemFinal",
          sortable: "false",
        },
        {
          text: this.$t("Destino"),
          value: "destino.descricao",
          sortable: "false",
        },
        {
          text: this.$t("janelaDestinoInicio"),
          value: "janelaDestinoInicio",
          sortable: "false",
        },
        {
          text: this.$t("janelaDestinoFinal"),
          value: "janelaDestinoFinal",
          sortable: "false",
        },
        {
          text: this.$t("Valor_total"),
          value: "valorTotal",
          sortable: "false",
        },
      ],
    };
  },

  mounted() {
    this.trajetos = this.payload.trajetos;
    this.roteiro = this.payload;
    console.log("this.roteiro", this.roteiro);
    this.getPedidos();
  },

  methods: {
    getPedidos() {
      DataService.getPedidos(this.roteiro.dataInicio.substring(0, 10)).then(
        (response) => {
          this.pedidos = response.data;
        }
      );
    },

    limpaFormTrajeto() {
      this.formCriaTrajeto = false;
    },

    addTrajeto() {
      this.novoTrajeto.pedidoId = this.pedidoAtual.id;
      this.novoTrajeto.roteiroId = this.roteiro.id;
      this.novoTrajeto.previsaoAtendimentoOrigem = DataService.createTrajeto(
        this.novoTrajeto
      )
        .then((trajetos) => {
          window.location.reload();
          // this.novoTrajeto.id = this.pegaId(trajetos.headers.location);
          // this.novoTrajeto.pedido = this.pedidoAtual;
          // //Remove o pedido adicionado ao trajeto da lista de pedidos
          // this.pedidos = this.pedidos.filter((pedido) => {
          //   return pedido.id != this.pedidoAtual.id;
          // });

          // console.log(this.novoTrajeto);
          // this.trajetos.push(this.novoTrajeto);
          // this.$emit("trajetos", this.trajetos);

          // this.formCriaTrajeto = false;
          // this.novoTrajeto = {};
        })
        .catch((error) => {
          console.log(error);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          this.errors = error.response.data.errors;
        });
    },

    pegaId(response) {
      var string = response.match(/([^\/]*$)/);
      return string[0];
    },

    mostraDetalhes(pedido) {
      this.formCriaTrajeto = false;
      this.modalDetalhes = true;
      this.pedidoAtual = pedido;
    },

    confirmaRemoveTrajeto(item) {
      this.trajetoAtual = item;
      this.formRemoveTrajeto = true;
    },

    removeTrajeto() {
      DataService.removeTrajeto(this.trajetoAtual.id)
        .then(() => {
          // this.trajetos = this.trajetos.filter((item) => {
          //   return item.pedido.id != this.trajetoAtual.id;
          // });

          // this.pedidos.push(this.trajetoAtual.pedido);

          // this.$emit("trajetos", this.trajetos);
          window.location.reload();

          this.formRemoveTrajeto = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },

    mostraFormTrajeto(pedido) {
      this.formCriaTrajeto = true;
      this.pedidoAtual = pedido;
      console.log("trajetos", this.trajetos);
      console.log("roteiro", this.roteiro);
    },
  },
};
</script>

<style>
.inputData {
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 99px;
}

.mdl-textfield:not(.is-dirty) input::-webkit-datetime-edit {
  color: transparent;
}

input:focus::-webkit-datetime-edit {
  color: rgba(255, 255, 255, 0.46) !important;
}
.INATIVO {
  color: red;
}
</style>