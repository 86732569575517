<template>
  <CadastroBase
    nomePlural="PedidoUniformes"
    nomeSingular="PedidoUniforme"
    @submitSuccessInsert="emitSubmitSuccess"
    @submitErrorInsert="emitSubmitError"
    @closeModalInsert="closeModal"
  >
    <v-dialog
      v-model="formRemoveProduto"
      persistent
      max-width="350"
      class="dialog-deletado"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t("Confirma_exclusao?") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            height="39"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            text
            @click="formRemoveProduto = false"
          >
            {{ $t("nao") }}
          </v-btn>

          <v-btn
            class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
            height="39"
            text
            @click="removeProduto()"
          >
            {{ $t("Confirmar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="formAddProduto" persistent max-width="350">
      <v-card>
        <v-form @submit.prevent="addProduto">
          <v-card-title class="headline">
            {{ $t("Adicionar_produto") }} <br />
            <v-btn
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >
              {{ this.produto.descricao }}
            </v-btn>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  type="number"
                  :label="$t('Quantidade')"
                  v-model="quantidadeSolicitada"
                  min="1"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              @click="limpaFormProduto"
            >
              {{ $t("Cancelar") }}
            </v-btn>

            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              @click.prevent="addProduto"
            >
              {{ $t("Adicionar") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-col cols="12">
      <v-row>
        <v-col cols="6" md="6" class="d-flex">
          <CampoData
            @date="dataPedido = $event"
            :label="$t('Data')"
            id="dataPedido"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            id="observacao"
            v-model="observacao"
            :label="$t('Observacao')"
            name="observacao"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>
        <!-- <v-col cols="6" md="6" class="d-flex">
            <CampoData
              @date="dataRetorno = $event"
              :label="$t('Previsao_retorno')"
            />
          </v-col> -->
        <v-col cols="12">
          <TabelaSelecionaRecurso
            @recurso="funcionario = $event"
            url="funcionarios/active"
            titulo="Funcionario"
            :headers="[
              {
                text: this.$t('Matricula'),
                value: 'matricula',
                sortable: 'false',
              },
              {
                text: this.$t('Nome'),
                value: 'nomeSocial',
                sortable: 'false',
              },
              {
                text: this.$t('Cargo'),
                value: 'cargo.descricao',
                sortable: 'false',
              },
              {
                text: this.$t('PostoTrabalho'),
                value: 'postoTrabalho.descricao',
                sortable: 'true',
              },
            ]"
          />
        </v-col>

        <v-col cols="12" md="12">
          <div class="card-header-padding">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                {{ $t("Listagem_de_produtos") }}
              </h5>
              <p class="text-sm text-body mb-0">
                {{ $t("Clique_para_adicionar") }}
              </p>
            </div>
          </div>

          <v-col cols="12" md="5">
            <v-text-field
              hide-details
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
              height="39"
              v-model="search"
              :label="$t('Procurar')"
              name="searchProdutos"
              ref="searchProdutos"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-data-table
            :headers="headers"
            :items="produtos"
            :search="search"
            @click:row="handleClick"
            class="table"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editCliente(item.id)"
                >ni-ruler-pencil</v-icon
              >
              <v-icon small @click="confirmaExclusao(item)"
                >ni-fat-remove</v-icon
              >
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12" md="12">
          <div>
            <h5 class="font-weight-bold text-h5 text-typo mb-0">
              {{ $t("Resumo_do_pedido") }}
            </h5>
          </div>

          <v-col cols="12" md="5">
            <v-text-field
              hide-details
              class="input-style font-size-input text-light-input placeholder-light input-icon"
              dense
              flat
              filled
              solo
              height="39"
              v-model="searchPedido"
              :label="$t('Procurar')"
              name="searchProdutosPedido"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-data-table
            :headers="headersPedido"
            :items="pedido"
            :search="searchPedido"
            class="table"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="confirmaRemoveProduto(item)"
                >ni-fat-remove</v-icon
              >
            </template>
          </v-data-table>
          <v-card-actions>
            <!-- Total: R$ {{ this.valorPedido }} -->
          </v-card-actions>
        </v-col>

        <v-col cols="12"> </v-col>
      </v-row>
    </v-col>
  </CadastroBase>
</template>

<script>
import CadastroBase from "@/components/base/Crud/CadastroBase";
import TabelaSelecionaRecurso from "@/components/base/proprios/TabelaSelecionaRecurso";
import DataService from "@/services/recursos/PedidoUniformeDataService";
export default {
  name: "CadastroPedido",
  components: { CadastroBase, TabelaSelecionaRecurso },
  data() {
    return {
      DataService: DataService,
      DeveLimparForm: true,
      submited: false,
      dataPedido: "",
      dataDevolucao: "",
      descricao: "",
      observacao: "",
      funcionario: {},

      pedido: [],
      produtos: [],
      search: "",
      searchPedido: "",
      errors: [],
      produto: {},
      formAddProduto: false,
      formRemoveProduto: false,
      quantidadeSolicitada: 0,
      headers: [
        {
          text: this.$t("Descricao"),
          value: "descricao",
          align: "start",
          sortable: "false",
        },
        // { text: this.$t("Valor"), value: "valor", sortable: "true" },
        // { text: this.$t("Estoque"), value: "estoque", sortable: "true" },
        // { text: this.$t("Status"), value: "status", sortable: "false" },
        { text: this.$t("cbarras"), value: "cbarras", sortable: "true" },
      ],
      headersPedido: [
        {
          text: this.$t("Descricao"),
          value: "produto.descricao",
          align: "start",
          sortable: "false",
        },
        {
          text: this.$t("quantidadeSolicitada"),
          value: "quantidadeSolicitada",
          sortable: "false",
        },
        {
          text: this.$t("cbarras"),
          value: "produto.cbarras",
          sortable: "true",
        },
        { text: this.$t("Acoes"), value: "actions", sortable: "false" },
      ],
    };
  },
  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccessInsert");
    },
    emitSubmitError() {
      this.$emit("submitErrorInsert");
    },
    closeModal() {
      this.$emit("closeModalInsert");
    },
    limpaForm() {
      this.pedido = [];
      this.dataPedido = "";
      this.dataDevolucao = "";
      this.observacao = "";
      this.descricao = "";
      this.funcionario = {};
    },

    getProdutos() {
      DataService.getProdutos().then((response) => {
        this.produtos = response.data;
      });
    },
    handleClick(event) {
      this.produto = event;
      this.formAddProduto = true;
    },
    addProduto() {
      var produtoExistente = this.pedido.find(
        (produto) => produto.produto.id == this.produto.id
      );
      if (produtoExistente) {
        this.quantidadeSolicitada =
          parseInt(this.quantidadeSolicitada) +
          parseInt(produtoExistente.quantidadeSolicitada);
        this.pedido = this.pedido.filter((produto) => {
          return produto.produto.id !== produtoExistente.produto.id;
        });
      }
      this.pedido.push({
        produto: this.produto,
        quantidadeSolicitada: this.quantidadeSolicitada,
      });
      this.produto = {};
      this.quantidadeSolicitada = 0;
      this.search = "";
      this.formAddProduto = false;
      console.log("searchProdutos", this.$refs.searchProdutos);
      this.$refs.searchProdutos.focus();
    },
    limpaFormProduto() {
      this.formAddProduto = false;
      this.quantidadeSolicitada = 0;
    },
    confirmaRemoveProduto(item) {
      this.produto = item.produto;
      this.formRemoveProduto = true;
    },
    removeProduto() {
      this.pedido = this.pedido.filter((produto) => {
        return produto.produto.id !== this.produto.id;
      });
      this.formRemoveProduto = false;
      this.$swal.fire(this.$t("Removido_com_sucesso"), "", "success");
    },
  },
  mounted() {
    this.getProdutos();
    this.getClientes();
  },
  computed: {
    recurso: function () {
      return {
        dataPedido: this.dataPedido,
        dataRetorno: this.dataRetorno,
        observacao: this.observacao,
        descricao: this.descricao,
        items: this.pedido,
        funcionario: this.funcionario,
      };
    },

    agora: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate().toString().padStart(2, "0");
      let minutes = toString(today.getMinutes());
      minutes = minutes.padStart(2, "0");
      const time = today.getHours() + ":" + minutes;
      const dateTime = date + "T" + time;
      return date + "T00:00";
    },
  },
};
</script>
<style scoped>
#data {
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 99px;
}
.mdl-textfield:not(.is-dirty) input::-webkit-datetime-edit {
  color: transparent;
}
input:focus::-webkit-datetime-edit {
  color: rgba(255, 255, 255, 0.46) !important;
}
</style>
