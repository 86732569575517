<template>
  <v-row>
    <v-col cols="12" v-for="(i, index) in enderecos.length" :key="i">
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            @change="emit"
            v-model="enderecos[index].descricao"
            :label="$t('Descricao')"
            name="descricao"
            class="
              input-style
              font-size-input
              text-light-input
              placeholder-light
              input-icon
            "
            dense
            flat
            filled
            solo
          />
        </v-col>

        <v-col cols="12" md="4">
          <BuscaCep
            @cidades="cidades = $event"
            @bairro="enderecos[index].bairro = $event"
            @cep="enderecos[index].cep = $event"
            @cidade="enderecos[index].cidade = $event"
            @uf="enderecos[index].uf = $event"
            @logradouro="enderecos[index].logradouro = $event"
            :cepProp="enderecos[index].cep || ''"
          ></BuscaCep>
        </v-col>

        <v-col cols="12" md="4">
          <v-autocomplete
            @change="emit"
            :label="$t('Cidade')"
            outlined
            v-model="enderecos[index].cidade"
            :items="cidades"
            :item-text="(item) => item.nome + '/' + item.estado.sigla"
            return-object
            class="
              input-style
              font-size-input
              text-light-input
              placeholder-light
              input-icon
            "
            dense
            flat
            filled
            solo
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12">
          <v-text-field
            @change="emit"
            v-model="enderecos[index].logradouro"
            :label="$t('Logradouro')"
            name="logradouro"
            class="
              input-style
              font-size-input
              text-light-input
              placeholder-light
              input-icon
            "
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="12">
          <v-text-field
            @change="emit"
            v-model="enderecos[index].bairro"
            :label="$t('Bairro')"
            name="bairro"
            class="
              input-style
              font-size-input
              text-light-input
              placeholder-light
              input-icon
            "
            dense
            flat
            filled
            solo
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            @change="emit"
            @blur="buscaGeoCode(index)"
            v-model="enderecos[index].numero"
            :label="$t('Numero')"
            name="numero"
            type="number"
            class="
              input-style
              font-size-input
              text-light-input
              placeholder-light
              input-icon
            "
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            @change="emit"
            v-model="enderecos[index].complemento"
            :label="$t('Complemento')"
            name="complemento"
            class="
              input-style
              font-size-input
              text-light-input
              placeholder-light
              input-icon
            "
            dense
            flat
            filled
            solo
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            @change="emit"
            v-model="enderecos[index].latitude"
            :label="$t('Latitude')"
            name="latitude"
            type="number"
            class="
              input-style
              font-size-input
              text-light-input
              placeholder-light
              input-icon
            "
            dense
            flat
            filled
            solo
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            @change="emit"
            v-model="enderecos[index].longitude"
            :label="$t('Longitude')"
            name="longitude"
            type="number"
            class="
              input-style
              font-size-input
              text-light-input
              placeholder-light
              input-icon
            "
            dense
            flat
            filled
            solo
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" class="text-right">
      <v-btn
        fab
        dark
        small
        height="39"
        class="
          font-weight-bold
          text-capitalize
          btn-ls btn-secondary
          bg-gradient-light
          py-3
          px-6
        "
        v-if="enderecos.length > 1"
        @click="removeEndereco"
      >
        <v-icon dark> mdi-minus </v-icon>
      </v-btn>

      <v-btn
        elevation="4"
        rounded
        @click="addEndereco"
        class="
          ma-1
          font-weight-bold
          text-none
          btn-primary
          bg-gradient-primary
          py-3
          px-6
          ms-3
        "
        height="39"
      >
        {{ $t("Adicionar_endereco") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import BuscaCep from "@/components/base/proprios/BuscaCep";
export default {
  name: "FormEndereco",

  components: { BuscaCep },

  props: {
    payload: { type: Array, required: false },
  },

  mounted() {
    if (this.payload) {
      if (this.payload.length > 0) {
        this.enderecos = this.payload;
      }
    }
  },

  data() {
    return {
      cidades: [],

      enderecos: [
        {
          cidade: {},
          bairro: "",
          logradouro: "",
          latitude: "",
          longitude: "",
        },
      ],

      enderecoVazio: {
        cidade: {},
        bairro: "",
        logradouro: "",
        latitude: "",
        longitude: "",
      },
    };
  },

  methods: {
    addEndereco() {
      this.enderecos.push({ ...this.enderecoVazio });
    },

    removeEndereco() {
      this.enderecos.pop();
    },

    emit() {
      this.$emit("recursos", this.enderecos);
    },

    buscaGeoCode(index) {
      console.log(index, "index");
      let geocoder = new google.maps.Geocoder();

      let end = this.enderecos[index];
      console.log(end);
      let address =
        end.logradouro + ", " + end.numero + ", " + end.bairro + " " + end.cep;

      geocoder.geocode({ address: address }, (results, status) => {
        if (status == "OK") {
          let lat = results[0].geometry.location.lat();
          let lng = results[0].geometry.location.lng();
          this.enderecos[index].latitude = lat;
          this.enderecos[index].longitude = lng;
        } else {
          console.log(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    },
  },

  watch: {
    enderecos: function (newValue, oldValue) {
      this.$emit("recursos", newValue);
    },
  },
};
</script>

<style></style>
