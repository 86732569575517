<template>
  <ListagemBase
    nomePlural="PedidoMateriais"
    nomeSingular="PedidoMaterial"
    :headers="headers"
    stringCadastro="Nova_solicitacao"
    :opcoesLegenda="[
      { text: 'Pendente', color: 'orange' },
      { text: 'Concluído', color: 'green' },
    ]"
  >
    <template v-slot:upload> </template>
  </ListagemBase>
</template>

<script>
import ListagemBase from "@/components/base/Crud/ListagemBase";
import DataService from "@/services/recursos/PedidoMaterialDataService";

export default {
  name: "ListagemPedidos",
  components: { ListagemBase },
  data() {
    return {
      DataService: DataService,
      headers: [
        { text: this.$t("Status"), value: "statusPedido", sortable: "false" },
        {
          text: this.$t("Data_do_evento"),
          value: "dataPedido",
          sortable: "false",
        },
        {
          text: this.$t("Descrição"),
          value: "descricao",
          sortable: "false",
        },
        {
          text: this.$t("dataSolicitacao"),
          value: "dtInclusao",
          sortable: "false",
        },
        {
          text: this.$t("Solicitante"),
          value: "solicitante.nome",
          sortable: "false",
        },
        {
          text: this.$t("Atividade"),
          value: "actionsPedidoMaterial",
          sortable: "false",
        },
        { text: this.$t("Acoes"), value: "actions", sortable: "false" },
      ],
    };
  },
};
</script>

<style>
</style>
