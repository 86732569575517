import Axios from "axios";

class FuncionarioCargoDataService{

  getAll(){
    return Axios.get('funcionarioCargos');
  }

  getPage(params){
    return Axios.get('funcionarioCargos/page', {params})
  }

  create(params){
    return Axios.post('funcionarioCargos', params)
  }

  update(params){
    console.log(params)
    return Axios.put('funcionarioCargos/' + (params.id), params)
  }

  find(id){
    return Axios.get(`funcionarioCargos/${id}`)
  }

  delete(id){
    return Axios.delete(`funcionarioCargos/${id}`);
  }
}

export default new FuncionarioCargoDataService()
