import Axios from "axios";

class FuncionarioPontoDataService {

  getFuncionarios() {
    return Axios.get('funcionarios')
  }

  getAll() {
    return Axios.get('funcionariosPonto/period', { params });
  }

  getPage(params) {
    return Axios.get('funcionariosPonto/page/period', { params })
  }

  create(params) {
    return Axios.post('funcionariosPonto', params)
  }

  update(params) {
    return Axios.put('funcionariosPonto/' + (params.id), params)
  }

  find(id) {
    return Axios.get(`funcionariosPonto/${id}`)
  }

  delete(id) {
    return Axios.delete(`funcionariosPonto/${id}`);
  }

  invert(id) {
    return Axios.put(`funcionariosPonto/invert/${id}`)
  }

  share(id) {
    return Axios.put(`funcionariosPonto/share/${id}`)
  }

  insertSimplifiedList(params) {
    return Axios.post("funcionariosPonto/import/list", params);
  }
}

export default new FuncionarioPontoDataService()
