var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-text-field-datetimepicker',{attrs:{"label":_vm.$t(_vm.label),"properties":{
         dense: true,
         clearable: false,
         outlined: true,
         prependIcon: 'mdi-calendar',
         appendIcon: ''
       },"options":{
         tabDateTitle: _vm.$t('Data'),
         tabTimeTitle: _vm.$t('Hora'),
         tabBackgroundColor: 'blue',
         locale: 'pt-BR',
         format: 'DD/MM/YYYY',
         closeOnDateClick: false,
         useSeconds: false,
       }},on:{"blur":function($event){return _vm.$emit('date', _vm.formatedValue)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})
}
var staticRenderFns = []

export { render, staticRenderFns }