<template>
  <v-row class="">
    <template v-for="(i, index) in recursosCount">
      <v-col cols="12" md="5" :key="i">
        <v-text-field
          v-model="recursos[index].horario"
          :label="$t('Horario') + ' - ' + (index + 1)"
          :name="'CompHorario_horario' + (index + 1)"
          hint="HH:MM:SS"
          v-mask="'##:##:##'"
          @blur="$emit('recursos', recursos)"
          autocomplete="ansdoasdjosk"
          validate-on-blur
          hide-details
          class="
            input-style
            font-size-input
            text-light-input
            placeholder-light
            input-icon
          "
          dense
          flat
          filled
          solo
        >
        </v-text-field>
      </v-col>
    </template>

    <v-col cols="12" md="2" class="text-left d-flex">
      <v-btn
        class="ml-1"
        fab
        dark
        small
        color="red"
        v-if="recursosCount > 1"
        @click="removeRecurso"
      >
        <v-icon dark> mdi-minus </v-icon>
      </v-btn>

      <v-btn class="ml-1" fab dark small color="green" @click="addRecurso">
        <v-icon dark> mdi-plus-circle </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CampoHorarios",

  props: {
    payload: {
      type: Array,
      required: false,
      default() {
        return [{ horario: "" }];
      },
    },
  },

  data() {
    return {
      recursos: this.payload,
    };
  },

  mounted() {
    console.log(this.recursos);
  },

  computed: {
    recursosCount() {
      return this.recursos.length;
    },
  },

  methods: {
    takePicture() {
      return new Promise((resolve, reject) => {});
    },

    userUploadPicture() {
      this.takePicture()
        .then((res) => {})
        .catch((err) => {});
    },

    addRecurso() {
      this.recursos.push({ horario: "" });
      this.$emit("recursos", this.recursos);
    },

    removeRecurso() {
      this.recursos.pop();
      this.$emit("recursos", this.recursos);
    },

    validaHorario(value, index) {
      if (value.length == 5) {
        this.recursos[index].horario = value + ":00";
        value = value + ":00";
      }
      if (value.length == 6) {
        this.recursos[index].horario = value + "00";
        value = value + "00";
      }

      let arr = value.split(":");

      let hora = arr[0];
      let min = arr[1];
      let seg = arr[2];

      if (
        Number(hora) > 23 ||
        Number(hora) < 0 ||
        (hora ? hora.length < 2 : true)
      ) {
        return this.$t("Horario_invalido");
      }

      if (
        Number(min) > 59 ||
        Number(min) < 0 ||
        (min ? min.length < 2 : true)
      ) {
        return this.$t("Horario_invalido");
      }

      if (
        Number(seg) > 59 ||
        Number(seg) < 0 ||
        (seg ? seg.length < 2 : true)
      ) {
        return this.$t("Horario_invalido");
      }

      return true;
    },
  },
};
</script>

<style></style>
