<template>
  <div>
    <v-dialog v-model="dialog" width="700" :scrollable="false">
      <template v-slot:activator="{ on, attrs }">
        <!-- Aqui vem a imagem definida no uso do componente -->

        <span v-bind="attrs" v-on="on" @click="openModal()">
          <slot>
            <!-- Fallback -->
            <v-btn color="red lighten-2" dark> Click Me </v-btn>
          </slot>
        </span>
      </template>

      <v-card class="">
        <v-icon
          style="display: inline"
          @click="dialog = false"
          aria-label="Close"
          large
          color="red"
        >
          mdi-close
        </v-icon>

        <v-img contain max-height="600" :src="url"></v-img>

        <div v-if="url !== null && url !== undefined">
          <a :href="url" target="_blank">
            <v-img v-if="imageUrl" :src="imageUrl" alt="Downloadable Image" />
            <v-btn
              style="align-self: center"
              color="secondary"
              dark
              v-else
              @click="openModal"
            >
              <v-icon color="blue">mdi-download</v-icon>
            </v-btn>
          </a>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ShowImage",

  props: {
    url: { type: String, required: true },
  },

  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    openModal() {
      console.log("openModal");
      this.dialog = true;
    },
    downloadImage() {
      const link = document.createElement("a");
      link.href = this.url;
      link.download = this.getFileName(this.url); // Extract filename
      link.click();

      // Optional: Update UI to show download success
      this.imageUrl = this.url; // Display downloaded image (optional)
    },
    getFileName(url) {
      // Extract filename from URL (you can customize this logic)
      return url.split("/").pop();
    },
  },
};
</script>

<style>
</style>