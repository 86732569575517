<template>
  <v-text-field
    v-model="value"
    :label="$t(label)"
    :name="name || label"
    hint="DD/MM/AAAA"
    v-mask="'##/##/####'"
    @blur="$emit('date', formatedValue)"
    autocomplete="ansdoasdjosk"
    :rules="[(v) => !!v || this.$t('Campo_obrigatorio'), validaData()]"
    hide-details
    class="input-style font-size-input text-light-input placeholder-light input-icon"
    dense
    flat
    filled
    solo
    :disabled="disabled"
  >
  </v-text-field>
</template>

<script>
export default {
  name: "CampoData",

  props: {
    label: { type: String, default: "Data" },
    payload: { type: String, required: false },
    name: { type: String, required: false },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  mounted() {
    setTimeout(() => {
      if (this.payload) {
        this.value = this.$options.filters.dataParaLeitura(this.payload);
      }
    }, 700);
  },

  watch: {
    payload: function (val) {
      this.value = this.$options.filters.dataParaLeitura(val);
    },
  },

  data() {
    return {
      value: "",
    };
  },

  methods: {
    validaData() {
      console.log("validaData()", this.formatedValue);
      var obj = new Date(this.formatedValue);

      if (obj.getYear()) {
        return true;
      }

      return this.$t("Data_invalida");
    },
  },

  computed: {
    formatedValue: function () {
      let data = this.value;

      if (data.length == 10) {
        var dia = data.split("/")[0];
        var mes = data.split("/")[1];
        var ano = data.split("/")[2];

        let string = ano + "-" + mes + "-" + dia;
        return string;
      } else {
        return data;
      }
    },
  },
};
</script>

<style></style>
