<template>
  <EditarBase
    nomeSingular="PedidoMaterial"
    nomePlural="PedidoMateriais"
    @recurso="recurso = $event"
    @submitSuccess="emitSubmitSuccess"
    @submitError="emitSubmitError"
    @closeModalEdit="closeModalEdit"
  >
    <v-container id="user-profile" fluid tag="section">
      <v-dialog v-model="modalTabelaProdutos" max-width="1000">
        <v-card>
          <div class="card-header-padding">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                {{ $t("Listagem_de_produtos") }}
              </h5>
              <p class="text-sm text-body mb-0">
                {{ $t("Clique_para_adicionar") }}
              </p>
            </div>
          </div>
          <v-card-text>
            <v-col cols="12" md="5">
              <v-text-field
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
                height="39"
                v-model="search"
                :label="$t('Procurar')"
              >
                <template slot="prepend-inner">
                  <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
                </template>
              </v-text-field>
            </v-col>

            <v-data-table
              :headers="headers"
              :items="produtos"
              :search="search"
              @click:row="handleClick"
              class="table"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="editCliente(item.id)"
                  >ni-ruler-pencil</v-icon
                >
                <v-icon small @click="confirmaExclusao(item)"
                  >ni-fat-remove</v-icon
                >
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="modalTabelaProdutos = false"
            >
              {{ $t("Fechar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="formRemoveProduto"
        persistent
        max-width="350"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">
            {{ $t("Confirma_exclusao?") }}
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              height="39"
              text
              @click="formRemoveProduto = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="removeProduto()"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="formEdicaoProduto"
        persistent
        max-width="350"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">
            {{ this.produto.descricao }}
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  type="number"
                  :label="$t('Quantidade_solicitada')"
                  v-model="quantidadeSolicitadaEdicao"
                  class="purple-input"
                  min="1"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="formEdicaoProduto = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="edicaoProduto()"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="formAddProduto" persistent max-width="350">
        <v-card>
          <v-form @submit.prevent="addProduto">
            <v-card-title class="headline">
              {{ $t("Adicionar_produto") }} <br />
              <v-btn
                class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              >
                {{ this.produto.descricao }}
              </v-btn>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    type="number"
                    :label="$t('Quantidade_solicitada')"
                    v-model="quantidadeSolicitada"
                    min="1"
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                height="39"
                class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
                @click="limpaFormProduto"
              >
                {{ $t("Cancelar") }}
              </v-btn>

              <v-btn
                class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                height="39"
                @click.prevent="addProduto"
              >
                {{ $t("Adicionar") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <v-row justify="center">
        <v-col cols="12" md="12">
          <v-form
            @submit.prevent="submitForm"
            v-if="!submited"
            autocomplete="off"
          >
            <v-container class="py-0">
              <v-row>
                <v-col cols="12">
                  <v-span class="opacity-5 text-dark">
                    {{ $t("Evento") }}
                  </v-span>
                  <v-text-field
                    v-model="recurso.descricao"
                    :label="$t('Evento')"
                    name="descricao"
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-span class="opacity-5 text-dark">
                    {{ $t("Centro_custo") }}
                  </v-span>
                  <v-autocomplete
                    id="selecionaEscopo"
                    outlined
                    v-model="recurso.centroCusto"
                    :items="centroCustos"
                    :item-text="(item) => item.descricao"
                    :label="$t('Centro_custo')"
                    return-object
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="6" md="6" class="d-flex">
                  <v-span class="opacity-5 text-dark">
                    {{ $t("Data_do_evento") }}
                  </v-span>
                  <CampoData
                    @date="recurso.dataPedido = $event"
                    :payload="recurso.dataPedido"
                    :label="$t('Data_do_evento')"
                  />
                </v-col>

                <v-col cols="6" md="6" class="d-flex">
                  <v-span class="opacity-5 text-dark">
                    {{ $t("Previsao_retorno") }}
                  </v-span>
                  <CampoData
                    @date="recurso.dataRetorno = $event"
                    :payload="recurso.dataRetorno"
                    :label="$t('Previsao_retorno')"
                  />
                </v-col>

                <v-col cols="12" md="12">
                  <v-span class="opacity-5 text-dark">
                    {{ $t("Descricao_pedido_material") }}
                  </v-span>
                  <v-textarea
                    v-model="recurso.observacao"
                    :payload="recurso.observacao"
                    :label="$t('Descricao_pedido_material')"
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                  >
                  </v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="card-header-padding">
                        <div>
                          <h5 class="font-weight-bold text-h5 text-typo mb-0">
                            {{ $t("Resumo_do_pedido") }}
                          </h5>
                        </div>
                      </div>

                      <v-col cols="12" md="5">
                        <v-text-field
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                          height="39"
                          v-model="searchPedido"
                          :label="$t('Procurar')"
                          name="searchProdutosPedido"
                        >
                          <template slot="prepend-inner">
                            <v-icon color="#adb5bd" size=".875rem"
                              >fas fa-search</v-icon
                            >
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-data-table
                        :headers="headersPedido"
                        :items="recurso.items"
                        :search="searchPedido"
                        class="table"
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            small
                            @click="confirmaEdicaoProduto(item)"
                            :title="$t('Editar')"
                            class="mr-2"
                            >ni-ruler-pencil</v-icon
                          >
                          <v-icon
                            small
                            @click="confirmaRemoveProduto(item)"
                            :title="$t('Excluir')"
                            class="mr-2"
                            >ni-fat-remove</v-icon
                          >
                        </template>
                      </v-data-table>
                      <v-card-actions>
                        <v-btn
                          @click="modalTabelaProdutos = true"
                          class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                          height="39"
                        >
                          {{ $t("Adicionar_produto") }}
                          <v-icon left dark> mdi-plus-circle </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-col>

                    <v-col cols="12"> </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </EditarBase>
</template>

<script>
import EditarBase from "@/components/base/Crud/EditarBase";
import ComumDataService from "@/services/ComumDataService";
import DataService from "@/services/recursos/PedidoMaterialDataService";
export default {
  name: "EditarPedido",
  components: { EditarBase },
  props: {
    recurso: Object,
  },
  data() {
    return {
      DataService: DataService,

      modalTabelaProdutos: false,
      submited: false,
      recurso: {
        statusPedido: "",
        id: "",
        dataPedido: "",
        descricao: "",
        observacao: "",
        items: [],
      },
      pedido: [],
      items: [],
      produtos: [],
      search: "",
      searchPedido: "",
      errors: [],
      produto: {},
      formAddProduto: false,
      formRemoveProduto: false,
      quantidadeSolicitada: 0,
      quantidadeSolicitadaEdicao: 0,
      formEdicaoProduto: false,
      dataPedido: "",
      centroCustos: [],
      centroCusto: {},
      headers: [
        {
          text: this.$t("Descricao"),
          value: "descricao",
          align: "start",
          sortable: "false",
        },
        {
          text: this.$t("Quantidade"),
          value: "quantidadeSolicitada",
          sortable: "true",
        },
        // ", sortable: "true" },
        // { text: this.$t("Status"), value: "status", sortable: "false" },
      ],
      headersPedido: [
        {
          text: this.$t("Descricao"),
          value: "produto.descricao",
          align: "start",
          sortable: "false",
        },
        // { text: this.$t("Valor"), value: "produto.valor", sortable: "true" },
        {
          text: this.$t("Quantidade_solicitada"),
          value: "quantidadeSolicitada",
          sortable: "true",
        },
        {
          text: this.$t("Quantidade_devolvida"),
          value: "quantidadeDevolvida",
          sortable: "true",
        },
        {
          text: this.$t("Estoque"),
          value: "produto.estoque",
          sortable: "true",
        },
        // { text: this.$t("Quantidade"), value: "quantidade", sortable: "false" },
        { text: this.$t("Acoes"), value: "actions", sortable: "false" },
      ],
    };
  },
  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccess");
    },
    emitSubmitError() {
      this.$emit("submitError");
    },
    closeModalEdit() {
      this.$emit("closeModalEdit");
    },
    submitForm(event) {
      console.log("submitForm", this.pedido);
      DataService.update({
        statusPedido: this.recurso.statusPedido,
        dataPedido: this.recurso.dataPedido,
        observacao: this.recurso.observacao,
        descricao: this.recurso.descricao,
        centroCusto: this.recurso.centroCusto,
        id: this.recurso.id,
        items: this.pedido,
      })
        .then((response) => {
          this.erros = [];
          this.submited = true;
          event.target.reset();
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
          this.errors = error.response.data.errors;
        });
    },

    getCentroCustos() {
      ComumDataService.get("centroCustos/tipo/4").then((res) => {
        this.centroCustos = res.data;
      });
    },
    // getClientes() {
    //   ClienteDataService.getAll().then((response) => {
    //     this.clientes = response.data;
    //   });
    // },

    getProdutos() {
      DataService.getProdutosEstoque().then((response) => {
        this.produtos = response.data;
      });
    },

    handleClick(event) {
      this.produto = event;
      this.formAddProduto = true;
    },

    addProduto() {
      var produtoExistente = this.recurso.items.find(
        (produto) => produto.produto.id == this.produto.id
      );
      if (produtoExistente) {
        this.quantidadeSolicitada =
          parseInt(this.quantidadeSolicitada) +
          parseInt(produtoExistente.quantidadeSolicitada);
        this.recurso.items = this.recurso.items.filter((produto) => {
          return produto.produto.id !== produtoExistente.produto.id;
        });
      }
      this.recurso.items.push({
        produto: this.produto,
        quantidadeSolicitada: this.quantidadeSolicitada,
      });
      // this.items = this.pedido;
      console.log("items", this.items);
      this.produto = {};
      this.quantidadeSolicitada = 0;
      this.formAddProduto = false;
      console.log("searchProdutosPedido", this.$refs.searchProdutosPedido);
      this.$refs.searchProdutosPedido.focus();
    },
    limpaFormProduto() {
      this.formAddProduto = false;
      this.quantidadeSolicitada = 0;
    },
    confirmaRemoveProduto(item) {
      this.produto = item.produto;
      this.formRemoveProduto = true;
    },

    confirmaEdicaoProduto(item) {
      this.produto = item.produto;
      this.quantidadeSolicitadaEdicao = item.quantidadeSolicitada;
      this.formEdicaoProduto = true;
    },

    edicaoProduto() {
      console.log("Edição produto");
      var produtoExistente = this.recurso.items.find(
        (produto) => produto.produto.id == this.produto.id
      );
      if (produtoExistente) {
        this.recurso.items = this.recurso.items.filter((produto) => {
          return produto.produto.id !== produtoExistente.produto.id;
        });
      }
      this.recurso.items.push({
        produto: this.produto,
        quantidadeSolicitada: this.quantidadeSolicitadaEdicao,
      });
      this.formEdicaoProduto = false;
    },
    removeProduto() {
      this.recurso.items = this.recurso.items.filter((produto) => {
        console.log(this.produto);
        return produto.produto.id !== this.produto.id;
      });

      this.formRemoveProduto = false;
      this.$swal.fire(this.$t("Removido_com_sucesso"), "", "success");
    },

    getPedido() {
      DataService.find(this.recurso.id)
        .then((response) => {
          this.recurso.dataPedido = response.data.dataPedido;
          this.recurso.statusPedido = response.data.statusPedido;
          this.recurso.id = response.data.id;
          this.pedido = response.data.items;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
          this.errors.push({ field: "ID", message: "Pedido não encontrado" });
        });
    },
    // getItemClass: function (item) {
    //   if (item.statusPedido === 1) {
    //     return "PENDENTE";
    //   }

    //   if (item.statusPedido === 2) {
    //     return "ROTEIRIZADO";
    //   }

    //   if (item.statusPedido === 3) {
    //     return "CANCELADO";
    //   }
    // },
  },

  mounted() {
    this.getProdutos();
    // this.getPedido();
    this.getCentroCustos();
  },

  computed: {
    // valorPedido: function () {
    //   var total = 0;
    //   this.pedido.forEach((item) => {
    //     total += item.produto.valor ?? 1 * item.quantidade;
    //   });
    //   return total;
    // },
    // agora: function () {
    //   const today = new Date();
    //   const date =
    //     today.getFullYear() +
    //     "-" +
    //     (today.getMonth() + 1) +
    //     "-" +
    //     today.getDate().toString().padStart(2, "0");
    //   let minutes = toString(today.getMinutes());
    //   minutes = minutes.padStart(2, "0");
    //   const time = today.getHours() + ":" + minutes;
    //   const dateTime = date + "T" + time;
    //   return date + "T00:00";
    // },
    // recurso: function () {
    //   return {
    //     dataPedido: this.dataPedido,
    //     dataDevolucao: this.dataDevolucao,
    //     observacao: this.observacao,
    //     descricao: this.descricao,
    //     items: this.pedido,
    //   };
    // },
  },
};
</script>

<style scoped>
#data {
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 99px;
}

.mdl-textfield:not(.is-dirty) input::-webkit-datetime-edit {
  color: transparent;
}

input:focus::-webkit-datetime-edit {
  color: rgba(255, 255, 255, 0.46) !important;
}
</style>
