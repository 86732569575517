<template>
  <ListagemBase
    nomePlural="Funcionarios"
    nomeSingular="Funcionario"
    :headers="headers"
    stringCadastro="Admissao"
    :buscaSimplificada="false"
    :pesquisaMatricula="true"
    :pesquisaNome="true"
    :pesquisaCliente="true"
    :pesquisaPostoTrabalho="true"
    :botaoTransferenciaEmpresaFuncionario="true"
    :botaoImport="true"
  >
    <template v-slot:upload> </template>
  </ListagemBase>
</template>

<script>
import FuncionarioDataService from "@/services/pessoal/FuncionarioDataService";
const DataService = FuncionarioDataService;

import ListagemBase from "@/components/base/Crud/ListagemBase";

export default {
  name: "ListagemFuncionarios",
  components: { ListagemBase },
  data() {
    return {
      DataService: DataService,

      //Recurso
      deleted: [],
      funcionarios: [],

      headers: [
        { text: this.$t("Matricula"), value: "matricula", sortable: "false" },
        { text: this.$t("Nome"), value: "nomeSocial", sortable: "false" },
        {
          text: this.$t("Sindicato"),
          value: "sindicato.descricao",
          sortable: "false",
        },
        {
          text: this.$t("Escala"),
          value: "funcionarioEscalaTrabalho.descricao",
          sortable: "false",
        },
        { text: this.$t("Cargo"), value: "cargo.descricao", sortable: "false" },
        {
          text: this.$t("PostoTrabalho"),
          value: "postoTrabalho.descricao",
          sortable: "true",
        },
        { text: this.$t("Status"), value: "status", sortable: "false" },
        { text: this.$t("Acoes"), value: "actions", sortable: false },
      ],
    };
  },
  methods: {},
  computed: {},
};
</script>

<style></style>
