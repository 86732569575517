<template>
  <CadastroBase
    nomePlural="Usuarios"
    nomeSingular="Usuario"
    :submitPersonalizado="true"
    :submited="submited"
    @submitSuccessInsert="emitSubmitSuccess"
    @submitErrorInsert="emitSubmitError"
    @closeModalInsert="closeModal"
  >
    <ErrorAlert :errors="errors"></ErrorAlert>
    <v-col cols="12">
      <v-row>
        <TabelaSelecionaPessoa
          @pessoa="recurso.pessoa = $event"
          :payload="pessoaUrl"
        >
        </TabelaSelecionaPessoa>

        <v-col cols="12">
          <v-text-field
            v-model="recurso.email"
            :label="$t('Email')"
            name="email"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="recurso.senha"
            :label="$t('senha')"
            name="senha"
            type="password"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="senhaConfirma"
            :label="$t('confirme_a_senha')"
            name="senhaConfirma"
            type="password"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          />
        </v-col>

        <v-col cols="12">
          <v-combobox
            v-model="recurso.perfis"
            :items="todosPerfis"
            item-text="descricao"
            return-object
            :label="$t('Perfis_do_usuario')"
            multiple
            chips
            deletable-chips
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-combobox>
        </v-col>
      </v-row>
    </v-col>
  </CadastroBase>
</template>

<script>
import TabelaSelecionaPessoa from "@/components/base/proprios/TabelaSelecionaPessoa";
import UsuarioDataService from "@/services/acessos/UsuarioDataService";
const DataService = UsuarioDataService;

import CadastroBase from "@/components/base/Crud/CadastroBase";

export default {
  name: "CadastroUsuario",
  components: { TabelaSelecionaPessoa, CadastroBase },
  data() {
    return {
      DataService: DataService,

      submited: false,
      recurso: {
        pessoa: {},

        email: "",
        senha: "",
        inquilino: this.$store.state.inquilino,
        perfis: [],
      },
      pessoaUrl: {},
      senhaConfirma: "",
      todosPerfisDescricao: [],
      perfisSelecionados: [],
      todosPerfis: [],
      errors: [],
      pessoas: [],
      pessoa: [],
      search: "",
      headers: [
        { text: this.$t("Nome"), value: "nome", sortable: "false" },
        { text: this.$t("CPF"), value: "cpf", sortable: "false" },
      ],
    };
  },
  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccessInsert");
    },
    emitSubmitError() {
      this.$emit("submitErrorInsert");
    },
    closeModal() {
      this.$emit("closeModalInsert");
    },

    submitForm() {
      return new Promise((resolve, reject) => {
        if (!this.senhaCoincide()) {
          reject();
          return;
        }

        DataService.create({
          pessoa: this.recurso.pessoa,
          email: this.recurso.email,
          imagem: "this.recurso.imagem",
          imagem64: "this.recurso.imagem64",
          perfis: this.recurso.perfis,
          senha: this.recurso.senha,
        })
          .then((response) => {
            this.erros = [];
            this.submited = true;
            this.recurso = {
              email: "",
              senha: "",
              senhaConfirma: "",
              inquilino: this.$store.state.inquilino,
              perfis: [],
            };
            resolve();

            if (this.$store.state.pendingTutorial == "Cadastrar_usuario") {
              startTutorial([{}]);
            }
          })
          .catch((error) => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            reject();
            this.errors = error.response.data.errors;

            if (
              this.$store.state.pendingTutorial == "Cadastrar_usuario" &&
              error.response.status == 422
            ) {
              startTutorial([{}]);
            }
          });
      });
    },

    senhaCoincide() {
      if (this.recurso.senha !== this.senhaConfirma) {
        this.errors = [
          { field: this.$t("senha"), message: this.$t("senhas_nao_conferem") },
        ];
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return false;
      }
      return true;
    },

    findPerfis() {
      DataService.findPerfis()
        .then((response) => {
          this.todosPerfis = response.data;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
        });
    },

    getPessoas() {
      DataService.getPessoas()
        .then((response) => {
          this.pessoas = response.data;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
        });
    },

    verificaUrlParametroPessoa() {
      let id = this.$route.query.idPessoaCriada;
      console.log(id);
      if (id) {
        this.pessoaUrl = { id: id };
      }
    },
  },
  mounted() {
    this.verificaUrlParametroPessoa();
    this.getPessoas();
    this.findPerfis();
  },
};
</script>

<style scoped>
</style>
