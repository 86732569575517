
<template>
  <!--               <ConfirmationOverlay @cancel="" subtitle="Só altere essa propriedade caso necessário" />
 -->
  <div class="c-overlay" v-if="isDisplayed">
    <div class="c-overlay__content">
      <div class="c-overlay__text-area">
        <h1>{{ title }}</h1>
        <h2 v-if="this.subtitle">{{ subtitle }}</h2>
      </div>

      <div class="btn-area bd-grid">
        <v-btn
          height="39"
          class="
            font-weight-bold
            text-capitalize
            btn-ls btn-secondary
            bg-gradient-light
            py-3
            px-6
          "
          @click="$emit('cancel')"
          >{{ $t("Nao_alterar!") }}</v-btn
        >
        <v-btn
          id="btn-confirmation-alterar"
          class="
            font-weight-bold
            text-none
            btn-primary
            bg-gradient-primary
            py-3
            px-6
            ms-3
          "
          height="39"
          @click="isDisplayed = false"
          >{{ $t("Quero_alterar!") }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmationOverlay",

  props: {
    title: {
      type: String,
      default: function () {
        return this.$t("Tem_certeza?");
      },
    },
    subtitle: { type: String },
  },

  data() {
    return {
      isDisplayed: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.text--bold {
  font-weight: bold !important;
}
.bd-grid {
  display: grid;
  grid-gap: 1rem;
}

.c-overlay {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.c-overlay__content {
  text-align: center;
  padding: 2rem 2rem 3rem;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(65, 11, 16, 0.15);
  border-radius: 1rem;
  width: calc(100% - 3rem);
  max-width: 500px;
  margin-top: 7rem;

  display: flex;
  flex-direction: column;
}

h1 {
  color: #161212;
  margin: 0;
  font-size: 2rem;
}

h2 {
  font-size: 1.25rem;
  color: #8f8a8a;
}

#app .c-overlay__text-area {
  margin-bottom: 2rem;
}

.btn-area {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  width: 90%;
  align-self: center;
}
</style>
