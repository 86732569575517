import Axios from "axios";

class RoteiroDataService {

  findItem(id) {
    return Axios.get(`roteiroTrajetos/${id}`)
  }

  getAll() {
    return Axios.get("roteiros");
  }

  getPage(params) {
    return Axios.get("roteiros/page", { params });
  }

  create(params) {
    return Axios.post("roteiros", params);
  }

  update(params) {
    return Axios.put("roteiros/" + params.id, params);
  }

  find(id) {
    return Axios.get(`roteiros/${id}`);
  }

  delete(id) {
    return Axios.delete(`roteiros/${id}`);
  }

  getUser() {
    return Axios.get(`usuarios/dados_Usuario`);
  }


  createTrajeto(params) {
    return Axios.post('/roteiroTrajetos', params)
  }

  removeTrajeto(id) {
    return Axios.delete(`/roteiroTrajetos/${id}`)
  }

  getPedidos(date) {
    return Axios.get('/pedidosTransporte/pending/' + date)
  }

  getFilter(params) {
    return Axios.post("roteiros/filter", params);
  }
}

export default new RoteiroDataService();
