<template>
<div class="maindiv">

<div class="box" name="box" id="caixa-legenda">
    <div class="item" v-for="(item, index) in this.itens" :key="item.id">
      <v-divider vertical :dark="true" v-if="index > 0" class="mx-2"></v-divider>

      <v-icon small :color="`${item.color} darken-2`">
        mdi-circle-slice-8
      </v-icon>
      <span>{{ item.text }}</span>
    </div>
  </div>
</div>
  
</template>

<script>
export default {
  name: "CaixaLegenda",
  props: ["itens"],
 
};
</script>

<style scoped>
.box {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.377);
  border-radius: 99px;
  padding: .5em;
}

.item {
  display: flex;
  align-items: center;
 
}

.item > span {
  margin-left: .5em;
}

.maindiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex: 1 3 auto;

    max-height: 32px;
    max-width: 130px;
    
    font-size: .8rem;
}




</style>