<template>
  <v-navigation-drawer
    bottom
    color="transparent"
    fixed
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list color="white" shaped>
      <template v-for="name in items">
        <!-- If item should redirect -->
        <v-list-item
          v-if="name.type == 'redirect'"
          :key="name.name"
          @click="clickDrawer(name.to)"
          :exact="name === 'Home'"
          color="primary"
        >
          <v-list-item-content>
            <v-list-item-title v-text="name.name" />
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-else
          :key="name"
          @click="
            $vuetify.goTo(name.id, {
              duration: 870,
              offset: 0,
              easing: 'linear',
            })
          "
          :exact="name === 'Home'"
          color="primary"
        >
          <v-list-item-content>
            <v-list-item-title v-text="name.name" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import goTo from "vuetify/es5/services/goto";

export default {
  name: "HomeDrawer",

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    clickDrawer: function (to) {
      this.$router.push(to);
      setTimeout(() => {
        goTo("#hero-section", {
          duration: 870,
          offset: 0,
          easing: "linear",
        });
      }, 100);
    },
  },
};
</script>
