<template>
  <div>
    <v-container fluid class="px-6 py-0">
      <ErrorAlert :errors="errors" />

      <!-- Dialog exportar  -->
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="400"
        v-model="modalExportar"
        :loading="loading"
        persistent
      >
        <v-card>
          <v-card-title class="d-flex">
            {{ $t("Exportar") }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="d-flex" cols="12" sm="12">
                <v-select
                  id="select_apuracao_por"
                  :items="[
                    { text: $t('Mes'), value: 'mes' },
                    { text: $t('Dia'), value: 'dia' },
                    { text: $t('Folha_pagamento'), value: 'folhaPagamento' },
                  ]"
                  v-model="opcaoApuracaoCsv"
                  :label="$t('Tipo_exportacao')"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              @click="modalExportar = false"
              >{{ $t("Cancelar") }}</v-btn
            >
            <v-btn
              class="ma-1 font-weight-bold text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              :disabled="!opcaoApuracaoCsv"
              :loading="loading"
              text
              @click="createCSV()"
              id="btn-download-exporta-tabela"
              >{{ $t("Download") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal Delete sucesso -->
      <div id="dialog">
        <v-dialog v-model="deletado" persistent max-width="350">
          <v-card>
            <v-card-title class="headline">
              {{ $t("deletado_sucesso") }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                height="39"
                text
                @click="deletado = false"
              >
                {{ $t("Confirmar") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <!-- Modal confirmar delete -->
      <v-dialog
        v-model="confirmarDelete"
        persistent
        max-width="350"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">{{
            $t("Confirma_exclusao?")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              height="39"
              text
              @click="confirmarDelete = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              text
              @click="deleteRecurso"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal mensagens -->
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="modalMensagens"
        :loading="loading"
        persistent
      >
        <v-card>
          <v-card-title class="d-flex">
            {{ $t("Mensagens") }} - {{ recurso.id }} -
            {{ recurso.solicitante.nome }}
          </v-card-title>
          <v-card-text>
            <div class="modal">
              <div class="modal-content">
                <div
                  v-for="mensagem in recurso.mensagens"
                  :key="mensagem.id"
                  class="mensagem-item"
                  :class="{
                    solicitante:
                      mensagem.responsavel.id === recurso.solicitante.id,
                    naoSolicitante:
                      mensagem.responsavel.id !== recurso.solicitante.id,
                  }"
                >
                  <p>Data: {{ mensagem.data | dataParaLeitura }}</p>
                  <p>Responsável: {{ mensagem.responsavel.nome }}</p>
                  <p>{{ mensagem.texto }}</p>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              @click="modalMensagens = false"
              >{{ $t("Fechar") }}</v-btn
            >
            <v-btn
              v-if="
                recurso.statusPedido !== 'CONCLUIDO' &&
                recurso.statusPedido !== 'CANCELADO'
              "
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              @click="modalNovaMensagem = true"
              >{{ $t("Novo_cadastro") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal nova mensagem -->
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="700"
        v-model="modalNovaMensagem"
        :loading="loading"
        persistent
      >
        <v-card>
          <v-card-title class="d-flex">
            {{ $t("Nova_mensagem") }} - {{ recurso.id }} -
            {{ recurso.solicitante.nome }}
          </v-card-title>
          <v-card-text>
            <div class="modal-content">
              <v-col cols="12" md="12">
                <v-textarea
                  id="nova-mensagem"
                  v-model="novaMensagem"
                  placeholder="Digite sua mensagem (máximo 255 caracteres)"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-textarea>
                <p>Caracteres restantes: {{ caracteresRestantes }}</p>
              </v-col>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              :loading="loading"
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              @click="modalNovaMensagem = false"
              >{{ $t("Cancelar") }}</v-btn
            >
            <v-btn
              class="ma-1 font-weight-bold text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              :disabled="caracteresRestantes < 0 || caracteresRestantes >= 250"
              :loading="loading"
              text
              @click="enviarMensagem()"
              id="btn-download-exporta-tabela"
              >{{ $t("Enviar") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row class="mt-8">
        <v-row>
          <v-btn
            :loading="loading"
            @click.stop="exportaCsv()"
            class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
            height="39"
            id="btn-exportar-tabela"
            v-can="'ROLE_PedidoOrcamentoDespesa_Export'"
          >
            <v-icon left dark> mdi-file-export-outline </v-icon>
            {{ $t("Exportar") }}
          </v-btn>

          <slot name="upload"> </slot>
        </v-row>
        <v-col cols="12">
          <template>
            <div>
              <v-card class="card-shadow border-radius-xl">
                <div class="card-header-padding">
                  <div class="d-flex align-center">
                    <v-col cols="12" md="7">
                      <v-text-field
                        v-model="nome"
                        :label="$t('Nome')"
                        name="nome"
                        hide-details
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        v-model="statusPedido"
                        :items="[
                          { value: 'PENDENTE', text: $t('PENDENTE') },
                          { value: 'APROVADO', text: $t('APROVADO') },
                          {
                            value: 'PROCESSAMENTO',
                            text: $t('PROCESSAMENTO'),
                          },
                          {
                            value: 'TRANSFERENCIARECURSO',
                            text: $t('TRANSFERENCIARECURSO'),
                          },
                          { value: 'ENCERRADO', text: $t('ENCERRADO') },
                          { value: 'CONCLUIDO', text: $t('CONCLUIDO') },
                          { value: 'CANCELADO', text: $t('CANCELADO') },
                        ]"
                        item-text="text"
                        item-value="value"
                        :label="$t('Status')"
                        hide-details
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="2" id="filtrar">
                      <v-btn
                        @click="filtrar()"
                        :loading="loading"
                        id="btn--filtrar"
                        :ripple="false"
                        class="ma-1 font-weight-bold text-none ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
                        height="39"
                      >
                        <v-icon left dark> ni-zoom-split-in </v-icon>
                        {{ $t("Filtrar") }}
                      </v-btn>
                    </v-col>
                  </div>
                </div>

                <v-card-text class="px-0 py-0">
                  <v-data-table
                    id="headers"
                    :headers="headers"
                    :items="recursos"
                    :search="search"
                    :items-per-page="isSearching ? 200 : pageSize"
                    :show-expand="true"
                    hide-default-footer
                    disable-pagination
                    deletable-chips
                    :single-expand="true"
                    class="elevation-1"
                    :loading="loading"
                    item-class="ATIVO"
                  >
                    <template v-slot:expanded-item="{ item, headers }">
                      <td :colspan="headers.length">
                        <conteudo-expansion-orcamento-despesa
                          :recurso="item"
                          :loading="loading"
                        >
                        </conteudo-expansion-orcamento-despesa>
                      </td>
                    </template>
                    <template v-slot:[`item.valorSolicitado`]="{ item }">
                      <span>{{ item.valorSolicitado | formatoMonetario }}</span>
                    </template>
                    <template v-slot:[`item.valorSaldo`]="{ item }">
                      <span>{{ item.valorSaldo | formatoMonetario }}</span>
                    </template>
                    <template v-slot:[`item.periodoInicio`]="{ item }">
                      <span>{{ item.periodoInicio | dataParaLeitura }}</span>
                    </template>
                    <template v-slot:[`item.periodoEncerramento`]="{ item }">
                      <span>{{
                        item.periodoEncerramento | dataParaLeitura
                      }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon
                        v-if="item.statusPedido === 'PENDENTE'"
                        small
                        name="Aprovar"
                        id="Aprovar"
                        :title="$t('Aprovar')"
                        class="mr-2 ma-1"
                        @click="aprovar(item)"
                        v-can="'ROLE_PedidoOrcamentoDespesa_Approve'"
                        >ni-button-play</v-icon
                      >
                      <v-icon
                        v-if="item.statusPedido === 'ENCERRADO'"
                        small
                        name="Concluir"
                        id="Concluir"
                        :title="$t('Concluir')"
                        class="mr-2 ma-1"
                        v-can="'ROLE_PedidoOrcamentoDespesa_Completed'"
                        @click="concluir(item)"
                        >ni-check-bold</v-icon
                      >
                      <v-icon
                        v-can="'ROLE_PedidoOrcamentoDespesa_Delete'"
                        small
                        :title="$t('Cancelar')"
                        name="deleteBtn"
                        @click="confirmaDelete(item)"
                        color="#67748e"
                        class="btn-ls me-0.90 my-1 rounded-sm ma-1"
                        icon
                        elevation="0"
                        :ripple="false"
                      >
                        ni-fat-remove</v-icon
                      >
                      <v-icon
                        small
                        :title="$t('Mensagens')"
                        name="messageBtn"
                        @click="showMessages(item)"
                        color="#67748e"
                        class="btn-ls me-0.90 my-1 rounded-sm ma-1"
                        icon
                        elevation="0"
                        :ripple="false"
                      >
                        ni-chat-round</v-icon
                      >
                    </template>

                    <template
                      v-slot:[`item.orcamentodespesaPeriodoApuracao.inicioPeriodo`]="{
                        item,
                      }"
                    >
                      {{
                        item.orcamentodespesaPeriodoApuracao.inicioPeriodo
                          | dataParaLeitura
                      }}
                    </template>
                    <template
                      v-slot:[`item.orcamentodespesaPeriodoApuracao.finalPeriodo`]="{
                        item,
                      }"
                    >
                      {{
                        item.orcamentodespesaPeriodoApuracao.finalPeriodo
                          | dataParaLeitura
                      }}
                    </template>
                    <template v-slot:[`item.inconsistencia`]="{ item }">
                      {{ $t(item.inconsistencia ? "sim" : "nao") }}
                    </template>
                    <template v-slot:[`item.valorTotal`]="{ item }">
                      {{ item.valorTotal | formatoMonetario }}
                    </template>
                    <template v-slot:[`item.outrosTotal`]="{ item }">
                      {{ item.outrosTotal | formatoMonetario }}
                    </template>

                    <template v-slot:[`item.valorUtilizado`]="{ item }">
                      {{ item.valorUtilizado | formatoMonetario }}
                    </template>
                    <template v-slot:[`item.vrTotal`]="{ item }">
                      {{ item.vrTotal | formatoMonetario }}
                    </template>
                    <template v-slot:[`item.vtTotal`]="{ item }">
                      {{ item.vtTotal | formatoMonetario }}
                    </template>
                    <template v-slot:[`item.statusPedido`]="{ item }">
                      {{ $t(item.statusPedido) }}
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-actions class="card-padding">
                  <v-row>
                    <v-col cols="6" lg="6" class="d-flex align-center">
                      <span class="text-body me-3"
                        >{{ $t("Itens_por_pagina") }}:</span
                      >
                      <v-col cols="3" sm="3">
                        <v-select
                          v-model="pageSize"
                          :items="pageSizes"
                          @change="handlePageSizeChange"
                          :disabled="this.isSearching"
                          :item-value="pageSize"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                        ></v-select>
                      </v-col>
                    </v-col>
                    <v-col cols="6" class="ml-auto d-flex justify-end">
                      <v-pagination
                        prev-icon="fa fa-angle-left"
                        next-icon="fa fa-angle-right"
                        class="pagination"
                        color="#516a78ee"
                        v-model="page"
                        :length="totalPages"
                        @input="handlePageChange"
                        :disabled="isSearching"
                        circle
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped>
.mensagem-item {
  /* Estilize cada item de mensagem conforme necessário */
  padding: 10px;
  border-bottom: 1px solid #7b7979; /* Adiciona uma linha separadora entre os itens */
}

.naoSolicitante {
  background-color: #f7f7f7; /* Cor de fundo para registros pares */
}

.solicitante {
  background-color: #cfe8c2; /* Cor de fundo para registros ímpares */
}
</style>

<script>
import TabelaSelecionaRecurso from "@/components/base/proprios/TabelaSelecionaRecurso";
import DataService from "@/services/ComumDataService";
import Axios from "axios";
import ConteudoExpansionOrcamentoDespesa from "./ConteudoExpansionOrcamentoDespesa.vue";
export default {
  name: "ListagemOrcamentoDespesaApuracao",
  components: {
    TabelaSelecionaRecurso,
    ConteudoExpansionOrcamentoDespesa,
  },
  data() {
    return {
      opcaoApuracaoCsv: null,
      modalExportar: false,
      modalMensagens: false,
      modalNovaMensagem: false,
      maxCaracteres: 255,
      novaMensagem: "",
      recursos: [],
      errors: [],
      recurso: {
        mensagens: [{ responsavel: {}, data: "", texto: "" }],
        solicitante: { nome: "" },
      },

      loading: false,
      deletado: false,
      confirmarDelete: false,
      nome: "",
      statusPedido: null,

      headers: [
        {
          text: this.$t("Id"),
          value: "id",
          sortable: "true",
        },
        {
          text: this.$t("Solicitante"),
          value: "solicitante.nome",
          sortable: "true",
        },
        {
          text: this.$t("Status"),
          value: "statusPedido",
          sortable: "true",
        },
        {
          text: this.$t("Data_inicio"),
          value: "periodoInicio",
          sortable: "true",
        },
        {
          text: this.$t("Data_fim"),
          value: "periodoEncerramento",
          sortable: "true",
        },
        {
          text: this.$t("Valor_solicitado"),
          value: "valorSolicitado",
          sortable: "true",
        },
        {
          text: this.$t("Valor_utilizado"),
          value: "valorUtilizado",
          sortable: "true",
        },
        {
          text: this.$t("Saldo"),
          value: "valorSaldo",
          sortable: "true",
        },
        {
          text: this.$t("Acoes"),
          value: "actions",
          sortable: "true",
        },
      ],

      headersApuracaoDia: [
        {
          text: this.$t("Data"),
          value: "data",
          sortable: "true",
        },
      ],

      //Paginação
      searchApuracaoDia: "",
      search: "",
      searchApuracao: "",
      isSearching: false,

      page: 1,
      totalPages: 0,
      pageSize: 8,
      pageSizes: [8, 16, 24],
      orderBy: null,

      direction: null,
      directions: [
        { text: this.$t("Crescente"), value: "ASC" },
        { text: this.$t("Decrescente"), value: "DESC" },
      ],
    };
  },

  computed: {
    caracteresRestantes() {
      return this.maxCaracteres - this.novaMensagem.length;
    },
  },

  mounted() {
    //this.getFuncionarios();
    this.getClientes();
  },

  watch: {
    // search: function () {
    //   this.handleSearchChange();
    // },

    submited: function (newValue, oldValue) {
      if (newValue) {
        this.requestResources();
      }
    },

    // matricula: function () {
    //   //  if (this.matricula.length == 0 || this.matricula.length > 0) {
    //   this.requestResources();
    //   //}
    // },
    // nome: function () {
    //   if (this.nome.length == 0 || this.nome.length > 3) {
    //     this.requestResources();
    //   }
    // },
  },

  methods: {
    filtrar() {
      this.requestResources();
    },
    download(content, fileName, mimeType) {
      var a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        location.href =
          "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      }
    },

    retrieveData() {
      this.loading = true;

      DataService.get("pedidoOrcamentoDespesas/page", {
        params: {
          ...{},
          ...this.getRequestParams(),
        },
      }).then((response) => {
        this.recursos = response.data.content;
        this.totalPages = response.data.totalPages;
        this.pageSize = response.data.size;
        this.loading = false;
      });
    },

    requestResources() {
      this.isSearching = true;
      console.log("requestResources");
      if (this.nome.length > 3 || this.statusPedido !== null) {
        this.loading = true;
        Axios.post("pedidoOrcamentoDespesas/filter", {
          status: this.statusPedido,
          solicitante: this.nome,
        })
          .then((response) => {
            this.recursos = response.data;
            console.log("requestResource", response.data);

            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else {
        this.isSearching = false;
        this.retrieveData();
      }
    },

    getAll() {
      DataService.get("orcamentodespesaApuracao/period", {
        params: {},
      }).then((response) => {
        this.recursos = response.data;
      });
    },

    downloadPdf(string) {
      console.log("metodo certo");
      window.open("data:application/pdf," + encodeURI(string));
    },

    exportaCsv() {
      this.loading = true;
      console.log("exporta CSV");
      DataService.put(
        "pedidoOrcamentoDespesas/exportCsv",
        { status: this.statusPedido, solicitante: this.nome },
        {
          responseType: "blob",
        }
      )
        .then((res) => {
          console.log("res", res, res.data);
          this.download(res.data, "orcamentodespesa.csv", "CSV/plain");
          this.loading = false;
        })
        .catch((err) => {
          console.log("err", err);
          this.loading = false;
        });
    },

    /* Paginação */
    getRequestParams() {
      let params = {};
      let page = this.page;
      let pageSize = this.pageSize;
      let orderBy = this.orderBy;
      let direction = this.direction;

      console.log("page", page);

      if (page) {
        params["page"] = page - 1;
      }

      if (pageSize) {
        params["linesPerPage"] = pageSize;
      }

      if (orderBy) {
        params["orderBy"] = orderBy;
      }

      if (direction) {
        params["direction"] = direction;
      }
      console.log("params", params);
      return params;
    },

    handlePageChange(value) {
      this.page = value;
      this.retrieveData();
    },

    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.retrieveData();
    },

    handleOrderByChange(value) {
      this.orderBy = value;
      this.page = 1;
      this.retrieveData();
    },

    handleDirectionChange(value) {
      (this.direction = value), this.retrieveData();
    },

    // getAll() {
    //   this.loading = true;
    //   console.log("getAll", this.search);
    //   DataService.get("orcamentodespesaApuracao/period/funcionario", {
    //     params: {
    //       inicio: this.periodoSelecionado.inicioPeriodo,
    //       final: this.periodoSelecionado.finalPeriodo,
    //       matricula: this.search.matricula,
    //     },
    //   }).then((response) => {
    //     this.recursos = response.data;
    //     this.loading = false;
    //   });
    // },

    confirmaDelete(recurso) {
      this.objetoAtual = recurso;
      this.confirmarDelete = true;
    },

    showMessages(recurso) {
      console.log("mensagens", recurso.mensagens);
      this.recurso = recurso;
      this.modalMensagens = true;
    },

    deleteRecurso() {
      this.loading = true;
      DataService.delete("pedidoOrcamentoDespesas/" + this.objetoAtual.id)
        .then(() => {
          this.loading = false;
          this.confirmarDelete = false;
          this.deletado = true;
          this.retrieveData();
        })
        .catch((error) => {
          this.loading = false;
          this.confirmarDelete = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          this.erros = error.response.data.errors;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_excluir_o_registro"),
            "error"
          );
        });
    },

    aprovar(recurso) {
      this.loading = true;

      DataService.put("pedidoOrcamentoDespesas/approve", {
        idOrigem: recurso.id,
      })
        .then(() => {
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Aprovacao_concluida"),
            "success"
          );
        })
        .catch((error) => {
          this.loading = false;
          this.confirmarDelete = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          this.erros = error.response.data.errors;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_excluir_o_registro"),
            "error"
          );
        });
    },

    concluir(recurso) {
      this.loading = true;

      DataService.put("pedidoOrcamentoDespesas/completed/" + recurso.id, {})
        .then(() => {
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Aprovacao_concluida"),
            "success"
          );
        })
        .catch((error) => {
          this.loading = false;
          this.confirmarDelete = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          this.erros = error.response.data.errors;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Nao_foi_possivel_excluir_o_registro"),
            "error"
          );
        });
    },
    enviarMensagem() {
      this.loading = true;

      DataService.put("pedidoOrcamentoDespesas/message", {
        id: this.recurso.id,
        texto: this.novaMensagem,
      })
        .then(() => {
          this.loading = false;
          this.retrieveData();
          this.$swal.fire(
            this.$t("Sucesso"),
            this.$t("Mensagem_enviada"),
            "success"
          );
          this.modalNovaMensagem = false;
          this.modalMensagens = false;
          this.retrieveData();
        })
        .catch((error) => {
          this.loading = false;
          this.confirmarDelete = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          this.erros = error.response.data.errors;
          this.$swal.fire(
            this.$t("Erro"),
            this.$t("Tente_novamente_mais_tarde"),
            "error"
          );
          this.modalNovaMensagem = false;
        });
    },
  },
  mounted() {
    this.retrieveData();
  },
};
</script>

<style>
.ATIVO {
  color: #67748e;
}
</style>
