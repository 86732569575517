var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CadastroBase',{attrs:{"nomePlural":"FuncionarioCargos","nomeSingular":"FuncionarioCargo"},on:{"submitSuccessInsert":_vm.emitSubmitSuccess,"submitErrorInsert":_vm.emitSubmitError,"closeModalInsert":_vm.closeModal}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"label":_vm.$t('Descricao'),"name":"descricao","dense":"","flat":"","filled":"","solo":""},model:{value:(_vm.recurso.descricao),callback:function ($$v) {_vm.$set(_vm.recurso, "descricao", $$v)},expression:"recurso.descricao"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field-money',{staticClass:"font-size-input text-light-input placeholder-light",attrs:{"label":_vm.$t('Salario_base'),"properties":{
            prefix: 'R$',
            readonly: false,
            disabled: false,
            outlined: false,
            clearable: true,
            rules: _vm.required,
          },"options":{
            locale: 'pt-BR',
            length: 12,
            precision: 2,
            empty: '0',
          },"dense":"","flat":"","filled":"","solo":""},model:{value:(_vm.recurso.salarioBase),callback:function ($$v) {_vm.$set(_vm.recurso, "salarioBase", $$v)},expression:"recurso.salarioBase"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"label":_vm.$t('CBO_extenso'),"name":"cbo","dense":"","flat":"","filled":"","solo":""},model:{value:(_vm.recurso.cbo),callback:function ($$v) {_vm.$set(_vm.recurso, "cbo", $$v)},expression:"recurso.cbo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("cargoConfianca"))+" ")]},proxy:true}]),model:{value:(_vm.recurso.cargoConfianca),callback:function ($$v) {_vm.$set(_vm.recurso, "cargoConfianca", $$v)},expression:"recurso.cargoConfianca"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }