<template>
  <v-container id="user-profile" fluid tag="section">
    <v-dialog v-model="submited" max-width="500">
      <v-card>
        <v-card-title>
          <div class="success rounded">
            {{ $t("usuario") }} {{ $t("editado_sucesso") }}
          </div>

          <v-spacer />

          <v-icon aria-label="Close" @click="submited = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn
            color="success"
            text
            @click="
              submited = false;
              this.getCliente;
            "
          >
            {{ $t("Ok") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-card class="card-shadow border-radius-xl mt-6" id="change">
        <div class="px-6 py-6">
          <div v-for="error in errors" :key="error.id">
            <base-material-alert color="error" dark dismissible>
              <span>{{ error.fieldName }}</span> — {{ error.message }}"
            </base-material-alert>
          </div>

          <div class="px-6 py-6">
            <h5 class="text-h5 font-weight-bold text-typo">
              {{ $t("Informacoes_pessoais") }}
            </h5>
          </div>

          <v-form @submit.prevent="submitForm">
            <div class="px-6 pb-6 pt-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="usuario.senha"
                    :label="$t('Nova_senha')"
                    name="senha"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    counter
                    @click:append="show1 = !show1"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    placeholder="New password"
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="senhaConfirma"
                    :label="$t('Confirmar_nova_senha')"
                    name="senha"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    counter
                    @click:append="show2 = !show2"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    placeholder="New password"
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                  />
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn
                    color="#516a78ee"
                    class="font-weight-bolder btn-default bg-gradient-default py-4 px-8 ms-auto mt-sm-auto mt-4"
                    type="submit"
                  >
                    {{ $t("Atualizar") }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import UsuarioDataService from "@/services/acessos/UsuarioDataService";

export default {
  name: "Perfil",
  data() {
    return {
      show1: false,
      show2: false,
      senhaConfirma: "",
      submited: false,
      errors: [],
      usuario: this.$store.state.user,
      inquilinos: [this.$store.state.inquilino],
      inquilino: this.$store.state.inquilino,
      inquilinoHeaders: [
        { text: this.$t("Fantasia"), value: "fantasia", sortable: "false" },
        {
          text: this.$t("RazaoSocial"),
          value: "razaoSocial",
          sortable: "false",
        },
        { text: this.$t("Tipo"), value: "tipo", sortable: "false" },
        { text: this.$t("Status"), value: "status", sortable: "false" },
        {
          text: this.$t("Tipo_contratacao"),
          value: "tipoContratacao",
          sortable: "false",
        },
        { text: this.$t("CPF"), value: "nRegistro", sortable: "false" },
      ],
    };
  },
  methods: {
    submitForm(event) {
      if (!this.senhaCoincide()) {
        return;
      }

      UsuarioDataService.updateSenha(this.usuario)
        .then((response) => {
          this.errors = [];
          this.submited = true;
        })
        .catch((error) => {
          console.log(error);
          this.errors = error.response.data.errors;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },

    senhaCoincide() {
      if (this.usuario.senha !== this.senhaConfirma) {
        this.errors = [
          { field: this.$t("senha"), message: this.$t("senhas_nao_conferem") },
        ];
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
</style>
