<template>
  <CadastroBase
    nomePlural="FuncionarioCargos"
    nomeSingular="FuncionarioCargo"
    @submitSuccessInsert="emitSubmitSuccess"
    @submitErrorInsert="emitSubmitError"
    @closeModalInsert="closeModal"
  >
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="recurso.descricao"
            :label="$t('Descricao')"
            name="descricao"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field-money
            v-model="recurso.salarioBase"
            v-bind:label="$t('Salario_base')"
            v-bind:properties="{
              prefix: 'R$',
              readonly: false,
              disabled: false,
              outlined: false,
              clearable: true,
              rules: required,
            }"
            v-bind:options="{
              locale: 'pt-BR',
              length: 12,
              precision: 2,
              empty: '0',
            }"
            class="font-size-input text-light-input placeholder-light"
            dense
            flat
            filled
            solo
          />
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="recurso.cbo"
            :label="$t('CBO_extenso')"
            name="cbo"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-checkbox v-model="recurso.cargoConfianca">
            <template v-slot:label>
              {{ $t("cargoConfianca") }}
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-col>
  </CadastroBase>
</template>

<script>
import CadastroBase from "@/components/base/Crud/CadastroBase";

import FuncionarioCargoDataService from "@/services/pessoal/FuncionarioCargoDataService";
const DataService = FuncionarioCargoDataService;

import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "R$",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  components: { CadastroBase },
  name: "CadastroFuncionarioCargo",

  data() {
    return {
      DataService: DataService,

      submited: false,
      mask: currencyMask,

      recurso: {
        descricao: "",
        salarioBase: "",
      },

      errors: [],
    };
  },
  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccessInsert");
    },
    emitSubmitError() {
      this.$emit("submitErrorInsert");
    },
    closeModal() {
      this.$emit("closeModalInsert");
    },
  },
};
</script>

<style scoped>
#app .INATIVO {
  color: red;
}
</style>
