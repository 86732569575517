<template>
  <CadastroBase nomePlural="FuncionarioPontos" nomeSingular="FuncionarioPonto">
    <v-col cols="12">
      <base-material-card
        icon="mdi-clipboard-text"
        :title="$t('Dados_FuncionarioPonto')"
        class="px-5 py-3"
      >
        <v-row>
          <v-col cols="12" class="d-flex">
            <CampoDataHora @date="recurso.data = $event" />
          </v-col>

          <v-col cols="12" class="d-flex">
            <v-autocomplete
              v-model="recurso.matricula"
              :items="this.todosFuncionarios"
              item-text="pessoa.nome"
              label="Funcionario"
              item-value="matricula"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12" class="" v-if="!registroPontoTravarJustificativa">
            <div class="font-weight-light rounded">
              <v-text-field
                v-model="recurso.justificativa"
                :label="$t('Justificativa')"
                name="Justificativa"
                class="purple-input"
              >
              </v-text-field>
            </div>
          </v-col>

          <v-col cols="12" class="" v-if="registroPontoTravarJustificativa">
            <v-select
              v-model="recurso.justificativa"
              :items="splitItemsRegistroPontoJustificativa"
              :label="$t('Justificativa')"
            ></v-select>
          </v-col>

          <!-- <v-col cols="12" md="6">
            <v-text-field
              v-model="recurso.latitude"
              :label="$t('Latitude')"
              class="purple-input"
              name="latitude"
              type="number"
              v-mask="''"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="recurso.longitude"
              :label="$t('Longitude')"
              class="purple-input"
              name="longitude"
              type="number"
              v-mask="''"
            />
          </v-col> -->
        </v-row>
      </base-material-card>
    </v-col>
  </CadastroBase>
</template>

<script>
import DataService from "@/services/pessoal/FuncionarioPontoDataService";

import CadastroBase from "@/components/base/Crud/CadastroBase";

export default {
  name: "CadastroFuncionarioPonto",
  components: { CadastroBase },

  data() {
    return {
      DataService: DataService,

      recurso: {
        data: "",
        justificativa: "",
        //imagem: "",

        matricula: "",
      },
      todosFuncionarios: [],

      registroPontoTravarJustificativa: false,
      splitItemsRegistroPontoJustificativa: [],
    };
  },

  computed: {
    agora: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate().toString().padStart(2, "0");

      let minutes = toString(today.getMinutes());
      minutes = minutes.padStart(2, "0");
      const time = today.getHours() + ":" + minutes;
      const dateTime = date + "T" + time;

      return date + "T00:00";
    },
  },

  mounted() {
    this.getFuncionarios();
    this.registroPontoVerificarTravaJustificativa();
  },

  methods: {
    getFuncionarios() {
      DataService.getFuncionarios()
        .then((response) => {
          console.log(response.data);
          this.todosFuncionarios = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    registroPontoVerificarTravaJustificativa() {
      console.log("registroPontoVerificarTravaJustificativa");
      var parametrizacaoExistente =
        this.$store.state.inquilino.parametrizacoes.find(
          (item) => item.tipo == "RESTRINGIRJUSTIFICATIVAREGISTROPONTO"
        );

      if (parametrizacaoExistente) {
        this.registroPontoTravarJustificativa = parametrizacaoExistente.ativo;

        this.splitItemsRegistroPontoJustificativa =
          parametrizacaoExistente.texto
            .split(";")
            .filter((item) => item.trim() !== ""); // Separa a string pelo ';' e remove itens vazios

        console.log(
          "  this.splitItemsRegistroPontoJustificativa",
          this.splitItemsRegistroPontoJustificativa
        );
      }
    },
  },
};
</script>

<style scoped>
#data {
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 99px;
}

.mdl-textfield:not(.is-dirty) input::-webkit-datetime-edit {
  color: transparent;
}

input:focus::-webkit-datetime-edit {
  color: rgba(255, 255, 255, 0.46) !important;
}
</style>
