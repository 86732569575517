import Axios from "axios";

class FuncionarioDataService {

  buscaCep(value) {
    return Axios.get(`http://cep.republicavirtual.com.br/web_cep.php?cep=${value}&formato=json`, {
      transformRequest: (data, headers) => {
        delete headers.common['Authorization'];
      }
    });
  }

  getFuncionarioSindicatos() {
    return Axios.get('FuncionarioSindicatos')
  }

  getEstados() {
    return Axios.get("estados");
  }

  getCidades() {
    return Axios.get("cidades");
  }

  getPostoTrabalho() {
    return Axios.get("postosTrabalho")
  }

  getAll() {
    return Axios.get("funcionarios");
  }

  getPage(params) {
    return Axios.get("funcionarios/page", { params });
  }

  create(params) {
    return Axios.post("funcionarios", params);
  }

  createResume(params) {
    return Axios.post("funcionarios/resume", params);
  }


  update(params) {
    return Axios.put("funcionarios/" + params.id, params);
  }

  find(id) {
    return Axios.get(`funcionarios/${id}`);
  }

  delete(id) {
    return Axios.delete(`funcionarios/${id}`);
  }

  getFilter(params) {
    return Axios.post("funcionarios/filter", params);
  }

  export(params) {
    return Axios.put("funcionarios/export", params);
  }

  transferCompany(params) {
    return Axios.post("funcionarios/transferencia/empresa", params);
  }

  insertSimplifiedList(params) {
    return Axios.post("funcionarios/resume/list", params);
  }

  importDexion(params) {
    return Axios.post("funcionarios/import/dexion", params);
  }

  getUniformes() {
    return Axios.get('produtos/ativos/tipoProduto/3')
  }

  getConvenios() {
    return Axios.get('beneficioConvenio/active')
  }


}

export default new FuncionarioDataService();
