<template>
  <ListagemBase
    nomePlural="PedidoUniformes"
    nomeSingular="PedidoUniforme"
    :headers="headers"
    stringCadastro="Nova_solicitacao"
    :buscaSimplificada="false"
    :pesquisaPeriodo="true"
    :pesquisaNome="true"
    :pesquisaMatricula="true"
    :pesquisaStatusPedidoUniforme="true"
  >
    <template v-slot:upload> </template>
  </ListagemBase>
</template>

<script>
import ListagemBase from "@/components/base/Crud/ListagemBase";
import DataService from "@/services/recursos/PedidoUniformeDataService";

export default {
  name: "ListagemPedidos",
  components: { ListagemBase },
  data() {
    return {
      DataService: DataService,
      headers: [
        { text: this.$t("Status"), value: "statusPedido", sortable: "false" },
        {
          text: this.$t("Data"),
          value: "dataPedido",
          sortable: "false",
        },
        {
          text: this.$t("Matricula"),
          value: "funcionario.matricula",
          sortable: "false",
        },
        {
          text: this.$t("Funcionario"),
          value: "funcionario.nomeSocial",
          sortable: "false",
        },
        {
          text: this.$t("PostoTrabalho"),
          value: "postoTrabalho.descricao",
          sortable: "false",
        },
        {
          text: this.$t("dataSolicitacao"),
          value: "dtInclusao",
          sortable: "false",
        },
        {
          text: this.$t("Solicitante"),
          value: "solicitante.nome",
          sortable: "false",
        },
        {
          text: this.$t("Atividade"),
          value: "actionsPedidoUniforme",
          sortable: "false",
        },
        { text: this.$t("Acoes"), value: "actions", sortable: "false" },
      ],
    };
  },
};
</script>

<style>
</style>
