<template>
  <ListagemBase
    nomePlural="ModeloDocumentacaoAcessorias"
    nomeSingular="ModeloDocumentacaoAcessoria"
    :headers="headers"
  >
  </ListagemBase>
</template>

<script>
import ModeloDocumentacaoAcessoriaDataService from "@/services/pessoal/ModeloDocumentacaoAcessoriaDataService";
const DataService = ModeloDocumentacaoAcessoriaDataService;

import ListagemBase from "@/components/base/Crud/ListagemBase";

export default {
  name: "ListagemModeloDocumentacaoAcessoria",
  components: { ListagemBase },
  data() {
    return {
      DataService: DataService,

      headers: [
        { text: this.$t("Descricao"), value: "descricao", sortable: "false" },
        { text: this.$t("Valido"), value: "valido", sortable: "false" },
        {
          text: this.$t("impressaoComCabecalho"),
          value: "impressaoComCabecalho",
          sortable: "false",
        },
        { text: this.$t("Acoes"), value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style>
</style>
