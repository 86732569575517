<template>
  <ListagemBase
    nomePlural="FuncionarioSindicatos"
    nomeSingular="FuncionarioSindicato"
    :headers="headers"
  >
    <template v-slot:expandable-content="slotProps">
      <v-row class="pa-4">
        <v-col cols="12">
          <h2>{{ $t("Listagem_de_parametrizacoes") }}</h2>
          <v-data-table
            :headers="headersParametrizacoes"
            :items="slotProps.item.parametrizacoes"
          ></v-data-table>
        </v-col>
        <v-col cols="12">
          <h2>{{ $t("Listagem_de_rubricas") }}</h2>
          <v-data-table
            :headers="headersRubricas"
            :items="slotProps.item.rubricas"
          ></v-data-table>
        </v-col>
      </v-row>
    </template>
  </ListagemBase>
</template>

<script>
import ListagemBase from "@/components/base/Crud/ListagemBase";
import FuncionarioSindicatoDataService from "@/services/pessoal/FuncionarioSindicatoDataService";
const DataService = FuncionarioSindicatoDataService;

export default {
  name: "ListagemFuncionarioSindicato",
  components: { ListagemBase },
  data() {
    return {
      DataService: DataService,

      headers: [
        { text: this.$t("Descricao"), value: "descricao", sortable: "false" },
        { text: this.$t("Status"), value: "statusComum", sortable: "false" },

        { text: this.$t("Acoes"), value: "actions", sortable: false },
      ],

      headersParametrizacoes: [
        { text: this.$t("Referencia"), value: "referencia", sortable: "false" },
        { text: this.$t("Tipo"), value: "tipo", sortable: "false" },
        { text: this.$t("Ativo"), value: "ativo", sortable: "false" },
      ],

      headersRubricas: [
        { text: this.$t("Rubrica"), value: "rubrica", sortable: "false" },
        { text: this.$t("Tipo"), value: "tipo", sortable: "false" },
        {
          text: this.$t("Tipo_apuracao"),
          value: "tipoApuracao",
          sortable: "false",
        },
      ],
    };
  },
};
</script>

<style></style>
