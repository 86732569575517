<template>
  <v-row class="pl-3">
    <template v-for="(i, index) in foneCount">
      <v-col cols="12" md="5" :key="i">
        <!--    <v-text-field
          v-model="telefones[index].fone"
          :label="$t('Telefone') + ' - ' + (index + 1)"
          :name="'telefone' + (index + 1)"
          v-mask="'(##)#####-####'"
          @blur="$emit('telefones', telefones)"
        /> -->

        <v-text-field-simplemask
          @blur="$emit('telefones', telefones)"
          v-model="telefones[index].fone"
          v-bind:label="$t('Telefone') + ' - ' + (index + 1)"
          v-bind:properties="{
            prefix: '',
            suffix: '',
            readonly: false,
            disabled: false,
            outlined: false,
            clearable: true,
            placeholder: '',
          }"
          v-bind:options="{
            inputMask: '(##) #####-####',
            outputMask: '###########',
            empty: null,
            applyAfter: false,
            alphanumeric: true,
            lowerCase: false,
          }"
          v-on:blur="$emit('telefones', telefones)"
        />
      </v-col>

      <v-col cols="12" md="5" :key="'desc' + i">
        <v-text-field
          v-model="telefones[index].descricao"
          :label="$t('Descricao') + ' - ' + (index + 1)"
          :name="'telefone' + (index + 1)"
          @blur="$emit('telefones', telefones)"
        />
      </v-col>
    </template>

    <v-col cols="12" md="2" class="text-left">
      <v-btn
        class="
          input-style
          font-size-input
          text-light-input
          placeholder-light
          input-icon
        "
        dense
        flat
        filled
        solo
        v-if="foneCount > 1"
        @click="removeFone"
      >
        <v-icon dark> mdi-minus </v-icon>
      </v-btn>

      <v-btn
        class="
          ma-1
          font-weight-bold
          text-none
          btn-primary
          bg-gradient-primary
          py-3
          px-6
          ms-3
        "
        @click="addFone"
      >
        <v-icon dark> mdi-plus-circle </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CampoTelefone",

  props: {
    payloadTelefones: {
      type: Array,
      required: false,
      default() {
        return [{ fone: "", descricao: "" }];
      },
    },
  },

  data() {
    return {
      telefones: [{ fone: "", descricao: "" }],
      foneCount: 1,
    };
  },

  mounted() {
    this.telefones = this.payloadTelefones;
    this.foneCount = this.payloadTelefones.length;
  },

  methods: {
    addFone() {
      this.foneCount++;
      this.telefones.push({ fone: "", descricao: "" });
      this.$emit("telefones", this.telefones);
    },

    removeFone() {
      this.foneCount--;
      this.telefones.pop();
      this.$emit("telefones", this.telefones);
    },
  },
};
</script>

<style></style>
