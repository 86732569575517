<template>
  <EditarBase
    nomeSingular="Pedido"
    nomePlural="Pedidos"
    @recurso="recurso = $event"
    @submitSuccess="emitSubmitSuccess"
    @submitError="emitSubmitError"
    @closeModalEdit="closeModalEdit"
  >
    <v-container id="user-profile" fluid tag="section">
      <v-dialog v-model="modalTabelaProdutos" max-width="700">
        <base-material-card class="px-5 py-3 mt-5">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ $t("Moedas") }}
            </div>

            <div class="subtitle-1 font-weight-light">
              {{ $t("Clique_para_adicionar") }}
            </div>
          </template>
          <v-card-text>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <v-data-table
              :headers="headers"
              :items="listaMoedas"
              :search="search"
              @click:row="handleClick"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="editCliente(item.id)"
                  >ni-ruler-pencil</v-icon
                >
                <v-icon small @click="confirmaExclusao(item)"
                  >ni-fat-remove</v-icon
                >
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="modalTabelaProdutos = false"
            >
              {{ $t("Fechar") }}
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-dialog>

      <v-dialog
        v-model="formRemoveProduto"
        persistent
        max-width="350"
        class="dialog-deletado"
      >
        <v-card>
          <v-card-title class="headline">
            {{ $t("Confirma_exclusao?") }}
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="formRemoveProduto = false"
            >
              {{ $t("nao") }}
            </v-btn>

            <v-btn color="red darken-1" text @click="removeProduto()">
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="formAddProduto" persistent max-width="350">
        <v-card>
          <v-form @submit.prevent="addProduto">
            <v-card-title class="headline">
              {{ $t("Adicionar_produto") }} <br />
              <v-btn class="ma-2" color="green" text-color="white">
                {{ $t(this.produto.tipoMoeda) }}
              </v-btn>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    type="number"
                    :label="$t('Quantidade')"
                    v-model="quantidade"
                    class="purple-input"
                    min="1"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                height="39"
                class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
                @click="limpaFormProduto"
              >
                {{ $t("Cancelar") }}
              </v-btn>

              <v-btn
                class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                height="39"
                @click.prevent="addProduto"
              >
                {{ $t("Adicionar") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <v-row justify="center">
        <v-col cols="12" md="12">
          <v-form v-if="!submited" autocomplete="off">
            <v-row>
              <v-col cols="12" md="2" id="campo-dt-inicio">
                <v-span class="opacity-5 text-dark">
                  {{ $t("Data") }}
                </v-span>
                <CampoData
                  :payload="recurso.data"
                  @date="recurso.data = $event"
                  :label="$t('Data')"
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-span class="opacity-5 text-dark">
                  {{ $t("Status") }}
                </v-span>
                <v-select
                  :items="[
                    { text: $t('Pendente'), value: 'PENDENTE' },
                    { text: $t('Roteirizado'), value: 'ROTEIRIZADO' },
                    { text: $t('Cancelado'), value: 'CANCELADO' },
                  ]"
                  v-model="recurso.statusPedido"
                  :label="$t('Status')"
                  disabled
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-span class="opacity-5 text-dark">
                  {{ $t("Classificacao") }}
                </v-span>
                <v-select
                  :items="[
                    { text: $t('ROTINEIRO'), value: 'ROTINEIRO' },
                    { text: $t('EVENTUAL'), value: 'EVENTUAL' },
                    { text: $t('ESPECIAL'), value: 'ESPECIAL' },
                    { text: $t('ASSISTENCIA'), value: 'ASSISTENCIA' },
                  ]"
                  v-model="recurso.classificacao"
                  :label="$t('Classificacao')"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-span class="opacity-5 text-dark">
                  {{ $t("Cliente") }}
                </v-span>
              </v-col>
              <v-col cols="12" md="12">
                <v-autocomplete
                  outlined
                  v-model="recurso.cliente"
                  :items="clientes"
                  :item-text="
                    (item) => item.fantasia + ' - ' + item.razaoSocial
                  "
                  :label="$t('Cliente')"
                  return-object
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="12">
                <v-span class="opacity-5 text-dark">
                  {{ $t("Origem") }}
                </v-span>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  outlined
                  v-model="clienteOrigem"
                  :items="clientes"
                  item-text="fantasia"
                  label="Cliente"
                  return-object
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  disabled
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="5">
                <v-autocomplete
                  outlined
                  :label="$t('Endereco')"
                  v-model="recurso.origem"
                  :items="clienteOrigem.enderecos"
                  :item-text="
                    (item) =>
                      item.descricao +
                      ' - ' +
                      item.logradouro +
                      ', ' +
                      item.numero +
                      ', ' +
                      item.bairro +
                      ' - ' +
                      item.cidade.nome +
                      '/' +
                      item.cidade.estado.sigla
                  "
                  return-object
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  disabled
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="2">
                <CampoDataHora
                  @date="recurso.janelaOrigemInicio = $event"
                  name="janelaOrigemInicio"
                  :label="$t('janelaOrigemInicio')"
                  :payload="recurso.janelaOrigemInicio"
                />
              </v-col>
              <v-col cols="12" md="2">
                <CampoDataHora
                  @date="recurso.janelaOrigemFinal = $event"
                  name="janelaOrigemFinal"
                  :label="$t('janelaOrigemFinal')"
                  :payload="recurso.janelaOrigemFinal"
                />
              </v-col>

              <v-col
                cols="12"
                md="12"
                v-if="recurso.classificacao !== 'ASSISTENCIA'"
              >
                <v-span class="opacity-5 text-dark">
                  {{ $t("Destino") }}
                </v-span>
              </v-col>
              <v-col
                cols="12"
                md="3"
                v-if="recurso.classificacao !== 'ASSISTENCIA'"
              >
                <v-autocomplete
                  outlined
                  v-model="clienteDestino"
                  :items="clientes"
                  item-text="fantasia"
                  label="Destino"
                  return-object
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  disabled
                >
                </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                md="5"
                v-if="recurso.classificacao !== 'ASSISTENCIA'"
              >
                <v-autocomplete
                  outlined
                  :label="$t('Endereco')"
                  v-model="recurso.destino"
                  :items="clienteDestino.enderecos"
                  :item-text="
                    (item) =>
                      item.descricao +
                      ' - ' +
                      item.logradouro +
                      ', ' +
                      item.numero +
                      ', ' +
                      item.bairro +
                      ' - ' +
                      item.cidade.nome +
                      '/' +
                      item.cidade.estado.sigla
                  "
                  return-object
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  disabled
                >
                </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                md="2"
                v-if="recurso.classificacao !== 'ASSISTENCIA'"
              >
                <CampoDataHora
                  @date="recurso.janelaDestinoInicio = $event"
                  name="janelaDestinoInicio"
                  :label="$t('janelaDestinoInicio')"
                  :payload="recurso.janelaDestinoInicio"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                v-if="recurso.classificacao !== 'ASSISTENCIA'"
              >
                <CampoDataHora
                  @date="recurso.janelaDestinoFinal = $event"
                  name="janelaDestinoFinal"
                  :label="$t('janelaDestinoFinal')"
                  :payload="recurso.janelaDestinoFinal"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-span class="opacity-5 text-dark">
                  {{ $t("Averbacao") }}
                </v-span>
                <v-text-field
                  v-model="recurso.averbacao"
                  :label="$t('Averbacao')"
                  name="averbacao"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-span class="opacity-5 text-dark">
                  {{ $t("SolicitacaoCliente") }}
                </v-span>
                <v-text-field
                  v-model="recurso.solicitacaoCliente"
                  :label="$t('SolicitacaoCliente')"
                  name="solicitacaoCliente"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <CriaSuperSet
                  :podeEditarRecursoFinal="true"
                  @recursos="recurso.itemsLogistica = $event"
                  :payload="recurso.itemsLogistica"
                  :usaRecursoBase="false"
                  tituloFinais="ItensLogistica"
                  subtituloFinais="Clique_para_adicionar"
                  :headersFinais="[
                    {
                      text: this.$t('Nota'),
                      value: 'documentoNumero',
                      sortable: 'false',
                    },
                    {
                      text: this.$t('OrdemCarga'),
                      value: 'ordemCarga',
                      sortable: 'false',
                    },
                    {
                      text: this.$t('Valor'),
                      value: 'valor',
                      sortable: 'false',
                    },
                    {
                      text: this.$t('Identificador'),
                      value: 'identificador',
                      sortable: 'false',
                    },

                    {
                      text: this.$t('Acoes'),
                      value: 'actions',
                      sortable: 'false',
                    },
                  ]"
                  textoBotaoCadastro="Criar_item"
                >
                  <template v-slot:default="slotProps">
                    <!-- Form cria recurso final -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.documentoNumero"
                        :label="$t('numeroDocumento')"
                        name="documentoNumero"
                        :rules="rule_required"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.documentoSerie"
                        :label="$t('serieDocumento')"
                        name="documentoSerie"
                        :rules="documentoSerie"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="5">
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.ordemCarga"
                        :label="$t('OrdemCarga')"
                        name="ordemCarga"
                        :rules="ordemCarga"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.volumes"
                        :label="$t('Volumes')"
                        name="volumes"
                        :rules="rule_required"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                        type="number"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field-money
                        v-model="slotProps.recursoFinalAtual.m3"
                        v-bind:label="$t('M3')"
                        v-bind:properties="{
                          prefix: '',
                          readonly: false,
                          disabled: false,
                          outlined: false,
                          clearable: true,
                          rules: required,
                        }"
                        v-bind:options="{
                          locale: 'pt-BR',
                          length: 12,
                          precision: 2,
                          empty: '0',
                        }"
                        class="font-size-input text-light-input placeholder-light"
                        dense
                        flat
                        filled
                        solo
                      />
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field-money
                        v-model="slotProps.recursoFinalAtual.peso"
                        v-bind:label="$t('PesoKg')"
                        v-bind:properties="{
                          prefix: '',
                          readonly: false,
                          disabled: false,
                          outlined: false,
                          clearable: true,
                          rules: required,
                        }"
                        v-bind:options="{
                          locale: 'pt-BR',
                          length: 12,
                          precision: 2,
                          empty: '0',
                        }"
                        class="font-size-input text-light-input placeholder-light"
                        dense
                        flat
                        filled
                        solo
                      />
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field-money
                        v-model="slotProps.recursoFinalAtual.valor"
                        v-bind:label="$t('Valor')"
                        v-bind:properties="{
                          prefix: 'R$',
                          readonly: false,
                          disabled: false,
                          outlined: false,
                          clearable: true,
                          rules: required,
                        }"
                        v-bind:options="{
                          locale: 'pt-BR',
                          length: 12,
                          precision: 2,
                          empty: '0',
                        }"
                        class="font-size-input text-light-input placeholder-light"
                        dense
                        flat
                        filled
                        solo
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.chaveNfe"
                        :label="$t('ChaveNfe')"
                        name="chaveNfe"
                        :rules="chaveNfe"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.chaveCte"
                        :label="$t('ChaveCte')"
                        name="chaveCte"
                        :rules="chaveCte"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.identificador"
                        :label="$t('Identificador')"
                        name="identificador"
                        :rules="identificador"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>
                  </template>
                  <template v-slot:formEditaRecursoFinal="slotProps">
                    <!-- Form cria recurso final -->
                    <v-col cols="12" md="4">
                      <v-span class="opacity-5 text-dark">
                        {{ $t("numeroDocumento") }}
                      </v-span>
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.documentoNumero"
                        :label="$t('numeroDocumento')"
                        name="documentoNumero"
                        :rules="rule_required"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-span class="opacity-5 text-dark">
                        {{ $t("serieDocumento") }}
                      </v-span>
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.documentoSerie"
                        :label="$t('serieDocumento')"
                        name="documentoSerie"
                        :rules="documentoSerie"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="5">
                      <v-span class="opacity-5 text-dark">
                        {{ $t("OrdemCarga") }}
                      </v-span>
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.ordemCarga"
                        :label="$t('OrdemCarga')"
                        name="ordemCarga"
                        :rules="ordemCarga"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-span class="opacity-5 text-dark">
                        {{ $t("Volumes") }}
                      </v-span>
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.volumes"
                        :label="$t('Volumes')"
                        name="volumes"
                        :rules="rule_required"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                        type="number"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field-money
                        v-model="slotProps.recursoFinalAtual.m3"
                        v-bind:label="$t('M3')"
                        v-bind:properties="{
                          prefix: '',
                          readonly: false,
                          disabled: false,
                          outlined: false,
                          clearable: true,
                          rules: required,
                        }"
                        v-bind:options="{
                          locale: 'pt-BR',
                          length: 12,
                          precision: 2,
                          empty: '0',
                        }"
                        class="font-size-input text-light-input placeholder-light"
                        dense
                        flat
                        filled
                        solo
                      />
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field-money
                        v-model="slotProps.recursoFinalAtual.peso"
                        v-bind:label="$t('PesoKg')"
                        v-bind:properties="{
                          prefix: '',
                          readonly: false,
                          disabled: false,
                          outlined: false,
                          clearable: true,
                          rules: required,
                        }"
                        v-bind:options="{
                          locale: 'pt-BR',
                          length: 12,
                          precision: 2,
                          empty: '0',
                        }"
                        class="font-size-input text-light-input placeholder-light"
                        dense
                        flat
                        filled
                        solo
                      />
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field-money
                        v-model="slotProps.recursoFinalAtual.valor"
                        v-bind:label="$t('Valor')"
                        v-bind:properties="{
                          prefix: 'R$',
                          readonly: false,
                          disabled: false,
                          outlined: false,
                          clearable: true,
                          rules: required,
                        }"
                        v-bind:options="{
                          locale: 'pt-BR',
                          length: 12,
                          precision: 2,
                          empty: '0',
                        }"
                        class="font-size-input text-light-input placeholder-light"
                        dense
                        flat
                        filled
                        solo
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-span class="opacity-5 text-dark">
                        {{ $t("ChaveNfe") }}
                      </v-span>
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.chaveNfe"
                        :label="$t('ChaveNfe')"
                        name="chaveNfe"
                        :rules="chaveNfe"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-span class="opacity-5 text-dark">
                        {{ $t("ChaveCte") }}
                      </v-span>
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.chaveCte"
                        :label="$t('ChaveCte')"
                        name="chaveCte"
                        :rules="chaveCte"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-span class="opacity-5 text-dark">
                        {{ $t("Identificador") }}
                      </v-span>
                      <v-text-field
                        v-model="slotProps.recursoFinalAtual.identificador"
                        :label="$t('Identificador')"
                        name="identificador"
                        :rules="identificador"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                      >
                      </v-text-field>
                    </v-col>
                  </template>
                </CriaSuperSet>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
                v-if="recurso.classificacao !== 'ASSISTENCIA'"
              >
                <div>
                  <div class="card-header-padding">
                    <div>
                      <h5 class="font-weight-bold text-h5 text-typo mb-0">
                        {{ $t("Listagem_de_composicao") }}
                      </h5>
                    </div>
                    <div class="d-flex align-center">
                      <v-col cols="12" md="5">
                        <!-- <v-text-field
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                          height="39"
                          v-model="search"
                          :label="$t('Procurar')"
                        >
                          <template slot="prepend-inner">
                            <v-icon color="#adb5bd" size=".875rem"
                              >fas fa-search</v-icon
                            >
                          </template></v-text-field
                        > -->
                      </v-col>
                    </div>
                    <!-- <v-col cols="12">
                      <div class="font-weight-bold text-h6 text-typo mb-0">
                        <p id="saldo">
                          Subtotal:
                          {{ subTotalValor | formatoMonetario }}
                        </p>
                      </div>
                    </v-col> -->
                    <v-col cols="12">
                      <div class="font-weight-bold text-h6 text-typo mb-0">
                        <p id="saldo">
                          Subtotal:
                          {{ subTotalValor | formatoMonetario }}
                        </p>
                      </div>
                    </v-col>
                  </div>

                  <v-data-table
                    :headers="headersPedido"
                    :items="recurso.composicao"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon @click="confirmaRemoveProduto(item)"
                        >ni-fat-remove</v-icon
                      >
                    </template>
                    <template v-slot:[`item.tipoMoeda`]="{ item }">
                      {{ $t(item.tipoMoeda) }}
                    </template>
                  </v-data-table>
                  <v-card-actions>
                    <v-btn
                      @click="modalTabelaProdutos = true"
                      class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                      height="39"
                    >
                      <v-icon left dark> mdi-plus-circle </v-icon>
                      {{ $t("Adicionar_composicao") }}
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions>
                    <!-- Total: R$ {{ this.valorPedido }} -->
                  </v-card-actions>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="12"
                v-if="recurso.classificacao === 'ASSISTENCIA'"
              >
                <div>
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      {{ $t("Listagem_tipos") }}
                    </h5>
                    <p class="text-sm text-body mb-0">
                      {{ $t("Clique_para_adicionar") }}
                    </p>
                  </div>
                  <div class="d-flex align-center">
                    <v-col cols="12" md="5">
                      <v-text-field
                        hide-details
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                        height="39"
                        v-model="search"
                        :label="$t('Procurar')"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >fas fa-search</v-icon
                          >
                        </template></v-text-field
                      >
                    </v-col>
                  </div>

                  <v-data-table
                    :headers="headersAssistenciaTipos"
                    :items="assistenciaTipos"
                    :search="search"
                    @click:row="handleClickTipoAssistencia"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="editCliente(item.id)"
                        >ni-ruler-pencil</v-icon
                      >
                      <v-icon small @click="confirmaExclusao(item)"
                        >ni-fat-remove</v-icon
                      >
                    </template>
                  </v-data-table>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="12"
                v-if="recurso.classificacao === 'ASSISTENCIA'"
              >
                <div>
                  <div class="card-header-padding">
                    <div>
                      <h5 class="font-weight-bold text-h5 text-typo mb-0">
                        {{ $t("Listagem_tipos") }}
                      </h5>
                    </div>
                    <div class="d-flex align-center">
                      <v-col cols="12" md="5">
                        <v-text-field
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                          height="39"
                          v-model="searchPedido"
                          :label="$t('Procurar')"
                        >
                          <template slot="prepend-inner">
                            <v-icon color="#adb5bd" size=".875rem"
                              >fas fa-search</v-icon
                            >
                          </template></v-text-field
                        >
                      </v-col>
                    </div>
                  </div>

                  <v-data-table
                    :headers="headersItemsAssistenciaTipos"
                    :items="recurso.assistenciaTecnicaTipo"
                    :search="searchPedido"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small @click="removeTipoAssistenciaTecnica(item)"
                        >ni-fat-remove</v-icon
                      >
                    </template>
                  </v-data-table>
                  <v-card-actions>
                    <!-- Total: R$ {{ this.valorSuprimento }} -->
                  </v-card-actions>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </EditarBase>
</template>

<script>
import EditarBase from "@/components/base/Crud/EditarBase";

import CriaSuperSet from "@/components/base/proprios/CriaSuperset.vue";
import ClienteDataService from "@/services/comercial/ClienteDataService";
import AssistenciaTecnicaTipoDataService from "@/services/operacoes/AssistenciaTecnicaTipoDataService";
import PedidoDataService from "@/services/operacoes/PedidoDataService";
const DataService = PedidoDataService;

export default {
  name: "EditarPedido",
  components: { EditarBase, CriaSuperSet },
  props: {
    recurso: Object,
  },
  data() {
    return {
      DataService: DataService,

      modalTabelaProdutos: false,
      submited: false,
      recurso: {
        ...this.recurso,
        assistenciaTecnicaTipo: this.recurso.assistenciaTecnicaTipo || [],
      },
      clienteOrigem: {
        enderecos: [],
      },
      clienteDestino: {
        enderecos: [],
      },
      pedido: [],
      produtos: [],
      search: "",
      clientes: [],
      cliente: {},
      todosPerfisDescricao: [],
      perfisSelecionados: [],
      todosPerfis: [],
      errors: [],
      produto: { tipoMoeda: "" },
      formAddProduto: false,
      formRemoveProduto: false,
      quantidade: 0,
      assistenciaTecnicaTipo: [],
      tipoAssistenciaTecnica: {},
      headers: [
        {
          text: this.$t("Descricao"),
          value: "desc",
          align: "start",
          sortable: "false",
        },
      ],
      headersPedido: [
        {
          text: this.$t("Descricao"),
          value: "tipoMoeda",
          align: "start",
          sortable: "false",
        },
        //{ text: this.$t("Valor"), value: "produto.valor", sortable: "true" },
        // {
        //   text: this.$t("Estoque"),
        //   value: "produto.estoque",
        //   sortable: "true",
        // },
        { text: this.$t("Quantidade"), value: "quantidade", sortable: "false" },
        { text: this.$t("Acoes"), value: "actions", sortable: "false" },
      ],
      subTotalValor: 0,
      listaMoedas: [
        {
          tipoMoeda: "BRAPF001C",
          desc: "R$ 1,00 Primeira Familia",
          value: 1.0,
        },
        {
          tipoMoeda: "BRAPF002C",
          desc: "R$ 2,00 Primeira Familia",
          value: 2.0,
        },
        {
          tipoMoeda: "BRAPF005C",
          desc: "R$ 5,00 Primeira Familia",
          value: 5.0,
        },
        {
          tipoMoeda: "BRAPF010C",
          desc: "R$ 10,00 Primeira Familia",
          value: 10.0,
        },
        {
          tipoMoeda: "BRAPF011C",
          desc: "R$ 10,00 Primeira Familia Polimero",
          value: 10.0,
        },
        {
          tipoMoeda: "BRAPF020C",
          desc: "R$ 20,00 Primeira Familia",
          value: 20.0,
        },
        {
          tipoMoeda: "BRAPF050C",
          desc: "R$ 50,00 Primeira Familia",
          value: 50.0,
        },
        {
          tipoMoeda: "BRAPF100C",
          desc: "R$ 100,00 Primeira Familia",
          value: 100.0,
        },
        {
          tipoMoeda: "BRAPF001M",
          desc: "R$ 0,01 Primeira Familia Moeda",
          value: 0.01,
        },
        {
          tipoMoeda: "BRAPF005M",
          desc: "R$ 0,05 Primeira Familia Moeda",
          value: 0.05,
        },
        {
          tipoMoeda: "BRAPF010M",
          desc: "R$ 0,10 Primeira Familia Moeda",
          value: 0.1,
        },
        {
          tipoMoeda: "BRAPF025M",
          desc: "R$ 0,25 Primeira Familia Moeda",
          value: 0.25,
        },
        {
          tipoMoeda: "BRAPF050M",
          desc: "R$ 0,50 Primeira Familia Moeda",
          value: 0.5,
        },
        {
          tipoMoeda: "BRASF002C",
          desc: "R$ 2,00 Segunda Familia",
          value: 2.0,
        },
        {
          tipoMoeda: "BRASF005C",
          desc: "R$ 5,00 Segunda Familia",
          value: 5.0,
        },
        {
          tipoMoeda: "BRASF010C",
          desc: "R$ 10,00 Segunda Familia",
          value: 10.0,
        },
        {
          tipoMoeda: "BRASF020C",
          desc: "R$ 20,00 Segunda Familia",
          value: 20.0,
        },
        {
          tipoMoeda: "BRASF050C",
          desc: "R$ 50,00 Segunda Familia",
          value: 50.0,
        },
        {
          tipoMoeda: "BRASF100C",
          desc: "R$ 100,00 Segunda Familia",
          value: 100.0,
        },
        {
          tipoMoeda: "BRASF200C",
          desc: "R$ 200,00 Segunda Familia",
          value: 200.0,
        },
        {
          tipoMoeda: "BRASF001M",
          desc: "R$ 0,01 Segunda Familia Moeda",
          value: 0.01,
        },
        {
          tipoMoeda: "BRASF005M",
          desc: "R$ 0,05 Segunda Familia Moeda",
          value: 0.05,
        },
        {
          tipoMoeda: "BRASF010M",
          desc: "R$ 0,10 Segunda Familia Moeda",
          value: 0.1,
        },
        {
          tipoMoeda: "BRASF025M",
          desc: "R$ 0,25 Segunda Familia Moeda",
          value: 0.25,
        },
        {
          tipoMoeda: "BRASF050M",
          desc: "R$ 0,50 Segunda Familia Moeda",
          value: 0.5,
        },
        {
          tipoMoeda: "BRASF100M",
          desc: "R$ 1,00 Segunda Familia Moeda",
          value: 1.0,
        },
      ],
      assistenciaTipos: [],
      headersAssistenciaTipos: [
        {
          text: this.$t("Codigo"),
          value: this.$t("codigo"),
          align: "start",
          sortable: "false",
        },
        {
          text: this.$t("Descricao"),
          value: this.$t("descricao"),
          align: "start",
          sortable: "false",
        },
        // { text: this.$t("Valor"), value: "valor", sortable: "true" },
        //{ text: this.$t("Estoque"), value: "estoque", sortable: "true" },
        // { text: this.$t("Status"), value: "statusComum", sortable: "false" },
      ],
      headersItemsAssistenciaTipos: [
        {
          text: this.$t("Codigo"),
          value: this.$t("codigo"),
          align: "start",
          sortable: "false",
        },
        {
          text: this.$t("Descricao"),
          value: this.$t("descricao"),
          align: "start",
          sortable: "false",
        },
        { text: this.$t("Acoes"), value: "actions", sortable: "false" },
      ],
    };
  },
  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccess");
    },
    emitSubmitError() {
      this.$emit("submitError");
    },
    closeModalEdit() {
      this.$emit("closeModalEdit");
    },

    getClientes() {
      ClienteDataService.getAll().then((res) => {
        this.clientes = res.data;

        let clienteEnderecoOrigem = this.recurso.origem;

        if (clienteEnderecoOrigem) {
          this.clienteOrigem = this.clientes.find((item) => {
            return item.enderecos.find(
              (end) => end.id === clienteEnderecoOrigem.id
            );
          });
        }

        let clienteEnderecoDestino = this.recurso.destino;

        if (clienteEnderecoDestino) {
          this.clienteDestino = this.clientes.find((item) => {
            return item.enderecos.find(
              (end) => end.id === clienteEnderecoDestino.id
            );
          });
        }
      });
    },
    getAssitenciaTecnicaTipos() {
      AssistenciaTecnicaTipoDataService.getActive().then((response) => {
        this.assistenciaTipos = response.data;
      });
    },

    handleClick(event) {
      this.produto = event;
      this.formAddProduto = true;
    },

    addProduto() {
      var produtoExistente = this.recurso.composicao.find(
        (produto) => produto.tipoMoeda == this.tipoMoeda
      );
      if (produtoExistente) {
        this.quantidade =
          parseInt(this.quantidade) + parseInt(produtoExistente.quantidade);
        this.recurso.composicao = this.recurso.composicao.filter((produto) => {
          return produto.tipoMoeda !== produtoExistente.tipoMoeda;
        });
      }

      this.recurso.composicao.push({
        tipoMoeda: this.produto.tipoMoeda,
        quantidade: parseInt(this.quantidade),
      });
      this.produto = {};
      this.quantidade = 0;
      this.formAddProduto = false;
      // this.$swal.fire(this.$t("Adicionado_com_sucesso"), "", "success");
    },
    limpaFormProduto() {
      this.formAddProduto = false;
      this.quantidade = 0;
    },
    confirmaRemoveProduto(item) {
      console.log("item", item);
      this.produto = item;
      this.formRemoveProduto = true;
    },
    removeProduto() {
      this.recurso.composicao = this.recurso.composicao.filter((produto) => {
        return produto.tipoMoeda !== this.produto.tipoMoeda;
      });
      this.formRemoveProduto = false;
      // this.$swal.fire(this.$t("Removido_com_sucesso"), "", "success");
    },

    getPedido() {
      DataService.find(this.recurso.id)
        .then((response) => {
          this.recurso.data = response.data.data;
          this.recurso.statusPedido = response.data.statusPedido;
          this.recurso.id = response.data.id;
          this.pedido = response.data.itens;
          console.log(response.data);
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
          this.errors.push({ field: "ID", message: "Pedido não encontrado" });
        });
    },
    getItemClass: function (item) {
      if (item.statusPedido === 1) {
        return "PENDENTE";
      }

      if (item.statusPedido === 2) {
        return "ROTEIRIZADO";
      }

      if (item.statusPedido === 3) {
        return "CANCELADO";
      }
    },
    calcularSubtotal() {
      console.log("calcularSubtotal", this.recurso.composicao);

      this.subTotalValor = this.recurso.composicao.reduce((total, item) => {
        // Encontrar o objeto correspondente em listaMoedas
        const moeda = this.listaMoedas.find(
          (moeda) => moeda.tipoMoeda === item.tipoMoeda
        );

        // Se a moeda existir, calcular o valor do título
        let valorTitulo = moeda ? parseFloat(moeda.value) * item.quantidade : 0;

        return total + valorTitulo;
      }, 0);

      this.subTotalValor = this.subTotalValor.toFixed(6);
    },

    handleClickTipoAssistencia(event) {
      this.tipoAssistenciaTecnica = event;
      this.addTipoAssistencia();
    },
    addTipoAssistencia() {
      console.log("item", this.tipoAssistenciaTecnica);
      console.log("items", this.recurso.assistenciaTecnicaTipo);
      if (this.recurso.assistenciaTecnicaTipo === undefined) {
        this.recurso.assistenciaTecnicaTipo = [{}];
      }

      var produtoExistente = this.recurso.assistenciaTecnicaTipo.find(
        (produto) => produto.id == this.tipoAssistenciaTecnica.id
      );

      if (
        !produtoExistente &&
        this.tipoAssistenciaTecnica.id !== undefined &&
        this.tipoAssistenciaTecnica.id > 0
      ) {
        this.recurso.assistenciaTecnicaTipo.push(this.tipoAssistenciaTecnica);
      }
      this.tipoAssistenciaTecnica = {};
    },

    removeTipoAssistenciaTecnica(event) {
      this.tipoAssistenciaTecnica = event;
      this.recurso.assistenciaTecnicaTipo =
        this.recurso.assistenciaTecnicaTipo.filter((produto) => {
          return produto.id !== this.tipoAssistenciaTecnica.id;
        });
      //this.$swal.fire(this.$t("Removido_com_sucesso"), "", "success");
    },
    verificaTipoAssistencia() {
      // Inicializa recurso.assistenciaTecnicaTipo se não existir
      if (!this.recurso.assistenciaTecnicaTipo) {
        this.$set(this.recurso, "assistenciaTecnicaTipo", []);
      }

      // Verifica se assistenciaTecnicaTipos está presente no recurso
      if (
        this.recurso.assistenciaTecnicaTipos &&
        this.recurso.assistenciaTecnicaTipos.length > 0
      ) {
        // Converte assistenciaTecnicaTipos para o formato necessário
        this.recurso.assistenciaTecnicaTipo =
          this.recurso.assistenciaTecnicaTipos.map((item) => ({
            id: item.assistenciaTecnicaTipo.id,
            descricao: item.assistenciaTecnicaTipo.descricao,
            codigo: item.assistenciaTecnicaTipo.codigo,
          }));
      }
    },
  },

  mounted() {
    if (!this.recurso.assistenciaTecnicaTipo) {
      this.$set(this.recurso, "assistenciaTecnicaTipo", []);
    }
    this.getClientes();
    // this.getPedido();
    this.getAssitenciaTecnicaTipos();
    this.calcularSubtotal();
    this.verificaTipoAssistencia();
  },

  computed: {
    valorPedido: function () {
      var total = 0;
      this.pedido.forEach((item) => {
        total += item.produto.valor ?? 1 * item.quantidade;
      });

      return total;
    },
    agora: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate().toString().padStart(2, "0");

      let minutes = toString(today.getMinutes());
      minutes = minutes.padStart(2, "0");
      const time = today.getHours() + ":" + minutes;
      const dateTime = date + "T" + time;

      return date + "T00:00";
    },
  },
  watch: {
    recurso: {
      handler: "calcularSubtotal",
      deep: true,
    },
  },
};
</script>

<style scoped>
#data {
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 99px;
}

.mdl-textfield:not(.is-dirty) input::-webkit-datetime-edit {
  color: transparent;
}

input:focus::-webkit-datetime-edit {
  color: rgba(255, 255, 255, 0.46) !important;
}
</style>
