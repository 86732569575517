<template>
  <v-app>
    <TutorialsList :positionRelative="false" />
    <drawer
      :drawer="drawer"
      :sidebarColor="sidebarColor"
      :sidebarTheme="sidebarTheme"
    >
    </drawer>
    <v-main>
      <div
        @click="drawer = false"
        v-if="drawer"
        class="position-absolute drawer-state"
      ></div>
      <app-bar
        v-if="$route.name != 'Profile'"
        background="bg-transparent"
        has-bg
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
        :navbarFixed="navbarFixed"
        @toggleSettingsDrawer="toggleSettingsDrawer"
      ></app-bar>
      <app-bar
        v-else-if="$route.name == 'Profile'"
        background="bg-default"
        has-bg
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
      ></app-bar>
      <app-bar
        v-else
        background="primary"
        linkColor="rgba(0,0,0,.6)"
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
      ></app-bar>
      <Transition origin="center top" mode="out-in">
        <!-- your content here -->

        <router-view> </router-view>
      </Transition>
      <content-footer v-if="$route.name !== 'Welcome'"></content-footer>

      <!-- <v-btn
        :ripple="false"
        icon
        rounded
        color="#fff"
        width="35px"
        height="35px"
        class="
          fixed-plugin-button
          position-fixed
          btn-light
          bg-white
          text-dark
          z-index-9999
        "
        @click="showSettingsDrawer = true"
      >
        <v-icon size="20">fa fa-cog py-2</v-icon>
      </v-btn> -->

      <settings-drawer
        :showSettingsDrawer="showSettingsDrawer"
        @toggleSettingsDrawer="toggleSettingsDrawer"
        @updateSidebarColor="updateSidebarColor"
        @updateSidebarTheme="updateSidebarTheme"
        @toggleNavbarPosition="toggleNavbarPosition"
      >
      </settings-drawer>
    </v-main>
    <!-- Ativar Sigame -->
    <!-- <TutorialsList :positionRelative="true" /> -->
  </v-app>
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import AppBar from "@/components/AppBar.vue";
import Drawer from "@/components/Drawer.vue";
import ContentFooter from "@/components/Footer.vue";
import SettingsDrawer from "@/components/Widgets/SettingsDrawer.vue";
import AuthDataService from "@/services/AuthDataService";
import Axios from "axios";
import jwt_decode from "jwt-decode";
//import { Transition } from "vue2-transitions";

export default {
  components: {
    ContentFooter,
    Drawer,
    AppBar,
    SettingsDrawer,
    SkipOptionModal: () =>
      import(
        "../../components/aaTutorialPackage/components/skipOptionModal.vue"
      ),
    GoBackToTutorialRoute: () =>
      import(
        "../../components/aaTutorialPackage/components/GoBackToTutorialRoute.vue"
      ),
  },
  data() {
    return {
      drawer: null,
      showSettingsDrawer: false,
      sidebarColor: "info",
      sidebarTheme: "transparent",
      navbarFixed: false,
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },

    logout: function () {
      this.$store.dispatch("AUTH_LOGOUT").then(() => {
        this.$router.push({ name: "Login" });
      });
    },

    deveFazerRefreshToken: function () {
      if (!this.$store.getters.isAuthenticated) return false;

      var epoch = jwt_decode(this.$store.state.token).exp;
      var expiracao = new Date(0);
      expiracao.setUTCSeconds(epoch);

      var agora = new Date();
      var horaRefresh = new Date(agora.getTime() + 20 * 60000);

      return horaRefresh > expiracao && expiracao > agora;
    },
    getUser() {
      UsuarioDataService.getUser().then((response) => {
        this.user = response.data;
      });
    },
  },
  mounted() {
    this.initScrollbar();
  },

  created() {
    document.addEventListener("tutorialAction", (event) => {
      console.log(`event`, event);
      if (event.detail.action == "breakAll") {
        this.$store.dispatch("SET_PENDING_TUTORIAL", null);
      }
    });

    const deveRedirecionar = (err) => {
      var epoch = jwt_decode(this.$store.state.token).exp;
      var expiracao = new Date(0);
      expiracao.setUTCSeconds(epoch);

      console.log("expiracao" + expiracao);

      if (expiracao < new Date()) {
        return true;
      } else {
        return false;
      }
    };

    Axios.interceptors.response.use(undefined, (err) => {
      return new Promise((resolve, reject) => {
        console.log(err, "status");
        if (deveRedirecionar(err)) {
          // if you ever get an unauthorized, logout the user
          this.logout();
          // you can also redirect to /login if needed !
        }
        throw err;
      });
    });

    Axios.interceptors.response.use(
      (response) => {
        if (this.deveFazerRefreshToken()) {
          AuthDataService.refreshToken().then((response) => {
            this.$store.commit("SET_TOKEN", response.headers.authorization);
          });
        }

        return response;
      },

      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        return Promise.reject(error);
      }
    );
  },
};
</script>
<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.overlay {
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
}

#tutorial-overlay-block {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
}

.tutorial__info-box--textonly {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  border-radius: 16px;
  padding: 1rem 0.5rem;

  width: 75vw;
  max-width: 400px;
}

.tutorial__info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  /*     box-shadow:
    12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3); */
  border-radius: 16px;
  padding: 1rem 0.5rem;
  color: #000;

  width: 75vw;
  max-width: 200px;
  position: relative;
}

.info-box__button {
  background: lightblue;
  padding: 0.5rem 1rem;
  width: 100%;
  max-width: 200px;
  border-radius: 4px;
  font-size: 1.125rem;
  text-align: center;
  margin-top: 1rem;
}

.info-box__title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.4rem;
  color: #000;
}

.info-box__text {
  font-size: 0.9rem;
  color: #3d3d3d;
  line-height: 1.3;

  text-align: center;
}

.tutorial__expandable-content {
  max-height: 0;
  max-width: 0;
  transition: transform 600ms;
  transform: scale(0) translateX(0);
  overflow: hidden;
  background: rgba(255, 255, 255, 0.925);
  border: 1px solid #3d3d3d;
  padding: 0.5rem;
  border-radius: 4px;
  text-align: center;
}

.tutorial__expandable-content--open {
  max-height: 200px;
  max-width: 200px;

  display: block;
  transform: scale(1) translateX(-23px);
}

.tutorial__button-exit {
  background-color: #c91d12;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  right: 0px;

  height: 20px;
  width: 20px;
}

.tutorial__button,
.tutorial__button--outline {
  padding: 1px;
  border: 1px solid #3d3d3d;
  border-radius: 4px;
}
.tutorial__button--red {
  border-color: #c91d12;
  color: #c91d12;
}
.tutorial__button--green {
  border-color: #009933;
  color: #009933;
}

.col-2-center {
  display: grid;
  width: 100%;
  justify-content: center;
  grid-column-gap: 0.5em;
  grid-template-columns: repeat(2, 0.5fr);
}

.tutorial-overlay-loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.tutorial-loading-wheel {
  width: 50px;
  height: 50px;
  margin-top: -40px;
  margin-left: -40px;

  position: absolute;
  top: 50%;
  left: 50%;

  border-width: 1px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
  border-style: solid;
  border-color: #fff #fff transparent;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
</style>
