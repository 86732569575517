<template>
  <div class="ml-5" id="pesquisa-menu">
    <v-text-field
      :label="$t('Pesquisar')"
      hide-details
      v-model="procurar"
      hide-details
      class="
        input-style
        font-size-input
        text-light-input
        placeholder-light
        input-icon
      "
      dense
      flat
      filled
      solo
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn fab small>
          <v-icon ccolor="#fff">mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <v-expand-transition>
      <v-card v-if="procurar.length > 0 && filtrados.length > 0" class="mt-0">
        <v-list dense>
          <v-list-item-group>
            <v-expand-transition v-for="(item, i) in filtrados" :key="i">
              <v-list-item @click="$emit('startTutorial', item.tutorialName)">
                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.subtitle"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-expand-transition>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: "PesquisaTutorial",

  props: {
    payload: { type: Array, required: true },
  },

  data() {
    return {
      itens: [],
      itensNaoFiltrados: [],
      procurar: "",
    };
  },

  computed: {
    itensFiltrados() {
      return this.transformaEmObjetos()
        .filter((item) => {
          let title = this.$t(item.title)
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          return title.toLowerCase().includes(
            this.procurar
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
        })
        .slice(0, 3);
    },

    filtrados() {
      let suitable = [];
      let searchWords = this.procurar.split(" ");

      searchWords = searchWords.map((item) => {
        return item
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      });

      let options = this.payload;

      options.forEach((item) => {
        let keywords = [...item.title.split(" "), ...item.subtitle.split(" ")];
        if (item.keys) {
          keywords = [...keywords, ...item.keys.split(" ")];
        }

        keywords = keywords.map((item) => {
          let obj = {
            value: item
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""),

            hasOcurred: false,
          };

          return obj;
        });

        let intersections = 0;
        keywords.forEach((word) => {
          searchWords.forEach((searchWord) => {
            if (!word.hasOcurred && word.value.includes(searchWord)) {
              word.hasOcurred = true;
              intersections = intersections + 1;

              return;
            } else if (searchWords.includes(word.value)) {
              intersections = intersections + 1;

              return;
            }
          });
        });

        if (intersections > 0) {
          item.intersections = intersections;
          suitable.push(item);
        }
      });

      if (suitable.length > 0) {
        return suitable
          .sort((a, b) => {
            return a.intersections < b.intersections;
          })
          .splice(0, 4);
      } else {
        return [];
      }
    },
  },

  methods: {
    transformaEmObjetos() {
      var objetos = [];

      this.payload.forEach((item) => {
        if (item.children) {
          item.children.forEach((child) => {
            if (child[4]) {
              child[4].forEach((child2) => {
                var object2 = {};
                object2.title = child2[0];
                object2.icon = child2[1];
                object2.to = child2[2];

                objetos.push(object2);
                return;
              });
            }

            if (!child[4]) {
              var object = {};
              object.title = child[0];
              object.icon = child[1];
              object.to = child[2];

              objetos.push(object);
            }
          });
        }
      });

      return objetos;
    },
  },

  mounted() {
    this.itensNaoFiltrados = this.payload;
  },
};
</script>

<style>
</style>