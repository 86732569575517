<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">
            {{ $t("Tem_certeza?") }}
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              height="39"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-secondary
                bg-gradient-light
                py-3
                px-6
              "
              text
              @click="dialog = false"
            >
              {{ this.$t("nao") }}
            </v-btn>
            <v-btn
              class="
                ma-1
                font-weight-bold
                text-none
                btn-primary
                bg-gradient-primary
                py-3
                px-6
                ms-3
              "
              height="39"
              @click="dialog = false"
            >
              {{ this.$t("sim") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ConfirmButton",

  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    showModal: function () {
      this.dialog = true;
    },
  },
};
</script>

<style>
</style>