export const formValidationRules = {
  //Pra funcionar tem que adicionar o evento no campo cep e declarar dataservice como uma
  //propriedade de data no componente

  mounted() {

  },
  data() {
    return {
      required: [v => !!v || this.$t('Campo_obrigatorio') ],
      rule_required: [v => !!v || this.$t('Campo_obrigatorio')],
      required_min10: [
        v => !!v || this.$t('Campo_obrigatorio'),
        v => (v && v.length >= 10) || this.$t('Minimo_de') + ' 10 ' + this.$t("caracteres"),
      ],

      required: [v => !!v || this.$t('Campo_obrigatorio'), ],


      rule_more_than_zero: [v => parseFloat(v) > 0 || this.$t('Campo_obrigatorio')]
    };
  },
  methods: {

  }
};
