<template>
  <v-row>
    <v-col cols="12" md="6" id="inicio">
      <v-dialog v-model="modalInicio" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            prepend-icon="mdi-clock-check"
            :label="$t('Inicio')"
            v-bind="attrs"
            v-on="on"
            :value="payload.inicio | dataParaLeitura"
          >
          </v-text-field>
        </template>

        <v-card class="rounded-lg">
          <v-card-title class="headline green pa-4 rounded">
            Editar inicio
          </v-card-title>

          <v-card-text>
            <CampoDataHora
              @date="novaDataInicio = $event"
              name="novaDataInicio"
              label="DataInicio"
            />
            <v-text-field
              v-model="justificativaInicio"
              :label="$t('Justificativa')"
            >
            </v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="modalInicio = false"
            >
              {{ this.$t("Cancelar") }}
            </v-btn>
            <v-btn
              :disabled="justificativaInicio.length < 5"
              color="primary"
              text
              @click="salvaInicio()"
              >{{ this.$t("Confirmar") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>

    <v-col cols="12" md="6" id="encerramento">
      <v-dialog v-model="modalEncerramento" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            prepend-icon="mdi-clock-end"
            :label="$t('Encerramento')"
            v-bind="attrs"
            v-on="on"
            :value="payload.encerramento | dataParaLeitura"
          >
          </v-text-field>
        </template>

        <v-card>
          <v-card-title class="headline green pa-4 rounded">
            Editar encerramento
          </v-card-title>

          <v-card-text>
            <!-- <input
              step="1"
              type="datetime-local"
              v-model="novaDataEncerramento"
              id="data"
            /> -->
            <CampoDataHora
              @date="novaDataEncerramento = $event"
              name="novaDataEncerramento"
              label="Data_de_encerramento"
            />
            <v-text-field
              v-model="justificativaEncerramento"
              :label="$t('Justificativa')"
            >
            </v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              text
              @click="modalEncerramento = false"
            >
              {{ this.$t("Cancelar") }}
            </v-btn>
            <v-btn
              :disabled="justificativaEncerramento.length < 5"
              color="primary"
              text
              @click="salvaEncerramento()"
              >{{ this.$t("Confirmar") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EditaDataFuncionarioPontoDetalhes",
  props: { payload: { required: true } },
  data() {
    return {
      modalInicio: false,
      modalEncerramento: false,

      novaDataInicio: "",
      justificativaInicio: "",

      novaDataEncerramento: "",
      justificativaEncerramento: "",
    };
  },

  computed: {
    disabledInicio: function () {},
  },

  mounted() {
    if (this.payload.inicio)
      this.novaDataInicio = this.payload.inicio.replace(" ", "T");

    if (this.payload.encerramento)
      this.novaDataEncerramento = this.payload.encerramento.replace(" ", "T");
  },

  methods: {
    salvaInicio: function () {
      let original = this.payload;
      // original.funcionarioPontoDetalhes =
      //   original.funcionarioPontoDetalhes.filter(
      //     (item) => item.tipoRegistro !== "INICIO"
      //   );
      // original.funcionarioPontoDetalhes.push({
      //   tipoRegistro: "INICIO",
      //   justificativa: this.justificativaInicio,
      // });
      original.justificativaInicio = this.justificativaInicio;
      original.inicio = this.novaDataInicio;

      this.$emit("FuncionarioPonto", original);
      this.modalInicio = false;
    },

    salvaEncerramento: function () {
      let original = this.payload;
      // original.funcionarioPontoDetalhes =
      //   original.funcionarioPontoDetalhes.filter(
      //     (item) => item.tipoRegistro !== "ENCERRAMENTO"
      //   );
      // original.funcionarioPontoDetalhes.push({
      //   tipoRegistro: "ENCERRAMENTO",
      //   justificativa: this.justificativaEncerramento,
      // });
      original.justificativaEncerramento = this.justificativaEncerramento;
      original.encerramento = this.novaDataEncerramento;

      this.$emit("FuncionarioPonto", original);
      this.modalEncerramento = false;
    },
  },
};
</script>

<style scoped>
#data {
  padding: 5px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 99px;
}
</style>
