/**
 * Retorna o(s) numDig Dígitos de Controle Módulo 11 do
 * dado, limitando o Valor de Multiplicação em limMult,
 * multiplicando a soma por 10, se indicado:
 *
 *    Números Comuns:   numDig:   limMult:   x10:
 *      CPF                2         12      true
 *      CNPJ               2          9      true
 *      PIS,C/C,Age        1          9      true
 *      RG SSP-SP          1          9      false
 *
 * @version                V5.0 - Mai/2001~Out/2015
 * @author                 CJDinfo
 * @param  string  dado    String dado contendo o número (sem o DV)
 * @param  int     numDig  Número de dígitos a calcular
 * @param  int     limMult Limite de multiplicação 
 * @param  boolean x10     Se true multiplica soma por 10
 * @return string          Dígitos calculados
 */
const calculaDigitomod11 = function (dado, numDig, limMult, x10) {

  var mult, soma, i, n, dig;

  if (!x10) numDig = 1;
  for (n = 1; n <= numDig; n++) {
    soma = 0;
    mult = 2;
    for (i = dado.length - 1; i >= 0; i--) {
      soma += (mult * parseInt(dado.charAt(i)));
      if (++mult > limMult) mult = 2;
    }
    if (x10) {
      dig = ((soma * 10) % 11) % 10;
    } else {
      dig = soma % 11;
      if (dig == 10) dig = "X";
    }
    dado += (dig);
  }
  return dado.substr(dado.length - numDig, numDig);
};

export const geraPIS = function () {
  let dado = "";
  for (let i = 0; i < 10; i++) {
    dado = dado + Math.floor(Math.random() * 10).toString();
  }

  dado = dado + calculaDigitomod11(dado, 1, 9, true).toString();

  return dado;
}

export const geraRG = function () {
  let dado = "";
  for (let i = 0; i < 8; i++) {
    dado = dado + Math.floor(Math.random() * 10).toString();
  }

  dado = dado + calculaDigitomod11(dado, 1, 9, false).toString();

  return dado;
}

export const geraCNPJ = function () {
  let dado = "";

  for (let i = 0; i < 12; i++) {
    dado = dado + Math.floor(Math.random() * 10).toString();
  }

  dado = dado + calculaDigitomod11(dado, 2, 9, false).toString();

  return dado;
}

export const gera5dig = function () {
  let dado = "";

  for (let i = 0; i < 5; i++) {
    dado = dado + Math.floor(Math.random() * 10).toString();
  }

  return dado
}

/**
 * Checa se todas as keys do objeto parcial tem o mesmo valor no objeto
 * completo, se sim retorna que são iguais
 * 
 * Útil para compara objetos criados pelo front (não tem campo id) com
 * objetos retornados pela API (tem campo ID)
 *
 *
 * @version                V5.0 - Mai/2001~Out/2015
 * @author                 CJDinfo
 * @param  object  partial    objeto parcial (sem todos os campos)
 * @param  object     complete  objeto completo (com todos os campos)
 * @return boolean          Objetos são considerados iguais
 */
export const objectPartiallyEqual = function (partial, complete) {
  console.log(partial)
  let keys = Object.keys(partial);
  let isEqual = true;

  keys.forEach((key) => {
    if (partial[key] !== complete[key]) {
      isEqual = false;
    }
  })

  return isEqual;
}

/****************************
 * Passa numero negativo para remover minutos
 *  */
export const addMinutes = function (minutes, date) {
  return new Date(date.getTime() + minutes * 60000);
}

export const dmy_to_ymd = function (data) {
  var dia = data.split("/")[0];
  var mes = data.split("/")[1];
  var ano = data.split("/")[2];

  return ano + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
  // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}


export const getCtps_numero = function (string) {
  return string.split("/")[0];
}
export const getCtps_serie = function (string) {
  let str = string.split("/")[1];

  str = str.toString();
  str = str.replace(/\$/gi, "");
  str = str.replace(/\,/gi, "");
  str = str.replace(/\./gi, "");
  str = str.replace(/\-/gi, "");
  str = str.replace(/([A-z])/, "");

  return string;
}


export const getCtps_uf = function (string) {
  let str = string.slice(-2);

  switch(str){
    case 'AP':
      return "Amapá"
      break;
    
    case 'GO':
      return "Goiás"
      break;
    
    case 'CE':
      return "Ceará"
      break;
    
    case 'DF':
      return 'Distrito Federal';
      break;

    case 'RJ':
      return 'Rio de janeiro';

    default:
      return 'Distrito Federal'
  }
 
}

export const dataParaLeitura = function(value) {
  const convertDateToUTC = function convertDateToUTC(date) {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  };

  if (!value) return;

  let data = convertDateToUTC(new Date(value));
  // let data = new Date(value + " 00:00:00")
  let string =
    data
      .getDate()
      .toString()
      .padStart(2, "0") +
    "/" +
    (data.getUTCMonth() + 1).toString().padStart(2, "0") +
    "/" +
    data.getFullYear();

  return string;
}