<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        class="header-auth position-relative ma-4 pb-16 pt-16 border-radius-xl"
        :class="this.$route.name == 'Landing' ? 'bg-gradient-primary' : ''"
      >
        <v-img
          src="@/assets/img/shapes/waves-white.svg"
          alt="pattern-lines"
          class="position-absolute opacity-6 start-0 top-0 w-100"
          v-if="this.$route.name == 'Landing'"
        ></v-img>
        <v-container>
          <v-row class="d-flex mt-5">
            <v-col cols="12" md="6" class="mx-auto py-0">
              <div class="logo-box">
                <img
                  :src="require('@/assets/Cajupe_simples.png')"
                  class="hero-image"
                  alt="Hero-image"
                />
              </div>
              <h2
                class="text-h3 font-weight-bold text-white text-center mb-2"
                v-if="this.$route.name == 'Landing'"
              >
                {{ headerTitle() }}
              </h2>
              <h1
                class="
                  text-h1 text-white
                  font-weight-bolder
                  text-center
                  mb-2
                  mt-5
                "
                v-else
              >
                {{ headerTitle() }}
              </h1>
              <p
                class="
                  text-white text-h2 text-white
                  font-weight-bolder font-size-root
                  text-center
                  font-weight-thin
                  mb-12
                "
              >
                {{ paragraphs }}
              </p>

              <v-btn
                v-if="this.$route.name == 'Landing'"
                elevation="0"
                height="45"
                to="login"
                depressed
                link
                class="
                  font-weight-bold
                  text-uppercase text-
                  py-2
                  px-5
                  me-2
                  mb-2
                  w-100
                "
                >Clique aqui para acessar</v-btn
              >

              <v-col
                md="6"
                cols="7"
                class="mx-auto text-center mb-14"
                v-if="this.$route.name != 'Pricing'"
              >
              </v-col>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- <app-bar-auth background="transparent" has-bg linkColor="white">
      </app-bar-auth> -->
      <Transition origin="center top" mode="out-in">
        <!-- your content here -->
        <v-container>
          <router-view></router-view>
          <content-footer auth v-if="!$route.meta.hideFooter"></content-footer>
        </v-container>
      </Transition>
    </v-main>
  </v-app>
</template>
<script>
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    ContentFooter,
  },
  data() {
    return {
      paragraphs: "",
      tab: null,
    };
  },
  methods: {
    headerTitle() {
      switch (this.$route.name) {
        case "Landing":
          this.paragraphs = "Gestão Empresarial Completa";
          return "Cajupe®";
        // case "SignUpBasic":
        //   this.paragraphs =
        //     "Use these awesome forms to login or create new account in your project for free.";
        //   return "Welcome!";
        // case "Register":
        //   this.paragraphs =
        //     "Use these awesome forms to login or create new account in your project for free.";
        //   return "Create an account";
        // case "Lock":
        //   this.paragraphs = "Better to be safe than sorry.";
        //   return "Lock screen";
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.9s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.logo-box {
  grid-column: 0;
  align-self: center;
  justify-self: center;

  display: flex;
  flex-direction: column;
  padding: 0 3em;
}

.hero-image {
  max-height: 45vh;
  object-fit: contain;
}
</style>

