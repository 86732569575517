
<template>
  <v-autocomplete clearable  :items="itensCargos" v-model="cargo" :label="$t('Cargo')" item-text="descricao" return-object>
  </v-autocomplete>
</template>


<script>
import DataService from "@/services/ComumDataService";

export default {
  name: "CampoCargo",
  data() {
    return {
      itensCargos: [],
      cargo: {},
    };
  },

  mounted() {
    this.getCargos();
  },

  watch: {
    cargo: function (cargo) {
      this.$emit("funcionarioCargo", {id: cargo.id});
    },
  },

  methods: {
    getCargos() {
      DataService.getCargos()
        .then((response) => {
          this.itensCargos = response.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style>
</style>