<template>
  <ListagemBase
    nomePlural="FuncionarioCargos"
    nomeSingular="FuncionarioCargo"
    :headers="headers"
  >  
  </ListagemBase>
</template>

<script>
import FuncionarioCargoDataService from "@/services/pessoal/FuncionarioCargoDataService"
const DataService = FuncionarioCargoDataService


import ListagemBase from "@/components/base/Crud/ListagemBase"

export default {
  name: "ListagemFuncionarioCargos",
 components: {ListagemBase},
  data() {
    return {
      DataService: DataService,
      
      headers: [
        { text: this.$t("Descricao"), value: "descricao", sortable: "false" },
        { text: this.$t("Status"), value: "statusComum", sortable: "false" },
        {text: this.$t("Salario_base"), value: "salarioBase"},
  
        { text: this.$t("Acoes"), value: "actions", sortable: false },
      ],
    };
  },

 
  
};
</script>

<style>
</style>
