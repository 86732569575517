<template>
  <CadastroBase
    nomePlural="Paises"
    nomeSingular="Pais"
    @submitSuccessInsert="emitSubmitSuccess"
    @submitErrorInsert="emitSubmitError"
    @closeModalInsert="closeModal"
  >
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="recurso.nome"
            :label="$t('Nome')"
            name="nome"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="recurso.sigla"
            :label="$t('Sigla')"
            name="sigla"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="recurso.moeda"
            :label="$t('Moeda')"
            name="sigla"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </CadastroBase>
</template>

<script>
import PaisDataService from "@/services/locais/PaisDataService";
const DataService = PaisDataService;

import CadastroBase from "@/components/base/Crud/CadastroBase";

export default {
  name: "CadastroPais",
  components: { CadastroBase },

  data() {
    return {
      DataService: DataService,

      recurso: {
        nome: "",
        sigla: "",
        moeda: "",
      },
    };
  },

  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccessInsert");
    },
    emitSubmitError() {
      this.$emit("submitErrorInsert");
    },
    closeModal() {
      this.$emit("closeModalInsert");
    },
  },
};
</script>

<style scoped>
</style>
