<template>
  <TabelaAdicionaTrajeto :payload="roteiro.trajetos" :isListagem="true">

  </TabelaAdicionaTrajeto>
</template>

<script>
import TabelaAdicionaTrajeto from "@/components/operacoes/roteiros/TabelaAdicionaTrajeto";

export default {
    name: 'ExpandedRoteiro',

    components: {TabelaAdicionaTrajeto},

    props: {roteiro: {type: Object, required: true}}
}
</script>

<style>

</style>