import Axios from "axios";

class PedidoConsumivelDataService {

  getAll() {
    return Axios.get('pedidoConsumivel');
  }

  getPage(params) {
    return Axios.get('pedidoConsumivel/page', { params })
  }

  create(params) {
    return Axios.post('pedidoConsumivel', params)
  }

  update(params) {
    return Axios.put('pedidoConsumivel/' + (params.id), params)
  }

  find(id) {
    return Axios.get(`pedidoConsumivel/${id}`)
  }

  delete(id) {
    return Axios.delete(`pedidoConsumivel/${id}`);
  }

  getUser() {
    return Axios.get(`usuarios/dados_Usuario`)
  }

  getPermissoes() {
    return Axios.get(`usuarios/permissoes`)
  }

  getProdutos() {
    return Axios.get('produtos/ativos/tipoProduto/2')
  }


  getCentroCustoAnalitico() {
    return Axios.get('centroCustos/tipo/4')
  }

  getProdutosEstoque() {
    return Axios.get('produtos/estoque/tipoProduto/2')
  }

  getPrintSingle(id, params) {
    return Axios.get(`pedidoConsumivel/romaneio/${id}`, { params })
  }

  get(url, params = null) {
    return Axios.get(url, params)
  }

  separacao(params) {
    return Axios.put('pedidoConsumivel/separacao/' + (params.id), params)
  }

  disponibilizado(params) {
    return Axios.put('pedidoConsumivel/disponibilizado/' + (params.id), params)
  }

  // retiradoTerceiro(params) {
  //   return Axios.put('pedidoConsumivel/retiradoTerceiro/' + (params.id), params)
  // }

  recebido(params) {
    return Axios.put('pedidoConsumivel/retirar/' + (params.id), params)
  }

  entregaPendente(params) {
    return Axios.put('pedidoConsumivel/entregaPendente/' + (params.id), params)
  }

  entregaTotal(params) {
    return Axios.put('pedidoConsumivel/entregaTotal/' + (params.id), params)
  }

  entregaParcial(params) {
    return Axios.put('pedidoConsumivel/entregaParcial/' + (params.id), params)
  }

  replicate(params) {
    return Axios.put('pedidoConsumivel/replicate/' + params.item.id)
  }
}

export default new PedidoConsumivelDataService()
