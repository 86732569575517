<template>
  <EditarBase
    nomeSingular="ModeloDocumentacaoAcessoria"
    nomePlural="ModeloDocumentacaoAcessorias"
    @recurso="recurso = $event"
    @submitSuccess="emitSubmitSuccess"
    @submitError="emitSubmitError"
    @closeModalEdit="closeModalEdit"
  >
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-span class="opacity-5 text-dark">
            {{ $t("Descricao") }}
          </v-span>
          <v-text-field
            v-model="recurso.descricao"
            :label="$t('Descricao')"
            name="descricao"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-checkbox v-model="recurso.impressaoComCabecalho">
            <template v-slot:label>
              {{ $t("impressaoComCabecalho") }}
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="6">
          <v-checkbox v-model="recurso.impressaoSemCabecalho">
            <template v-slot:label>
              {{ $t("impressaoSemCabecalho") }}
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" md="4">
          <v-checkbox v-model="recurso.geracaoAutomaticaAdmissao">
            <template v-slot:label>
              {{ $t("geracaoAutomaticaAdmissao") }}
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" md="4">
          <v-checkbox v-model="recurso.geracaoAutomaticaAdmissaoIntermitente">
            <template v-slot:label>
              {{ $t("geracaoAutomaticaAdmissaoIntermitente") }}
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" md="4">
          <v-checkbox v-model="recurso.geracaoAutomaticaAvisoPrevio">
            <template v-slot:label>
              {{ $t("geracaoAutomaticaAvisoPrevio") }}
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" md="4">
          <v-checkbox v-model="recurso.geracaoAutomaticaFerias">
            <template v-slot:label>
              {{ $t("geracaoAutomaticaFerias") }}
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="4">
          <v-checkbox v-model="recurso.geracaoAutomaticaSuspensao">
            <template v-slot:label>
              {{ $t("geracaoAutomaticaSuspensao") }}
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="4">
          <v-checkbox v-model="recurso.geracaoAutomaticaAdvertencia">
            <template v-slot:label>
              {{ $t("geracaoAutomaticaAdvertencia") }}
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" md="12">
          <v-span class="opacity-5 text-dark">
            {{ $t("Texto") }}
          </v-span>
          <v-textarea
            v-model="recurso.texto"
            :label="$t('Texto')"
            :payload="recurso.texto"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-textarea>
        </v-col>
      </v-row>
    </v-col>
  </EditarBase>
</template>

<script>
import EditarBase from "@/components/base/Crud/EditarBase";

import ModeloDocumentacaoAcessoriaDataService from "@/services/pessoal/ModeloDocumentacaoAcessoriaDataService";
const DataService = ModeloDocumentacaoAcessoriaDataService;

export default {
  name: "EditarModeloDocumentacaoAcessoria",
  components: { EditarBase },
  props: {
    recurso: Object,
  },
  data() {
    return {
      DataService: DataService,
      submited: false,

      recurso: {},
      errors: [],

      itensStatusComum: [
        { text: this.$t("Ativo"), value: "ATIVO" },
        { text: this.$t("Inativo"), value: "INATIVO" },
      ],
    };
  },

  computed: {},

  mounted() {
    //this.getRecurso();
  },

  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccess");
    },
    emitSubmitError() {
      this.$emit("submitError");
    },
    closeModalEdit() {
      this.$emit("closeModalEdit");
    },
    getRecurso() {
      DataService.find(this.recurso.id)
        .then((response) => {
          this.recurso.id = response.data.id;
          this.recurso.salarioBase = response.data.salarioBase;
          this.recurso.descricao = response.data.descricao;
          this.recurso.statusComum = response.data.statusComum;
        })
        .catch((error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(error);
          this.errors.push({ field: "ID", message: "Usuário não encontrado" });
        });
    },
  },
};
</script>

<style scoped>
</style>
