<template>
  <v-card elevation="2">
    <v-card-title :id="uuidInputFiltro">
      {{ $t("Despesas") }}
    </v-card-title>

    <v-card-text>
      <v-data-table
        hide-default-footer
        disable-pagination
        :headers="headersApuracaoDia"
        :items="recursosDia"
        :search="searchApuracaoDia"
        :item-class="getItemClass"
        :show-expand="true"
        :single-expand="true"
        class="table"
        @click:row="handleClick"
      >


      <template v-slot:top>
          <v-toolbar flat>
            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <!-- <template v-slot:activator="{ props }">
                <v-btn class="mb-2" color="primary" dark v-bind="props">
                  {{ $t("Criar_item") }}
                </v-btn>
              </template> -->
              <template v-slot:activator="{ props }">
                <v-btn
                  class="mb-2"
                  color="primary"
                  dark
                  :disabled="!buttonSave"
                  @click="update"
                >
                  {{ $t("Salvar") }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field-money
                          v-model="editedItem.valor"
                          v-bind:label="$t('Valor')"
                          v-bind:properties="{
                            prefix: 'R$',
                            readonly: false,
                            disabled: false,
                            outlined: false,
                            clearable: true,
                            rules: required,
                          }"
                          v-bind:options="{
                            locale: 'pt-BR',
                            length: 12,
                            precision: 2,
                            empty: '0',
                          }"
                          class="font-size-input text-light-input placeholder-light"
                          dense
                          flat
                          filled
                          solo
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
                    variant="text"
                    @click="close"
                  >
                  {{ $t("Cancelar") }}
                  </v-btn>
                  <v-btn
                    class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                    variant="text"
                    @click="save"
                  >
                  {{ $t("Enviar") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"></v-card-title>
                  {{ $t("Confirma_exclusao?")}} </v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                  class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
                    variant="text"
                    @click="closeDelete"
                    > {{ $t("Cancelar") }}</v-btn
                  >
                  <v-btn
                  class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                    variant="text"
                    @click="deleteItemConfirm"
                    >{{ $t("Enviar") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template
          v-slot:item.actions="{ item }"
          v-if="item.statusPedido !== 'CONCLUIDO' && item.statusPedido !== 'CANCELADO'" 
        >
          <v-icon class="me-2" size="small" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon size="small" @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>


        <template v-slot:[`item.data`]="{ item }">
          {{ item.data | dataParaLeitura }}
        </template>
        <template v-slot:[`item.valor`]="{ item }">
          {{ item.valor | formatoMonetario }}
        </template>

        <template v-slot:expanded-item="{ item, headers }">
          <td :colspan="headers.length">
            <v-row class="d-flex">
              <v-col cols="12" md="6" sm="6">
                <v-row>
                  <v-col cols="6" style="margin-top: 10%">
                    {{ $t("operador") }}
                    <ShowImage :url="item.imagemOperador">
                      <v-avatar
                        color="primary"
                        size="150"
                        margin-top="15px"
                        :margin-bottom="auto"
                        :margin-left="auto"
                        :margin-right="auto"
                      >
                        <v-img
                          contain
                          max-height="450"
                          max-width="450"
                          :src="item.imagemOperador"
                        ></v-img>
                      </v-avatar>
                    </ShowImage>
                  </v-col>

                  <v-col cols="6" class="" style="margin-top: 10%">
                    {{ $t("Documento") }}
                    <ShowImage :url="item.imagemDocumento">
                      <v-avatar
                        color="primary"
                        size="150"
                        margin-top="15px"
                        :margin-bottom="auto"
                        :margin-left="auto"
                        :margin-right="auto"
                      >
                        <v-img
                          contain
                          max-height="450"
                          max-width="450"
                          :src="item.imagemDocumento"
                        ></v-img>
                      </v-avatar>
                    </ShowImage>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="" style="margin-top: 10%">
                    {{ $t("Descricao") }}: {{ item.descricao }}</v-col
                  >
                </v-row>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <MapaLocalPonto style="max-height: 350px" :item="item" />
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import MapaLocalPonto from "@/components/base/proprios/MapaLocalPonto";
import ShowImage from "@/components/base/proprios/ShowImage";
import listas from "@/mixins/listas";
import DataService from "@/services/ComumDataService";
export default {
  name: "CardApuracoesDetalhesOrcamentoDespesa",
  mixins: [listas],
  components: { MapaLocalPonto, ShowImage },
  data() {
    return {
      searchApuracaoDia: "",
      parametrizacaoManualBancoHoras: false,
      parametrizacaoManualBancoHorasTipoDois: false,
      headersApuracaoDia: [
        {
          text: this.$t("Data"),
          value: "data",
          sortable: "true",
        },
        {
          text: this.$t("Valor"),
          value: "valor",
          sortable: "false",
        },
        {
          text: this.$t("contaFinanceira"),
          value: "contaFinanceira.descricao",
          sortable: "false",
        },
        {
          text: this.$t("centroCusto"),
          value: "centroCusto.descricao",
          sortable: "false",
        },
        { title: "Actions", value: "actions", sortable: false },
      ],

      editedIndex: -1,
      editedItem: {},
    };
  },

  props: {
    item: { required: true },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Item"
        : this.editedIndex.contaFinanceira.descricao;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    getWeekDayFromString(date) {
      console.log("item", date);

      let data = new Date(date);
      console.log("date", data, data.getDay());

      let dia = data.getDay();
      if (dia == 0) {
        return "Segunda";
      }
      if (dia == 1) {
        return "Terca";
      }
      if (dia == 2) {
        return "Quarta";
      }
      if (dia == 3) {
        return "Quinta";
      }
      if (dia == 4) {
        return "Sexta";
      }
      if (dia == 5) {
        return "Sabado";
      }
      if (dia == 6) {
        return "Domingo";
      }
    },

    editItem(item) {
      this.editedIndex = item;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = item;
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var itemExistente = this.item.items.find(
        (item) => item.id == this.editedItem.id
      );
      // this.recurso.itemsPedido = [];

      if (itemExistente) {
        this.item.items = this.item.items.filter((item) => {
          return item.id !== itemExistente.id;
        });
      }
      this.buttonSave = true;
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      console.log("this.editedItem", this.editedItem);
      var itemExistente = this.item.items.find(
        (item) => item.id == this.editedItem.id
      );
      // this.recurso.itemsPedido = [];

      if (itemExistente) {
        this.item.items = this.item.items.filter((item) => {
          return item.id !== itemExistente.id;
        });
      }
      this.item.items.push(this.editedItem);
      // } else {
      //   this.recurso.itemspedido.push(this.editedItem);
      //   console.log("edited 2", this.recurso);
      // }
      this.buttonSave = true;
      this.close();
    },

    update() {
      this.loading = true;
      DataService.put("pedidoOrcamentoDespesas/" + this.item.id, this.item)
        .then(() => {
          this.loading = false;
          this.buttonSave = false;

          this.retrieveData();
        })
        .catch((error) => {
          this.loading = false;

          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          this.erros = error.response.data.errors;
          this.$swal.fire(this.$t("Erro"), this.$t("Tente_novamente"), "error");
        });
    },
  },

  computed: {
    recursosDia() {
      return this.item.items;
    },
  },
  mounted() {},
};
</script>

<style></style>
