<template>
  <div class="tutorial__info-box">
    <p class="info-box__title">Qual informação você quer alterar ?</p>
    <p class="info-box__text">
      Você pode alterar uma ou mais informações de cada vez.
      <br />
      Somente altere as informações necessárias
    </p>

    <v-list dense>
      <v-list-item-group color="primary">
        <v-expand-transition v-for="(item, i) in opcoes" :key="i">
          <v-list-item @click="launchTutorial(item.tutorialName)">
            <v-list-item-content>
              <v-list-item-title v-html="item.title"></v-list-item-title>
              <v-list-item-subtitle
                v-html="item.subtitle"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-expand-transition>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { dispatchAction, startTutorial } from "../main.js";

export default {
  name: "OkInfoBox",
  props: {
    propobject: { required: true },
  },

  data() {
    return {
      opcoes: [
        {
          tutorialName: "Cargo",
          title: "Cargo",
          subtitle: "Alterar o cargo do funcionário selecionado",
        },
        {
          tutorialName: "Escala",
          title: "Escala de trabalho",
          subtitle: "Alterar a escala de trabalho do funcionário selecionado",
        },
        {
          tutorialName: "Sindicato",
          title: "Sindicato",
          subtitle: "Alterar o sindicato do funcionário selecionado",
        },
        {
          tutorialName: "Posto",
          title: "Posto de trabalho",
          subtitle: "Alterar o posto do funcionário selecionado.",
        },
      ],
    };
  },

  mounted() {
    window.addEventListener("tutorial-overlay-click", () => {
      let btn = document.getElementById("okInfoBox-button");

      btn.classList.add("okInfoBox-button-grow");

      setTimeout(() => {
        btn.classList.remove("okInfoBox-button-grow");
      }, 300);
    });
  },

  methods: {
    resolveStep() {
      dispatchAction("resolve");
    },

    launchTutorial(tutorialName) {
      this.resolveStep();

      let selector = "";

      switch (tutorialName) {
        case "Cargo":
          selector = "#expansion-FuncionarioCargo";
          break;

        case "Escala":
          selector = "#expansion-escala_de_trabalho";
          break;

        case "Sindicato":
          selector = "#expansion-sindicato";
          break;

        case "Posto":
          selector = "#expansion-PostoTrabalho";
          break;
      }

      let steps = [
        {
          type: "text",
          boxTitle: "Alterar informação",
          boxText: "Clique aqui para alterar esta informação.",
          selector: selector,
        },

        {
          boxTitle: "Alterar informação",
          boxText:
            "Clique aqui para confirmar a necessidade de alterar esta informação.",
          selector: "#btn-confirmation-alterar",
          type: "text",
        },
        {
          type: "textOnly",
          boxTitle: "Preencha o formulário",
          boxText: `Preencha os campos e caso seja necessário 
                altere as outras informações <br> Depois clique em enviar`,
          clickTarget: false,
        },
      ];

      setTimeout(() => {
        startTutorial(steps);
      }, 500);
    },
  },
};
</script>

<style scoped>
.tutorial__info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  /*     box-shadow: 
    12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3); */
  border-radius: 16px;
  padding: 1rem 0.5rem;
  color: #000;

  width: 75vw;
  max-width: 200px;
  position: relative;
}

.info-box__button {
  background: lightblue;
  padding: 0.5rem 1rem;
  width: 100%;
  max-width: 200px;
  border-radius: 4px;
  font-size: 1.125rem;
  text-align: center;
  margin-top: 1rem;
}

.info-box__title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.4rem;
  color: #000;
}

.info-box__text {
  font-size: 0.9rem;
  color: #3d3d3d;
  line-height: 1.3;

  text-align: center;
}

.info-box__button {
  background: lightblue;
  padding: 0.5rem 1rem;
  width: 100%;
  max-width: 200px;
  border-radius: 4px;
  font-size: 1.125rem;
  text-align: center;
  margin-top: 1rem;

  transition: transform 0.3s;
}

.okInfoBox-button-grow {
  transform: scale(1.1) translateY(-5px);
}
</style>