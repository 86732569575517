import { render, staticRenderFns } from "./CampoHorario.vue?vue&type=template&id=988f94d8&scoped=true"
import script from "./CampoHorario.vue?vue&type=script&lang=js"
export * from "./CampoHorario.vue?vue&type=script&lang=js"
import style0 from "./CampoHorario.vue?vue&type=style&index=0&id=988f94d8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "988f94d8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VTextField})
