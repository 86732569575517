import Axios from "axios";

class FuncionarioFolhaPagamentoVerbaDataService {

  getAll() {
    return Axios.get('funcionarioFolhaPagamentoVerbas');
  }

  getPage(params) {
    return Axios.get('funcionarioFolhaPagamentoVerbas/page', { params })
  }

  create(params) {
    return Axios.post('funcionarioFolhaPagamentoVerbas', params)
  }

  update(params) {
    console.log(params)
    return Axios.put('funcionarioFolhaPagamentoVerbas/' + (params.id), params)
  }

  find(id) {
    return Axios.get(`funcionarioFolhaPagamentoVerbas/${id}`)
  }

  delete(id) {
    return Axios.delete(`funcionarioFolhaPagamentoVerbas/${id}`);
  }
}

export default new FuncionarioFolhaPagamentoVerbaDataService()
