<template>
 <FormEndereco @recursos="recurso.enderecos = $event"></FormEndereco>
</template>

<script>
import DataService from "@/services/ComumDataService";
import BuscaCep from "@/components/base/proprios/BuscaCep";
export default {
  name: "TabelaSelecionaEndereco",

  props: {
    payload: { type: Array, required: false },
  },

  mounted() {
    if (this.payload) {
      if (this.payload.length > 0) {
        this.enderecos = this.payload;
      }
    }
  },

  data() {
    return {
      cidades: [],

      enderecos: [
        {
          cidade: {},
          bairro: "",
          logradouro: "",
          latitude: "",
          longitude: "",
        },
      ],

      enderecoVazio: {
        cidade: {},
        bairro: "",
        logradouro: "",
        latitude: "",
        longitude: "",
      },

      headersEnderecos: [
        { text: this.$t("Descricao"), value: "descricao" },
        { text: this.$t("Logradouro"), value: "logradouro" },
        { text: this.$t("Numero"), value: "numero" },
        { text: this.$t("Bairro"), value: "bairro" },
        { text: this.$t("Cidade"), value: "cidade.nome" },
        { text: this.$t("Acoes"), value: "actions", sortable: "false" },
      ],
    };
  },

  methods: {
    addEndereco() {
      this.enderecos.push({ ...this.enderecoVazio });
    },

    removeEndereco() {
      this.enderecos.pop();
    },

    emit() {
      this.$emit("recursos", this.enderecos);
    },

    buscaGeoCode(index) {
      console.log(index, "index");
      let geocoder = new google.maps.Geocoder();

      let end = this.enderecos[index];
      console.log(end);
      let address =
        end.logradouro + ", " + end.numero + ", " + end.bairro + " " + end.cep;

      geocoder.geocode({ address: address }, (results, status) => {
        if (status == "OK") {
          let lat = results[0].geometry.location.lat();
          let lng = results[0].geometry.location.lng();
          this.enderecos[index].latitude = lat;
          this.enderecos[index].longitude = lng;
        } else {
          console.log(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    },
  },

  watch: {
    enderecos: function (newValue, oldValue) {
      this.$emit("recursos", newValue);
    },
  },
};
</script>

<style>
</style>