<template>
  <div id="map"></div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/OverlappingMarkerSpiderfier/1.0.3/oms.min.js"></script>
<script>
export default {
  name: "MapaLocalPonto",

  props: {
    item: { type: Object, required: true },
  },

  computed: {
    payload: function () {
      console.log("payload", this.item);
      return this.item;
    },
  },

  data() {
    return {
      map: {},
      markers: [],
      oms: {},
    };
  },

  methods: {
    initMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: this.payload.latitude, lng: this.payload.longitude },
        zoom: 7,
      });

      this.oms = new OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true,
      });

      // this.payload.forEach((item) => {
      this.setMarker(
        {
          lat: this.payload.latitude,
          lng: this.payload.longitude,
        }
        // this.$t(item.tipoRegistro) +
        //   " - " +
        //   this.$options.filters.dataParaLeitura(
        //     item.tipoRegistro == "INICIO"
        //       ? this.item.inicio
        //       : this.item.encerramento
        //   ),
        // item.tipoRegistro
      );
      // });

      // var markerCluster = new MarkerClusterer(this.map, this.markers, {
      //   imagePath: `${"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer"}/m`,
      // });
    },

    setMarker(Points, title = "Local") {
      //Criação do marker
      console.log("Points", Points);
      var image = require("@/assets/perm_identity-24px-green.svg");
      // tipoRegistro === "ENCERRAMENTO"
      //   ? require("@/assets/perm_identity-24px-red.svg")
      //   : require("@/assets/perm_identity-24px-green.svg");

      const icon = {
        url: image,
        // This marker is 20 pixels wide by 32 pixels high.
        size: new google.maps.Size(32, 32),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(0, 32),
      };

      const marker = new google.maps.Marker({
        position: Points,
        map: this.map,
        title: title,
        icon: icon,
      });

      this.oms.addMarker(marker);

      this.markers.push(marker);
    },
  },

  mounted() {
    this.initMap();
  },

  watch: {
    payload: function (newValue) {
      this.initMap();
    },
  },
};
</script>

<style scoped>
#map {
  height: 450px;
}
</style>
